import {
	CardType,
	ContainerTemplate,
	ContainerType,
	ContainerTypeSpecificProperties,
	ItemPropertyActionType,
	ItemTemplate,
	ItemType,
	PropertyType,
	ShellCapabilityKey,
} from '@src/graphql/generated';

import { ICON_NAME } from '../icon';

export enum AttributeTypename {
	Campus = 'AppCampus',
	IcalFeed = 'IcalFeed',
	PodcastFeed = 'PodcastFeed',
	RssFeed = 'RssFeed',
	VideoFeed = 'VideoFeed',
	VimeoFeed = 'VimeoFeed',
	YouTubeFeed = 'YouTubeFeed',
}

export type DraggableItemName =
	| 'add_item'
	| 'add_item_event'
	| 'add_item_destination'
	| 'add_item_contact'
	| 'add_item_media'
	| 'add_item_sermon'
	| 'add_item_webview'
	| 'add_item_impact';

export type DraggableContainerName =
	| 'add_container'
	| 'add_container_events'
	| 'add_container_places'
	| 'ccb_check_in'
	| 'ccb_groups'
	| 'ccb_serving'
	| 'ccb_public_needs'
	| 'add_container_give'
	| 'add_container_messages'
	| 'add_container_select_campus'
	| 'add_container_resi_library'
	| 'add_container_studio_c';

export type DraggableAttributeName =
	| 'add_attribute_campus'
	| 'add_attribute_feed_events'
	| 'add_attribute_feed'
	| 'add_attribute_feed_youtube'
	| 'add_attribute_feed_vimeo';

export type DraggablePropertyName =
	| 'add_property_share'
	| 'add_property_give'
	| 'add_property_add_to_contacts'
	| 'add_property_address'
	| 'add_property_app_link'
	| 'add_property_audio'
	| 'add_property_blankify'
	| 'add_property_email'
	| 'add_property_phone'
	| 'add_property_facetime'
	| 'add_property_share'
	| 'add_property_sms'
	| 'add_property_text'
	| 'add_property_text_html'
	| 'add_property_timeframe'
	| 'add_property_user_note'
	| 'add_property_video'
	| 'add_property_youtube'
	| 'add_property_vimeo'
	| 'add_property_website_in'
	| 'add_property_call_to_action'
	| 'add_property_carousel'
	| 'add_property_resi'
	| 'add_property_key_metrics';

export type DraggableIcon = ICON_NAME | 'custom-youtube' | 'custom-campus' | 'close';

export type DraggableType = 'attribute' | 'property' | 'item' | 'container';

export type DraggableName = DraggableItemName | DraggableContainerName | DraggableAttributeName | DraggablePropertyName;

export type DraggableCategory = 'ChMS' | 'Resi' | 'StudioC';

export type DraggableContainerData = {
	icon: string;
	type: ContainerType;
	name: string;
	template: ContainerTemplate;
	relatedContentOn?: boolean;
	typeSpecificProperties?: ContainerTypeSpecificProperties;
};

export type DraggableItemData = {
	icon: string;
	type: ItemType;
	name: string;
	template: ItemTemplate;
};

export type DraggablePropertyData = {
	type: PropertyType;
	header: string;
	icon: string;
	bos?: Record<string, unknown>;
	getPropertySpecificFields?: () => Record<string, unknown>;
	action?: {
		click: ItemPropertyActionType;
	};
	actionBar?: number;
	hideOriginalProperty?: boolean;
};

export type DraggableAttributeData = {
	type: AttributeTypename;
	bos?: Record<string, unknown>;
};

export type CardDefinitionData = {
	preferredCardType: CardType;
	featured: boolean;
	callToActionText: string;
	showProperties: {
		keyMetrics: boolean;
		title: boolean;
	};
	genericContent: Record<string, string>;
};

export type Draggable<
	T = DraggableContainerData | DraggableItemData | DraggablePropertyData | CardDefinitionData | DraggableAttributeData
> = {
	id?: string;
	icon: DraggableIcon;
	label: string;
	name: DraggableName;
	actions: Array<{
		action_type?: 'create';
		model?: 'attribute' | 'property' | 'action' | 'container' | 'item' | 'card_definition';
		data: T;
	}>;
	unique: boolean;
	type: DraggableType;
	getPropertySpecificFields?: () => Record<string, unknown>;
	disabled?: boolean;
	tooltip?: string;
	category?: DraggableCategory;
	requiredShellCapability?: ShellCapabilityKey;
	isSupported?: boolean;
};
