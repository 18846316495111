type ImageUploaderState = {
	imageUrl: string;
	isLoading: boolean;
	isInvalidUrl: boolean;
	isInvalidFileType: boolean;
};

export const initialState: ImageUploaderState = {
	imageUrl: '',
	isLoading: false,
	isInvalidUrl: false,
	isInvalidFileType: false,
};

export const ImageUrlInputActions = {
	SetUrl: 'set_url',
	ResetUrl: 'reset_url',
	ApplyUrlStart: 'apply_url_start',
	ApplyUrlSuccess: 'apply_url_success',
	InvalidUrl: 'invalid_url',
	InvalidFileType: 'invalid_file_type',
} as const;

type ActionTypes = (typeof ImageUrlInputActions)[keyof typeof ImageUrlInputActions];

type ImageUrlInputAction =
	| { type: Extract<ActionTypes, 'set_url'>; imageUrl: string }
	| { type: Exclude<ActionTypes, 'set_url'> };

export function imageUploaderReducer(state: ImageUploaderState, action: ImageUrlInputAction): ImageUploaderState {
	switch (action.type) {
		case ImageUrlInputActions.SetUrl:
			return {
				...state,
				imageUrl: action.imageUrl,
			};
		case ImageUrlInputActions.ApplyUrlStart:
			return {
				...state,
				isLoading: true,
				isInvalidUrl: false,
				isInvalidFileType: false,
			};

		case ImageUrlInputActions.InvalidUrl:
			return {
				...state,
				isLoading: false,
				isInvalidUrl: true,
				isInvalidFileType: false,
			};

		case ImageUrlInputActions.InvalidFileType:
			return {
				...state,
				isLoading: false,
				isInvalidFileType: true,
				isInvalidUrl: false,
			};

		case ImageUrlInputActions.ApplyUrlSuccess:
		case ImageUrlInputActions.ResetUrl:
			return {
				...state,
				imageUrl: '',
				isLoading: false,
				isInvalidUrl: false,
				isInvalidFileType: false,
			};
		default:
			return state;
	}
}
