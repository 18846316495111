import { useMemo } from 'react';

import { clsx } from '@pushpay/styles';
import { useMediaBreakpoint } from '@pushpay/theming';
import Tooltip from '@pushpay/tooltip';
import { ComponentProps } from '@pushpay/types';

import { Draggable, DraggableType } from '@src/components/draggable';
import { useFeature } from '@src/featureFlags';
import { useTranslation } from '@src/i18n';

import { useStyles } from './draggablePaneStyles';
import { EmptyDraggablesIcon } from './EmptyDraggablesIcon';

export type DraggablePaneComponentProps = ComponentProps<
	{
		draggables: DraggableType[];
		isDHSScreen: boolean;
		hasCcb: boolean;
		loading?: boolean;
	},
	typeof useStyles
>;

export const DraggablePaneComponent = ({
	classes: classesProp,
	'data-pp-at-target': targetId,
	draggables,
	hasCcb,
	isDHSScreen,
	loading,
}: DraggablePaneComponentProps) => {
	const { translate } = useTranslation('appDesign');
	const isTablet = useMediaBreakpoint('DESKTOP_AND_BELOW');
	const classes = useStyles(classesProp, {
		isTablet,
	});
	const isStudioCIntegrationEnabled = useFeature('StudioCIntegration');

	const { ppDraggables, ccbDraggables, resiDraggables, studioCDraggables } = useMemo(
		() => ({
			ppDraggables: draggables?.filter(draggable => draggable.category === undefined),
			ccbDraggables: draggables?.filter(draggable => draggable.category === 'ChMS'),
			resiDraggables: draggables?.filter(draggable => draggable.category === 'Resi'),
			studioCDraggables: draggables?.filter(draggable => draggable.category === 'StudioC'),
		}),
		[draggables]
	);

	const otherIntegrationsDraggables = [...resiDraggables, ...studioCDraggables];
	const chmsDraggableDisabled = ccbDraggables.every(draggable => draggable.disabled);
	const resiDraggableDisabled = resiDraggables.every(draggable => draggable.disabled);
	const isAnyResiDraggableSupported = resiDraggables.some(draggable => draggable.isSupported);

	return (
		<fieldset
			className={clsx(classes.root, loading && classes.loading)}
			data-pp-at-target={targetId}
			disabled={loading}
		>
			<p className={classes.title}>{translate('draggables.title')}</p>
			{!isDHSScreen && (
				<div className={classes.hint} data-pp-at-target={`${targetId}-hint`}>
					{translate('draggables.hint')}
				</div>
			)}
			{draggables.length > 0 ? (
				<>
					{ppDraggables.length > 0 && (
						<div data-pp-at-target={`${targetId}-pp-draggables`}>
							<div className={classes.draggableWrapper}>
								{ppDraggables?.map(draggable => (
									<Draggable
										key={draggable.name}
										data-pp-at-target={`${draggable.name}`}
										disabled={isDHSScreen}
										{...draggable}
									/>
								))}
							</div>
						</div>
					)}
					{hasCcb && ccbDraggables.length > 0 && (
						<div data-pp-at-target={`${targetId}-ccb-draggables`}>
							<Tooltip
								className={clsx(
									classes.draggables,
									classes.ccbDraggables,
									chmsDraggableDisabled && classes.disabledDraggables,
									isDHSScreen && classes.dhs
								)}
								content={translate('draggables.disabledChMSDraggableTooltip')}
								disabled={!chmsDraggableDisabled}
								placement="top"
							>
								<p
									className={clsx(
										classes.draggables,
										classes.ccbDraggables,
										isDHSScreen && classes.dhs
									)}
								>
									{translate(isTablet ? 'draggables.ccbAbbr' : 'draggables.ccbDraggables')}
								</p>
							</Tooltip>
							<div className={classes.draggableWrapper}>
								{ccbDraggables?.map(({ disabled, ...draggable }) => (
									<Draggable
										key={draggable.name}
										data-pp-at-target={`${draggable.name}`}
										disabled={isDHSScreen || disabled}
										{...draggable}
									/>
								))}
							</div>
						</div>
					)}
					{!isStudioCIntegrationEnabled && resiDraggables.length > 0 && (
						<div data-pp-at-target={`${targetId}-resi-draggables`}>
							<Tooltip
								className={clsx(
									classes.draggables,
									classes.resiDraggables,
									resiDraggableDisabled && classes.disabledDraggables,
									isDHSScreen && classes.dhs
								)}
								content={translate('draggables.unsupportedResiDraggableTooltip')}
								disabled={isAnyResiDraggableSupported}
								placement="top"
							>
								<p
									className={clsx(
										classes.draggables,
										classes.resiDraggables,
										isDHSScreen && classes.dhs
									)}
								>
									{translate('draggables.resiDraggable')}
								</p>
							</Tooltip>
							<div className={classes.draggableWrapper}>
								{resiDraggables?.map(({ disabled, ...draggable }) => (
									<Draggable
										key={draggable.name}
										data-pp-at-target={`${draggable.name}`}
										disabled={isDHSScreen || disabled}
										{...draggable}
									/>
								))}
							</div>
						</div>
					)}
					{isStudioCIntegrationEnabled && otherIntegrationsDraggables.length > 0 && (
						<div data-pp-at-target={`${targetId}-other-integrations-draggables`}>
							<p
								className={clsx(
									classes.draggables,
									classes.otherIntegrationsDraggables,
									isDHSScreen && classes.dhs
								)}
							>
								{translate('draggables.otherIntegrations')}
							</p>
							<div className={classes.draggableWrapper}>
								{otherIntegrationsDraggables?.map(({ disabled, ...draggable }) => (
									<Draggable
										key={draggable.name}
										data-pp-at-target={`${draggable.name}`}
										disabled={isDHSScreen || disabled}
										{...draggable}
									/>
								))}
							</div>
						</div>
					)}
				</>
			) : (
				<div className={classes.emptyDraggablesWrapper} data-pp-at-target={`${targetId}-empty-draggables`}>
					<div>
						<EmptyDraggablesIcon
							className={classes.emptyDraggablesIcon}
							data-pp-at-target={`${targetId}-empty-draggables-icon`}
							title={translate('draggables.emptyDraggables')}
						/>
					</div>
					<p className={classes.emptyDraggablesLabel}>
						{translate(isDHSScreen ? 'draggables.dhsHint' : 'draggables.emptyDraggables')}
					</p>
				</div>
			)}
		</fieldset>
	);
};
