import {
	TextField,
	TextAreaField,
	FormState,
	ValidationRule,
	RuleType,
	StringLengthValidationRule,
} from '@pushpay/forms';
import { Tiles } from '@pushpay/layout';
import { createUseStyles } from '@pushpay/styles';
import { Theme } from '@pushpay/theming';
import { ComponentProps } from '@pushpay/types';

import { UrlField } from '@src/components/formFields';
import { SaveContainerSettingsSchema } from '@src/graphql/generated';
import { useTranslation } from '@src/i18n/translation';

const useStyles = createUseStyles((theme: Theme) => ({
	label: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
		color: theme.colors['text-default'],
	},
	tile: {
		paddingTop: 0,
	},
	stackItem: {
		marginTop: theme.SPACING.SMALL,
	},
	title: {
		font: theme.typography['text-4-semiBold'],
		margin: `${theme.SPACING.SMALL} 0 ${theme.SPACING.XSMALL}`,
	},
	textFieldWrapper: {
		margin: 0,
	},
	description: {
		marginTop: 0,
		'& textarea': {
			resize: 'vertical',
		},
	},
	fieldRoot: {
		marginTop: 0,
	},
	url: {
		paddingTop: theme.SPACING.SMALL,
	},
	cta: {
		'& input': {
			textTransform: 'uppercase',
		},
	},
	conditionallyRequiredLabel: {
		font: theme.typography['text-4'],
		marginBottom: theme.SPACING.XSMALL,
		color: theme.colors['text-default'],
		'&::after': {
			content: '"*"',
			color: theme.colors['palette-red'],
			marginLeft: theme.SPACING.XXSMALL,
		},
	},
}));

export type SubtitleFieldProps = ComponentProps<
	{
		fields: FormState<SaveContainerSettingsSchema['input']['typeSpecificProperties']>;
	},
	typeof useStyles
>;

const getStringLengthFromValidationRules = (validationRules: ValidationRule[]) => {
	const stringLengthRule = validationRules.find(rule => rule.type === RuleType.StringLength) as
		| StringLengthValidationRule
		| undefined;
	return stringLengthRule?.maximumLength;
};

export function StudioCField({ fields, classes: classesProp }: SubtitleFieldProps) {
	const classes = useStyles(classesProp);
	const { translate } = useTranslation('appDesign');

	return (
		<Tiles
			classes={{
				tile: classes.tile,
			}}
			columns={1}
		>
			<UrlField
				classes={{ label: classes.label, root: classes.url }}
				field={fields.link}
				label={translate('settings.container.studioC.link')}
				labelPosition="top"
				placeholder={translate('settings.container.studioC.linkPlaceholder')}
				showLabel
			/>
			<div className={classes.title}>{translate('settings.container.studioC.layout')}</div>
			<TextField
				characterCounter={{ label: '' }}
				classes={{
					label: classes.conditionallyRequiredLabel,
					wrapper: classes.textFieldWrapper,
					root: classes.fieldRoot,
				}}
				field={fields.title}
				label={translate('settings.container.studioC.title')}
				labelPosition="top"
				textInputProps={{
					maxLength: getStringLengthFromValidationRules(fields.title.validationRules),
				}}
				showLabel
			/>
			<TextAreaField
				characterCounter={{ label: '' }}
				classes={{
					label: classes.label,
					root: classes.description,
				}}
				field={fields.description}
				label={translate('settings.container.studioC.description')}
				labelPosition="top"
				rows={2}
				textAreaProps={{
					maxLength: getStringLengthFromValidationRules(fields.description.validationRules),
				}}
				showLabel
			/>
			<TextField
				characterCounter={{ label: '' }}
				classes={{
					label: classes.conditionallyRequiredLabel,
					wrapper: classes.textFieldWrapper,
					root: classes.fieldRoot,
					input: classes.cta,
				}}
				field={fields.ctaLabel}
				label={translate('settings.container.studioC.ctaLabel')}
				labelPosition="top"
				textInputProps={{
					maxLength: getStringLengthFromValidationRules(fields.ctaLabel.validationRules),
				}}
				showLabel
			/>
		</Tiles>
	);
}
