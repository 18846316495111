/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-use-before-define */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';

import * as ApolloForms from '@pushpay/apollo-forms';
import * as Forms from '@pushpay/forms';

import {
	Instant,
	Key,
	GUID,
	URL,
	LocalDate,
	OlsonTimeZone,
	JSONObject,
	EmailAddress,
	FacetimeUrl,
	PhoneNumber,
	CacheContext,
} from './types';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & {
	[P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	EmailAddress: string;
	FacetimeUrl: string;
	FileUpload: any;
	GUID: string;
	IdentityKey: string;
	Instant: Instant;
	JSONObject: object;
	JsonString: any;
	Key: string;
	LocalDate: LocalDate;
	Offset: any;
	OlsonTimeZone: any;
	PhoneNumber: string;
	URL: any;
	WindowsTimeZone: any;
};

export enum AccountType {
	Customer = 'CUSTOMER',
	EndUser = 'END_USER',
	Legacy = 'LEGACY',
	PushpayAdmin = 'PUSHPAY_ADMIN',
}

export type ActionBar = {
	__typename?: 'ActionBar';
	hideOriginalProperty: Scalars['Boolean'];
	position: Scalars['Int'];
};

export type ActionBarInput = {
	hideOriginalProperty: Scalars['Boolean'];
	position: Scalars['Int'];
};

export type AddToContactsProperty = ItemProperty & {
	__typename?: 'AddToContactsProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type AddToContactsPropertyInput = {
	baseProperty: BasePropertyInput;
};

export type Address = {
	__typename?: 'Address';
	countryCode?: Maybe<Scalars['String']>;
};

export type AddressArgumentsInput = {
	city?: Maybe<Scalars['String']>;
	countryCode?: Maybe<Cca3CountryCodeEnumType>;
	line1?: Maybe<Scalars['String']>;
	line2?: Maybe<Scalars['String']>;
	postcode?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
};

export type AddressProperty = ItemProperty & {
	__typename?: 'AddressProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	city?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	latitude?: Maybe<Scalars['String']>;
	longitude?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	state?: Maybe<Scalars['String']>;
	street?: Maybe<Scalars['String']>;
	type: PropertyType;
	zip?: Maybe<Scalars['String']>;
};

export type AddressPropertyInput = {
	baseProperty: BasePropertyInput;
	city?: Maybe<Scalars['String']>;
	latitude?: Maybe<Scalars['String']>;
	longitude?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
	street?: Maybe<Scalars['String']>;
	zip?: Maybe<Scalars['String']>;
};

export type AdministeredOrganization = {
	__typename?: 'AdministeredOrganization';
	isMerchantAdmin: Scalars['Boolean'];
	key: Scalars['String'];
	logoUrl: Scalars['String'];
	name: Scalars['String'];
};

export type AllIdentityPermissions = {
	__typename?: 'AllIdentityPermissions';
	organizationPermissions: Array<OrganizationPermissions>;
	permissions: Array<Permission>;
};

export type AppCampus = {
	__typename?: 'AppCampus';
	givingLink?: Maybe<Scalars['URL']>;
	id: Scalars['GUID'];
	name: Scalars['String'];
	organizationId: Scalars['GUID'];
};

export type AppLinkProperty = ItemProperty & {
	__typename?: 'AppLinkProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	androidAppUrl?: Maybe<Scalars['String']>;
	androidDefaultUrl?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	iosAppUrl?: Maybe<Scalars['String']>;
	iosDefaultUrl?: Maybe<Scalars['String']>;
	itemId: Scalars['GUID'];
	label?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	type: PropertyType;
};

export type AppLinkPropertyInput = {
	androidAppUrl?: Maybe<Scalars['String']>;
	androidDefaultUrl?: Maybe<Scalars['String']>;
	baseProperty: BasePropertyInput;
	iosAppUrl?: Maybe<Scalars['String']>;
	iosDefaultUrl?: Maybe<Scalars['String']>;
	label?: Maybe<Scalars['String']>;
};

export type AppStudio = {
	__typename?: 'AppStudio';
	isAppStudioDesignEnabled: Scalars['Boolean'];
	isAppStudioPushNotificationsEnabled: Scalars['Boolean'];
};

export type Application = {
	__typename?: 'Application';
	campus?: Maybe<AppCampus>;
	campuses: Array<AppCampus>;
	container?: Maybe<Container>;
	downloadLink?: Maybe<Scalars['String']>;
	dynamicHomeScreen?: Maybe<DynamicHomeScreen>;
	features: Array<Feature>;
	givingLink?: Maybe<Scalars['URL']>;
	id: Scalars['GUID'];
	images: ApplicationImages;
	name: Scalars['String'];
	organizationId: Scalars['GUID'];
	pendingChanges: Scalars['Int'];
	platformCampusKey?: Maybe<Scalars['String']>;
	productType: ProductType;
	productVersion: ProductVersion;
	publishStatus: ApplicationPublishStatus;
	rootContainer: Container;
	setting: Array<ApplicationSetting>;
	shellCapabilities: Array<ShellCapability>;
};

export type ApplicationCampusArgs = {
	campusId: Scalars['GUID'];
	organizationKey: Scalars['String'];
};

export type ApplicationCampusesArgs = {
	organizationKey: Scalars['String'];
};

export type ApplicationContainerArgs = {
	containerId: Scalars['GUID'];
};

export type ApplicationImages = {
	__typename?: 'ApplicationImages';
	brandedHeader?: Maybe<Scalars['URL']>;
	brandedHeaderDark?: Maybe<Scalars['URL']>;
	brandmark?: Maybe<Scalars['URL']>;
	fullLogo?: Maybe<Scalars['URL']>;
	homeScreenPlaceholder?: Maybe<Scalars['URL']>;
	openGraph?: Maybe<Scalars['URL']>;
};

export enum ApplicationPublishStatus {
	InProcess = 'IN_PROCESS',
	Live = 'LIVE',
	NeedsSync = 'NEEDS_SYNC',
	Requested = 'REQUESTED',
	ReSync = 'RE_SYNC',
	Unknown = 'UNKNOWN',
}

export type ApplicationSetting = {
	__typename?: 'ApplicationSetting';
	enabled: Scalars['Boolean'];
	id: Scalars['GUID'];
	key: ApplicationSettingKey;
	required: Scalars['Boolean'];
	valid_select_options?: Maybe<Array<Scalars['String']>>;
	value?: Maybe<Scalars['String']>;
	valueType: ApplicationSettingValueType;
};

export enum ApplicationSettingKey {
	ApiMessageExpiry = 'API_MESSAGE_EXPIRY',
	ApiUrlBase = 'API_URL_BASE',
	AppAuthenticationType = 'APP_AUTHENTICATION_TYPE',
	AppNameDisplay = 'APP_NAME_DISPLAY',
	AppSearchData = 'APP_SEARCH_DATA',
	AwsCognitoId = 'AWS_COGNITO_ID',
	BrandedHeader = 'BRANDED_HEADER',
	DefaultsKeepAwakeOn = 'DEFAULTS_KEEP_AWAKE_ON',
	PlacesUom = 'PLACES_UOM',
	UiAds = 'UI_ADS',
	UiAdPlacement = 'UI_AD_PLACEMENT',
	UiBackgroundColor = 'UI_BACKGROUND_COLOR',
	UiBackgroundImage = 'UI_BACKGROUND_IMAGE',
	UiColorBar = 'UI_COLOR_BAR',
	UiColorPrimary = 'UI_COLOR_PRIMARY',
	UiColorPrimaryDark = 'UI_COLOR_PRIMARY_DARK',
	UiColorSearchBar = 'UI_COLOR_SEARCH_BAR',
	UiColorSearchTint = 'UI_COLOR_SEARCH_TINT',
	UiColorSeparator = 'UI_COLOR_SEPARATOR',
	UiColorTab = 'UI_COLOR_TAB',
	UiColorTabInactive = 'UI_COLOR_TAB_INACTIVE',
	UiColorTabTint = 'UI_COLOR_TAB_TINT',
	UiColorTabTintDark = 'UI_COLOR_TAB_TINT_DARK',
	UiColorTitlebarFont = 'UI_COLOR_TITLEBAR_FONT',
	UiDhsTabIcon = 'UI_DHS_TAB_ICON',
	UiDhsTabLabel = 'UI_DHS_TAB_LABEL',
	UiEventsDayLimit = 'UI_EVENTS_DAY_LIMIT',
	UiEventsDayStart = 'UI_EVENTS_DAY_START',
	UiFavoritesAlias = 'UI_FAVORITES_ALIAS',
	UiFont = 'UI_FONT',
	UiIndicatorColor = 'UI_INDICATOR_COLOR',
	UiLandingPageEnable = 'UI_LANDING_PAGE_ENABLE',
	UiNavigationStyle = 'UI_NAVIGATION_STYLE',
	UiPropertyHeadersHide = 'UI_PROPERTY_HEADERS_HIDE',
	UiSelectCampusTitle = 'UI_SELECT_CAMPUS_TITLE',
	UiSortDestination = 'UI_SORT_DESTINATION',
	UiTabActive = 'UI_TAB_ACTIVE',
	UiTargetDevice = 'UI_TARGET_DEVICE',
	UiTitlebarColor = 'UI_TITLEBAR_COLOR',
	UiTitlebarFontColor = 'UI_TITLEBAR_FONT_COLOR',
	UiTitlebarFontColorDark = 'UI_TITLEBAR_FONT_COLOR_DARK',
	UiTitlebarTheme = 'UI_TITLEBAR_THEME',
	UiTitlebarTransparencyIos = 'UI_TITLEBAR_TRANSPARENCY_IOS',
	UiTitleImageEnable = 'UI_TITLE_IMAGE_ENABLE',
	UiWindowTitle = 'UI_WINDOW_TITLE',
	Unknown = 'UNKNOWN',
}

export enum ApplicationSettingValueType {
	Boolean = 'BOOLEAN',
	Number = 'NUMBER',
	String = 'STRING',
	Unknown = 'UNKNOWN',
}

export enum ApplyPolicy {
	AfterResolver = 'AFTER_RESOLVER',
	BeforeResolver = 'BEFORE_RESOLVER',
	Validation = 'VALIDATION',
}

export type AudioCard = Card & {
	__typename?: 'AudioCard';
	audioUrl?: Maybe<Scalars['String']>;
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

export type AudioProperty = ItemProperty & {
	__typename?: 'AudioProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	label?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	type: PropertyType;
	url?: Maybe<Scalars['String']>;
};

export type AudioPropertyInput = {
	baseProperty: BasePropertyInput;
	label?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export type BaseCardDefinition = ItemCardDefinition & {
	__typename?: 'BaseCardDefinition';
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	content?: Maybe<ItemCardContent>;
	displayProperties: ItemDisplayProperties;
	featuredCard?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	isFeatured: Scalars['Boolean'];
	sortTimestamp: Scalars['Instant'];
};

export type BasePropertyInput = {
	action?: Maybe<ItemPropertyActionInput>;
	actionBar?: Maybe<ActionBarInput>;
	header?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	isHidden: Scalars['Boolean'];
	position: Scalars['Int'];
};

export type Birthday = {
	__typename?: 'Birthday';
	day: Scalars['Int'];
	month: MonthOfYear;
	year?: Maybe<Scalars['Int']>;
};

export type BirthdayArgumentsInput = {
	day: Scalars['Int'];
	month: MonthOfYear;
	year?: Maybe<Scalars['Int']>;
};

export type BlankifyProperty = ItemProperty & {
	__typename?: 'BlankifyProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	content?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type BlankifyPropertyInput = {
	baseProperty: BasePropertyInput;
	content?: Maybe<Scalars['String']>;
};

export type BrowserDataInput = {
	userAgent?: Maybe<Scalars['String']>;
};

export type CallToActionProperty = ItemProperty & {
	__typename?: 'CallToActionProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	isExternal: Scalars['Boolean'];
	itemId: Scalars['GUID'];
	label?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	type: PropertyType;
	url?: Maybe<Scalars['String']>;
};

export type CallToActionPropertyInput = {
	baseProperty: BasePropertyInput;
	isExternal: Scalars['Boolean'];
	label?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export enum CallToActionType {
	GetInvolved = 'GET_INVOLVED',
	LearnMore = 'LEARN_MORE',
	ReadMore = 'READ_MORE',
	SeeCampaign = 'SEE_CAMPAIGN',
	SeeDetails = 'SEE_DETAILS',
}

export type CampusPermissions = {
	__typename?: 'CampusPermissions';
	campusKey: Scalars['Key'];
	permissions: Array<Permission>;
};

export type Card = {
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

export type CardDataMap = {
	__typename?: 'CardDataMap';
	audioUrl?: Maybe<DataMap>;
	publishedTime?: Maybe<DataMap>;
	subtitle?: Maybe<DataMap>;
	summary?: Maybe<DataMap>;
	videoUrl?: Maybe<DataMap>;
};

export type CardDataMapInput = {
	audioUrl?: Maybe<DataMapInput>;
	publishedTime?: Maybe<DataMapInput>;
	subtitle?: Maybe<DataMapInput>;
	summary?: Maybe<DataMapInput>;
	videoUrl?: Maybe<DataMapInput>;
};

export type CardDefinitionGenericContentInput = {
	subtitle?: Maybe<Scalars['String']>;
	summary?: Maybe<Scalars['String']>;
};

export enum CardType {
	Audio = 'AUDIO',
	Default = 'DEFAULT',
	Event = 'EVENT',
	Impact = 'IMPACT',
	Intro = 'INTRO',
	Special = 'SPECIAL',
	Unknown = 'UNKNOWN',
	Video = 'VIDEO',
}

export type CarouselProperty = ItemProperty & {
	__typename?: 'CarouselProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	images: Array<Image>;
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	title?: Maybe<Scalars['String']>;
	type: PropertyType;
};

export type CarouselPropertyInput = {
	baseProperty: BasePropertyInput;
	images: Array<ImageInput>;
	title?: Maybe<Scalars['String']>;
};

export enum CatholicEntity {
	Cluster = 'CLUSTER',
	Diocese = 'DIOCESE',
	Other = 'OTHER',
	Parish = 'PARISH',
	Unknown = 'UNKNOWN',
}

export enum Cca2CountryCodeEnumType {
	Au = 'AU',
	Be = 'BE',
	Ca = 'CA',
	Ch = 'CH',
	De = 'DE',
	Dk = 'DK',
	Fi = 'FI',
	Gb = 'GB',
	Gt = 'GT',
	Is = 'IS',
	Lv = 'LV',
	Mx = 'MX',
	No = 'NO',
	Nz = 'NZ',
	Pr = 'PR',
	Se = 'SE',
	Sg = 'SG',
	Us = 'US',
	Za = 'ZA',
}

export enum Cca3CountryCodeEnumType {
	Aus = 'AUS',
	Bel = 'BEL',
	Can = 'CAN',
	Che = 'CHE',
	Deu = 'DEU',
	Dnk = 'DNK',
	Fin = 'FIN',
	Gbr = 'GBR',
	Gtm = 'GTM',
	Isl = 'ISL',
	Lva = 'LVA',
	Mex = 'MEX',
	Nor = 'NOR',
	Nzl = 'NZL',
	Pri = 'PRI',
	Sgp = 'SGP',
	Swe = 'SWE',
	Usa = 'USA',
	Zaf = 'ZAF',
}

export type ChildContainer = ContainerChild & {
	__typename?: 'ChildContainer';
	container: Container;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	name: Scalars['String'];
	position: Scalars['Int'];
};

export type ChildItem = ContainerChild & {
	__typename?: 'ChildItem';
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	item: Item;
	name: Scalars['String'];
	position: Scalars['Int'];
};

export type ChmsInfo = {
	__typename?: 'ChmsInfo';
	/** 'Unknown', 'Inactive', 'None' or 'Active'. */
	contractStatus?: Maybe<Scalars['String']>;
	/** The CCB ID for the Organization. */
	id?: Maybe<Scalars['String']>;
	/** True if this Organization can have CCB product. */
	isIncludedInContract: Scalars['Boolean'];
	/** True if this Organization has purchased Premier CCB support. */
	isPremier: Scalars['Boolean'];
	/** True if CCB is live. */
	isProvisioned: Scalars['Boolean'];
	/** The CCB Package that this Organization has. 'Unknown', 'Essential', 'Standard' or 'Deluxe'. */
	package?: Maybe<Scalars['String']>;
	/** The CCB Tenant Url for the Organization. */
	tenantUrl?: Maybe<Scalars['String']>;
};

export type ClientField = {
	__typename?: 'ClientField';
	field: Scalars['String'];
};

export type ClientIpAddress = {
	__typename?: 'ClientIpAddress';
	ipV4: Scalars['String'];
	ipV6: Scalars['String'];
};

export type Community = {
	__typename?: 'Community';
	isInSync: Scalars['Boolean'];
};

export type Container = {
	__typename?: 'Container';
	applicationId: Scalars['GUID'];
	campus?: Maybe<AppCampus>;
	cardDefinition?: Maybe<ContainerCardDefinition>;
	children: CursorPagedContainerChildren;
	feed?: Maybe<Feed>;
	givingLink?: Maybe<Scalars['URL']>;
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	image?: Maybe<Image>;
	isDeletable: Scalars['Boolean'];
	isHidden: Scalars['Boolean'];
	item?: Maybe<Item>;
	name: Scalars['String'];
	navigationAction?: Maybe<NavigationAction>;
	parentContainer?: Maybe<Container>;
	relatedContent: RelatedContent;
	resiLink?: Maybe<Scalars['URL']>;
	subtitle?: Maybe<Scalars['String']>;
	template: ContainerTemplate;
	type: ContainerType;
	typeSpecificProperties?: Maybe<ContainerTypeSpecificProperties>;
};

export type ContainerCampusArgs = {
	organizationKey: Scalars['String'];
};

export type ContainerChildrenArgs = {
	paging?: Maybe<CursorPagingInput>;
};

export type ContainerItemArgs = {
	itemId: Scalars['GUID'];
};

export type ContainerCardDefinition = {
	__typename?: 'ContainerCardDefinition';
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	dataMap: CardDataMap;
	featuredCard?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	isFeatured: Scalars['Boolean'];
	maxItems: Scalars['Int'];
	showCategory: Scalars['Boolean'];
	showPublishedTime: Scalars['Boolean'];
};

export type ContainerCardDefinitionInput = {
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	dataMap: CardDataMapInput;
	featuredLabel?: Maybe<Scalars['String']>;
	maxItems: Scalars['Int'];
};

export type ContainerChild = {
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	name: Scalars['String'];
	position: Scalars['Int'];
};

export type ContainerNotEmptyError = Error & {
	__typename?: 'ContainerNotEmptyError';
	message: Scalars['String'];
};

export enum ContainerTemplate {
	List_44DpIconLegacy = 'LIST_44DP_ICON_LEGACY',
	List_44DpIconTidbitLegacy = 'LIST_44DP_ICON_TIDBIT_LEGACY',
	List_44DpLegacy = 'LIST_44DP_LEGACY',
	List_44DpTidbit = 'LIST_44DP_TIDBIT',
	List_72Dp = 'LIST_72DP',
	List_72DpIcon = 'LIST_72DP_ICON',
	List_72DpIconTidbit = 'LIST_72DP_ICON_TIDBIT',
	List_72DpMessages = 'LIST_72DP_MESSAGES',
	List_72DpThumb = 'LIST_72DP_THUMB',
	List_72DpTidbit = 'LIST_72DP_TIDBIT',
	List_90DpSectionLegacy = 'LIST_90DP_SECTION_LEGACY',
	List_90DpThumb = 'LIST_90DP_THUMB',
	List_132DpPhoto = 'LIST_132DP_PHOTO',
	List_132DpPhotoNoLabel = 'LIST_132DP_PHOTO_NO_LABEL',
	Root = 'ROOT',
	Unknown = 'UNKNOWN',
}

export enum ContainerType {
	All = 'ALL',
	CcbCheckIn = 'CCB_CHECK_IN',
	CcbGroups = 'CCB_GROUPS',
	CcbPublicNeeds = 'CCB_PUBLIC_NEEDS',
	CcbServing = 'CCB_SERVING',
	Default = 'DEFAULT',
	Events = 'EVENTS',
	Favorites = 'FAVORITES',
	Give = 'GIVE',
	Messages = 'MESSAGES',
	Places = 'PLACES',
	PushCategory = 'PUSH_CATEGORY',
	Resi = 'RESI',
	Root = 'ROOT',
	SelectCampus = 'SELECT_CAMPUS',
	StudioC = 'STUDIO_C',
	Unknown = 'UNKNOWN',
}

export type ContainerTypeSpecificProperties = StudioContainerTypeProperties;

export type ContainerTypeSpecificPropertyInput = {
	ctaLabel: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	link?: Maybe<Scalars['URL']>;
	title: Scalars['String'];
};

export type CopyItemError = Error & {
	__typename?: 'CopyItemError';
	itemId: Scalars['GUID'];
	message: Scalars['String'];
};

export type CopyItemInput = {
	copiedItemName: Scalars['String'];
	itemId: Scalars['GUID'];
};

export type CopyItemResult = CopyItemError | CopyItemSuccess;

export type CopyItemSuccess = {
	__typename?: 'CopyItemSuccess';
	copiedItem: Item;
	itemId: Scalars['GUID'];
};

export type CopyItemsInput = {
	applicationId: Scalars['GUID'];
	copyItems: Array<CopyItemInput>;
	currentCursor?: Maybe<Scalars['String']>;
	parentContainerId: Scalars['String'];
};

export type CopyItemsOutput = {
	__typename?: 'CopyItemsOutput';
	copyItemResults: Array<CopyItemResult>;
	nextCursor?: Maybe<Scalars['String']>;
};

export type CopyItemsResult = CopyItemsOutput | InvalidRequestError | RootContainerError;

export type CountryType = {
	__typename?: 'CountryType';
	callingCode: Scalars['String'];
	cca2?: Maybe<Cca2CountryCodeEnumType>;
	cca3?: Maybe<Cca3CountryCodeEnumType>;
	name: Scalars['String'];
	placeholder: Scalars['String'];
	timeZones?: Maybe<Array<Maybe<TimeZone>>>;
};

export type CreateContainerInput = {
	applicationId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	insertBefore?: Maybe<Scalars['GUID']>;
	name: Scalars['String'];
	parentContainerId: Scalars['GUID'];
	relatedContent: RelatedContentInput;
	template: ContainerTemplate;
	type: ContainerType;
	typeSpecificProperties?: Maybe<ContainerTypeSpecificPropertyInput>;
};

export type CreateContainerResult = {
	__typename?: 'CreateContainerResult';
	createdContainer: Container;
	nextCursor?: Maybe<Scalars['String']>;
};

export type CreateItemInput = {
	applicationId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	insertBefore?: Maybe<Scalars['GUID']>;
	name: Scalars['String'];
	parentContainerId: Scalars['GUID'];
	properties: Array<ItemPropertyInput>;
	template: ItemTemplate;
	type: ItemType;
};

export type CreateItemResult = {
	__typename?: 'CreateItemResult';
	createdItem: Item;
	nextCursor?: Maybe<Scalars['String']>;
};

export type CursorPagedContainerChildren = {
	__typename?: 'CursorPagedContainerChildren';
	nodes: Array<ContainerChild>;
	paging: CursorPagingOutput;
};

export type CursorPagingInput = {
	after?: Maybe<Scalars['String']>;
	size?: Maybe<Scalars['Int']>;
};

export type CursorPagingOutput = {
	__typename?: 'CursorPagingOutput';
	nextCursor?: Maybe<Scalars['String']>;
	size: Scalars['Int'];
	totalItemCount: Scalars['Int'];
};

export type DataMap = {
	__typename?: 'DataMap';
	position: Scalars['Int'];
	type: PropertyType;
};

export type DataMapInput = {
	position: Scalars['Int'];
	type: PropertyType;
};

export type DefaultCard = Card & {
	__typename?: 'DefaultCard';
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

export type DefaultProperty = ItemProperty & {
	__typename?: 'DefaultProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	data?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type DefaultPropertyInput = {
	baseProperty: BasePropertyInput;
	data?: Maybe<Scalars['String']>;
};

export type DeleteContainerInput = {
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
};

export type DeleteContainerNewInput = {
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
};

export type DeleteContainerNewResult =
	| ContainerNotEmptyError
	| DeleteContainerNewSuccess
	| InvalidRequestError
	| RootContainerError;

export type DeleteContainerNewSuccess = {
	__typename?: 'DeleteContainerNewSuccess';
	containerId: Scalars['GUID'];
	nextCursor?: Maybe<Scalars['String']>;
};

export type DeleteContainerResult = {
	__typename?: 'DeleteContainerResult';
	containerId: Scalars['GUID'];
	/** @deprecated Replaced by 'containerId' */
	deletedContainer: Container;
	nextCursor?: Maybe<Scalars['String']>;
};

export type DeleteItemInput = {
	applicationId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
	itemId: Scalars['GUID'];
	parentContainerId: Scalars['GUID'];
};

export type DeleteItemResult = {
	__typename?: 'DeleteItemResult';
	/** @deprecated Replaced by 'itemId' */
	deletedItem: Item;
	itemId: Scalars['GUID'];
	nextCursor?: Maybe<Scalars['String']>;
};

export type DynamicHomeScreen = {
	__typename?: 'DynamicHomeScreen';
	cards: Array<Card>;
	isEnabled: Scalars['Boolean'];
	publish: Scalars['Boolean'];
};

export type DynamicHomeScreenCardsArgs = {
	campusId?: Maybe<Scalars['GUID']>;
	timeZone?: Maybe<Scalars['String']>;
};

export type EmailContact = {
	__typename?: 'EmailContact';
	emailAddress?: Maybe<Scalars['String']>;
	verified?: Maybe<Scalars['Boolean']>;
};

export type EmailPropertyInput = {
	baseProperty: BasePropertyInput;
	emailAddress?: Maybe<Scalars['EmailAddress']>;
};

export type Entitlement = {
	__typename?: 'Entitlement';
	displayName: Scalars['String'];
	key: EntitlementKey;
};

export enum EntitlementKey {
	ApplePay = 'APPLE_PAY',
	AppBlankify = 'APP_BLANKIFY',
	AppChms = 'APP_CHMS',
	AppContent = 'APP_CONTENT',
	AppCustomization = 'APP_CUSTOMIZATION',
	AppCustomizationAdvanced = 'APP_CUSTOMIZATION_ADVANCED',
	AppDashboard = 'APP_DASHBOARD',
	AppDashboardAdvanced = 'APP_DASHBOARD_ADVANCED',
	AppDynamicHomeScreen = 'APP_DYNAMIC_HOME_SCREEN',
	AppGivingDraggable = 'APP_GIVING_DRAGGABLE',
	AppMyGiving = 'APP_MY_GIVING',
	AppMyList = 'APP_MY_LIST',
	AppPnAdvanced = 'APP_PN_ADVANCED',
	AppPnBasic = 'APP_PN_BASIC',
	AppProfile = 'APP_PROFILE',
	AppUsersInsights = 'APP_USERS_INSIGHTS',
	Campus = 'CAMPUS',
	DonorDevelopment = 'DONOR_DEVELOPMENT',
	GivingLocalization = 'GIVING_LOCALIZATION',
	Insights = 'INSIGHTS',
	Integrations = 'INTEGRATIONS',
	OneTimeFutureGifts = 'ONE_TIME_FUTURE_GIFTS',
	PreConfiguredGivingUrl = 'PRE_CONFIGURED_GIVING_URL',
	ProcessQueues = 'PROCESS_QUEUES',
	Unknown = 'UNKNOWN',
}

export type Error = {
	message: Scalars['String'];
};

export type EventCard = Card & {
	__typename?: 'EventCard';
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	timeframe?: Maybe<Timeframe>;
	title: Scalars['String'];
};

export type ExistingImageInput = {
	id: Scalars['GUID'];
};

export type FacetimePropertyInput = {
	baseProperty: BasePropertyInput;
	facetimeUrl?: Maybe<Scalars['FacetimeUrl']>;
};

export type Feature = {
	__typename?: 'Feature';
	enabled: Scalars['Boolean'];
	id: Scalars['GUID'];
	key: FeatureKey;
	value?: Maybe<Scalars['String']>;
};

export type FeatureFlag = {
	__typename?: 'FeatureFlag';
	enabled: Scalars['Boolean'];
	name: Scalars['String'];
};

export enum FeatureKey {
	AppContentFeed = 'APP_CONTENT_FEED',
	AppDarkMode = 'APP_DARK_MODE',
	AppDraggableBlankify = 'APP_DRAGGABLE_BLANKIFY',
	AppEngagementAnalytics = 'APP_ENGAGEMENT_ANALYTICS',
	AppProfile = 'APP_PROFILE',
	AppProfileMyGiving = 'APP_PROFILE_MY_GIVING',
	AppProfileMyGivingStatements = 'APP_PROFILE_MY_GIVING_STATEMENTS',
	AppPushNotifications = 'APP_PUSH_NOTIFICATIONS',
	AppUiRefresh_2023 = 'APP_UI_REFRESH_2023',
	AttitudePoll = 'ATTITUDE_POLL',
	DetailFullTemplate = 'DETAIL_FULL_TEMPLATE',
	OpenResponse = 'OPEN_RESPONSE',
	Poll = 'POLL',
	Regions = 'REGIONS',
	RichPushNotifications = 'RICH_PUSH_NOTIFICATIONS',
	Unknown = 'UNKNOWN',
}

export type FeaturedCard = {
	__typename?: 'FeaturedCard';
	label?: Maybe<Scalars['String']>;
};

export type Feed = {
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export enum FeedProcessState {
	Complete = 'COMPLETE',
	Failed = 'FAILED',
	Processing = 'PROCESSING',
	Requested = 'REQUESTED',
	Unknown = 'UNKNOWN',
	Unprocessed = 'UNPROCESSED',
}

export type FeedProperty = {
	__typename?: 'FeedProperty';
	actionBarArray?: Maybe<Array<Maybe<Scalars['String']>>>;
	forceRegenerate?: Maybe<Scalars['Boolean']>;
	hiddenProperties?: Maybe<Array<Maybe<Scalars['String']>>>;
	itemTemplate?: Maybe<ItemTemplate>;
	itemTemplateRaw?: Maybe<Scalars['String']>;
	playlistId?: Maybe<Scalars['String']>;
	shareProperties?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FetchItemVideoThumbnailInput = {
	applicationId: Scalars['GUID'];
	itemId: Scalars['GUID'];
	parentContainerId: Scalars['GUID'];
	videoPropertyId: Scalars['GUID'];
};

export type FetchItemVideoThumbnailResult = {
	__typename?: 'FetchItemVideoThumbnailResult';
	image: Image;
};

export type GenerateFileUploadPostInput = {
	applicationId: Scalars['GUID'];
};

export type GenerateFileUploadPostResult = {
	__typename?: 'GenerateFileUploadPostResult';
	presignedPost: PresignedPost;
	unprocessedImageUrl: Scalars['URL'];
};

export type GiveProperty = ItemProperty & {
	__typename?: 'GiveProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	givingLink?: Maybe<Scalars['URL']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	isExternal: Scalars['Boolean'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type GivePropertyInput = {
	baseProperty: BasePropertyInput;
	givingLink?: Maybe<Scalars['URL']>;
};

export type IcalFeed = Feed & {
	__typename?: 'IcalFeed';
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type IcalFeedInput = {
	properties?: Maybe<Scalars['JSONObject']>;
	url?: Maybe<Scalars['URL']>;
};

export type Identity = {
	__typename?: 'Identity';
	accountType?: Maybe<AccountType>;
	address?: Maybe<IdentityAddress>;
	administeredOrganizations?: Maybe<Array<AdministeredOrganization>>;
	allPermissions?: Maybe<AllIdentityPermissions>;
	birthday?: Maybe<Birthday>;
	clientIpAddress?: Maybe<ClientIpAddress>;
	createdOn?: Maybe<Scalars['String']>;
	emailAddresses: Array<Maybe<EmailContact>>;
	firstName?: Maybe<Scalars['String']>;
	identityKey: Scalars['IdentityKey'];
	images?: Maybe<IdentityImageSet>;
	lastName?: Maybe<Scalars['String']>;
	olsonTimeZone?: Maybe<Scalars['OlsonTimeZone']>;
	primaryEmailAddress?: Maybe<EmailContact>;
	timeZoneId?: Maybe<Scalars['WindowsTimeZone']>;
};

export type IdentityAddress = {
	__typename?: 'IdentityAddress';
	city?: Maybe<Scalars['String']>;
	countryCode?: Maybe<Scalars['String']>;
	line1?: Maybe<Scalars['String']>;
	line2?: Maybe<Scalars['String']>;
	postcode?: Maybe<Scalars['String']>;
	state?: Maybe<Scalars['String']>;
};

export type IdentityImage = {
	__typename?: 'IdentityImage';
	url?: Maybe<Scalars['String']>;
};

export type IdentityImageSet = {
	__typename?: 'IdentityImageSet';
	avatar?: Maybe<IdentityImage>;
};

export type IdentityImageSetAvatarArgs = {
	size?: Maybe<ImageSizeInput>;
};

export type Image = {
	__typename?: 'Image';
	id: Scalars['GUID'];
	urls?: Maybe<ImageFormatUrls>;
};

export type ImageFormatUrls = {
	__typename?: 'ImageFormatUrls';
	dynamicHomeScreen?: Maybe<Scalars['URL']>;
	landscape?: Maybe<Scalars['URL']>;
	original?: Maybe<Scalars['URL']>;
	panorama?: Maybe<Scalars['URL']>;
	square?: Maybe<Scalars['URL']>;
	unprocessedImageUrl?: Maybe<Scalars['URL']>;
};

export type ImageInput = {
	existingImageInput?: Maybe<ExistingImageInput>;
	newImageInput?: Maybe<NewImageInput>;
};

export type ImageSizeInput = {
	height: Scalars['Int'];
	width: Scalars['Int'];
};

export type ImpactCard = Card & {
	__typename?: 'ImpactCard';
	callToActionText?: Maybe<CallToActionType>;
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	keyMetrics: Array<KeyMetric>;
	keyMetricsCount: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

export type ImpactCardContent = {
	__typename?: 'ImpactCardContent';
	summary?: Maybe<Scalars['String']>;
};

export type ImpactCardDefinition = ItemCardDefinition & {
	__typename?: 'ImpactCardDefinition';
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	content?: Maybe<ImpactCardContent>;
	displayProperties: ImpactDisplayProperties;
	featuredCard?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	isFeatured: Scalars['Boolean'];
	sortTimestamp: Scalars['Instant'];
};

export type ImpactDisplayProperties = {
	__typename?: 'ImpactDisplayProperties';
	showKeyMetrics: Scalars['Boolean'];
	showTitle: Scalars['Boolean'];
};

export type IntroCard = Card & {
	__typename?: 'IntroCard';
	callToActionText?: Maybe<CallToActionType>;
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

/**
 * The server understands the content of the request and the syntax of the request is correct, but it was unable or unwilling to process the request.
 * This is similar to a 422 Unprocessable Content status code.
 * This usually occurs due to a programming mistake or a manipulated request, rather than something that is fixable by the user.
 */
export type InvalidRequestError = Error & {
	__typename?: 'InvalidRequestError';
	message: Scalars['String'];
};

export type Item = {
	__typename?: 'Item';
	actions: Array<ItemAction>;
	cardDefinition?: Maybe<ItemCardDefinition>;
	createdDate?: Maybe<Scalars['Instant']>;
	feedId?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	image?: Maybe<Image>;
	isHidden: Scalars['Boolean'];
	isMirrored: Scalars['Boolean'];
	name: Scalars['String'];
	parentContainer?: Maybe<Container>;
	properties: Array<ItemProperty>;
	related: Array<Item>;
	template: ItemTemplate;
	type: ItemType;
	updatedDate?: Maybe<Scalars['Instant']>;
};

export type ItemAction = {
	__typename?: 'ItemAction';
	isHidden: Scalars['Boolean'];
	property: ItemProperty;
};

export type ItemCardContent = {
	__typename?: 'ItemCardContent';
	subtitle?: Maybe<Scalars['String']>;
	summary?: Maybe<Scalars['String']>;
};

export type ItemCardDefinition = {
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	featuredCard?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	isFeatured: Scalars['Boolean'];
	sortTimestamp: Scalars['Instant'];
};

export type ItemDisplayProperties = {
	__typename?: 'ItemDisplayProperties';
	showCategory: Scalars['Boolean'];
	showPublishedTime: Scalars['Boolean'];
};

export type ItemProperty = {
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type ItemPropertyAction = {
	__typename?: 'ItemPropertyAction';
	click: ItemPropertyActionType;
};

export type ItemPropertyActionInput = {
	click: ItemPropertyActionType;
};

export enum ItemPropertyActionType {
	AddToCalendar = 'ADD_TO_CALENDAR',
	AddToContacts = 'ADD_TO_CONTACTS',
	AppLink = 'APP_LINK',
	Call = 'CALL',
	Directions = 'DIRECTIONS',
	Facetime = 'FACETIME',
	OpenUrl = 'OPEN_URL',
	PlayAudio = 'PLAY_AUDIO',
	PlayVideo = 'PLAY_VIDEO',
	SendEmail = 'SEND_EMAIL',
	Share = 'SHARE',
	Unknown = 'UNKNOWN',
}

export type ItemPropertyInput = {
	addToContactsPropertyInput?: Maybe<AddToContactsPropertyInput>;
	addressPropertyInput?: Maybe<AddressPropertyInput>;
	appLinkPropertyInput?: Maybe<AppLinkPropertyInput>;
	audioPropertyInput?: Maybe<AudioPropertyInput>;
	blankifyPropertyInput?: Maybe<BlankifyPropertyInput>;
	callToActionPropertyInput?: Maybe<CallToActionPropertyInput>;
	carouselPropertyInput?: Maybe<CarouselPropertyInput>;
	defaultPropertyInput?: Maybe<DefaultPropertyInput>;
	emailPropertyInput?: Maybe<EmailPropertyInput>;
	facetimePropertyInput?: Maybe<FacetimePropertyInput>;
	givePropertyInput?: Maybe<GivePropertyInput>;
	keyMetricsPropertyInput?: Maybe<KeyMetricsPropertyInput>;
	phonePropertyInput?: Maybe<PhonePropertyInput>;
	sharePropertyInput?: Maybe<SharePropertyInput>;
	smsPropertyInput?: Maybe<SmsPropertyInput>;
	textHtmlPropertyInput?: Maybe<TextHtmlPropertyInput>;
	textPropertyInput?: Maybe<TextPropertyInput>;
	timeframePropertyInput?: Maybe<TimeframePropertyInput>;
	userNotePropertyInput?: Maybe<UserNotePropertyInput>;
	videoPropertyInput?: Maybe<VideoPropertyInput>;
	websitePropertyInput?: Maybe<WebsitePropertyInput>;
};

export enum ItemTemplate {
	DetailFull = 'DETAIL_FULL',
	DetailFullNoLabel = 'DETAIL_FULL_NO_LABEL',
	DetailFullNoOverlay = 'DETAIL_FULL_NO_OVERLAY',
	DetailPlain = 'DETAIL_PLAIN',
	DetailSquare = 'DETAIL_SQUARE',
	DetailWebview = 'DETAIL_WEBVIEW',
	List_44DpTidbitLegacy = 'LIST_44DP_TIDBIT_LEGACY',
	List_72DpMessagesLegacy = 'LIST_72DP_MESSAGES_LEGACY',
	List_90DpSectionLegacy = 'LIST_90DP_SECTION_LEGACY',
	Unknown = 'UNKNOWN',
}

export enum ItemType {
	CcbGroup = 'CCB_GROUP',
	CcbGroups = 'CCB_GROUPS',
	Contact = 'CONTACT',
	Default = 'DEFAULT',
	Destination = 'DESTINATION',
	Event = 'EVENT',
	Events = 'EVENTS',
	Favorites = 'FAVORITES',
	Impact = 'IMPACT',
	Media = 'MEDIA',
	Messages = 'MESSAGES',
	News = 'NEWS',
	Places = 'PLACES',
	Podcast = 'PODCAST',
	PushCategory = 'PUSH_CATEGORY',
	SelectCampus = 'SELECT_CAMPUS',
	Sermon = 'SERMON',
	Unknown = 'UNKNOWN',
	Video = 'VIDEO',
	Webview = 'WEBVIEW',
	WebviewExternal = 'WEBVIEW_EXTERNAL',
}

export type KeyMetric = {
	__typename?: 'KeyMetric';
	title?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type KeyMetricInput = {
	title?: Maybe<Scalars['String']>;
	value?: Maybe<Scalars['String']>;
};

export type KeyMetricsProperty = ItemProperty & {
	__typename?: 'KeyMetricsProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	metrics: Array<KeyMetric>;
	metricsCount: Scalars['Int'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type KeyMetricsPropertyInput = {
	baseProperty: BasePropertyInput;
	metrics: Array<KeyMetricInput>;
	metricsCount: Scalars['Int'];
};

export enum MonthOfYear {
	April = 'APRIL',
	August = 'AUGUST',
	December = 'DECEMBER',
	February = 'FEBRUARY',
	January = 'JANUARY',
	July = 'JULY',
	June = 'JUNE',
	March = 'MARCH',
	May = 'MAY',
	November = 'NOVEMBER',
	October = 'OCTOBER',
	September = 'SEPTEMBER',
	Unknown = 'UNKNOWN',
}

export type MoveChildError = Error & {
	__typename?: 'MoveChildError';
	childId: Scalars['GUID'];
	message: Scalars['String'];
};

export type MoveChildInput = {
	childId: Scalars['GUID'];
};

export type MoveChildResult = MoveChildError | MoveChildSuccess;

export type MoveChildSuccess = {
	__typename?: 'MoveChildSuccess';
	movedChild: MovedChild;
};

export type MoveChildrenInput = {
	applicationId: Scalars['GUID'];
	currentCursor?: Maybe<Scalars['String']>;
	moveChildren: Array<MoveChildInput>;
	newParentContainerId: Scalars['GUID'];
	parentContainerId: Scalars['GUID'];
};

export type MoveChildrenOutput = {
	__typename?: 'MoveChildrenOutput';
	moveChildResults: Array<MoveChildResult>;
	newParentContainer?: Maybe<Container>;
	nextCursor?: Maybe<Scalars['String']>;
	previousParentContainer?: Maybe<Container>;
};

export type MoveChildrenResult = InvalidRequestError | MoveChildrenOutput | RootContainerError;

export type MovedChild = Container | Item;

export type Mutation = {
	__typename?: 'Mutation';
	copyItems: CopyItemsResult;
	createContainer: CreateContainerResult;
	createItem: CreateItemResult;
	deleteContainer: DeleteContainerResult;
	deleteContainerNew: DeleteContainerNewResult;
	deleteItem: DeleteItemResult;
	fetchItemVideoThumbnail: FetchItemVideoThumbnailResult;
	generateFileUploadPost: GenerateFileUploadPostResult;
	moveChildren: MoveChildrenResult;
	processContainerFeed: ProcessContainerFeedResult;
	publishChanges: PublishChangesResult;
	reorderContainerChildren: ReorderContainerChildrenResult;
	saveContainerSettings: SaveContainerSettingsResult;
	saveItemSettings: SaveItemSettingsResult;
	updateContainerVisibility: UpdateContainerVisibilityResult;
	updateItemVisibility: UpdateItemVisibilityResult;
	updateProfile?: Maybe<UpdateProfileResult>;
};

export type MutationCopyItemsArgs = {
	copyItemsInput: CopyItemsInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationCreateContainerArgs = {
	createContainerInput: CreateContainerInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationCreateItemArgs = {
	createItemInput: CreateItemInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationDeleteContainerArgs = {
	deleteContainerInput: DeleteContainerInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationDeleteContainerNewArgs = {
	deleteContainerNewInput: DeleteContainerNewInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationDeleteItemArgs = {
	deleteItemInput: DeleteItemInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationFetchItemVideoThumbnailArgs = {
	fetchItemVideoThumbnailInput: FetchItemVideoThumbnailInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationGenerateFileUploadPostArgs = {
	generateFileUploadPostInput: GenerateFileUploadPostInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationMoveChildrenArgs = {
	moveChildrenInput: MoveChildrenInput;
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type MutationProcessContainerFeedArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	processContainerFeedInput: ProcessContainerFeedInput;
};

export type MutationPublishChangesArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	publishChangesInput: PublishChangesInput;
};

export type MutationReorderContainerChildrenArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	reorderContainerChildrenInput: ReorderContainerChildrenInput;
};

export type MutationSaveContainerSettingsArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	saveContainerSettingsInput: SaveContainerSettingsInput;
};

export type MutationSaveItemSettingsArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	saveItemSettingsInput: SaveItemSettingsInput;
};

export type MutationUpdateContainerVisibilityArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	updateContainerVisibilityInput: UpdateContainerVisibilityInput;
};

export type MutationUpdateItemVisibilityArgs = {
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	updateItemVisibilityInput: UpdateItemVisibilityInput;
};

export type MutationUpdateProfileArgs = {
	input: UpdateProfileInput;
	organizationKey: Scalars['String'];
};

export enum NavigationAction {
	Filter = 'FILTER',
	Map = 'MAP',
	Search = 'SEARCH',
	Unknown = 'UNKNOWN',
}

export type NewImageInput = {
	id: Scalars['GUID'];
	unprocessedImageUrl: Scalars['URL'];
};

export type Organization = {
	__typename?: 'Organization';
	appStudio?: Maybe<AppStudio>;
	application?: Maybe<Application>;
	applications: Array<Application>;
	details?: Maybe<OrganizationDetails>;
	featureFlags: Array<FeatureFlag>;
	key: Scalars['String'];
};

export type OrganizationApplicationArgs = {
	id: Scalars['GUID'];
	platformCampusKey?: Maybe<Scalars['String']>;
};

export type OrganizationDetails = {
	__typename?: 'OrganizationDetails';
	catholicEntity: CatholicEntity;
	catholicSoftwareStatus: Scalars['Boolean'];
	community?: Maybe<Community>;
	ecgId?: Maybe<Scalars['String']>;
	entitlements: Array<Entitlement>;
	isGivingPlatformActive?: Maybe<Scalars['Boolean']>;
	isMasAppPresent?: Maybe<Scalars['Boolean']>;
	isSalesforceCustomAppPresent?: Maybe<Scalars['Boolean']>;
	name?: Maybe<Scalars['String']>;
	productInformation: ProductInfo;
	shippingAddress?: Maybe<Address>;
};

export type OrganizationPermissions = {
	__typename?: 'OrganizationPermissions';
	campusPermissions: Array<CampusPermissions>;
	organization?: Maybe<OrganizationDetails>;
	organizationKey: Scalars['Key'];
	permissions: Array<Permission>;
};

export type Permission = {
	__typename?: 'Permission';
	name: Scalars['String'];
	permissionKey: Scalars['Key'];
};

export type PhonePropertyInput = {
	baseProperty: BasePropertyInput;
	phoneNumber?: Maybe<Scalars['PhoneNumber']>;
};

export type PodcastFeed = Feed & {
	__typename?: 'PodcastFeed';
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type PresignedPost = {
	__typename?: 'PresignedPost';
	acl: Scalars['String'];
	algorithm: Scalars['String'];
	credential: Scalars['String'];
	date: Scalars['String'];
	key: Scalars['String'];
	policy: Scalars['String'];
	signature: Scalars['String'];
	tagging: Scalars['String'];
	token: Scalars['String'];
	url: Scalars['URL'];
};

export type ProcessContainerFeedInput = {
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
};

export type ProcessContainerFeedResult = {
	__typename?: 'ProcessContainerFeedResult';
	feed: Feed;
};

export type ProductInfo = {
	__typename?: 'ProductInfo';
	chmsInformation: ChmsInfo;
};

export enum ProductType {
	Consolidated = 'CONSOLIDATED',
	Custom = 'CUSTOM',
}

export enum ProductVersion {
	Ea3 = 'EA3',
	Ea4 = 'EA4',
}

export enum PropertyType {
	Address = 'ADDRESS',
	AddToContacts = 'ADD_TO_CONTACTS',
	AppLink = 'APP_LINK',
	Audio = 'AUDIO',
	Blankify = 'BLANKIFY',
	CallToAction = 'CALL_TO_ACTION',
	Carousel = 'CAROUSEL',
	Default = 'DEFAULT',
	Give = 'GIVE',
	KeyMetrics = 'KEY_METRICS',
	Share = 'SHARE',
	Sms = 'SMS',
	Text = 'TEXT',
	TextHtml = 'TEXT_HTML',
	Timeframe = 'TIMEFRAME',
	Unknown = 'UNKNOWN',
	UserNote = 'USER_NOTE',
	Video = 'VIDEO',
	Website = 'WEBSITE',
}

export type PublishChangesInput = {
	applicationId: Scalars['GUID'];
};

export type PublishChangesResult = {
	__typename?: 'PublishChangesResult';
	application: Application;
};

export type Query = {
	__typename?: 'Query';
	isGuideValid: Scalars['Boolean'];
	me?: Maybe<Identity>;
	organization?: Maybe<Organization>;
	supportedCountriesDetails: Array<CountryType>;
};

export type QueryIsGuideValidArgs = {
	hash: Scalars['String'];
};

export type QueryOrganizationArgs = {
	organizationKey: Scalars['String'];
};

export type RelatedContent = {
	__typename?: 'RelatedContent';
	/** @deprecated Replaced by isEnabled */
	enabled: Scalars['Boolean'];
	isEnabled: Scalars['Boolean'];
	title?: Maybe<Scalars['String']>;
};

export type RelatedContentInput = {
	isEnabled: Scalars['Boolean'];
	title?: Maybe<Scalars['String']>;
};

export type ReorderContainerChildrenInput = {
	applicationId: Scalars['GUID'];
	containerChildId: Scalars['GUID'];
	insertBefore?: Maybe<Scalars['GUID']>;
	parentContainerId: Scalars['GUID'];
};

export type ReorderContainerChildrenResult = {
	__typename?: 'ReorderContainerChildrenResult';
	updatedContainerChildren: Array<ContainerChild>;
};

export type RootContainerError = Error & {
	__typename?: 'RootContainerError';
	message: Scalars['String'];
};

export type RssFeed = Feed & {
	__typename?: 'RssFeed';
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type RssFeedInput = {
	properties?: Maybe<Scalars['JSONObject']>;
	type: RssFeedType;
	url?: Maybe<Scalars['URL']>;
};

export enum RssFeedType {
	News = 'NEWS',
	Podcast = 'PODCAST',
	Video = 'VIDEO',
}

export type SaveContainerSettingsInput = {
	applicationId: Scalars['GUID'];
	campusId?: Maybe<Scalars['GUID']>;
	cardDefinition?: Maybe<ContainerCardDefinitionInput>;
	containerId: Scalars['GUID'];
	givingLink?: Maybe<Scalars['URL']>;
	icalFeed?: Maybe<IcalFeedInput>;
	icon?: Maybe<Scalars['String']>;
	imageUrl?: Maybe<Scalars['URL']>;
	name: Scalars['String'];
	navigationAction?: Maybe<NavigationAction>;
	parentContainerId: Scalars['GUID'];
	relatedContent: RelatedContentInput;
	resiLink?: Maybe<Scalars['URL']>;
	rssFeed?: Maybe<RssFeedInput>;
	subtitle?: Maybe<Scalars['String']>;
	template: ContainerTemplate;
	type: ContainerType;
	typeSpecificProperties?: Maybe<ContainerTypeSpecificPropertyInput>;
	vimeoFeed?: Maybe<VimeoFeedInput>;
	youtubeFeed?: Maybe<YouTubeFeedInput>;
};

export type SaveContainerSettingsResult = {
	__typename?: 'SaveContainerSettingsResult';
	updatedContainer: Container;
};

export type SaveItemCardDefinitionInput = {
	callToActionText?: Maybe<CallToActionType>;
	cardType: CardType;
	featuredLabel?: Maybe<Scalars['String']>;
	genericContent?: Maybe<CardDefinitionGenericContentInput>;
	isFeatured?: Maybe<Scalars['Boolean']>;
	showProperties: ShowPropertyInput;
};

export type SaveItemSettingsInput = {
	applicationId: Scalars['GUID'];
	cardDefinition?: Maybe<SaveItemCardDefinitionInput>;
	icon?: Maybe<Scalars['String']>;
	imageUrl?: Maybe<Scalars['URL']>;
	itemId: Scalars['GUID'];
	name: Scalars['String'];
	parentContainerId: Scalars['GUID'];
	properties: Array<ItemPropertyInput>;
	template: ItemTemplate;
	type: ItemType;
};

export type SaveItemSettingsResult = {
	__typename?: 'SaveItemSettingsResult';
	updatedItem: Item;
};

export type ShareProperty = ItemProperty & {
	__typename?: 'ShareProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	sharedProperties: Array<ItemProperty>;
	type: PropertyType;
};

export type SharePropertyInput = {
	baseProperty: BasePropertyInput;
	sharedProperties?: Maybe<Array<Maybe<Scalars['GUID']>>>;
};

export type ShellCapability = {
	__typename?: 'ShellCapability';
	isSupported: Scalars['Boolean'];
	key: ShellCapabilityKey;
};

export enum ShellCapabilityKey {
	ChmsDraggables = 'CHMS_DRAGGABLES',
	DarkMode = 'DARK_MODE',
	ResiDraggable = 'RESI_DRAGGABLE',
	ResiVodDraggable = 'RESI_VOD_DRAGGABLE',
	SecondaryScreen = 'SECONDARY_SCREEN',
}

export type ShowPropertyInput = {
	category?: Maybe<Scalars['Boolean']>;
	keyMetrics?: Maybe<Scalars['Boolean']>;
	publishedTime?: Maybe<Scalars['Boolean']>;
	title?: Maybe<Scalars['Boolean']>;
};

export type SmsProperty = ItemProperty & {
	__typename?: 'SmsProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	androidAppUrl?: Maybe<Scalars['String']>;
	androidDefaultUrl?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	iosAppUrl?: Maybe<Scalars['String']>;
	iosDefaultUrl?: Maybe<Scalars['String']>;
	itemId: Scalars['GUID'];
	messageBody?: Maybe<Scalars['String']>;
	messageLabel?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	type: PropertyType;
};

export type SmsPropertyInput = {
	androidAppUrl?: Maybe<Scalars['String']>;
	androidDefaultUrl?: Maybe<Scalars['String']>;
	baseProperty: BasePropertyInput;
	iosAppUrl?: Maybe<Scalars['String']>;
	iosDefaultUrl?: Maybe<Scalars['String']>;
	messageBody?: Maybe<Scalars['String']>;
	messageLabel?: Maybe<Scalars['String']>;
	phoneNumber?: Maybe<Scalars['String']>;
};

export type SpecialCard = Card & {
	__typename?: 'SpecialCard';
	callToActionText?: Maybe<CallToActionType>;
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
};

export type StudioContainerTypeProperties = {
	__typename?: 'StudioContainerTypeProperties';
	ctaLabel: Scalars['String'];
	description?: Maybe<Scalars['String']>;
	link?: Maybe<Scalars['URL']>;
	title: Scalars['String'];
};

export type TextHtmlProperty = ItemProperty & {
	__typename?: 'TextHtmlProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	text?: Maybe<Scalars['String']>;
	textHtml?: Maybe<Scalars['String']>;
	type: PropertyType;
};

export type TextHtmlPropertyInput = {
	baseProperty: BasePropertyInput;
	text?: Maybe<Scalars['String']>;
	textHtml?: Maybe<Scalars['String']>;
};

export type TextProperty = ItemProperty & {
	__typename?: 'TextProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	textDescription?: Maybe<Scalars['String']>;
	type: PropertyType;
};

export type TextPropertyInput = {
	baseProperty: BasePropertyInput;
	textDescription?: Maybe<Scalars['String']>;
};

export type TimeZone = {
	__typename?: 'TimeZone';
	olsonName: Scalars['String'];
	windowsName: Scalars['String'];
};

export type Timeframe = {
	__typename?: 'Timeframe';
	allDay: Scalars['Boolean'];
	endTime?: Maybe<Scalars['Instant']>;
	startTime?: Maybe<Scalars['Instant']>;
};

export type TimeframeProperty = ItemProperty & {
	__typename?: 'TimeframeProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	timeframe: Timeframe;
	type: PropertyType;
};

export type TimeframePropertyInput = {
	allDay: Scalars['Boolean'];
	baseProperty: BasePropertyInput;
	endTime?: Maybe<Scalars['Instant']>;
	startTime?: Maybe<Scalars['Instant']>;
};

export type UpdateContainerVisibilityInput = {
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	isHidden: Scalars['Boolean'];
};

export type UpdateContainerVisibilityResult = {
	__typename?: 'UpdateContainerVisibilityResult';
	updatedContainer: Container;
};

export type UpdateItemVisibilityInput = {
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	isHidden: Scalars['Boolean'];
	itemId: Scalars['GUID'];
};

export type UpdateItemVisibilityResult = {
	__typename?: 'UpdateItemVisibilityResult';
	updatedItem: Item;
};

export type UpdateProfileInput = {
	address?: Maybe<AddressArgumentsInput>;
	birthday?: Maybe<BirthdayArgumentsInput>;
	commandId: Scalars['String'];
	emailAddresses?: Maybe<Array<Maybe<Scalars['String']>>>;
	firstName?: Maybe<Scalars['String']>;
	identityKey: Scalars['Key'];
	lastName?: Maybe<Scalars['String']>;
	timeZone?: Maybe<Scalars['OlsonTimeZone']>;
	userContext: UserContextDataInput;
};

export type UpdateProfileResult = {
	__typename?: 'UpdateProfileResult';
	identity?: Maybe<Identity>;
	success: Scalars['Boolean'];
};

export type UserContextDataInput = {
	browser?: Maybe<BrowserDataInput>;
	ipAddress: Scalars['String'];
	olsonTimeZone: Scalars['OlsonTimeZone'];
};

export type UserNoteProperty = ItemProperty & {
	__typename?: 'UserNoteProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	hintText?: Maybe<Scalars['String']>;
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
};

export type UserNotePropertyInput = {
	baseProperty: BasePropertyInput;
	hintText?: Maybe<Scalars['String']>;
};

export type VideoCard = Card & {
	__typename?: 'VideoCard';
	category: Scalars['String'];
	featured?: Maybe<FeaturedCard>;
	id: Scalars['GUID'];
	item: Item;
	publishedTime: Scalars['Int'];
	subtitle: Scalars['String'];
	summary: Scalars['String'];
	tabName: Scalars['String'];
	title: Scalars['String'];
	videoUrl: Scalars['String'];
};

export type VideoFeed = Feed & {
	__typename?: 'VideoFeed';
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type VideoProperty = ItemProperty & {
	__typename?: 'VideoProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	description?: Maybe<Scalars['String']>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	isEmbed: Scalars['Boolean'];
	itemId: Scalars['GUID'];
	position: Scalars['Int'];
	type: PropertyType;
	url?: Maybe<Scalars['String']>;
};

export type VideoPropertyInput = {
	baseProperty: BasePropertyInput;
	description?: Maybe<Scalars['String']>;
	isEmbed?: Maybe<Scalars['Boolean']>;
	url?: Maybe<Scalars['String']>;
};

export type VimeoFeed = Feed & {
	__typename?: 'VimeoFeed';
	id: Scalars['GUID'];
	identifiers?: Maybe<VimeoFeedIdentifiers>;
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type VimeoFeedIdentifiers = {
	__typename?: 'VimeoFeedIdentifiers';
	channelId?: Maybe<Scalars['String']>;
	groupId?: Maybe<Scalars['String']>;
	showcaseId?: Maybe<Scalars['String']>;
	type: VimeoIdentifierType;
	userId?: Maybe<Scalars['String']>;
};

export type VimeoFeedInput = {
	channelId?: Maybe<Scalars['String']>;
	groupId?: Maybe<Scalars['String']>;
	identifier: VimeoIdentifierType;
	properties?: Maybe<Scalars['JSONObject']>;
	showcaseId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
};

export enum VimeoIdentifierType {
	Channel = 'CHANNEL',
	Group = 'GROUP',
	Showcase = 'SHOWCASE',
	UserNotes = 'USER_NOTES',
}

export type WebsiteProperty = ItemProperty & {
	__typename?: 'WebsiteProperty';
	action?: Maybe<ItemPropertyAction>;
	actionBar?: Maybe<ActionBar>;
	header?: Maybe<Scalars['String']>;
	hidden: Scalars['Boolean'];
	icon?: Maybe<Scalars['String']>;
	id: Scalars['GUID'];
	isExternal: Scalars['Boolean'];
	itemId: Scalars['GUID'];
	label?: Maybe<Scalars['String']>;
	position: Scalars['Int'];
	type: PropertyType;
	url?: Maybe<Scalars['String']>;
};

export type WebsitePropertyInput = {
	baseProperty: BasePropertyInput;
	isExternal: Scalars['Boolean'];
	label?: Maybe<Scalars['String']>;
	url?: Maybe<Scalars['String']>;
};

export type YouTubeFeed = Feed & {
	__typename?: 'YouTubeFeed';
	id: Scalars['GUID'];
	processState: FeedProcessState;
	properties?: Maybe<FeedProperty>;
	url?: Maybe<Scalars['URL']>;
};

export type YouTubeFeedInput = {
	properties?: Maybe<Scalars['JSONObject']>;
	url?: Maybe<Scalars['URL']>;
};

export type ActionBarKeySpecifier = ('hideOriginalProperty' | 'position' | ActionBarKeySpecifier)[];
export type ActionBarFieldPolicy = {
	hideOriginalProperty?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddToContactsPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'type'
	| AddToContactsPropertyKeySpecifier
)[];
export type AddToContactsPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressKeySpecifier = ('countryCode' | AddressKeySpecifier)[];
export type AddressFieldPolicy = {
	countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AddressPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'city'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'latitude'
	| 'longitude'
	| 'position'
	| 'state'
	| 'street'
	| 'type'
	| 'zip'
	| AddressPropertyKeySpecifier
)[];
export type AddressPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	city?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	latitude?: FieldPolicy<any> | FieldReadFunction<any>;
	longitude?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	state?: FieldPolicy<any> | FieldReadFunction<any>;
	street?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	zip?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdministeredOrganizationKeySpecifier = (
	| 'isMerchantAdmin'
	| 'key'
	| 'logoUrl'
	| 'name'
	| AdministeredOrganizationKeySpecifier
)[];
export type AdministeredOrganizationFieldPolicy = {
	isMerchantAdmin?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
	logoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AllIdentityPermissionsKeySpecifier = (
	| 'organizationPermissions'
	| 'permissions'
	| AllIdentityPermissionsKeySpecifier
)[];
export type AllIdentityPermissionsFieldPolicy = {
	organizationPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
	permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppCampusKeySpecifier = ('givingLink' | 'id' | 'name' | 'organizationId' | AppCampusKeySpecifier)[];
export type AppCampusFieldPolicy = {
	givingLink?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppLinkPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'androidAppUrl'
	| 'androidDefaultUrl'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'iosAppUrl'
	| 'iosDefaultUrl'
	| 'itemId'
	| 'label'
	| 'position'
	| 'type'
	| AppLinkPropertyKeySpecifier
)[];
export type AppLinkPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	androidAppUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	androidDefaultUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	iosAppUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	iosDefaultUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	label?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AppStudioKeySpecifier = (
	| 'isAppStudioDesignEnabled'
	| 'isAppStudioPushNotificationsEnabled'
	| AppStudioKeySpecifier
)[];
export type AppStudioFieldPolicy = {
	isAppStudioDesignEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
	isAppStudioPushNotificationsEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApplicationKeySpecifier = (
	| 'campus'
	| 'campuses'
	| 'container'
	| 'downloadLink'
	| 'dynamicHomeScreen'
	| 'features'
	| 'givingLink'
	| 'id'
	| 'images'
	| 'name'
	| 'organizationId'
	| 'pendingChanges'
	| 'platformCampusKey'
	| 'productType'
	| 'productVersion'
	| 'publishStatus'
	| 'rootContainer'
	| 'setting'
	| 'shellCapabilities'
	| ApplicationKeySpecifier
)[];
export type ApplicationFieldPolicy = {
	campus?: FieldPolicy<any> | FieldReadFunction<any>;
	campuses?: FieldPolicy<any> | FieldReadFunction<any>;
	container?: FieldPolicy<any> | FieldReadFunction<any>;
	downloadLink?: FieldPolicy<any> | FieldReadFunction<any>;
	dynamicHomeScreen?: FieldPolicy<any> | FieldReadFunction<any>;
	features?: FieldPolicy<any> | FieldReadFunction<any>;
	givingLink?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	images?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	organizationId?: FieldPolicy<any> | FieldReadFunction<any>;
	pendingChanges?: FieldPolicy<any> | FieldReadFunction<any>;
	platformCampusKey?: FieldPolicy<any> | FieldReadFunction<any>;
	productType?: FieldPolicy<any> | FieldReadFunction<any>;
	productVersion?: FieldPolicy<any> | FieldReadFunction<any>;
	publishStatus?: FieldPolicy<any> | FieldReadFunction<any>;
	rootContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	setting?: FieldPolicy<any> | FieldReadFunction<any>;
	shellCapabilities?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApplicationImagesKeySpecifier = (
	| 'brandedHeader'
	| 'brandedHeaderDark'
	| 'brandmark'
	| 'fullLogo'
	| 'homeScreenPlaceholder'
	| 'openGraph'
	| ApplicationImagesKeySpecifier
)[];
export type ApplicationImagesFieldPolicy = {
	brandedHeader?: FieldPolicy<any> | FieldReadFunction<any>;
	brandedHeaderDark?: FieldPolicy<any> | FieldReadFunction<any>;
	brandmark?: FieldPolicy<any> | FieldReadFunction<any>;
	fullLogo?: FieldPolicy<any> | FieldReadFunction<any>;
	homeScreenPlaceholder?: FieldPolicy<any> | FieldReadFunction<any>;
	openGraph?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ApplicationSettingKeySpecifier = (
	| 'enabled'
	| 'id'
	| 'key'
	| 'required'
	| 'valid_select_options'
	| 'value'
	| 'valueType'
	| ApplicationSettingKeySpecifier
)[];
export type ApplicationSettingFieldPolicy = {
	enabled?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
	required?: FieldPolicy<any> | FieldReadFunction<any>;
	valid_select_options?: FieldPolicy<any> | FieldReadFunction<any>;
	value?: FieldPolicy<any> | FieldReadFunction<any>;
	valueType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AudioCardKeySpecifier = (
	| 'audioUrl'
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| AudioCardKeySpecifier
)[];
export type AudioCardFieldPolicy = {
	audioUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AudioPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'label'
	| 'position'
	| 'type'
	| 'url'
	| AudioPropertyKeySpecifier
)[];
export type AudioPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	label?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BaseCardDefinitionKeySpecifier = (
	| 'callToActionText'
	| 'cardType'
	| 'content'
	| 'displayProperties'
	| 'featuredCard'
	| 'id'
	| 'isFeatured'
	| 'sortTimestamp'
	| BaseCardDefinitionKeySpecifier
)[];
export type BaseCardDefinitionFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	cardType?: FieldPolicy<any> | FieldReadFunction<any>;
	content?: FieldPolicy<any> | FieldReadFunction<any>;
	displayProperties?: FieldPolicy<any> | FieldReadFunction<any>;
	featuredCard?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isFeatured?: FieldPolicy<any> | FieldReadFunction<any>;
	sortTimestamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BirthdayKeySpecifier = ('day' | 'month' | 'year' | BirthdayKeySpecifier)[];
export type BirthdayFieldPolicy = {
	day?: FieldPolicy<any> | FieldReadFunction<any>;
	month?: FieldPolicy<any> | FieldReadFunction<any>;
	year?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BlankifyPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'content'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'type'
	| BlankifyPropertyKeySpecifier
)[];
export type BlankifyPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	content?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CallToActionPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'isExternal'
	| 'itemId'
	| 'label'
	| 'position'
	| 'type'
	| 'url'
	| CallToActionPropertyKeySpecifier
)[];
export type CallToActionPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isExternal?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	label?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CampusPermissionsKeySpecifier = ('campusKey' | 'permissions' | CampusPermissionsKeySpecifier)[];
export type CampusPermissionsFieldPolicy = {
	campusKey?: FieldPolicy<any> | FieldReadFunction<any>;
	permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CardKeySpecifier = (
	| 'featured'
	| 'id'
	| 'item'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| CardKeySpecifier
)[];
export type CardFieldPolicy = {
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CardDataMapKeySpecifier = (
	| 'audioUrl'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'videoUrl'
	| CardDataMapKeySpecifier
)[];
export type CardDataMapFieldPolicy = {
	audioUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	videoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CarouselPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'images'
	| 'itemId'
	| 'position'
	| 'title'
	| 'type'
	| CarouselPropertyKeySpecifier
)[];
export type CarouselPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	images?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChildContainerKeySpecifier = (
	| 'container'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'name'
	| 'position'
	| ChildContainerKeySpecifier
)[];
export type ChildContainerFieldPolicy = {
	container?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChildItemKeySpecifier = ('hidden' | 'icon' | 'id' | 'item' | 'name' | 'position' | ChildItemKeySpecifier)[];
export type ChildItemFieldPolicy = {
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChmsInfoKeySpecifier = (
	| 'contractStatus'
	| 'id'
	| 'isIncludedInContract'
	| 'isPremier'
	| 'isProvisioned'
	| 'package'
	| 'tenantUrl'
	| ChmsInfoKeySpecifier
)[];
export type ChmsInfoFieldPolicy = {
	contractStatus?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isIncludedInContract?: FieldPolicy<any> | FieldReadFunction<any>;
	isPremier?: FieldPolicy<any> | FieldReadFunction<any>;
	isProvisioned?: FieldPolicy<any> | FieldReadFunction<any>;
	package?: FieldPolicy<any> | FieldReadFunction<any>;
	tenantUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientFieldKeySpecifier = ('field' | ClientFieldKeySpecifier)[];
export type ClientFieldFieldPolicy = {
	field?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClientIpAddressKeySpecifier = ('ipV4' | 'ipV6' | ClientIpAddressKeySpecifier)[];
export type ClientIpAddressFieldPolicy = {
	ipV4?: FieldPolicy<any> | FieldReadFunction<any>;
	ipV6?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommunityKeySpecifier = ('isInSync' | CommunityKeySpecifier)[];
export type CommunityFieldPolicy = {
	isInSync?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerKeySpecifier = (
	| 'applicationId'
	| 'campus'
	| 'cardDefinition'
	| 'children'
	| 'feed'
	| 'givingLink'
	| 'icon'
	| 'id'
	| 'image'
	| 'isDeletable'
	| 'isHidden'
	| 'item'
	| 'name'
	| 'navigationAction'
	| 'parentContainer'
	| 'relatedContent'
	| 'resiLink'
	| 'subtitle'
	| 'template'
	| 'type'
	| 'typeSpecificProperties'
	| ContainerKeySpecifier
)[];
export type ContainerFieldPolicy = {
	applicationId?: FieldPolicy<any> | FieldReadFunction<any>;
	campus?: FieldPolicy<any> | FieldReadFunction<any>;
	cardDefinition?: FieldPolicy<any> | FieldReadFunction<any>;
	children?: FieldPolicy<any> | FieldReadFunction<any>;
	feed?: FieldPolicy<any> | FieldReadFunction<any>;
	givingLink?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	image?: FieldPolicy<any> | FieldReadFunction<any>;
	isDeletable?: FieldPolicy<any> | FieldReadFunction<any>;
	isHidden?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	navigationAction?: FieldPolicy<any> | FieldReadFunction<any>;
	parentContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	relatedContent?: FieldPolicy<any> | FieldReadFunction<any>;
	resiLink?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	template?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	typeSpecificProperties?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerCardDefinitionKeySpecifier = (
	| 'callToActionText'
	| 'cardType'
	| 'dataMap'
	| 'featuredCard'
	| 'id'
	| 'isFeatured'
	| 'maxItems'
	| 'showCategory'
	| 'showPublishedTime'
	| ContainerCardDefinitionKeySpecifier
)[];
export type ContainerCardDefinitionFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	cardType?: FieldPolicy<any> | FieldReadFunction<any>;
	dataMap?: FieldPolicy<any> | FieldReadFunction<any>;
	featuredCard?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isFeatured?: FieldPolicy<any> | FieldReadFunction<any>;
	maxItems?: FieldPolicy<any> | FieldReadFunction<any>;
	showCategory?: FieldPolicy<any> | FieldReadFunction<any>;
	showPublishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerChildKeySpecifier = (
	| 'hidden'
	| 'icon'
	| 'id'
	| 'name'
	| 'position'
	| ContainerChildKeySpecifier
)[];
export type ContainerChildFieldPolicy = {
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ContainerNotEmptyErrorKeySpecifier = ('message' | ContainerNotEmptyErrorKeySpecifier)[];
export type ContainerNotEmptyErrorFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CopyItemErrorKeySpecifier = ('itemId' | 'message' | CopyItemErrorKeySpecifier)[];
export type CopyItemErrorFieldPolicy = {
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CopyItemSuccessKeySpecifier = ('copiedItem' | 'itemId' | CopyItemSuccessKeySpecifier)[];
export type CopyItemSuccessFieldPolicy = {
	copiedItem?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CopyItemsOutputKeySpecifier = ('copyItemResults' | 'nextCursor' | CopyItemsOutputKeySpecifier)[];
export type CopyItemsOutputFieldPolicy = {
	copyItemResults?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CountryTypeKeySpecifier = (
	| 'callingCode'
	| 'cca2'
	| 'cca3'
	| 'name'
	| 'placeholder'
	| 'timeZones'
	| CountryTypeKeySpecifier
)[];
export type CountryTypeFieldPolicy = {
	callingCode?: FieldPolicy<any> | FieldReadFunction<any>;
	cca2?: FieldPolicy<any> | FieldReadFunction<any>;
	cca3?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	placeholder?: FieldPolicy<any> | FieldReadFunction<any>;
	timeZones?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateContainerResultKeySpecifier = (
	| 'createdContainer'
	| 'nextCursor'
	| CreateContainerResultKeySpecifier
)[];
export type CreateContainerResultFieldPolicy = {
	createdContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreateItemResultKeySpecifier = ('createdItem' | 'nextCursor' | CreateItemResultKeySpecifier)[];
export type CreateItemResultFieldPolicy = {
	createdItem?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagedContainerChildrenKeySpecifier = (
	| 'nodes'
	| 'paging'
	| CursorPagedContainerChildrenKeySpecifier
)[];
export type CursorPagedContainerChildrenFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>;
	paging?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CursorPagingOutputKeySpecifier = (
	| 'nextCursor'
	| 'size'
	| 'totalItemCount'
	| CursorPagingOutputKeySpecifier
)[];
export type CursorPagingOutputFieldPolicy = {
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
	size?: FieldPolicy<any> | FieldReadFunction<any>;
	totalItemCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DataMapKeySpecifier = ('position' | 'type' | DataMapKeySpecifier)[];
export type DataMapFieldPolicy = {
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DefaultCardKeySpecifier = (
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| DefaultCardKeySpecifier
)[];
export type DefaultCardFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DefaultPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'data'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'type'
	| DefaultPropertyKeySpecifier
)[];
export type DefaultPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	data?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteContainerNewSuccessKeySpecifier = (
	| 'containerId'
	| 'nextCursor'
	| DeleteContainerNewSuccessKeySpecifier
)[];
export type DeleteContainerNewSuccessFieldPolicy = {
	containerId?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteContainerResultKeySpecifier = (
	| 'containerId'
	| 'deletedContainer'
	| 'nextCursor'
	| DeleteContainerResultKeySpecifier
)[];
export type DeleteContainerResultFieldPolicy = {
	containerId?: FieldPolicy<any> | FieldReadFunction<any>;
	deletedContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteItemResultKeySpecifier = ('deletedItem' | 'itemId' | 'nextCursor' | DeleteItemResultKeySpecifier)[];
export type DeleteItemResultFieldPolicy = {
	deletedItem?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DynamicHomeScreenKeySpecifier = ('cards' | 'isEnabled' | 'publish' | DynamicHomeScreenKeySpecifier)[];
export type DynamicHomeScreenFieldPolicy = {
	cards?: FieldPolicy<any> | FieldReadFunction<any>;
	isEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
	publish?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmailContactKeySpecifier = ('emailAddress' | 'verified' | EmailContactKeySpecifier)[];
export type EmailContactFieldPolicy = {
	emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
	verified?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EntitlementKeySpecifier = ('displayName' | 'key' | EntitlementKeySpecifier)[];
export type EntitlementFieldPolicy = {
	displayName?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ErrorKeySpecifier = ('message' | ErrorKeySpecifier)[];
export type ErrorFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EventCardKeySpecifier = (
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'timeframe'
	| 'title'
	| EventCardKeySpecifier
)[];
export type EventCardFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	timeframe?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeatureKeySpecifier = ('enabled' | 'id' | 'key' | 'value' | FeatureKeySpecifier)[];
export type FeatureFieldPolicy = {
	enabled?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
	value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeatureFlagKeySpecifier = ('enabled' | 'name' | FeatureFlagKeySpecifier)[];
export type FeatureFlagFieldPolicy = {
	enabled?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeaturedCardKeySpecifier = ('label' | FeaturedCardKeySpecifier)[];
export type FeaturedCardFieldPolicy = {
	label?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | FeedKeySpecifier)[];
export type FeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FeedPropertyKeySpecifier = (
	| 'actionBarArray'
	| 'forceRegenerate'
	| 'hiddenProperties'
	| 'itemTemplate'
	| 'itemTemplateRaw'
	| 'playlistId'
	| 'shareProperties'
	| FeedPropertyKeySpecifier
)[];
export type FeedPropertyFieldPolicy = {
	actionBarArray?: FieldPolicy<any> | FieldReadFunction<any>;
	forceRegenerate?: FieldPolicy<any> | FieldReadFunction<any>;
	hiddenProperties?: FieldPolicy<any> | FieldReadFunction<any>;
	itemTemplate?: FieldPolicy<any> | FieldReadFunction<any>;
	itemTemplateRaw?: FieldPolicy<any> | FieldReadFunction<any>;
	playlistId?: FieldPolicy<any> | FieldReadFunction<any>;
	shareProperties?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FetchItemVideoThumbnailResultKeySpecifier = ('image' | FetchItemVideoThumbnailResultKeySpecifier)[];
export type FetchItemVideoThumbnailResultFieldPolicy = {
	image?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GenerateFileUploadPostResultKeySpecifier = (
	| 'presignedPost'
	| 'unprocessedImageUrl'
	| GenerateFileUploadPostResultKeySpecifier
)[];
export type GenerateFileUploadPostResultFieldPolicy = {
	presignedPost?: FieldPolicy<any> | FieldReadFunction<any>;
	unprocessedImageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GivePropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'givingLink'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'isExternal'
	| 'itemId'
	| 'position'
	| 'type'
	| GivePropertyKeySpecifier
)[];
export type GivePropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	givingLink?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isExternal?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IcalFeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | IcalFeedKeySpecifier)[];
export type IcalFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IdentityKeySpecifier = (
	| 'accountType'
	| 'address'
	| 'administeredOrganizations'
	| 'allPermissions'
	| 'birthday'
	| 'clientIpAddress'
	| 'createdOn'
	| 'emailAddresses'
	| 'firstName'
	| 'identityKey'
	| 'images'
	| 'lastName'
	| 'olsonTimeZone'
	| 'primaryEmailAddress'
	| 'timeZoneId'
	| IdentityKeySpecifier
)[];
export type IdentityFieldPolicy = {
	accountType?: FieldPolicy<any> | FieldReadFunction<any>;
	address?: FieldPolicy<any> | FieldReadFunction<any>;
	administeredOrganizations?: FieldPolicy<any> | FieldReadFunction<any>;
	allPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
	birthday?: FieldPolicy<any> | FieldReadFunction<any>;
	clientIpAddress?: FieldPolicy<any> | FieldReadFunction<any>;
	createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
	emailAddresses?: FieldPolicy<any> | FieldReadFunction<any>;
	firstName?: FieldPolicy<any> | FieldReadFunction<any>;
	identityKey?: FieldPolicy<any> | FieldReadFunction<any>;
	images?: FieldPolicy<any> | FieldReadFunction<any>;
	lastName?: FieldPolicy<any> | FieldReadFunction<any>;
	olsonTimeZone?: FieldPolicy<any> | FieldReadFunction<any>;
	primaryEmailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
	timeZoneId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IdentityAddressKeySpecifier = (
	| 'city'
	| 'countryCode'
	| 'line1'
	| 'line2'
	| 'postcode'
	| 'state'
	| IdentityAddressKeySpecifier
)[];
export type IdentityAddressFieldPolicy = {
	city?: FieldPolicy<any> | FieldReadFunction<any>;
	countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
	line1?: FieldPolicy<any> | FieldReadFunction<any>;
	line2?: FieldPolicy<any> | FieldReadFunction<any>;
	postcode?: FieldPolicy<any> | FieldReadFunction<any>;
	state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IdentityImageKeySpecifier = ('url' | IdentityImageKeySpecifier)[];
export type IdentityImageFieldPolicy = {
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IdentityImageSetKeySpecifier = ('avatar' | IdentityImageSetKeySpecifier)[];
export type IdentityImageSetFieldPolicy = {
	avatar?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImageKeySpecifier = ('id' | 'urls' | ImageKeySpecifier)[];
export type ImageFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	urls?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImageFormatUrlsKeySpecifier = (
	| 'dynamicHomeScreen'
	| 'landscape'
	| 'original'
	| 'panorama'
	| 'square'
	| 'unprocessedImageUrl'
	| ImageFormatUrlsKeySpecifier
)[];
export type ImageFormatUrlsFieldPolicy = {
	dynamicHomeScreen?: FieldPolicy<any> | FieldReadFunction<any>;
	landscape?: FieldPolicy<any> | FieldReadFunction<any>;
	original?: FieldPolicy<any> | FieldReadFunction<any>;
	panorama?: FieldPolicy<any> | FieldReadFunction<any>;
	square?: FieldPolicy<any> | FieldReadFunction<any>;
	unprocessedImageUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImpactCardKeySpecifier = (
	| 'callToActionText'
	| 'featured'
	| 'id'
	| 'item'
	| 'keyMetrics'
	| 'keyMetricsCount'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| ImpactCardKeySpecifier
)[];
export type ImpactCardFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	keyMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
	keyMetricsCount?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImpactCardContentKeySpecifier = ('summary' | ImpactCardContentKeySpecifier)[];
export type ImpactCardContentFieldPolicy = {
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImpactCardDefinitionKeySpecifier = (
	| 'callToActionText'
	| 'cardType'
	| 'content'
	| 'displayProperties'
	| 'featuredCard'
	| 'id'
	| 'isFeatured'
	| 'sortTimestamp'
	| ImpactCardDefinitionKeySpecifier
)[];
export type ImpactCardDefinitionFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	cardType?: FieldPolicy<any> | FieldReadFunction<any>;
	content?: FieldPolicy<any> | FieldReadFunction<any>;
	displayProperties?: FieldPolicy<any> | FieldReadFunction<any>;
	featuredCard?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isFeatured?: FieldPolicy<any> | FieldReadFunction<any>;
	sortTimestamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ImpactDisplayPropertiesKeySpecifier = (
	| 'showKeyMetrics'
	| 'showTitle'
	| ImpactDisplayPropertiesKeySpecifier
)[];
export type ImpactDisplayPropertiesFieldPolicy = {
	showKeyMetrics?: FieldPolicy<any> | FieldReadFunction<any>;
	showTitle?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IntroCardKeySpecifier = (
	| 'callToActionText'
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| IntroCardKeySpecifier
)[];
export type IntroCardFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvalidRequestErrorKeySpecifier = ('message' | InvalidRequestErrorKeySpecifier)[];
export type InvalidRequestErrorFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemKeySpecifier = (
	| 'actions'
	| 'cardDefinition'
	| 'createdDate'
	| 'feedId'
	| 'icon'
	| 'id'
	| 'image'
	| 'isHidden'
	| 'isMirrored'
	| 'name'
	| 'parentContainer'
	| 'properties'
	| 'related'
	| 'template'
	| 'type'
	| 'updatedDate'
	| ItemKeySpecifier
)[];
export type ItemFieldPolicy = {
	actions?: FieldPolicy<any> | FieldReadFunction<any>;
	cardDefinition?: FieldPolicy<any> | FieldReadFunction<any>;
	createdDate?: FieldPolicy<any> | FieldReadFunction<any>;
	feedId?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	image?: FieldPolicy<any> | FieldReadFunction<any>;
	isHidden?: FieldPolicy<any> | FieldReadFunction<any>;
	isMirrored?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	parentContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	related?: FieldPolicy<any> | FieldReadFunction<any>;
	template?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	updatedDate?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemActionKeySpecifier = ('isHidden' | 'property' | ItemActionKeySpecifier)[];
export type ItemActionFieldPolicy = {
	isHidden?: FieldPolicy<any> | FieldReadFunction<any>;
	property?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemCardContentKeySpecifier = ('subtitle' | 'summary' | ItemCardContentKeySpecifier)[];
export type ItemCardContentFieldPolicy = {
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemCardDefinitionKeySpecifier = (
	| 'callToActionText'
	| 'cardType'
	| 'featuredCard'
	| 'id'
	| 'isFeatured'
	| 'sortTimestamp'
	| ItemCardDefinitionKeySpecifier
)[];
export type ItemCardDefinitionFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	cardType?: FieldPolicy<any> | FieldReadFunction<any>;
	featuredCard?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isFeatured?: FieldPolicy<any> | FieldReadFunction<any>;
	sortTimestamp?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemDisplayPropertiesKeySpecifier = (
	| 'showCategory'
	| 'showPublishedTime'
	| ItemDisplayPropertiesKeySpecifier
)[];
export type ItemDisplayPropertiesFieldPolicy = {
	showCategory?: FieldPolicy<any> | FieldReadFunction<any>;
	showPublishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'type'
	| ItemPropertyKeySpecifier
)[];
export type ItemPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ItemPropertyActionKeySpecifier = ('click' | ItemPropertyActionKeySpecifier)[];
export type ItemPropertyActionFieldPolicy = {
	click?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KeyMetricKeySpecifier = ('title' | 'value' | KeyMetricKeySpecifier)[];
export type KeyMetricFieldPolicy = {
	title?: FieldPolicy<any> | FieldReadFunction<any>;
	value?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type KeyMetricsPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'metrics'
	| 'metricsCount'
	| 'position'
	| 'type'
	| KeyMetricsPropertyKeySpecifier
)[];
export type KeyMetricsPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	metrics?: FieldPolicy<any> | FieldReadFunction<any>;
	metricsCount?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoveChildErrorKeySpecifier = ('childId' | 'message' | MoveChildErrorKeySpecifier)[];
export type MoveChildErrorFieldPolicy = {
	childId?: FieldPolicy<any> | FieldReadFunction<any>;
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoveChildSuccessKeySpecifier = ('movedChild' | MoveChildSuccessKeySpecifier)[];
export type MoveChildSuccessFieldPolicy = {
	movedChild?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MoveChildrenOutputKeySpecifier = (
	| 'moveChildResults'
	| 'newParentContainer'
	| 'nextCursor'
	| 'previousParentContainer'
	| MoveChildrenOutputKeySpecifier
)[];
export type MoveChildrenOutputFieldPolicy = {
	moveChildResults?: FieldPolicy<any> | FieldReadFunction<any>;
	newParentContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	nextCursor?: FieldPolicy<any> | FieldReadFunction<any>;
	previousParentContainer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
	| 'copyItems'
	| 'createContainer'
	| 'createItem'
	| 'deleteContainer'
	| 'deleteContainerNew'
	| 'deleteItem'
	| 'fetchItemVideoThumbnail'
	| 'generateFileUploadPost'
	| 'moveChildren'
	| 'processContainerFeed'
	| 'publishChanges'
	| 'reorderContainerChildren'
	| 'saveContainerSettings'
	| 'saveItemSettings'
	| 'updateContainerVisibility'
	| 'updateItemVisibility'
	| 'updateProfile'
	| MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
	copyItems?: FieldPolicy<any> | FieldReadFunction<any>;
	createContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	createItem?: FieldPolicy<any> | FieldReadFunction<any>;
	deleteContainer?: FieldPolicy<any> | FieldReadFunction<any>;
	deleteContainerNew?: FieldPolicy<any> | FieldReadFunction<any>;
	deleteItem?: FieldPolicy<any> | FieldReadFunction<any>;
	fetchItemVideoThumbnail?: FieldPolicy<any> | FieldReadFunction<any>;
	generateFileUploadPost?: FieldPolicy<any> | FieldReadFunction<any>;
	moveChildren?: FieldPolicy<any> | FieldReadFunction<any>;
	processContainerFeed?: FieldPolicy<any> | FieldReadFunction<any>;
	publishChanges?: FieldPolicy<any> | FieldReadFunction<any>;
	reorderContainerChildren?: FieldPolicy<any> | FieldReadFunction<any>;
	saveContainerSettings?: FieldPolicy<any> | FieldReadFunction<any>;
	saveItemSettings?: FieldPolicy<any> | FieldReadFunction<any>;
	updateContainerVisibility?: FieldPolicy<any> | FieldReadFunction<any>;
	updateItemVisibility?: FieldPolicy<any> | FieldReadFunction<any>;
	updateProfile?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationKeySpecifier = (
	| 'appStudio'
	| 'application'
	| 'applications'
	| 'details'
	| 'featureFlags'
	| 'key'
	| OrganizationKeySpecifier
)[];
export type OrganizationFieldPolicy = {
	appStudio?: FieldPolicy<any> | FieldReadFunction<any>;
	application?: FieldPolicy<any> | FieldReadFunction<any>;
	applications?: FieldPolicy<any> | FieldReadFunction<any>;
	details?: FieldPolicy<any> | FieldReadFunction<any>;
	featureFlags?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationDetailsKeySpecifier = (
	| 'catholicEntity'
	| 'catholicSoftwareStatus'
	| 'community'
	| 'ecgId'
	| 'entitlements'
	| 'isGivingPlatformActive'
	| 'isMasAppPresent'
	| 'isSalesforceCustomAppPresent'
	| 'name'
	| 'productInformation'
	| 'shippingAddress'
	| OrganizationDetailsKeySpecifier
)[];
export type OrganizationDetailsFieldPolicy = {
	catholicEntity?: FieldPolicy<any> | FieldReadFunction<any>;
	catholicSoftwareStatus?: FieldPolicy<any> | FieldReadFunction<any>;
	community?: FieldPolicy<any> | FieldReadFunction<any>;
	ecgId?: FieldPolicy<any> | FieldReadFunction<any>;
	entitlements?: FieldPolicy<any> | FieldReadFunction<any>;
	isGivingPlatformActive?: FieldPolicy<any> | FieldReadFunction<any>;
	isMasAppPresent?: FieldPolicy<any> | FieldReadFunction<any>;
	isSalesforceCustomAppPresent?: FieldPolicy<any> | FieldReadFunction<any>;
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	productInformation?: FieldPolicy<any> | FieldReadFunction<any>;
	shippingAddress?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrganizationPermissionsKeySpecifier = (
	| 'campusPermissions'
	| 'organization'
	| 'organizationKey'
	| 'permissions'
	| OrganizationPermissionsKeySpecifier
)[];
export type OrganizationPermissionsFieldPolicy = {
	campusPermissions?: FieldPolicy<any> | FieldReadFunction<any>;
	organization?: FieldPolicy<any> | FieldReadFunction<any>;
	organizationKey?: FieldPolicy<any> | FieldReadFunction<any>;
	permissions?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PermissionKeySpecifier = ('name' | 'permissionKey' | PermissionKeySpecifier)[];
export type PermissionFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>;
	permissionKey?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PodcastFeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | PodcastFeedKeySpecifier)[];
export type PodcastFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresignedPostKeySpecifier = (
	| 'acl'
	| 'algorithm'
	| 'credential'
	| 'date'
	| 'key'
	| 'policy'
	| 'signature'
	| 'tagging'
	| 'token'
	| 'url'
	| PresignedPostKeySpecifier
)[];
export type PresignedPostFieldPolicy = {
	acl?: FieldPolicy<any> | FieldReadFunction<any>;
	algorithm?: FieldPolicy<any> | FieldReadFunction<any>;
	credential?: FieldPolicy<any> | FieldReadFunction<any>;
	date?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
	policy?: FieldPolicy<any> | FieldReadFunction<any>;
	signature?: FieldPolicy<any> | FieldReadFunction<any>;
	tagging?: FieldPolicy<any> | FieldReadFunction<any>;
	token?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProcessContainerFeedResultKeySpecifier = ('feed' | ProcessContainerFeedResultKeySpecifier)[];
export type ProcessContainerFeedResultFieldPolicy = {
	feed?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductInfoKeySpecifier = ('chmsInformation' | ProductInfoKeySpecifier)[];
export type ProductInfoFieldPolicy = {
	chmsInformation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PublishChangesResultKeySpecifier = ('application' | PublishChangesResultKeySpecifier)[];
export type PublishChangesResultFieldPolicy = {
	application?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
	| 'isGuideValid'
	| 'me'
	| 'organization'
	| 'supportedCountriesDetails'
	| QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
	isGuideValid?: FieldPolicy<any> | FieldReadFunction<any>;
	me?: FieldPolicy<any> | FieldReadFunction<any>;
	organization?: FieldPolicy<any> | FieldReadFunction<any>;
	supportedCountriesDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RelatedContentKeySpecifier = ('enabled' | 'isEnabled' | 'title' | RelatedContentKeySpecifier)[];
export type RelatedContentFieldPolicy = {
	enabled?: FieldPolicy<any> | FieldReadFunction<any>;
	isEnabled?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReorderContainerChildrenResultKeySpecifier = (
	| 'updatedContainerChildren'
	| ReorderContainerChildrenResultKeySpecifier
)[];
export type ReorderContainerChildrenResultFieldPolicy = {
	updatedContainerChildren?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RootContainerErrorKeySpecifier = ('message' | RootContainerErrorKeySpecifier)[];
export type RootContainerErrorFieldPolicy = {
	message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RssFeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | RssFeedKeySpecifier)[];
export type RssFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaveContainerSettingsResultKeySpecifier = ('updatedContainer' | SaveContainerSettingsResultKeySpecifier)[];
export type SaveContainerSettingsResultFieldPolicy = {
	updatedContainer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SaveItemSettingsResultKeySpecifier = ('updatedItem' | SaveItemSettingsResultKeySpecifier)[];
export type SaveItemSettingsResultFieldPolicy = {
	updatedItem?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SharePropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'sharedProperties'
	| 'type'
	| SharePropertyKeySpecifier
)[];
export type SharePropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	sharedProperties?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ShellCapabilityKeySpecifier = ('isSupported' | 'key' | ShellCapabilityKeySpecifier)[];
export type ShellCapabilityFieldPolicy = {
	isSupported?: FieldPolicy<any> | FieldReadFunction<any>;
	key?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SmsPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'androidAppUrl'
	| 'androidDefaultUrl'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'iosAppUrl'
	| 'iosDefaultUrl'
	| 'itemId'
	| 'messageBody'
	| 'messageLabel'
	| 'phoneNumber'
	| 'position'
	| 'type'
	| SmsPropertyKeySpecifier
)[];
export type SmsPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	androidAppUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	androidDefaultUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	iosAppUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	iosDefaultUrl?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	messageBody?: FieldPolicy<any> | FieldReadFunction<any>;
	messageLabel?: FieldPolicy<any> | FieldReadFunction<any>;
	phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SpecialCardKeySpecifier = (
	| 'callToActionText'
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| SpecialCardKeySpecifier
)[];
export type SpecialCardFieldPolicy = {
	callToActionText?: FieldPolicy<any> | FieldReadFunction<any>;
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StudioContainerTypePropertiesKeySpecifier = (
	| 'ctaLabel'
	| 'description'
	| 'link'
	| 'title'
	| StudioContainerTypePropertiesKeySpecifier
)[];
export type StudioContainerTypePropertiesFieldPolicy = {
	ctaLabel?: FieldPolicy<any> | FieldReadFunction<any>;
	description?: FieldPolicy<any> | FieldReadFunction<any>;
	link?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TextHtmlPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'text'
	| 'textHtml'
	| 'type'
	| TextHtmlPropertyKeySpecifier
)[];
export type TextHtmlPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	text?: FieldPolicy<any> | FieldReadFunction<any>;
	textHtml?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TextPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'textDescription'
	| 'type'
	| TextPropertyKeySpecifier
)[];
export type TextPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	textDescription?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeZoneKeySpecifier = ('olsonName' | 'windowsName' | TimeZoneKeySpecifier)[];
export type TimeZoneFieldPolicy = {
	olsonName?: FieldPolicy<any> | FieldReadFunction<any>;
	windowsName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeframeKeySpecifier = ('allDay' | 'endTime' | 'startTime' | TimeframeKeySpecifier)[];
export type TimeframeFieldPolicy = {
	allDay?: FieldPolicy<any> | FieldReadFunction<any>;
	endTime?: FieldPolicy<any> | FieldReadFunction<any>;
	startTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimeframePropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'timeframe'
	| 'type'
	| TimeframePropertyKeySpecifier
)[];
export type TimeframePropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	timeframe?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateContainerVisibilityResultKeySpecifier = (
	| 'updatedContainer'
	| UpdateContainerVisibilityResultKeySpecifier
)[];
export type UpdateContainerVisibilityResultFieldPolicy = {
	updatedContainer?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateItemVisibilityResultKeySpecifier = ('updatedItem' | UpdateItemVisibilityResultKeySpecifier)[];
export type UpdateItemVisibilityResultFieldPolicy = {
	updatedItem?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateProfileResultKeySpecifier = ('identity' | 'success' | UpdateProfileResultKeySpecifier)[];
export type UpdateProfileResultFieldPolicy = {
	identity?: FieldPolicy<any> | FieldReadFunction<any>;
	success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserNotePropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'hintText'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'position'
	| 'type'
	| UserNotePropertyKeySpecifier
)[];
export type UserNotePropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	hintText?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VideoCardKeySpecifier = (
	| 'category'
	| 'featured'
	| 'id'
	| 'item'
	| 'publishedTime'
	| 'subtitle'
	| 'summary'
	| 'tabName'
	| 'title'
	| 'videoUrl'
	| VideoCardKeySpecifier
)[];
export type VideoCardFieldPolicy = {
	category?: FieldPolicy<any> | FieldReadFunction<any>;
	featured?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	item?: FieldPolicy<any> | FieldReadFunction<any>;
	publishedTime?: FieldPolicy<any> | FieldReadFunction<any>;
	subtitle?: FieldPolicy<any> | FieldReadFunction<any>;
	summary?: FieldPolicy<any> | FieldReadFunction<any>;
	tabName?: FieldPolicy<any> | FieldReadFunction<any>;
	title?: FieldPolicy<any> | FieldReadFunction<any>;
	videoUrl?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VideoFeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | VideoFeedKeySpecifier)[];
export type VideoFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VideoPropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'description'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'isEmbed'
	| 'itemId'
	| 'position'
	| 'type'
	| 'url'
	| VideoPropertyKeySpecifier
)[];
export type VideoPropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	description?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isEmbed?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VimeoFeedKeySpecifier = (
	| 'id'
	| 'identifiers'
	| 'processState'
	| 'properties'
	| 'url'
	| VimeoFeedKeySpecifier
)[];
export type VimeoFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	identifiers?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VimeoFeedIdentifiersKeySpecifier = (
	| 'channelId'
	| 'groupId'
	| 'showcaseId'
	| 'type'
	| 'userId'
	| VimeoFeedIdentifiersKeySpecifier
)[];
export type VimeoFeedIdentifiersFieldPolicy = {
	channelId?: FieldPolicy<any> | FieldReadFunction<any>;
	groupId?: FieldPolicy<any> | FieldReadFunction<any>;
	showcaseId?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WebsitePropertyKeySpecifier = (
	| 'action'
	| 'actionBar'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'isExternal'
	| 'itemId'
	| 'label'
	| 'position'
	| 'type'
	| 'url'
	| WebsitePropertyKeySpecifier
)[];
export type WebsitePropertyFieldPolicy = {
	action?: FieldPolicy<any> | FieldReadFunction<any>;
	actionBar?: FieldPolicy<any> | FieldReadFunction<any>;
	header?: FieldPolicy<any> | FieldReadFunction<any>;
	hidden?: FieldPolicy<any> | FieldReadFunction<any>;
	icon?: FieldPolicy<any> | FieldReadFunction<any>;
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	isExternal?: FieldPolicy<any> | FieldReadFunction<any>;
	itemId?: FieldPolicy<any> | FieldReadFunction<any>;
	label?: FieldPolicy<any> | FieldReadFunction<any>;
	position?: FieldPolicy<any> | FieldReadFunction<any>;
	type?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type YouTubeFeedKeySpecifier = ('id' | 'processState' | 'properties' | 'url' | YouTubeFeedKeySpecifier)[];
export type YouTubeFeedFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>;
	processState?: FieldPolicy<any> | FieldReadFunction<any>;
	properties?: FieldPolicy<any> | FieldReadFunction<any>;
	url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
	ActionBar?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ActionBarKeySpecifier | (() => undefined | ActionBarKeySpecifier);
		fields?: ActionBarFieldPolicy;
	};
	AddToContactsProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AddToContactsPropertyKeySpecifier | (() => undefined | AddToContactsPropertyKeySpecifier);
		fields?: AddToContactsPropertyFieldPolicy;
	};
	Address?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AddressKeySpecifier | (() => undefined | AddressKeySpecifier);
		fields?: AddressFieldPolicy;
	};
	AddressProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AddressPropertyKeySpecifier | (() => undefined | AddressPropertyKeySpecifier);
		fields?: AddressPropertyFieldPolicy;
	};
	AdministeredOrganization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| AdministeredOrganizationKeySpecifier
			| (() => undefined | AdministeredOrganizationKeySpecifier);
		fields?: AdministeredOrganizationFieldPolicy;
	};
	AllIdentityPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AllIdentityPermissionsKeySpecifier | (() => undefined | AllIdentityPermissionsKeySpecifier);
		fields?: AllIdentityPermissionsFieldPolicy;
	};
	AppCampus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AppCampusKeySpecifier | (() => undefined | AppCampusKeySpecifier);
		fields?: AppCampusFieldPolicy;
	};
	AppLinkProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AppLinkPropertyKeySpecifier | (() => undefined | AppLinkPropertyKeySpecifier);
		fields?: AppLinkPropertyFieldPolicy;
	};
	AppStudio?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AppStudioKeySpecifier | (() => undefined | AppStudioKeySpecifier);
		fields?: AppStudioFieldPolicy;
	};
	Application?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ApplicationKeySpecifier | (() => undefined | ApplicationKeySpecifier);
		fields?: ApplicationFieldPolicy;
	};
	ApplicationImages?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ApplicationImagesKeySpecifier | (() => undefined | ApplicationImagesKeySpecifier);
		fields?: ApplicationImagesFieldPolicy;
	};
	ApplicationSetting?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ApplicationSettingKeySpecifier | (() => undefined | ApplicationSettingKeySpecifier);
		fields?: ApplicationSettingFieldPolicy;
	};
	AudioCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AudioCardKeySpecifier | (() => undefined | AudioCardKeySpecifier);
		fields?: AudioCardFieldPolicy;
	};
	AudioProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | AudioPropertyKeySpecifier | (() => undefined | AudioPropertyKeySpecifier);
		fields?: AudioPropertyFieldPolicy;
	};
	BaseCardDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | BaseCardDefinitionKeySpecifier | (() => undefined | BaseCardDefinitionKeySpecifier);
		fields?: BaseCardDefinitionFieldPolicy;
	};
	Birthday?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | BirthdayKeySpecifier | (() => undefined | BirthdayKeySpecifier);
		fields?: BirthdayFieldPolicy;
	};
	BlankifyProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | BlankifyPropertyKeySpecifier | (() => undefined | BlankifyPropertyKeySpecifier);
		fields?: BlankifyPropertyFieldPolicy;
	};
	CallToActionProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CallToActionPropertyKeySpecifier | (() => undefined | CallToActionPropertyKeySpecifier);
		fields?: CallToActionPropertyFieldPolicy;
	};
	CampusPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CampusPermissionsKeySpecifier | (() => undefined | CampusPermissionsKeySpecifier);
		fields?: CampusPermissionsFieldPolicy;
	};
	Card?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CardKeySpecifier | (() => undefined | CardKeySpecifier);
		fields?: CardFieldPolicy;
	};
	CardDataMap?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CardDataMapKeySpecifier | (() => undefined | CardDataMapKeySpecifier);
		fields?: CardDataMapFieldPolicy;
	};
	CarouselProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CarouselPropertyKeySpecifier | (() => undefined | CarouselPropertyKeySpecifier);
		fields?: CarouselPropertyFieldPolicy;
	};
	ChildContainer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ChildContainerKeySpecifier | (() => undefined | ChildContainerKeySpecifier);
		fields?: ChildContainerFieldPolicy;
	};
	ChildItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ChildItemKeySpecifier | (() => undefined | ChildItemKeySpecifier);
		fields?: ChildItemFieldPolicy;
	};
	ChmsInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ChmsInfoKeySpecifier | (() => undefined | ChmsInfoKeySpecifier);
		fields?: ChmsInfoFieldPolicy;
	};
	ClientField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ClientFieldKeySpecifier | (() => undefined | ClientFieldKeySpecifier);
		fields?: ClientFieldFieldPolicy;
	};
	ClientIpAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ClientIpAddressKeySpecifier | (() => undefined | ClientIpAddressKeySpecifier);
		fields?: ClientIpAddressFieldPolicy;
	};
	Community?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CommunityKeySpecifier | (() => undefined | CommunityKeySpecifier);
		fields?: CommunityFieldPolicy;
	};
	Container?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ContainerKeySpecifier | (() => undefined | ContainerKeySpecifier);
		fields?: ContainerFieldPolicy;
	};
	ContainerCardDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| ContainerCardDefinitionKeySpecifier
			| (() => undefined | ContainerCardDefinitionKeySpecifier);
		fields?: ContainerCardDefinitionFieldPolicy;
	};
	ContainerChild?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ContainerChildKeySpecifier | (() => undefined | ContainerChildKeySpecifier);
		fields?: ContainerChildFieldPolicy;
	};
	ContainerNotEmptyError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ContainerNotEmptyErrorKeySpecifier | (() => undefined | ContainerNotEmptyErrorKeySpecifier);
		fields?: ContainerNotEmptyErrorFieldPolicy;
	};
	CopyItemError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CopyItemErrorKeySpecifier | (() => undefined | CopyItemErrorKeySpecifier);
		fields?: CopyItemErrorFieldPolicy;
	};
	CopyItemSuccess?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CopyItemSuccessKeySpecifier | (() => undefined | CopyItemSuccessKeySpecifier);
		fields?: CopyItemSuccessFieldPolicy;
	};
	CopyItemsOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CopyItemsOutputKeySpecifier | (() => undefined | CopyItemsOutputKeySpecifier);
		fields?: CopyItemsOutputFieldPolicy;
	};
	CountryType?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CountryTypeKeySpecifier | (() => undefined | CountryTypeKeySpecifier);
		fields?: CountryTypeFieldPolicy;
	};
	CreateContainerResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CreateContainerResultKeySpecifier | (() => undefined | CreateContainerResultKeySpecifier);
		fields?: CreateContainerResultFieldPolicy;
	};
	CreateItemResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CreateItemResultKeySpecifier | (() => undefined | CreateItemResultKeySpecifier);
		fields?: CreateItemResultFieldPolicy;
	};
	CursorPagedContainerChildren?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| CursorPagedContainerChildrenKeySpecifier
			| (() => undefined | CursorPagedContainerChildrenKeySpecifier);
		fields?: CursorPagedContainerChildrenFieldPolicy;
	};
	CursorPagingOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | CursorPagingOutputKeySpecifier | (() => undefined | CursorPagingOutputKeySpecifier);
		fields?: CursorPagingOutputFieldPolicy;
	};
	DataMap?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DataMapKeySpecifier | (() => undefined | DataMapKeySpecifier);
		fields?: DataMapFieldPolicy;
	};
	DefaultCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DefaultCardKeySpecifier | (() => undefined | DefaultCardKeySpecifier);
		fields?: DefaultCardFieldPolicy;
	};
	DefaultProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DefaultPropertyKeySpecifier | (() => undefined | DefaultPropertyKeySpecifier);
		fields?: DefaultPropertyFieldPolicy;
	};
	DeleteContainerNewSuccess?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| DeleteContainerNewSuccessKeySpecifier
			| (() => undefined | DeleteContainerNewSuccessKeySpecifier);
		fields?: DeleteContainerNewSuccessFieldPolicy;
	};
	DeleteContainerResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DeleteContainerResultKeySpecifier | (() => undefined | DeleteContainerResultKeySpecifier);
		fields?: DeleteContainerResultFieldPolicy;
	};
	DeleteItemResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DeleteItemResultKeySpecifier | (() => undefined | DeleteItemResultKeySpecifier);
		fields?: DeleteItemResultFieldPolicy;
	};
	DynamicHomeScreen?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | DynamicHomeScreenKeySpecifier | (() => undefined | DynamicHomeScreenKeySpecifier);
		fields?: DynamicHomeScreenFieldPolicy;
	};
	EmailContact?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | EmailContactKeySpecifier | (() => undefined | EmailContactKeySpecifier);
		fields?: EmailContactFieldPolicy;
	};
	Entitlement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | EntitlementKeySpecifier | (() => undefined | EntitlementKeySpecifier);
		fields?: EntitlementFieldPolicy;
	};
	Error?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ErrorKeySpecifier | (() => undefined | ErrorKeySpecifier);
		fields?: ErrorFieldPolicy;
	};
	EventCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | EventCardKeySpecifier | (() => undefined | EventCardKeySpecifier);
		fields?: EventCardFieldPolicy;
	};
	Feature?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | FeatureKeySpecifier | (() => undefined | FeatureKeySpecifier);
		fields?: FeatureFieldPolicy;
	};
	FeatureFlag?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | FeatureFlagKeySpecifier | (() => undefined | FeatureFlagKeySpecifier);
		fields?: FeatureFlagFieldPolicy;
	};
	FeaturedCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | FeaturedCardKeySpecifier | (() => undefined | FeaturedCardKeySpecifier);
		fields?: FeaturedCardFieldPolicy;
	};
	Feed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | FeedKeySpecifier | (() => undefined | FeedKeySpecifier);
		fields?: FeedFieldPolicy;
	};
	FeedProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | FeedPropertyKeySpecifier | (() => undefined | FeedPropertyKeySpecifier);
		fields?: FeedPropertyFieldPolicy;
	};
	FetchItemVideoThumbnailResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| FetchItemVideoThumbnailResultKeySpecifier
			| (() => undefined | FetchItemVideoThumbnailResultKeySpecifier);
		fields?: FetchItemVideoThumbnailResultFieldPolicy;
	};
	GenerateFileUploadPostResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| GenerateFileUploadPostResultKeySpecifier
			| (() => undefined | GenerateFileUploadPostResultKeySpecifier);
		fields?: GenerateFileUploadPostResultFieldPolicy;
	};
	GiveProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | GivePropertyKeySpecifier | (() => undefined | GivePropertyKeySpecifier);
		fields?: GivePropertyFieldPolicy;
	};
	IcalFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IcalFeedKeySpecifier | (() => undefined | IcalFeedKeySpecifier);
		fields?: IcalFeedFieldPolicy;
	};
	Identity?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IdentityKeySpecifier | (() => undefined | IdentityKeySpecifier);
		fields?: IdentityFieldPolicy;
	};
	IdentityAddress?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IdentityAddressKeySpecifier | (() => undefined | IdentityAddressKeySpecifier);
		fields?: IdentityAddressFieldPolicy;
	};
	IdentityImage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IdentityImageKeySpecifier | (() => undefined | IdentityImageKeySpecifier);
		fields?: IdentityImageFieldPolicy;
	};
	IdentityImageSet?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IdentityImageSetKeySpecifier | (() => undefined | IdentityImageSetKeySpecifier);
		fields?: IdentityImageSetFieldPolicy;
	};
	Image?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ImageKeySpecifier | (() => undefined | ImageKeySpecifier);
		fields?: ImageFieldPolicy;
	};
	ImageFormatUrls?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ImageFormatUrlsKeySpecifier | (() => undefined | ImageFormatUrlsKeySpecifier);
		fields?: ImageFormatUrlsFieldPolicy;
	};
	ImpactCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ImpactCardKeySpecifier | (() => undefined | ImpactCardKeySpecifier);
		fields?: ImpactCardFieldPolicy;
	};
	ImpactCardContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ImpactCardContentKeySpecifier | (() => undefined | ImpactCardContentKeySpecifier);
		fields?: ImpactCardContentFieldPolicy;
	};
	ImpactCardDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ImpactCardDefinitionKeySpecifier | (() => undefined | ImpactCardDefinitionKeySpecifier);
		fields?: ImpactCardDefinitionFieldPolicy;
	};
	ImpactDisplayProperties?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| ImpactDisplayPropertiesKeySpecifier
			| (() => undefined | ImpactDisplayPropertiesKeySpecifier);
		fields?: ImpactDisplayPropertiesFieldPolicy;
	};
	IntroCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | IntroCardKeySpecifier | (() => undefined | IntroCardKeySpecifier);
		fields?: IntroCardFieldPolicy;
	};
	InvalidRequestError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | InvalidRequestErrorKeySpecifier | (() => undefined | InvalidRequestErrorKeySpecifier);
		fields?: InvalidRequestErrorFieldPolicy;
	};
	Item?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemKeySpecifier | (() => undefined | ItemKeySpecifier);
		fields?: ItemFieldPolicy;
	};
	ItemAction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemActionKeySpecifier | (() => undefined | ItemActionKeySpecifier);
		fields?: ItemActionFieldPolicy;
	};
	ItemCardContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemCardContentKeySpecifier | (() => undefined | ItemCardContentKeySpecifier);
		fields?: ItemCardContentFieldPolicy;
	};
	ItemCardDefinition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemCardDefinitionKeySpecifier | (() => undefined | ItemCardDefinitionKeySpecifier);
		fields?: ItemCardDefinitionFieldPolicy;
	};
	ItemDisplayProperties?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemDisplayPropertiesKeySpecifier | (() => undefined | ItemDisplayPropertiesKeySpecifier);
		fields?: ItemDisplayPropertiesFieldPolicy;
	};
	ItemProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemPropertyKeySpecifier | (() => undefined | ItemPropertyKeySpecifier);
		fields?: ItemPropertyFieldPolicy;
	};
	ItemPropertyAction?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ItemPropertyActionKeySpecifier | (() => undefined | ItemPropertyActionKeySpecifier);
		fields?: ItemPropertyActionFieldPolicy;
	};
	KeyMetric?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | KeyMetricKeySpecifier | (() => undefined | KeyMetricKeySpecifier);
		fields?: KeyMetricFieldPolicy;
	};
	KeyMetricsProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | KeyMetricsPropertyKeySpecifier | (() => undefined | KeyMetricsPropertyKeySpecifier);
		fields?: KeyMetricsPropertyFieldPolicy;
	};
	MoveChildError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | MoveChildErrorKeySpecifier | (() => undefined | MoveChildErrorKeySpecifier);
		fields?: MoveChildErrorFieldPolicy;
	};
	MoveChildSuccess?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | MoveChildSuccessKeySpecifier | (() => undefined | MoveChildSuccessKeySpecifier);
		fields?: MoveChildSuccessFieldPolicy;
	};
	MoveChildrenOutput?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | MoveChildrenOutputKeySpecifier | (() => undefined | MoveChildrenOutputKeySpecifier);
		fields?: MoveChildrenOutputFieldPolicy;
	};
	Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
		fields?: MutationFieldPolicy;
	};
	Organization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | OrganizationKeySpecifier | (() => undefined | OrganizationKeySpecifier);
		fields?: OrganizationFieldPolicy;
	};
	OrganizationDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | OrganizationDetailsKeySpecifier | (() => undefined | OrganizationDetailsKeySpecifier);
		fields?: OrganizationDetailsFieldPolicy;
	};
	OrganizationPermissions?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| OrganizationPermissionsKeySpecifier
			| (() => undefined | OrganizationPermissionsKeySpecifier);
		fields?: OrganizationPermissionsFieldPolicy;
	};
	Permission?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | PermissionKeySpecifier | (() => undefined | PermissionKeySpecifier);
		fields?: PermissionFieldPolicy;
	};
	PodcastFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | PodcastFeedKeySpecifier | (() => undefined | PodcastFeedKeySpecifier);
		fields?: PodcastFeedFieldPolicy;
	};
	PresignedPost?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | PresignedPostKeySpecifier | (() => undefined | PresignedPostKeySpecifier);
		fields?: PresignedPostFieldPolicy;
	};
	ProcessContainerFeedResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| ProcessContainerFeedResultKeySpecifier
			| (() => undefined | ProcessContainerFeedResultKeySpecifier);
		fields?: ProcessContainerFeedResultFieldPolicy;
	};
	ProductInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ProductInfoKeySpecifier | (() => undefined | ProductInfoKeySpecifier);
		fields?: ProductInfoFieldPolicy;
	};
	PublishChangesResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | PublishChangesResultKeySpecifier | (() => undefined | PublishChangesResultKeySpecifier);
		fields?: PublishChangesResultFieldPolicy;
	};
	Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
		fields?: QueryFieldPolicy;
	};
	RelatedContent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | RelatedContentKeySpecifier | (() => undefined | RelatedContentKeySpecifier);
		fields?: RelatedContentFieldPolicy;
	};
	ReorderContainerChildrenResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| ReorderContainerChildrenResultKeySpecifier
			| (() => undefined | ReorderContainerChildrenResultKeySpecifier);
		fields?: ReorderContainerChildrenResultFieldPolicy;
	};
	RootContainerError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | RootContainerErrorKeySpecifier | (() => undefined | RootContainerErrorKeySpecifier);
		fields?: RootContainerErrorFieldPolicy;
	};
	RssFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | RssFeedKeySpecifier | (() => undefined | RssFeedKeySpecifier);
		fields?: RssFeedFieldPolicy;
	};
	SaveContainerSettingsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| SaveContainerSettingsResultKeySpecifier
			| (() => undefined | SaveContainerSettingsResultKeySpecifier);
		fields?: SaveContainerSettingsResultFieldPolicy;
	};
	SaveItemSettingsResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | SaveItemSettingsResultKeySpecifier | (() => undefined | SaveItemSettingsResultKeySpecifier);
		fields?: SaveItemSettingsResultFieldPolicy;
	};
	ShareProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | SharePropertyKeySpecifier | (() => undefined | SharePropertyKeySpecifier);
		fields?: SharePropertyFieldPolicy;
	};
	ShellCapability?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | ShellCapabilityKeySpecifier | (() => undefined | ShellCapabilityKeySpecifier);
		fields?: ShellCapabilityFieldPolicy;
	};
	SmsProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | SmsPropertyKeySpecifier | (() => undefined | SmsPropertyKeySpecifier);
		fields?: SmsPropertyFieldPolicy;
	};
	SpecialCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | SpecialCardKeySpecifier | (() => undefined | SpecialCardKeySpecifier);
		fields?: SpecialCardFieldPolicy;
	};
	StudioContainerTypeProperties?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| StudioContainerTypePropertiesKeySpecifier
			| (() => undefined | StudioContainerTypePropertiesKeySpecifier);
		fields?: StudioContainerTypePropertiesFieldPolicy;
	};
	TextHtmlProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | TextHtmlPropertyKeySpecifier | (() => undefined | TextHtmlPropertyKeySpecifier);
		fields?: TextHtmlPropertyFieldPolicy;
	};
	TextProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | TextPropertyKeySpecifier | (() => undefined | TextPropertyKeySpecifier);
		fields?: TextPropertyFieldPolicy;
	};
	TimeZone?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | TimeZoneKeySpecifier | (() => undefined | TimeZoneKeySpecifier);
		fields?: TimeZoneFieldPolicy;
	};
	Timeframe?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | TimeframeKeySpecifier | (() => undefined | TimeframeKeySpecifier);
		fields?: TimeframeFieldPolicy;
	};
	TimeframeProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | TimeframePropertyKeySpecifier | (() => undefined | TimeframePropertyKeySpecifier);
		fields?: TimeframePropertyFieldPolicy;
	};
	UpdateContainerVisibilityResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| UpdateContainerVisibilityResultKeySpecifier
			| (() => undefined | UpdateContainerVisibilityResultKeySpecifier);
		fields?: UpdateContainerVisibilityResultFieldPolicy;
	};
	UpdateItemVisibilityResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?:
			| false
			| UpdateItemVisibilityResultKeySpecifier
			| (() => undefined | UpdateItemVisibilityResultKeySpecifier);
		fields?: UpdateItemVisibilityResultFieldPolicy;
	};
	UpdateProfileResult?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | UpdateProfileResultKeySpecifier | (() => undefined | UpdateProfileResultKeySpecifier);
		fields?: UpdateProfileResultFieldPolicy;
	};
	UserNoteProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | UserNotePropertyKeySpecifier | (() => undefined | UserNotePropertyKeySpecifier);
		fields?: UserNotePropertyFieldPolicy;
	};
	VideoCard?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | VideoCardKeySpecifier | (() => undefined | VideoCardKeySpecifier);
		fields?: VideoCardFieldPolicy;
	};
	VideoFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | VideoFeedKeySpecifier | (() => undefined | VideoFeedKeySpecifier);
		fields?: VideoFeedFieldPolicy;
	};
	VideoProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | VideoPropertyKeySpecifier | (() => undefined | VideoPropertyKeySpecifier);
		fields?: VideoPropertyFieldPolicy;
	};
	VimeoFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | VimeoFeedKeySpecifier | (() => undefined | VimeoFeedKeySpecifier);
		fields?: VimeoFeedFieldPolicy;
	};
	VimeoFeedIdentifiers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | VimeoFeedIdentifiersKeySpecifier | (() => undefined | VimeoFeedIdentifiersKeySpecifier);
		fields?: VimeoFeedIdentifiersFieldPolicy;
	};
	WebsiteProperty?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | WebsitePropertyKeySpecifier | (() => undefined | WebsitePropertyKeySpecifier);
		fields?: WebsitePropertyFieldPolicy;
	};
	YouTubeFeed?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
		keyFields?: false | YouTubeFeedKeySpecifier | (() => undefined | YouTubeFeedKeySpecifier);
		fields?: YouTubeFeedFieldPolicy;
	};
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export type ChildContainerFragment = { __typename?: 'ChildContainer' } & Pick<
	ChildContainer,
	'id' | 'name' | 'icon' | 'hidden' | 'position'
> & {
		container: { __typename?: 'Container' } & Pick<
			Container,
			'id' | 'navigationAction' | 'isDeletable' | 'type'
		> & {
				campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name'>>;
				image?: Maybe<
					{ __typename?: 'Image' } & Pick<Image, 'id'> & {
							urls?: Maybe<
								{ __typename?: 'ImageFormatUrls' } & Pick<
									ImageFormatUrls,
									| 'square'
									| 'original'
									| 'panorama'
									| 'landscape'
									| 'dynamicHomeScreen'
									| 'unprocessedImageUrl'
								>
							>;
						}
				>;
				feed?: Maybe<
					| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
					| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
					| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
					| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
					| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
					| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
				>;
			};
	};

export type ChildContainerWithChildrenFragment = { __typename?: 'ChildContainer' } & Pick<
	ChildContainer,
	'id' | 'name' | 'icon' | 'hidden' | 'position'
> & {
		container: { __typename?: 'Container' } & Pick<
			Container,
			'id' | 'navigationAction' | 'isDeletable' | 'type'
		> & {
				campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name'>>;
				image?: Maybe<
					{ __typename?: 'Image' } & Pick<Image, 'id'> & {
							urls?: Maybe<
								{ __typename?: 'ImageFormatUrls' } & Pick<
									ImageFormatUrls,
									| 'square'
									| 'original'
									| 'panorama'
									| 'landscape'
									| 'dynamicHomeScreen'
									| 'unprocessedImageUrl'
								>
							>;
						}
				>;
				feed?: Maybe<
					| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
					| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
					| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
					| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
					| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
					| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
				>;
				children: { __typename?: 'CursorPagedContainerChildren' } & {
					nodes: Array<
						| ({ __typename?: 'ChildContainer' } & Pick<
								ChildContainer,
								'id' | 'name' | 'icon' | 'hidden' | 'position'
						  > & {
									container: { __typename?: 'Container' } & Pick<
										Container,
										'id' | 'navigationAction' | 'isDeletable' | 'type'
									> & {
											campus?: Maybe<
												{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name'>
											>;
											image?: Maybe<
												{ __typename?: 'Image' } & Pick<Image, 'id'> & {
														urls?: Maybe<
															{ __typename?: 'ImageFormatUrls' } & Pick<
																ImageFormatUrls,
																| 'square'
																| 'original'
																| 'panorama'
																| 'landscape'
																| 'dynamicHomeScreen'
																| 'unprocessedImageUrl'
															>
														>;
													}
											>;
											feed?: Maybe<
												| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
												| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
												| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
												| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
												| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
												| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
											>;
										};
								})
						| ({ __typename?: 'ChildItem' } & Pick<
								ChildItem,
								'id' | 'name' | 'icon' | 'hidden' | 'position'
						  > & {
									item: { __typename?: 'Item' } & Pick<
										Item,
										'id' | 'type' | 'isMirrored' | 'feedId'
									> & {
											image?: Maybe<
												{ __typename?: 'Image' } & Pick<Image, 'id'> & {
														urls?: Maybe<
															{ __typename?: 'ImageFormatUrls' } & Pick<
																ImageFormatUrls,
																| 'square'
																| 'original'
																| 'panorama'
																| 'landscape'
																| 'dynamicHomeScreen'
																| 'unprocessedImageUrl'
															>
														>;
													}
											>;
											properties: Array<
												| ({ __typename?: 'AddToContactsProperty' } & Pick<
														AddToContactsProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'AddressProperty' } & Pick<
														AddressProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'AppLinkProperty' } & Pick<
														AppLinkProperty,
														'label' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'AudioProperty' } & Pick<
														AudioProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'BlankifyProperty' } & Pick<
														BlankifyProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'CallToActionProperty' } & Pick<
														CallToActionProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'CarouselProperty' } & Pick<
														CarouselProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'DefaultProperty' } & Pick<
														DefaultProperty,
														'data' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'GiveProperty' } & Pick<
														GiveProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'KeyMetricsProperty' } & Pick<
														KeyMetricsProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'ShareProperty' } & Pick<
														ShareProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'SmsProperty' } & Pick<
														SmsProperty,
														'messageLabel' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'TextHtmlProperty' } & Pick<
														TextHtmlProperty,
														'text' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'TextProperty' } & Pick<
														TextProperty,
														'textDescription' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'TimeframeProperty' } & Pick<
														TimeframeProperty,
														'type' | 'header' | 'position'
												  > & {
															timeframe: { __typename?: 'Timeframe' } & Pick<
																Timeframe,
																'allDay' | 'endTime' | 'startTime'
															>;
														})
												| ({ __typename?: 'UserNoteProperty' } & Pick<
														UserNoteProperty,
														'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'VideoProperty' } & Pick<
														VideoProperty,
														'url' | 'type' | 'header' | 'position'
												  >)
												| ({ __typename?: 'WebsiteProperty' } & Pick<
														WebsiteProperty,
														'label' | 'type' | 'header' | 'position'
												  >)
											>;
										};
								})
					>;
					paging: { __typename?: 'CursorPagingOutput' } & Pick<
						CursorPagingOutput,
						'totalItemCount' | 'nextCursor' | 'size'
					>;
				};
			};
	};

export type ChildItemFragment = { __typename?: 'ChildItem' } & Pick<
	ChildItem,
	'id' | 'name' | 'icon' | 'hidden' | 'position'
> & {
		item: { __typename?: 'Item' } & Pick<Item, 'id' | 'type' | 'isMirrored' | 'feedId'> & {
				image?: Maybe<
					{ __typename?: 'Image' } & Pick<Image, 'id'> & {
							urls?: Maybe<
								{ __typename?: 'ImageFormatUrls' } & Pick<
									ImageFormatUrls,
									| 'square'
									| 'original'
									| 'panorama'
									| 'landscape'
									| 'dynamicHomeScreen'
									| 'unprocessedImageUrl'
								>
							>;
						}
				>;
				properties: Array<
					| ({ __typename?: 'AddToContactsProperty' } & Pick<
							AddToContactsProperty,
							'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'AddressProperty' } & Pick<AddressProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'AppLinkProperty' } & Pick<
							AppLinkProperty,
							'label' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'AudioProperty' } & Pick<AudioProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'BlankifyProperty' } & Pick<BlankifyProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'CallToActionProperty' } & Pick<
							CallToActionProperty,
							'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'CarouselProperty' } & Pick<CarouselProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'DefaultProperty' } & Pick<
							DefaultProperty,
							'data' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'GiveProperty' } & Pick<GiveProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'KeyMetricsProperty' } & Pick<KeyMetricsProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'ShareProperty' } & Pick<ShareProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'SmsProperty' } & Pick<
							SmsProperty,
							'messageLabel' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'TextHtmlProperty' } & Pick<
							TextHtmlProperty,
							'text' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'TextProperty' } & Pick<
							TextProperty,
							'textDescription' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'TimeframeProperty' } & Pick<
							TimeframeProperty,
							'type' | 'header' | 'position'
					  > & {
								timeframe: { __typename?: 'Timeframe' } & Pick<
									Timeframe,
									'allDay' | 'endTime' | 'startTime'
								>;
							})
					| ({ __typename?: 'UserNoteProperty' } & Pick<UserNoteProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'VideoProperty' } & Pick<VideoProperty, 'url' | 'type' | 'header' | 'position'>)
					| ({ __typename?: 'WebsiteProperty' } & Pick<
							WebsiteProperty,
							'label' | 'type' | 'header' | 'position'
					  >)
				>;
			};
	};

export type ContainerChildrenNodesFragment = { __typename?: 'CursorPagedContainerChildren' } & {
	nodes: Array<
		| ({ __typename?: 'ChildContainer' } & Pick<ChildContainer, 'id' | 'name' | 'icon' | 'hidden' | 'position'> & {
					container: { __typename?: 'Container' } & Pick<
						Container,
						'id' | 'navigationAction' | 'isDeletable' | 'type'
					> & {
							campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name'>>;
							image?: Maybe<
								{ __typename?: 'Image' } & Pick<Image, 'id'> & {
										urls?: Maybe<
											{ __typename?: 'ImageFormatUrls' } & Pick<
												ImageFormatUrls,
												| 'square'
												| 'original'
												| 'panorama'
												| 'landscape'
												| 'dynamicHomeScreen'
												| 'unprocessedImageUrl'
											>
										>;
									}
							>;
							feed?: Maybe<
								| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
								| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
								| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
								| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
								| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
								| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
							>;
						};
				})
		| ({ __typename?: 'ChildItem' } & Pick<ChildItem, 'id' | 'name' | 'icon' | 'hidden' | 'position'> & {
					item: { __typename?: 'Item' } & Pick<Item, 'id' | 'type' | 'isMirrored' | 'feedId'> & {
							image?: Maybe<
								{ __typename?: 'Image' } & Pick<Image, 'id'> & {
										urls?: Maybe<
											{ __typename?: 'ImageFormatUrls' } & Pick<
												ImageFormatUrls,
												| 'square'
												| 'original'
												| 'panorama'
												| 'landscape'
												| 'dynamicHomeScreen'
												| 'unprocessedImageUrl'
											>
										>;
									}
							>;
							properties: Array<
								| ({ __typename?: 'AddToContactsProperty' } & Pick<
										AddToContactsProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'AddressProperty' } & Pick<
										AddressProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'AppLinkProperty' } & Pick<
										AppLinkProperty,
										'label' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'AudioProperty' } & Pick<
										AudioProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'BlankifyProperty' } & Pick<
										BlankifyProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'CallToActionProperty' } & Pick<
										CallToActionProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'CarouselProperty' } & Pick<
										CarouselProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'DefaultProperty' } & Pick<
										DefaultProperty,
										'data' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'GiveProperty' } & Pick<GiveProperty, 'type' | 'header' | 'position'>)
								| ({ __typename?: 'KeyMetricsProperty' } & Pick<
										KeyMetricsProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'ShareProperty' } & Pick<
										ShareProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'SmsProperty' } & Pick<
										SmsProperty,
										'messageLabel' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'TextHtmlProperty' } & Pick<
										TextHtmlProperty,
										'text' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'TextProperty' } & Pick<
										TextProperty,
										'textDescription' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'TimeframeProperty' } & Pick<
										TimeframeProperty,
										'type' | 'header' | 'position'
								  > & {
											timeframe: { __typename?: 'Timeframe' } & Pick<
												Timeframe,
												'allDay' | 'endTime' | 'startTime'
											>;
										})
								| ({ __typename?: 'UserNoteProperty' } & Pick<
										UserNoteProperty,
										'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'VideoProperty' } & Pick<
										VideoProperty,
										'url' | 'type' | 'header' | 'position'
								  >)
								| ({ __typename?: 'WebsiteProperty' } & Pick<
										WebsiteProperty,
										'label' | 'type' | 'header' | 'position'
								  >)
							>;
						};
				})
	>;
};

export type ContainerChildrenPagingFragment = { __typename?: 'CursorPagedContainerChildren' } & {
	paging: { __typename?: 'CursorPagingOutput' } & Pick<CursorPagingOutput, 'totalItemCount' | 'nextCursor' | 'size'>;
};

export type ContainerSettingFragment = { __typename?: 'Container' } & Pick<
	Container,
	| 'id'
	| 'name'
	| 'isDeletable'
	| 'isHidden'
	| 'icon'
	| 'template'
	| 'givingLink'
	| 'type'
	| 'navigationAction'
	| 'subtitle'
	| 'resiLink'
> & {
		typeSpecificProperties?: Maybe<
			{ __typename?: 'StudioContainerTypeProperties' } & Pick<
				StudioContainerTypeProperties,
				'ctaLabel' | 'description' | 'link' | 'title'
			>
		>;
		parentContainer?: Maybe<
			{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
					parentContainer?: Maybe<
						{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
								parentContainer?: Maybe<{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'>>;
							}
					>;
				}
		>;
		image?: Maybe<
			{ __typename?: 'Image' } & Pick<Image, 'id'> & {
					urls?: Maybe<
						{ __typename?: 'ImageFormatUrls' } & Pick<
							ImageFormatUrls,
							| 'square'
							| 'original'
							| 'panorama'
							| 'landscape'
							| 'dynamicHomeScreen'
							| 'unprocessedImageUrl'
						>
					>;
				}
		>;
		cardDefinition?: Maybe<
			{ __typename?: 'ContainerCardDefinition' } & Pick<
				ContainerCardDefinition,
				'cardType' | 'id' | 'maxItems' | 'callToActionText'
			> & {
					dataMap: { __typename?: 'CardDataMap' } & {
						audioUrl?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
						publishedTime?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
						subtitle?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
						summary?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
						videoUrl?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
					};
					featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
				}
		>;
		relatedContent: { __typename?: 'RelatedContent' } & Pick<RelatedContent, 'isEnabled' | 'title'>;
		campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name' | 'givingLink'>>;
		feed?: Maybe<
			| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id' | 'url' | 'processState'> & {
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
			| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id' | 'url' | 'processState'> & {
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
			| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
			| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id' | 'url' | 'processState'> & {
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
			| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id' | 'url' | 'processState'> & {
						identifiers?: Maybe<
							{ __typename?: 'VimeoFeedIdentifiers' } & Pick<
								VimeoFeedIdentifiers,
								'channelId' | 'groupId' | 'showcaseId' | 'type' | 'userId'
							>
						>;
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
			| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id' | 'url' | 'processState'> & {
						properties?: Maybe<
							{ __typename?: 'FeedProperty' } & Pick<
								FeedProperty,
								| 'actionBarArray'
								| 'forceRegenerate'
								| 'hiddenProperties'
								| 'itemTemplate'
								| 'itemTemplateRaw'
								| 'playlistId'
								| 'shareProperties'
							>
						>;
					})
		>;
	};

type Feed_IcalFeed_Fragment = { __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id' | 'url' | 'processState'> & {
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

type Feed_PodcastFeed_Fragment = { __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id' | 'url' | 'processState'> & {
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

type Feed_RssFeed_Fragment = { __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

type Feed_VideoFeed_Fragment = { __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id' | 'url' | 'processState'> & {
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

type Feed_VimeoFeed_Fragment = { __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id' | 'url' | 'processState'> & {
		identifiers?: Maybe<
			{ __typename?: 'VimeoFeedIdentifiers' } & Pick<
				VimeoFeedIdentifiers,
				'channelId' | 'groupId' | 'showcaseId' | 'type' | 'userId'
			>
		>;
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

type Feed_YouTubeFeed_Fragment = { __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id' | 'url' | 'processState'> & {
		properties?: Maybe<
			{ __typename?: 'FeedProperty' } & Pick<
				FeedProperty,
				| 'actionBarArray'
				| 'forceRegenerate'
				| 'hiddenProperties'
				| 'itemTemplate'
				| 'itemTemplateRaw'
				| 'playlistId'
				| 'shareProperties'
			>
		>;
	};

export type FeedFragment =
	| Feed_IcalFeed_Fragment
	| Feed_PodcastFeed_Fragment
	| Feed_RssFeed_Fragment
	| Feed_VideoFeed_Fragment
	| Feed_VimeoFeed_Fragment
	| Feed_YouTubeFeed_Fragment;

export type ImageUrlsFragment = { __typename?: 'ImageFormatUrls' } & Pick<
	ImageFormatUrls,
	'square' | 'original' | 'panorama' | 'landscape' | 'dynamicHomeScreen' | 'unprocessedImageUrl'
>;

export type ItemSettingFragment = { __typename?: 'Item' } & Pick<
	Item,
	'id' | 'name' | 'feedId' | 'isHidden' | 'type' | 'template' | 'icon'
> & {
		properties: Array<
			| ({ __typename?: 'AddToContactsProperty' } & Pick<
					AddToContactsProperty,
					'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'AddressProperty' } & Pick<
					AddressProperty,
					| 'city'
					| 'latitude'
					| 'longitude'
					| 'state'
					| 'street'
					| 'zip'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'AppLinkProperty' } & Pick<
					AppLinkProperty,
					| 'androidAppUrl'
					| 'androidDefaultUrl'
					| 'iosAppUrl'
					| 'iosDefaultUrl'
					| 'label'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'AudioProperty' } & Pick<
					AudioProperty,
					'label' | 'url' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'BlankifyProperty' } & Pick<
					BlankifyProperty,
					'content' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'CallToActionProperty' } & Pick<
					CallToActionProperty,
					| 'isExternal'
					| 'label'
					| 'url'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'CarouselProperty' } & Pick<
					CarouselProperty,
					'title' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						images: Array<
							{ __typename?: 'Image' } & Pick<Image, 'id'> & {
									urls?: Maybe<
										{ __typename?: 'ImageFormatUrls' } & Pick<
											ImageFormatUrls,
											| 'square'
											| 'original'
											| 'panorama'
											| 'landscape'
											| 'dynamicHomeScreen'
											| 'unprocessedImageUrl'
										>
									>;
								}
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'DefaultProperty' } & Pick<
					DefaultProperty,
					'data' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'GiveProperty' } & Pick<
					GiveProperty,
					'givingLink' | 'isExternal' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'KeyMetricsProperty' } & Pick<
					KeyMetricsProperty,
					'metricsCount' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						metrics: Array<{ __typename?: 'KeyMetric' } & Pick<KeyMetric, 'title' | 'value'>>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'ShareProperty' } & Pick<
					ShareProperty,
					'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						sharedProperties: Array<
							| ({ __typename?: 'AddToContactsProperty' } & Pick<
									AddToContactsProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'AddressProperty' } & Pick<
									AddressProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'AppLinkProperty' } & Pick<
									AppLinkProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'AudioProperty' } & Pick<
									AudioProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'BlankifyProperty' } & Pick<
									BlankifyProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'CallToActionProperty' } & Pick<
									CallToActionProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'CarouselProperty' } & Pick<
									CarouselProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'DefaultProperty' } & Pick<
									DefaultProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'GiveProperty' } & Pick<
									GiveProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'KeyMetricsProperty' } & Pick<
									KeyMetricsProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'ShareProperty' } & Pick<
									ShareProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'SmsProperty' } & Pick<
									SmsProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'TextHtmlProperty' } & Pick<
									TextHtmlProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'TextProperty' } & Pick<
									TextProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'TimeframeProperty' } & Pick<
									TimeframeProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'UserNoteProperty' } & Pick<
									UserNoteProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'VideoProperty' } & Pick<
									VideoProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
							| ({ __typename?: 'WebsiteProperty' } & Pick<
									WebsiteProperty,
									'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
							  >)
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'SmsProperty' } & Pick<
					SmsProperty,
					| 'messageBody'
					| 'messageLabel'
					| 'phoneNumber'
					| 'androidDefaultUrl'
					| 'androidAppUrl'
					| 'iosDefaultUrl'
					| 'iosAppUrl'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'TextHtmlProperty' } & Pick<
					TextHtmlProperty,
					'text' | 'textHtml' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'TextProperty' } & Pick<
					TextProperty,
					'textDescription' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'TimeframeProperty' } & Pick<
					TimeframeProperty,
					'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						timeframe: { __typename?: 'Timeframe' } & Pick<Timeframe, 'allDay' | 'endTime' | 'startTime'>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'UserNoteProperty' } & Pick<
					UserNoteProperty,
					'hintText' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'VideoProperty' } & Pick<
					VideoProperty,
					| 'description'
					| 'isEmbed'
					| 'url'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
			| ({ __typename?: 'WebsiteProperty' } & Pick<
					WebsiteProperty,
					| 'isExternal'
					| 'label'
					| 'url'
					| 'header'
					| 'hidden'
					| 'icon'
					| 'id'
					| 'itemId'
					| 'type'
					| 'position'
			  > & {
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
						>;
					})
		>;
		cardDefinition?: Maybe<
			| ({ __typename?: 'BaseCardDefinition' } & Pick<
					BaseCardDefinition,
					'id' | 'cardType' | 'callToActionText' | 'isFeatured'
			  > & {
						displayProperties: { __typename?: 'ItemDisplayProperties' } & Pick<
							ItemDisplayProperties,
							'showPublishedTime' | 'showCategory'
						>;
						content?: Maybe<
							{ __typename?: 'ItemCardContent' } & Pick<ItemCardContent, 'subtitle' | 'summary'>
						>;
						featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
					})
			| ({ __typename?: 'ImpactCardDefinition' } & Pick<
					ImpactCardDefinition,
					'id' | 'cardType' | 'callToActionText' | 'isFeatured'
			  > & {
						displayProperties: { __typename?: 'ImpactDisplayProperties' } & Pick<
							ImpactDisplayProperties,
							'showKeyMetrics' | 'showTitle'
						>;
						content?: Maybe<{ __typename?: 'ImpactCardContent' } & Pick<ImpactCardContent, 'summary'>>;
						featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
					})
		>;
		parentContainer?: Maybe<
			{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
					campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id'>>;
					parentContainer?: Maybe<
						{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
								parentContainer?: Maybe<{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'>>;
							}
					>;
				}
		>;
		image?: Maybe<
			{ __typename?: 'Image' } & Pick<Image, 'id'> & {
					urls?: Maybe<
						{ __typename?: 'ImageFormatUrls' } & Pick<
							ImageFormatUrls,
							| 'square'
							| 'original'
							| 'panorama'
							| 'landscape'
							| 'dynamicHomeScreen'
							| 'unprocessedImageUrl'
						>
					>;
				}
		>;
	};

export type MeFragment = { __typename?: 'Identity' } & Pick<
	Identity,
	'accountType' | 'identityKey' | 'firstName' | 'lastName' | 'createdOn' | 'olsonTimeZone' | 'timeZoneId'
> & {
		primaryEmailAddress?: Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress'>>;
		allPermissions?: Maybe<
			{ __typename?: 'AllIdentityPermissions' } & {
				organizationPermissions: Array<
					{ __typename?: 'OrganizationPermissions' } & Pick<OrganizationPermissions, 'organizationKey'> & {
							organization?: Maybe<
								{ __typename?: 'OrganizationDetails' } & Pick<OrganizationDetails, 'name' | 'ecgId'>
							>;
							permissions: Array<{ __typename?: 'Permission' } & Pick<Permission, 'name'>>;
							campusPermissions: Array<
								{ __typename?: 'CampusPermissions' } & Pick<CampusPermissions, 'campusKey'> & {
										permissions: Array<{ __typename?: 'Permission' } & Pick<Permission, 'name'>>;
									}
							>;
						}
				>;
			}
		>;
		images?: Maybe<
			{ __typename?: 'IdentityImageSet' } & {
				avatar?: Maybe<{ __typename?: 'IdentityImage' } & Pick<IdentityImage, 'url'>>;
			}
		>;
		administeredOrganizations?: Maybe<
			Array<
				{ __typename?: 'AdministeredOrganization' } & Pick<AdministeredOrganization, 'key' | 'name' | 'logoUrl'>
			>
		>;
	};

export type ProfileFragment = { __typename?: 'Identity' } & Pick<
	Identity,
	'accountType' | 'firstName' | 'identityKey' | 'lastName' | 'olsonTimeZone'
> & {
		address?: Maybe<
			{ __typename?: 'IdentityAddress' } & Pick<
				IdentityAddress,
				'line1' | 'line2' | 'city' | 'postcode' | 'state' | 'countryCode'
			>
		>;
		birthday?: Maybe<{ __typename?: 'Birthday' } & Pick<Birthday, 'day' | 'month' | 'year'>>;
		emailAddresses: Array<Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress' | 'verified'>>>;
		primaryEmailAddress?: Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress'>>;
		clientIpAddress?: Maybe<{ __typename?: 'ClientIpAddress' } & Pick<ClientIpAddress, 'ipV4'>>;
	};

type PropertyData_AddToContactsProperty_Fragment = { __typename?: 'AddToContactsProperty' };

type PropertyData_AddressProperty_Fragment = { __typename?: 'AddressProperty' };

type PropertyData_AppLinkProperty_Fragment = { __typename?: 'AppLinkProperty' } & { data: AppLinkProperty['label'] };

type PropertyData_AudioProperty_Fragment = { __typename?: 'AudioProperty' } & { data: AudioProperty['url'] };

type PropertyData_BlankifyProperty_Fragment = { __typename?: 'BlankifyProperty' } & {
	data: BlankifyProperty['content'];
};

type PropertyData_CallToActionProperty_Fragment = { __typename?: 'CallToActionProperty' };

type PropertyData_CarouselProperty_Fragment = { __typename?: 'CarouselProperty' };

type PropertyData_DefaultProperty_Fragment = { __typename?: 'DefaultProperty' } & Pick<DefaultProperty, 'data'>;

type PropertyData_GiveProperty_Fragment = { __typename?: 'GiveProperty' };

type PropertyData_KeyMetricsProperty_Fragment = { __typename?: 'KeyMetricsProperty' };

type PropertyData_ShareProperty_Fragment = { __typename?: 'ShareProperty' };

type PropertyData_SmsProperty_Fragment = { __typename?: 'SmsProperty' } & { data: SmsProperty['messageLabel'] };

type PropertyData_TextHtmlProperty_Fragment = { __typename?: 'TextHtmlProperty' } & {
	data: TextHtmlProperty['textHtml'];
};

type PropertyData_TextProperty_Fragment = { __typename?: 'TextProperty' } & { data: TextProperty['textDescription'] };

type PropertyData_TimeframeProperty_Fragment = { __typename?: 'TimeframeProperty' } & {
	timeframe: { __typename?: 'Timeframe' } & Pick<Timeframe, 'allDay' | 'endTime' | 'startTime'>;
};

type PropertyData_UserNoteProperty_Fragment = { __typename?: 'UserNoteProperty' };

type PropertyData_VideoProperty_Fragment = { __typename?: 'VideoProperty' } & { data: VideoProperty['url'] };

type PropertyData_WebsiteProperty_Fragment = { __typename?: 'WebsiteProperty' } & { data: WebsiteProperty['label'] };

export type PropertyDataFragment =
	| PropertyData_AddToContactsProperty_Fragment
	| PropertyData_AddressProperty_Fragment
	| PropertyData_AppLinkProperty_Fragment
	| PropertyData_AudioProperty_Fragment
	| PropertyData_BlankifyProperty_Fragment
	| PropertyData_CallToActionProperty_Fragment
	| PropertyData_CarouselProperty_Fragment
	| PropertyData_DefaultProperty_Fragment
	| PropertyData_GiveProperty_Fragment
	| PropertyData_KeyMetricsProperty_Fragment
	| PropertyData_ShareProperty_Fragment
	| PropertyData_SmsProperty_Fragment
	| PropertyData_TextHtmlProperty_Fragment
	| PropertyData_TextProperty_Fragment
	| PropertyData_TimeframeProperty_Fragment
	| PropertyData_UserNoteProperty_Fragment
	| PropertyData_VideoProperty_Fragment
	| PropertyData_WebsiteProperty_Fragment;

type Property_AddToContactsProperty_Fragment = { __typename?: 'AddToContactsProperty' } & Pick<
	AddToContactsProperty,
	'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_AddressProperty_Fragment = { __typename?: 'AddressProperty' } & Pick<
	AddressProperty,
	| 'city'
	| 'latitude'
	| 'longitude'
	| 'state'
	| 'street'
	| 'zip'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'type'
	| 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_AppLinkProperty_Fragment = { __typename?: 'AppLinkProperty' } & Pick<
	AppLinkProperty,
	| 'label'
	| 'androidAppUrl'
	| 'androidDefaultUrl'
	| 'iosAppUrl'
	| 'iosDefaultUrl'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'type'
	| 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_AudioProperty_Fragment = { __typename?: 'AudioProperty' } & Pick<
	AudioProperty,
	'url' | 'label' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_BlankifyProperty_Fragment = { __typename?: 'BlankifyProperty' } & Pick<
	BlankifyProperty,
	'content' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_CallToActionProperty_Fragment = { __typename?: 'CallToActionProperty' } & Pick<
	CallToActionProperty,
	'isExternal' | 'label' | 'url' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_CarouselProperty_Fragment = { __typename?: 'CarouselProperty' } & Pick<
	CarouselProperty,
	'title' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		images: Array<
			{ __typename?: 'Image' } & Pick<Image, 'id'> & {
					urls?: Maybe<
						{ __typename?: 'ImageFormatUrls' } & Pick<
							ImageFormatUrls,
							| 'square'
							| 'original'
							| 'panorama'
							| 'landscape'
							| 'dynamicHomeScreen'
							| 'unprocessedImageUrl'
						>
					>;
				}
		>;
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_DefaultProperty_Fragment = { __typename?: 'DefaultProperty' } & Pick<
	DefaultProperty,
	'data' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_GiveProperty_Fragment = { __typename?: 'GiveProperty' } & Pick<
	GiveProperty,
	'givingLink' | 'isExternal' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_KeyMetricsProperty_Fragment = { __typename?: 'KeyMetricsProperty' } & Pick<
	KeyMetricsProperty,
	'metricsCount' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		metrics: Array<{ __typename?: 'KeyMetric' } & Pick<KeyMetric, 'title' | 'value'>>;
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_ShareProperty_Fragment = { __typename?: 'ShareProperty' } & Pick<
	ShareProperty,
	'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		sharedProperties: Array<
			| ({ __typename?: 'AddToContactsProperty' } & Pick<
					AddToContactsProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'AddressProperty' } & Pick<
					AddressProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'AppLinkProperty' } & Pick<
					AppLinkProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'AudioProperty' } & Pick<
					AudioProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'BlankifyProperty' } & Pick<
					BlankifyProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'CallToActionProperty' } & Pick<
					CallToActionProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'CarouselProperty' } & Pick<
					CarouselProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'DefaultProperty' } & Pick<
					DefaultProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'GiveProperty' } & Pick<
					GiveProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'KeyMetricsProperty' } & Pick<
					KeyMetricsProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'ShareProperty' } & Pick<
					ShareProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'SmsProperty' } & Pick<
					SmsProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'TextHtmlProperty' } & Pick<
					TextHtmlProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'TextProperty' } & Pick<
					TextProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'TimeframeProperty' } & Pick<
					TimeframeProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'UserNoteProperty' } & Pick<
					UserNoteProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'VideoProperty' } & Pick<
					VideoProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
			| ({ __typename?: 'WebsiteProperty' } & Pick<
					WebsiteProperty,
					'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
			  > & {
						actionBar?: Maybe<
							{ __typename?: 'ActionBar' } & Pick<ActionBar, 'position' | 'hideOriginalProperty'>
						>;
						action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
					})
		>;
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_SmsProperty_Fragment = { __typename?: 'SmsProperty' } & Pick<
	SmsProperty,
	| 'messageLabel'
	| 'messageBody'
	| 'phoneNumber'
	| 'androidDefaultUrl'
	| 'androidAppUrl'
	| 'iosDefaultUrl'
	| 'iosAppUrl'
	| 'header'
	| 'hidden'
	| 'icon'
	| 'id'
	| 'itemId'
	| 'type'
	| 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_TextHtmlProperty_Fragment = { __typename?: 'TextHtmlProperty' } & Pick<
	TextHtmlProperty,
	'textHtml' | 'text' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_TextProperty_Fragment = { __typename?: 'TextProperty' } & Pick<
	TextProperty,
	'textDescription' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_TimeframeProperty_Fragment = { __typename?: 'TimeframeProperty' } & Pick<
	TimeframeProperty,
	'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		timeframe: { __typename?: 'Timeframe' } & Pick<Timeframe, 'allDay' | 'endTime' | 'startTime'>;
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_UserNoteProperty_Fragment = { __typename?: 'UserNoteProperty' } & Pick<
	UserNoteProperty,
	'hintText' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_VideoProperty_Fragment = { __typename?: 'VideoProperty' } & Pick<
	VideoProperty,
	'url' | 'description' | 'isEmbed' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

type Property_WebsiteProperty_Fragment = { __typename?: 'WebsiteProperty' } & Pick<
	WebsiteProperty,
	'url' | 'isExternal' | 'label' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
> & {
		action?: Maybe<{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>>;
		actionBar?: Maybe<{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>>;
	};

export type PropertyFragment =
	| Property_AddToContactsProperty_Fragment
	| Property_AddressProperty_Fragment
	| Property_AppLinkProperty_Fragment
	| Property_AudioProperty_Fragment
	| Property_BlankifyProperty_Fragment
	| Property_CallToActionProperty_Fragment
	| Property_CarouselProperty_Fragment
	| Property_DefaultProperty_Fragment
	| Property_GiveProperty_Fragment
	| Property_KeyMetricsProperty_Fragment
	| Property_ShareProperty_Fragment
	| Property_SmsProperty_Fragment
	| Property_TextHtmlProperty_Fragment
	| Property_TextProperty_Fragment
	| Property_TimeframeProperty_Fragment
	| Property_UserNoteProperty_Fragment
	| Property_VideoProperty_Fragment
	| Property_WebsiteProperty_Fragment;

export type CreateContainerMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: CreateContainerInput;
}>;

export type CreateContainerMutation = { __typename?: 'Mutation' } & {
	createContainer: { __typename?: 'CreateContainerResult' } & {
		createdContainer: { __typename?: 'Container' } & Pick<
			Container,
			'id' | 'isDeletable' | 'isHidden' | 'name' | 'icon' | 'type'
		> & {
				campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name'>>;
				parentContainer?: Maybe<{ __typename?: 'Container' } & Pick<Container, 'id' | 'navigationAction'>>;
				typeSpecificProperties?: Maybe<
					{ __typename?: 'StudioContainerTypeProperties' } & Pick<
						StudioContainerTypeProperties,
						'ctaLabel' | 'description' | 'link' | 'title'
					>
				>;
			};
	};
};

export type ReorderContainerChildrenMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: ReorderContainerChildrenInput;
}>;

export type ReorderContainerChildrenMutation = { __typename?: 'Mutation' } & {
	reorderContainerChildren: { __typename?: 'ReorderContainerChildrenResult' } & {
		updatedContainerChildren: Array<
			| ({ __typename?: 'ChildContainer' } & Pick<ChildContainer, 'id' | 'position'>)
			| ({ __typename?: 'ChildItem' } & Pick<ChildItem, 'id' | 'position'>)
		>;
	};
};

export type SaveContainerSettingsMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: SaveContainerSettingsInput;
}>;

export type SaveContainerSettingsMutation = { __typename?: 'Mutation' } & {
	saveContainerSettings: { __typename?: 'SaveContainerSettingsResult' } & {
		updatedContainer: { __typename?: 'Container' } & Pick<
			Container,
			| 'id'
			| 'name'
			| 'isDeletable'
			| 'isHidden'
			| 'icon'
			| 'template'
			| 'givingLink'
			| 'type'
			| 'navigationAction'
			| 'subtitle'
			| 'resiLink'
		> & {
				typeSpecificProperties?: Maybe<
					{ __typename?: 'StudioContainerTypeProperties' } & Pick<
						StudioContainerTypeProperties,
						'ctaLabel' | 'description' | 'link' | 'title'
					>
				>;
				parentContainer?: Maybe<
					{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
							parentContainer?: Maybe<
								{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
										parentContainer?: Maybe<
											{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'>
										>;
									}
							>;
						}
				>;
				image?: Maybe<
					{ __typename?: 'Image' } & Pick<Image, 'id'> & {
							urls?: Maybe<
								{ __typename?: 'ImageFormatUrls' } & Pick<
									ImageFormatUrls,
									| 'square'
									| 'original'
									| 'panorama'
									| 'landscape'
									| 'dynamicHomeScreen'
									| 'unprocessedImageUrl'
								>
							>;
						}
				>;
				cardDefinition?: Maybe<
					{ __typename?: 'ContainerCardDefinition' } & Pick<
						ContainerCardDefinition,
						'cardType' | 'id' | 'maxItems' | 'callToActionText'
					> & {
							dataMap: { __typename?: 'CardDataMap' } & {
								audioUrl?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
								publishedTime?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
								subtitle?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
								summary?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
								videoUrl?: Maybe<{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>>;
							};
							featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
						}
				>;
				relatedContent: { __typename?: 'RelatedContent' } & Pick<RelatedContent, 'isEnabled' | 'title'>;
				campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name' | 'givingLink'>>;
				feed?: Maybe<
					| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id' | 'url' | 'processState'> & {
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
					| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id' | 'url' | 'processState'> & {
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
					| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
					| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id' | 'url' | 'processState'> & {
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
					| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id' | 'url' | 'processState'> & {
								identifiers?: Maybe<
									{ __typename?: 'VimeoFeedIdentifiers' } & Pick<
										VimeoFeedIdentifiers,
										'channelId' | 'groupId' | 'showcaseId' | 'type' | 'userId'
									>
								>;
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
					| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id' | 'url' | 'processState'> & {
								properties?: Maybe<
									{ __typename?: 'FeedProperty' } & Pick<
										FeedProperty,
										| 'actionBarArray'
										| 'forceRegenerate'
										| 'hiddenProperties'
										| 'itemTemplate'
										| 'itemTemplateRaw'
										| 'playlistId'
										| 'shareProperties'
									>
								>;
							})
				>;
			};
	};
};

export type DeleteContainerMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	deleteContainerInput: DeleteContainerInput;
}>;

export type DeleteContainerMutation = { __typename?: 'Mutation' } & {
	deleteContainer: { __typename?: 'DeleteContainerResult' } & {
		deletedContainer: { __typename?: 'Container' } & Pick<Container, 'id'>;
	};
};

export type ProcessContainerFeedMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: ProcessContainerFeedInput;
}>;

export type ProcessContainerFeedMutation = { __typename?: 'Mutation' } & {
	processContainerFeed: { __typename?: 'ProcessContainerFeedResult' } & {
		feed:
			| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id' | 'processState'>)
			| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id' | 'processState'>)
			| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'processState'>)
			| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id' | 'processState'>)
			| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id' | 'processState'>)
			| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id' | 'processState'>);
	};
};

export type UpdateContainerVisibilityMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: UpdateContainerVisibilityInput;
}>;

export type UpdateContainerVisibilityMutation = { __typename?: 'Mutation' } & {
	updateContainerVisibility: { __typename?: 'UpdateContainerVisibilityResult' } & {
		updatedContainer: { __typename?: 'Container' } & Pick<Container, 'id' | 'isHidden'>;
	};
};

export type GenerateFileUploadPostMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: GenerateFileUploadPostInput;
}>;

export type GenerateFileUploadPostMutation = { __typename?: 'Mutation' } & {
	generateFileUploadPost: { __typename?: 'GenerateFileUploadPostResult' } & Pick<
		GenerateFileUploadPostResult,
		'unprocessedImageUrl'
	> & {
			presignedPost: { __typename?: 'PresignedPost' } & Pick<
				PresignedPost,
				| 'url'
				| 'key'
				| 'algorithm'
				| 'credential'
				| 'date'
				| 'policy'
				| 'token'
				| 'signature'
				| 'tagging'
				| 'acl'
			>;
		};
};

export type CopyItemsMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: CopyItemsInput;
}>;

export type CopyItemsMutation = { __typename?: 'Mutation' } & {
	copyItems:
		| ({ __typename?: 'CopyItemsOutput' } & {
				copyItemResults: Array<
					| ({ __typename?: 'CopyItemError' } & Pick<CopyItemError, 'itemId' | 'message'>)
					| ({ __typename?: 'CopyItemSuccess' } & Pick<CopyItemSuccess, 'itemId'> & {
								copiedItem: { __typename?: 'Item' } & Pick<
									Item,
									'id' | 'name' | 'isHidden' | 'icon' | 'type' | 'isMirrored' | 'feedId'
								> & {
										image?: Maybe<
											{ __typename?: 'Image' } & Pick<Image, 'id'> & {
													urls?: Maybe<
														{ __typename?: 'ImageFormatUrls' } & Pick<
															ImageFormatUrls,
															| 'square'
															| 'original'
															| 'panorama'
															| 'landscape'
															| 'dynamicHomeScreen'
															| 'unprocessedImageUrl'
														>
													>;
												}
										>;
										properties: Array<
											| ({ __typename?: 'AddToContactsProperty' } & Pick<
													AddToContactsProperty,
													'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'AddressProperty' } & Pick<
													AddressProperty,
													| 'city'
													| 'latitude'
													| 'longitude'
													| 'state'
													| 'street'
													| 'zip'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'AppLinkProperty' } & Pick<
													AppLinkProperty,
													| 'label'
													| 'androidAppUrl'
													| 'androidDefaultUrl'
													| 'iosAppUrl'
													| 'iosDefaultUrl'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'AudioProperty' } & Pick<
													AudioProperty,
													| 'url'
													| 'label'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'BlankifyProperty' } & Pick<
													BlankifyProperty,
													| 'content'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'CallToActionProperty' } & Pick<
													CallToActionProperty,
													| 'isExternal'
													| 'label'
													| 'url'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'CarouselProperty' } & Pick<
													CarouselProperty,
													| 'title'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														images: Array<
															{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																	urls?: Maybe<
																		{ __typename?: 'ImageFormatUrls' } & Pick<
																			ImageFormatUrls,
																			| 'square'
																			| 'original'
																			| 'panorama'
																			| 'landscape'
																			| 'dynamicHomeScreen'
																			| 'unprocessedImageUrl'
																		>
																	>;
																}
														>;
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'DefaultProperty' } & Pick<
													DefaultProperty,
													| 'data'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'GiveProperty' } & Pick<
													GiveProperty,
													| 'givingLink'
													| 'isExternal'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'KeyMetricsProperty' } & Pick<
													KeyMetricsProperty,
													| 'metricsCount'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														metrics: Array<
															{ __typename?: 'KeyMetric' } & Pick<
																KeyMetric,
																'title' | 'value'
															>
														>;
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'ShareProperty' } & Pick<
													ShareProperty,
													'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
											  > & {
														sharedProperties: Array<
															| ({ __typename?: 'AddToContactsProperty' } & Pick<
																	AddToContactsProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'AddressProperty' } & Pick<
																	AddressProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'AppLinkProperty' } & Pick<
																	AppLinkProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'AudioProperty' } & Pick<
																	AudioProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'BlankifyProperty' } & Pick<
																	BlankifyProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'CallToActionProperty' } & Pick<
																	CallToActionProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'CarouselProperty' } & Pick<
																	CarouselProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'DefaultProperty' } & Pick<
																	DefaultProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'GiveProperty' } & Pick<
																	GiveProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																	KeyMetricsProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'ShareProperty' } & Pick<
																	ShareProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'SmsProperty' } & Pick<
																	SmsProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'TextHtmlProperty' } & Pick<
																	TextHtmlProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'TextProperty' } & Pick<
																	TextProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'TimeframeProperty' } & Pick<
																	TimeframeProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'UserNoteProperty' } & Pick<
																	UserNoteProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'VideoProperty' } & Pick<
																	VideoProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
															| ({ __typename?: 'WebsiteProperty' } & Pick<
																	WebsiteProperty,
																	| 'type'
																	| 'header'
																	| 'hidden'
																	| 'icon'
																	| 'id'
																	| 'itemId'
																	| 'position'
															  > & {
																		actionBar?: Maybe<
																			{ __typename?: 'ActionBar' } & Pick<
																				ActionBar,
																				'position' | 'hideOriginalProperty'
																			>
																		>;
																		action?: Maybe<
																			{
																				__typename?: 'ItemPropertyAction';
																			} & Pick<ItemPropertyAction, 'click'>
																		>;
																	})
														>;
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'SmsProperty' } & Pick<
													SmsProperty,
													| 'messageLabel'
													| 'messageBody'
													| 'phoneNumber'
													| 'androidDefaultUrl'
													| 'androidAppUrl'
													| 'iosDefaultUrl'
													| 'iosAppUrl'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'TextHtmlProperty' } & Pick<
													TextHtmlProperty,
													| 'textHtml'
													| 'text'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'TextProperty' } & Pick<
													TextProperty,
													| 'textDescription'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'TimeframeProperty' } & Pick<
													TimeframeProperty,
													'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
											  > & {
														timeframe: { __typename?: 'Timeframe' } & Pick<
															Timeframe,
															'allDay' | 'endTime' | 'startTime'
														>;
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'UserNoteProperty' } & Pick<
													UserNoteProperty,
													| 'hintText'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'VideoProperty' } & Pick<
													VideoProperty,
													| 'url'
													| 'description'
													| 'isEmbed'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
											| ({ __typename?: 'WebsiteProperty' } & Pick<
													WebsiteProperty,
													| 'url'
													| 'isExternal'
													| 'label'
													| 'header'
													| 'hidden'
													| 'icon'
													| 'id'
													| 'itemId'
													| 'type'
													| 'position'
											  > & {
														action?: Maybe<
															{ __typename?: 'ItemPropertyAction' } & Pick<
																ItemPropertyAction,
																'click'
															>
														>;
														actionBar?: Maybe<
															{ __typename?: 'ActionBar' } & Pick<
																ActionBar,
																'hideOriginalProperty' | 'position'
															>
														>;
													})
										>;
									};
							})
				>;
		  })
		| { __typename?: 'InvalidRequestError' }
		| { __typename?: 'RootContainerError' };
};

export type CreateItemMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: CreateItemInput;
}>;

export type CreateItemMutation = { __typename?: 'Mutation' } & {
	createItem: { __typename?: 'CreateItemResult' } & {
		createdItem: { __typename?: 'Item' } & Pick<
			Item,
			'id' | 'name' | 'isHidden' | 'icon' | 'type' | 'isMirrored'
		> & {
				properties: Array<
					| ({ __typename?: 'AddToContactsProperty' } & Pick<
							AddToContactsProperty,
							'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'AddressProperty' } & Pick<AddressProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'AppLinkProperty' } & Pick<AppLinkProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'AudioProperty' } & Pick<AudioProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'BlankifyProperty' } & Pick<BlankifyProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'CallToActionProperty' } & Pick<
							CallToActionProperty,
							'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'CarouselProperty' } & Pick<CarouselProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'DefaultProperty' } & Pick<DefaultProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'GiveProperty' } & Pick<GiveProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'KeyMetricsProperty' } & Pick<KeyMetricsProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'ShareProperty' } & Pick<ShareProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'SmsProperty' } & Pick<SmsProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'TextHtmlProperty' } & Pick<
							TextHtmlProperty,
							'text' | 'type' | 'header' | 'position'
					  >)
					| ({ __typename?: 'TextProperty' } & Pick<TextProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'TimeframeProperty' } & Pick<
							TimeframeProperty,
							'type' | 'header' | 'position'
					  > & {
								timeframe: { __typename?: 'Timeframe' } & Pick<
									Timeframe,
									'allDay' | 'endTime' | 'startTime'
								>;
							})
					| ({ __typename?: 'UserNoteProperty' } & Pick<UserNoteProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'VideoProperty' } & Pick<VideoProperty, 'type' | 'header' | 'position'>)
					| ({ __typename?: 'WebsiteProperty' } & Pick<WebsiteProperty, 'type' | 'header' | 'position'>)
				>;
			};
	};
};

export type FetchItemVideoThumbnailMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: FetchItemVideoThumbnailInput;
}>;

export type FetchItemVideoThumbnailMutation = { __typename?: 'Mutation' } & {
	fetchItemVideoThumbnail: { __typename?: 'FetchItemVideoThumbnailResult' } & {
		image: { __typename?: 'Image' } & Pick<Image, 'id'> & {
				urls?: Maybe<
					{ __typename?: 'ImageFormatUrls' } & Pick<
						ImageFormatUrls,
						'square' | 'original' | 'panorama' | 'landscape' | 'dynamicHomeScreen' | 'unprocessedImageUrl'
					>
				>;
			};
	};
};

export type SaveItemSettingsMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: SaveItemSettingsInput;
}>;

export type SaveItemSettingsMutation = { __typename?: 'Mutation' } & {
	saveItemSettings: { __typename?: 'SaveItemSettingsResult' } & {
		updatedItem: { __typename?: 'Item' } & Pick<
			Item,
			'id' | 'name' | 'feedId' | 'isHidden' | 'type' | 'template' | 'icon'
		> & {
				properties: Array<
					| ({ __typename?: 'AddToContactsProperty' } & Pick<
							AddToContactsProperty,
							'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'AddressProperty' } & Pick<
							AddressProperty,
							| 'city'
							| 'latitude'
							| 'longitude'
							| 'state'
							| 'street'
							| 'zip'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'AppLinkProperty' } & Pick<
							AppLinkProperty,
							| 'androidAppUrl'
							| 'androidDefaultUrl'
							| 'iosAppUrl'
							| 'iosDefaultUrl'
							| 'label'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'AudioProperty' } & Pick<
							AudioProperty,
							'label' | 'url' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'BlankifyProperty' } & Pick<
							BlankifyProperty,
							'content' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'CallToActionProperty' } & Pick<
							CallToActionProperty,
							| 'isExternal'
							| 'label'
							| 'url'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'CarouselProperty' } & Pick<
							CarouselProperty,
							'title' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								images: Array<
									{ __typename?: 'Image' } & Pick<Image, 'id'> & {
											urls?: Maybe<
												{ __typename?: 'ImageFormatUrls' } & Pick<
													ImageFormatUrls,
													| 'square'
													| 'original'
													| 'panorama'
													| 'landscape'
													| 'dynamicHomeScreen'
													| 'unprocessedImageUrl'
												>
											>;
										}
								>;
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'DefaultProperty' } & Pick<
							DefaultProperty,
							'data' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'GiveProperty' } & Pick<
							GiveProperty,
							| 'givingLink'
							| 'isExternal'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'KeyMetricsProperty' } & Pick<
							KeyMetricsProperty,
							'metricsCount' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								metrics: Array<{ __typename?: 'KeyMetric' } & Pick<KeyMetric, 'title' | 'value'>>;
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'ShareProperty' } & Pick<
							ShareProperty,
							'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								sharedProperties: Array<
									| ({ __typename?: 'AddToContactsProperty' } & Pick<
											AddToContactsProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'AddressProperty' } & Pick<
											AddressProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'AppLinkProperty' } & Pick<
											AppLinkProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'AudioProperty' } & Pick<
											AudioProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'BlankifyProperty' } & Pick<
											BlankifyProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'CallToActionProperty' } & Pick<
											CallToActionProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'CarouselProperty' } & Pick<
											CarouselProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'DefaultProperty' } & Pick<
											DefaultProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'GiveProperty' } & Pick<
											GiveProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'KeyMetricsProperty' } & Pick<
											KeyMetricsProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'ShareProperty' } & Pick<
											ShareProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'SmsProperty' } & Pick<
											SmsProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'TextHtmlProperty' } & Pick<
											TextHtmlProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'TextProperty' } & Pick<
											TextProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'TimeframeProperty' } & Pick<
											TimeframeProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'UserNoteProperty' } & Pick<
											UserNoteProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'VideoProperty' } & Pick<
											VideoProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
									| ({ __typename?: 'WebsiteProperty' } & Pick<
											WebsiteProperty,
											'type' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'position'
									  >)
								>;
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'SmsProperty' } & Pick<
							SmsProperty,
							| 'messageBody'
							| 'messageLabel'
							| 'phoneNumber'
							| 'androidDefaultUrl'
							| 'androidAppUrl'
							| 'iosDefaultUrl'
							| 'iosAppUrl'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'TextHtmlProperty' } & Pick<
							TextHtmlProperty,
							'text' | 'textHtml' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'TextProperty' } & Pick<
							TextProperty,
							'textDescription' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'TimeframeProperty' } & Pick<
							TimeframeProperty,
							'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								timeframe: { __typename?: 'Timeframe' } & Pick<
									Timeframe,
									'allDay' | 'endTime' | 'startTime'
								>;
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'UserNoteProperty' } & Pick<
							UserNoteProperty,
							'hintText' | 'header' | 'hidden' | 'icon' | 'id' | 'itemId' | 'type' | 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'VideoProperty' } & Pick<
							VideoProperty,
							| 'description'
							| 'isEmbed'
							| 'url'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
					| ({ __typename?: 'WebsiteProperty' } & Pick<
							WebsiteProperty,
							| 'isExternal'
							| 'label'
							| 'url'
							| 'header'
							| 'hidden'
							| 'icon'
							| 'id'
							| 'itemId'
							| 'type'
							| 'position'
					  > & {
								action?: Maybe<
									{ __typename?: 'ItemPropertyAction' } & Pick<ItemPropertyAction, 'click'>
								>;
								actionBar?: Maybe<
									{ __typename?: 'ActionBar' } & Pick<ActionBar, 'hideOriginalProperty' | 'position'>
								>;
							})
				>;
				cardDefinition?: Maybe<
					| ({ __typename?: 'BaseCardDefinition' } & Pick<
							BaseCardDefinition,
							'id' | 'cardType' | 'callToActionText' | 'isFeatured'
					  > & {
								displayProperties: { __typename?: 'ItemDisplayProperties' } & Pick<
									ItemDisplayProperties,
									'showPublishedTime' | 'showCategory'
								>;
								content?: Maybe<
									{ __typename?: 'ItemCardContent' } & Pick<ItemCardContent, 'subtitle' | 'summary'>
								>;
								featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
							})
					| ({ __typename?: 'ImpactCardDefinition' } & Pick<
							ImpactCardDefinition,
							'id' | 'cardType' | 'callToActionText' | 'isFeatured'
					  > & {
								displayProperties: { __typename?: 'ImpactDisplayProperties' } & Pick<
									ImpactDisplayProperties,
									'showKeyMetrics' | 'showTitle'
								>;
								content?: Maybe<
									{ __typename?: 'ImpactCardContent' } & Pick<ImpactCardContent, 'summary'>
								>;
								featuredCard?: Maybe<{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>>;
							})
				>;
				parentContainer?: Maybe<
					{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
							campus?: Maybe<{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id'>>;
							parentContainer?: Maybe<
								{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
										parentContainer?: Maybe<
											{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'>
										>;
									}
							>;
						}
				>;
				image?: Maybe<
					{ __typename?: 'Image' } & Pick<Image, 'id'> & {
							urls?: Maybe<
								{ __typename?: 'ImageFormatUrls' } & Pick<
									ImageFormatUrls,
									| 'square'
									| 'original'
									| 'panorama'
									| 'landscape'
									| 'dynamicHomeScreen'
									| 'unprocessedImageUrl'
								>
							>;
						}
				>;
			};
	};
};

export type DeleteItemMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	deleteItemInput: DeleteItemInput;
}>;

export type DeleteItemMutation = { __typename?: 'Mutation' } & {
	deleteItem: { __typename?: 'DeleteItemResult' } & { deletedItem: { __typename?: 'Item' } & Pick<Item, 'id'> };
};

export type UpdateItemVisibilityMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: UpdateItemVisibilityInput;
}>;

export type UpdateItemVisibilityMutation = { __typename?: 'Mutation' } & {
	updateItemVisibility: { __typename?: 'UpdateItemVisibilityResult' } & {
		updatedItem: { __typename?: 'Item' } & Pick<Item, 'id' | 'isHidden'>;
	};
};

export type MoveChildrenMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: MoveChildrenInput;
}>;

export type MoveChildrenMutation = { __typename?: 'Mutation' } & {
	moveChildren:
		| { __typename?: 'InvalidRequestError' }
		| ({ __typename?: 'MoveChildrenOutput' } & {
				moveChildResults: Array<
					| ({ __typename?: 'MoveChildError' } & Pick<MoveChildError, 'childId' | 'message'>)
					| ({ __typename?: 'MoveChildSuccess' } & {
							movedChild:
								| ({ __typename?: 'Container' } & Pick<Container, 'id'> & {
											parentContainer?: Maybe<
												{ __typename?: 'Container' } & Pick<Container, 'id'>
											>;
										})
								| ({ __typename?: 'Item' } & Pick<Item, 'id'> & {
											parentContainer?: Maybe<
												{ __typename?: 'Container' } & Pick<Container, 'id'>
											>;
										});
					  })
				>;
				newParentContainer?: Maybe<
					{ __typename?: 'Container' } & Pick<Container, 'id' | 'isDeletable'> & {
							children: { __typename?: 'CursorPagedContainerChildren' } & {
								nodes: Array<
									| ({ __typename?: 'ChildContainer' } & Pick<
											ChildContainer,
											'id' | 'name' | 'icon' | 'hidden' | 'position'
									  > & {
												container: { __typename?: 'Container' } & Pick<
													Container,
													'id' | 'navigationAction' | 'isDeletable' | 'type'
												> & {
														campus?: Maybe<
															{ __typename?: 'AppCampus' } & Pick<
																AppCampus,
																'id' | 'name'
															>
														>;
														image?: Maybe<
															{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																	urls?: Maybe<
																		{ __typename?: 'ImageFormatUrls' } & Pick<
																			ImageFormatUrls,
																			| 'square'
																			| 'original'
																			| 'panorama'
																			| 'landscape'
																			| 'dynamicHomeScreen'
																			| 'unprocessedImageUrl'
																		>
																	>;
																}
														>;
														feed?: Maybe<
															| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
															| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
															| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
															| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
															| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
															| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
														>;
													};
											})
									| ({ __typename?: 'ChildItem' } & Pick<
											ChildItem,
											'id' | 'name' | 'icon' | 'hidden' | 'position'
									  > & {
												item: { __typename?: 'Item' } & Pick<
													Item,
													'id' | 'type' | 'isMirrored' | 'feedId'
												> & {
														image?: Maybe<
															{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																	urls?: Maybe<
																		{ __typename?: 'ImageFormatUrls' } & Pick<
																			ImageFormatUrls,
																			| 'square'
																			| 'original'
																			| 'panorama'
																			| 'landscape'
																			| 'dynamicHomeScreen'
																			| 'unprocessedImageUrl'
																		>
																	>;
																}
														>;
														properties: Array<
															| ({ __typename?: 'AddToContactsProperty' } & Pick<
																	AddToContactsProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'AddressProperty' } & Pick<
																	AddressProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'AppLinkProperty' } & Pick<
																	AppLinkProperty,
																	'label' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'AudioProperty' } & Pick<
																	AudioProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'BlankifyProperty' } & Pick<
																	BlankifyProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'CallToActionProperty' } & Pick<
																	CallToActionProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'CarouselProperty' } & Pick<
																	CarouselProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'DefaultProperty' } & Pick<
																	DefaultProperty,
																	'data' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'GiveProperty' } & Pick<
																	GiveProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																	KeyMetricsProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'ShareProperty' } & Pick<
																	ShareProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'SmsProperty' } & Pick<
																	SmsProperty,
																	'messageLabel' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'TextHtmlProperty' } & Pick<
																	TextHtmlProperty,
																	'text' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'TextProperty' } & Pick<
																	TextProperty,
																	'textDescription' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'TimeframeProperty' } & Pick<
																	TimeframeProperty,
																	'type' | 'header' | 'position'
															  > & {
																		timeframe: { __typename?: 'Timeframe' } & Pick<
																			Timeframe,
																			'allDay' | 'endTime' | 'startTime'
																		>;
																	})
															| ({ __typename?: 'UserNoteProperty' } & Pick<
																	UserNoteProperty,
																	'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'VideoProperty' } & Pick<
																	VideoProperty,
																	'url' | 'type' | 'header' | 'position'
															  >)
															| ({ __typename?: 'WebsiteProperty' } & Pick<
																	WebsiteProperty,
																	'label' | 'type' | 'header' | 'position'
															  >)
														>;
													};
											})
								>;
							};
						}
				>;
				previousParentContainer?: Maybe<{ __typename?: 'Container' } & Pick<Container, 'id' | 'isDeletable'>>;
		  })
		| { __typename?: 'RootContainerError' };
};

export type UpdateProfileMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = { __typename?: 'Mutation' } & {
	updateProfile?: Maybe<
		{ __typename?: 'UpdateProfileResult' } & {
			identity?: Maybe<
				{ __typename?: 'Identity' } & Pick<
					Identity,
					'accountType' | 'firstName' | 'identityKey' | 'lastName' | 'olsonTimeZone'
				> & {
						address?: Maybe<
							{ __typename?: 'IdentityAddress' } & Pick<
								IdentityAddress,
								'line1' | 'line2' | 'city' | 'postcode' | 'state' | 'countryCode'
							>
						>;
						birthday?: Maybe<{ __typename?: 'Birthday' } & Pick<Birthday, 'day' | 'month' | 'year'>>;
						emailAddresses: Array<
							Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress' | 'verified'>>
						>;
						primaryEmailAddress?: Maybe<
							{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress'>
						>;
						clientIpAddress?: Maybe<{ __typename?: 'ClientIpAddress' } & Pick<ClientIpAddress, 'ipV4'>>;
					}
			>;
		}
	>;
};

export type PublishChangesMutationVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	input: PublishChangesInput;
}>;

export type PublishChangesMutation = { __typename?: 'Mutation' } & {
	publishChanges: { __typename?: 'PublishChangesResult' } & {
		application: { __typename?: 'Application' } & Pick<Application, 'id' | 'pendingChanges' | 'publishStatus'>;
	};
};

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQuery = { __typename?: 'Query' } & {
	me?: Maybe<
		{ __typename?: 'Identity' } & Pick<
			Identity,
			'accountType' | 'firstName' | 'identityKey' | 'lastName' | 'olsonTimeZone'
		> & {
				address?: Maybe<
					{ __typename?: 'IdentityAddress' } & Pick<
						IdentityAddress,
						'line1' | 'line2' | 'city' | 'postcode' | 'state' | 'countryCode'
					>
				>;
				birthday?: Maybe<{ __typename?: 'Birthday' } & Pick<Birthday, 'day' | 'month' | 'year'>>;
				emailAddresses: Array<
					Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress' | 'verified'>>
				>;
				primaryEmailAddress?: Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress'>>;
				clientIpAddress?: Maybe<{ __typename?: 'ClientIpAddress' } & Pick<ClientIpAddress, 'ipV4'>>;
			}
	>;
};

export type GetApplicationPublishStatusQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
}>;

export type GetApplicationPublishStatusQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id' | 'pendingChanges' | 'publishStatus'>
			>;
		}
	>;
};

export type GetApplicationSettingsQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
}>;

export type GetApplicationSettingsQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						setting: Array<
							{ __typename?: 'ApplicationSetting' } & Pick<
								ApplicationSetting,
								'id' | 'key' | 'value' | 'valueType' | 'enabled'
							>
						>;
						shellCapabilities: Array<
							{ __typename?: 'ShellCapability' } & Pick<ShellCapability, 'isSupported' | 'key'>
						>;
					}
			>;
		}
	>;
};

export type GetCarouselImagesQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	itemId: Scalars['GUID'];
}>;

export type GetCarouselImagesQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & {
					container?: Maybe<
						{ __typename?: 'Container' } & {
							item?: Maybe<
								{ __typename?: 'Item' } & Pick<Item, 'id'> & {
										properties: Array<
											| ({ __typename?: 'AddToContactsProperty' } & Pick<
													AddToContactsProperty,
													'id'
											  >)
											| ({ __typename?: 'AddressProperty' } & Pick<AddressProperty, 'id'>)
											| ({ __typename?: 'AppLinkProperty' } & Pick<AppLinkProperty, 'id'>)
											| ({ __typename?: 'AudioProperty' } & Pick<AudioProperty, 'id'>)
											| ({ __typename?: 'BlankifyProperty' } & Pick<BlankifyProperty, 'id'>)
											| ({ __typename?: 'CallToActionProperty' } & Pick<
													CallToActionProperty,
													'id'
											  >)
											| ({ __typename?: 'CarouselProperty' } & Pick<CarouselProperty, 'id'> & {
														images: Array<
															{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																	urls?: Maybe<
																		{ __typename?: 'ImageFormatUrls' } & Pick<
																			ImageFormatUrls,
																			| 'square'
																			| 'original'
																			| 'panorama'
																			| 'landscape'
																			| 'dynamicHomeScreen'
																			| 'unprocessedImageUrl'
																		>
																	>;
																}
														>;
													})
											| ({ __typename?: 'DefaultProperty' } & Pick<DefaultProperty, 'id'>)
											| ({ __typename?: 'GiveProperty' } & Pick<GiveProperty, 'id'>)
											| ({ __typename?: 'KeyMetricsProperty' } & Pick<KeyMetricsProperty, 'id'>)
											| ({ __typename?: 'ShareProperty' } & Pick<ShareProperty, 'id'>)
											| ({ __typename?: 'SmsProperty' } & Pick<SmsProperty, 'id'>)
											| ({ __typename?: 'TextHtmlProperty' } & Pick<TextHtmlProperty, 'id'>)
											| ({ __typename?: 'TextProperty' } & Pick<TextProperty, 'id'>)
											| ({ __typename?: 'TimeframeProperty' } & Pick<TimeframeProperty, 'id'>)
											| ({ __typename?: 'UserNoteProperty' } & Pick<UserNoteProperty, 'id'>)
											| ({ __typename?: 'VideoProperty' } & Pick<VideoProperty, 'id'>)
											| ({ __typename?: 'WebsiteProperty' } & Pick<WebsiteProperty, 'id'>)
										>;
									}
							>;
						}
					>;
				}
			>;
		}
	>;
};

export type GetContainerChildrenQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	paging: CursorPagingInput;
}>;

export type GetContainerChildrenQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<
								Container,
								| 'id'
								| 'name'
								| 'isDeletable'
								| 'isHidden'
								| 'icon'
								| 'template'
								| 'type'
								| 'subtitle'
								| 'navigationAction'
							> & {
									feed?: Maybe<
										| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
										| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
										| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
										| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
										| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
										| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
									>;
									parentContainer?: Maybe<
										{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'type'> & {
												feed?: Maybe<
													| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
													| ({ __typename?: 'PodcastFeed' } & Pick<PodcastFeed, 'id'>)
													| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
													| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
													| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
													| ({ __typename?: 'YouTubeFeed' } & Pick<YouTubeFeed, 'id'>)
												>;
												parentContainer?: Maybe<
													{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
															feed?: Maybe<
																| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
																| ({ __typename?: 'PodcastFeed' } & Pick<
																		PodcastFeed,
																		'id'
																  >)
																| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
																| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
																| ({ __typename?: 'YouTubeFeed' } & Pick<
																		YouTubeFeed,
																		'id'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		feed?: Maybe<
																			| ({ __typename?: 'IcalFeed' } & Pick<
																					IcalFeed,
																					'id'
																			  >)
																			| ({ __typename?: 'PodcastFeed' } & Pick<
																					PodcastFeed,
																					'id'
																			  >)
																			| ({ __typename?: 'RssFeed' } & Pick<
																					RssFeed,
																					'id'
																			  >)
																			| ({ __typename?: 'VideoFeed' } & Pick<
																					VideoFeed,
																					'id'
																			  >)
																			| ({ __typename?: 'VimeoFeed' } & Pick<
																					VimeoFeed,
																					'id'
																			  >)
																			| ({ __typename?: 'YouTubeFeed' } & Pick<
																					YouTubeFeed,
																					'id'
																			  >)
																		>;
																	}
															>;
														}
												>;
											}
									>;
									image?: Maybe<
										{ __typename?: 'Image' } & Pick<Image, 'id'> & {
												urls?: Maybe<
													{ __typename?: 'ImageFormatUrls' } & Pick<
														ImageFormatUrls,
														| 'square'
														| 'original'
														| 'panorama'
														| 'landscape'
														| 'dynamicHomeScreen'
														| 'unprocessedImageUrl'
													>
												>;
											}
									>;
									children: { __typename?: 'CursorPagedContainerChildren' } & {
										nodes: Array<
											| ({ __typename?: 'ChildContainer' } & Pick<
													ChildContainer,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														container: { __typename?: 'Container' } & Pick<
															Container,
															'id' | 'navigationAction' | 'isDeletable' | 'type'
														> & {
																campus?: Maybe<
																	{ __typename?: 'AppCampus' } & Pick<
																		AppCampus,
																		'id' | 'name'
																	>
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																feed?: Maybe<
																	| ({ __typename?: 'IcalFeed' } & Pick<
																			IcalFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'PodcastFeed' } & Pick<
																			PodcastFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																	| ({ __typename?: 'VideoFeed' } & Pick<
																			VideoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'VimeoFeed' } & Pick<
																			VimeoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'YouTubeFeed' } & Pick<
																			YouTubeFeed,
																			'id'
																	  >)
																>;
															};
													})
											| ({ __typename?: 'ChildItem' } & Pick<
													ChildItem,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														item: { __typename?: 'Item' } & Pick<
															Item,
															'id' | 'type' | 'isMirrored' | 'feedId'
														> & {
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																properties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			'data' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'messageLabel'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			'text' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'textDescription'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			'type' | 'header' | 'position'
																	  > & {
																				timeframe: {
																					__typename?: 'Timeframe';
																				} & Pick<
																					Timeframe,
																					'allDay' | 'endTime' | 'startTime'
																				>;
																			})
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			'url' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																>;
															};
													})
										>;
										paging: { __typename?: 'CursorPagingOutput' } & Pick<
											CursorPagingOutput,
											'totalItemCount' | 'nextCursor' | 'size'
										>;
									};
								}
						>;
					}
			>;
		}
	>;
};

export type GetContainerChildrenWithNestedChildrenQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	paging: CursorPagingInput;
}>;

export type GetContainerChildrenWithNestedChildrenQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<Container, 'id'> & {
									children: { __typename?: 'CursorPagedContainerChildren' } & {
										nodes: Array<
											| ({ __typename?: 'ChildContainer' } & Pick<
													ChildContainer,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														container: { __typename?: 'Container' } & Pick<
															Container,
															'id' | 'navigationAction' | 'isDeletable' | 'type'
														> & {
																campus?: Maybe<
																	{ __typename?: 'AppCampus' } & Pick<
																		AppCampus,
																		'id' | 'name'
																	>
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																feed?: Maybe<
																	| ({ __typename?: 'IcalFeed' } & Pick<
																			IcalFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'PodcastFeed' } & Pick<
																			PodcastFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																	| ({ __typename?: 'VideoFeed' } & Pick<
																			VideoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'VimeoFeed' } & Pick<
																			VimeoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'YouTubeFeed' } & Pick<
																			YouTubeFeed,
																			'id'
																	  >)
																>;
																children: {
																	__typename?: 'CursorPagedContainerChildren';
																} & {
																	nodes: Array<
																		| ({ __typename?: 'ChildContainer' } & Pick<
																				ChildContainer,
																				| 'id'
																				| 'name'
																				| 'icon'
																				| 'hidden'
																				| 'position'
																		  > & {
																					container: {
																						__typename?: 'Container';
																					} & Pick<
																						Container,
																						| 'id'
																						| 'navigationAction'
																						| 'isDeletable'
																						| 'type'
																					> & {
																							campus?: Maybe<
																								{
																									__typename?: 'AppCampus';
																								} & Pick<
																									AppCampus,
																									'id' | 'name'
																								>
																							>;
																							image?: Maybe<
																								{
																									__typename?: 'Image';
																								} & Pick<
																									Image,
																									'id'
																								> & {
																										urls?: Maybe<
																											{
																												__typename?: 'ImageFormatUrls';
																											} & Pick<
																												ImageFormatUrls,
																												| 'square'
																												| 'original'
																												| 'panorama'
																												| 'landscape'
																												| 'dynamicHomeScreen'
																												| 'unprocessedImageUrl'
																											>
																										>;
																									}
																							>;
																							feed?: Maybe<
																								| ({
																										__typename?: 'IcalFeed';
																								  } & Pick<
																										IcalFeed,
																										'id'
																								  >)
																								| ({
																										__typename?: 'PodcastFeed';
																								  } & Pick<
																										PodcastFeed,
																										'id'
																								  >)
																								| ({
																										__typename?: 'RssFeed';
																								  } & Pick<
																										RssFeed,
																										'id'
																								  >)
																								| ({
																										__typename?: 'VideoFeed';
																								  } & Pick<
																										VideoFeed,
																										'id'
																								  >)
																								| ({
																										__typename?: 'VimeoFeed';
																								  } & Pick<
																										VimeoFeed,
																										'id'
																								  >)
																								| ({
																										__typename?: 'YouTubeFeed';
																								  } & Pick<
																										YouTubeFeed,
																										'id'
																								  >)
																							>;
																						};
																				})
																		| ({ __typename?: 'ChildItem' } & Pick<
																				ChildItem,
																				| 'id'
																				| 'name'
																				| 'icon'
																				| 'hidden'
																				| 'position'
																		  > & {
																					item: {
																						__typename?: 'Item';
																					} & Pick<
																						Item,
																						| 'id'
																						| 'type'
																						| 'isMirrored'
																						| 'feedId'
																					> & {
																							image?: Maybe<
																								{
																									__typename?: 'Image';
																								} & Pick<
																									Image,
																									'id'
																								> & {
																										urls?: Maybe<
																											{
																												__typename?: 'ImageFormatUrls';
																											} & Pick<
																												ImageFormatUrls,
																												| 'square'
																												| 'original'
																												| 'panorama'
																												| 'landscape'
																												| 'dynamicHomeScreen'
																												| 'unprocessedImageUrl'
																											>
																										>;
																									}
																							>;
																							properties: Array<
																								| ({
																										__typename?: 'AddToContactsProperty';
																								  } & Pick<
																										AddToContactsProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'AddressProperty';
																								  } & Pick<
																										AddressProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'AppLinkProperty';
																								  } & Pick<
																										AppLinkProperty,
																										| 'label'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'AudioProperty';
																								  } & Pick<
																										AudioProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'BlankifyProperty';
																								  } & Pick<
																										BlankifyProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'CallToActionProperty';
																								  } & Pick<
																										CallToActionProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'CarouselProperty';
																								  } & Pick<
																										CarouselProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'DefaultProperty';
																								  } & Pick<
																										DefaultProperty,
																										| 'data'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'GiveProperty';
																								  } & Pick<
																										GiveProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'KeyMetricsProperty';
																								  } & Pick<
																										KeyMetricsProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'ShareProperty';
																								  } & Pick<
																										ShareProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'SmsProperty';
																								  } & Pick<
																										SmsProperty,
																										| 'messageLabel'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'TextHtmlProperty';
																								  } & Pick<
																										TextHtmlProperty,
																										| 'text'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'TextProperty';
																								  } & Pick<
																										TextProperty,
																										| 'textDescription'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'TimeframeProperty';
																								  } & Pick<
																										TimeframeProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  > & {
																											timeframe: {
																												__typename?: 'Timeframe';
																											} & Pick<
																												Timeframe,
																												| 'allDay'
																												| 'endTime'
																												| 'startTime'
																											>;
																										})
																								| ({
																										__typename?: 'UserNoteProperty';
																								  } & Pick<
																										UserNoteProperty,
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'VideoProperty';
																								  } & Pick<
																										VideoProperty,
																										| 'url'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																								| ({
																										__typename?: 'WebsiteProperty';
																								  } & Pick<
																										WebsiteProperty,
																										| 'label'
																										| 'type'
																										| 'header'
																										| 'position'
																								  >)
																							>;
																						};
																				})
																	>;
																	paging: {
																		__typename?: 'CursorPagingOutput';
																	} & Pick<
																		CursorPagingOutput,
																		'totalItemCount' | 'nextCursor' | 'size'
																	>;
																};
															};
													})
											| ({ __typename?: 'ChildItem' } & Pick<
													ChildItem,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														item: { __typename?: 'Item' } & Pick<
															Item,
															'id' | 'type' | 'isMirrored' | 'feedId'
														> & {
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																properties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			'data' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'messageLabel'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			'text' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'textDescription'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			'type' | 'header' | 'position'
																	  > & {
																				timeframe: {
																					__typename?: 'Timeframe';
																				} & Pick<
																					Timeframe,
																					'allDay' | 'endTime' | 'startTime'
																				>;
																			})
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			'url' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																>;
															};
													})
										>;
										paging: { __typename?: 'CursorPagingOutput' } & Pick<
											CursorPagingOutput,
											'totalItemCount' | 'nextCursor' | 'size'
										>;
									};
								}
						>;
					}
			>;
		}
	>;
};

export type GetContainerFeedQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
}>;

export type GetContainerFeedQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<Container, 'id'> & {
									children: { __typename?: 'CursorPagedContainerChildren' } & {
										nodes: Array<
											| ({ __typename?: 'ChildContainer' } & Pick<
													ChildContainer,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														container: { __typename?: 'Container' } & Pick<
															Container,
															'id' | 'navigationAction' | 'isDeletable' | 'type'
														> & {
																campus?: Maybe<
																	{ __typename?: 'AppCampus' } & Pick<
																		AppCampus,
																		'id' | 'name'
																	>
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																feed?: Maybe<
																	| ({ __typename?: 'IcalFeed' } & Pick<
																			IcalFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'PodcastFeed' } & Pick<
																			PodcastFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																	| ({ __typename?: 'VideoFeed' } & Pick<
																			VideoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'VimeoFeed' } & Pick<
																			VimeoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'YouTubeFeed' } & Pick<
																			YouTubeFeed,
																			'id'
																	  >)
																>;
															};
													})
											| ({ __typename?: 'ChildItem' } & Pick<
													ChildItem,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														item: { __typename?: 'Item' } & Pick<
															Item,
															'id' | 'type' | 'isMirrored' | 'feedId'
														> & {
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																properties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			'data' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'messageLabel'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			'text' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'textDescription'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			'type' | 'header' | 'position'
																	  > & {
																				timeframe: {
																					__typename?: 'Timeframe';
																				} & Pick<
																					Timeframe,
																					'allDay' | 'endTime' | 'startTime'
																				>;
																			})
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			'url' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																>;
															};
													})
										>;
										paging: { __typename?: 'CursorPagingOutput' } & Pick<
											CursorPagingOutput,
											'totalItemCount' | 'nextCursor' | 'size'
										>;
									};
									feed?: Maybe<
										| ({ __typename?: 'IcalFeed' } & Pick<
												IcalFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'PodcastFeed' } & Pick<
												PodcastFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VideoFeed' } & Pick<
												VideoFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VimeoFeed' } & Pick<
												VimeoFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'YouTubeFeed' } & Pick<
												YouTubeFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
									>;
								}
						>;
					}
			>;
		}
	>;
};

export type GetContainerImageQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
}>;

export type GetContainerImageQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & {
					container?: Maybe<
						{ __typename?: 'Container' } & {
							image?: Maybe<
								{ __typename?: 'Image' } & Pick<Image, 'id'> & {
										urls?: Maybe<
											{ __typename?: 'ImageFormatUrls' } & Pick<
												ImageFormatUrls,
												| 'square'
												| 'original'
												| 'panorama'
												| 'landscape'
												| 'dynamicHomeScreen'
												| 'unprocessedImageUrl'
											>
										>;
									}
							>;
						}
					>;
				}
			>;
		}
	>;
};

export type GetContainerSettingsQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	paging: CursorPagingInput;
}>;

export type GetContainerSettingsQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<
								Container,
								| 'id'
								| 'name'
								| 'isDeletable'
								| 'isHidden'
								| 'icon'
								| 'template'
								| 'givingLink'
								| 'type'
								| 'navigationAction'
								| 'subtitle'
								| 'resiLink'
							> & {
									children: { __typename?: 'CursorPagedContainerChildren' } & {
										paging: { __typename?: 'CursorPagingOutput' } & Pick<
											CursorPagingOutput,
											'totalItemCount'
										>;
										nodes: Array<
											| ({ __typename?: 'ChildContainer' } & Pick<
													ChildContainer,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														container: { __typename?: 'Container' } & Pick<
															Container,
															'id' | 'navigationAction' | 'isDeletable' | 'type'
														> & {
																campus?: Maybe<
																	{ __typename?: 'AppCampus' } & Pick<
																		AppCampus,
																		'id' | 'name'
																	>
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																feed?: Maybe<
																	| ({ __typename?: 'IcalFeed' } & Pick<
																			IcalFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'PodcastFeed' } & Pick<
																			PodcastFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																	| ({ __typename?: 'VideoFeed' } & Pick<
																			VideoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'VimeoFeed' } & Pick<
																			VimeoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'YouTubeFeed' } & Pick<
																			YouTubeFeed,
																			'id'
																	  >)
																>;
															};
													})
											| ({ __typename?: 'ChildItem' } & Pick<
													ChildItem,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														item: { __typename?: 'Item' } & Pick<
															Item,
															'id' | 'type' | 'isMirrored' | 'feedId'
														> & {
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																properties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			'data' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'messageLabel'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			'text' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'textDescription'
																			| 'type'
																			| 'header'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			'type' | 'header' | 'position'
																	  > & {
																				timeframe: {
																					__typename?: 'Timeframe';
																				} & Pick<
																					Timeframe,
																					'allDay' | 'endTime' | 'startTime'
																				>;
																			})
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			'url' | 'type' | 'header' | 'position'
																	  >)
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			'label' | 'type' | 'header' | 'position'
																	  >)
																>;
															};
													})
										>;
									};
									typeSpecificProperties?: Maybe<
										{ __typename?: 'StudioContainerTypeProperties' } & Pick<
											StudioContainerTypeProperties,
											'ctaLabel' | 'description' | 'link' | 'title'
										>
									>;
									parentContainer?: Maybe<
										{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
												parentContainer?: Maybe<
													{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																>
															>;
														}
												>;
											}
									>;
									image?: Maybe<
										{ __typename?: 'Image' } & Pick<Image, 'id'> & {
												urls?: Maybe<
													{ __typename?: 'ImageFormatUrls' } & Pick<
														ImageFormatUrls,
														| 'square'
														| 'original'
														| 'panorama'
														| 'landscape'
														| 'dynamicHomeScreen'
														| 'unprocessedImageUrl'
													>
												>;
											}
									>;
									cardDefinition?: Maybe<
										{ __typename?: 'ContainerCardDefinition' } & Pick<
											ContainerCardDefinition,
											'cardType' | 'id' | 'maxItems' | 'callToActionText'
										> & {
												dataMap: { __typename?: 'CardDataMap' } & {
													audioUrl?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													publishedTime?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													subtitle?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													summary?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													videoUrl?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
												};
												featuredCard?: Maybe<
													{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>
												>;
											}
									>;
									relatedContent: { __typename?: 'RelatedContent' } & Pick<
										RelatedContent,
										'isEnabled' | 'title'
									>;
									campus?: Maybe<
										{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name' | 'givingLink'>
									>;
									feed?: Maybe<
										| ({ __typename?: 'IcalFeed' } & Pick<
												IcalFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'PodcastFeed' } & Pick<
												PodcastFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VideoFeed' } & Pick<
												VideoFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VimeoFeed' } & Pick<
												VimeoFeed,
												'id' | 'url' | 'processState'
										  > & {
													identifiers?: Maybe<
														{ __typename?: 'VimeoFeedIdentifiers' } & Pick<
															VimeoFeedIdentifiers,
															'channelId' | 'groupId' | 'showcaseId' | 'type' | 'userId'
														>
													>;
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'YouTubeFeed' } & Pick<
												YouTubeFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
									>;
								}
						>;
					}
			>;
		}
	>;
};

export type GetContainerVisibilityQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
}>;

export type GetContainerVisibilityQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'isHidden'>>;
					}
			>;
		}
	>;
};

export type GetDhsCardsQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	campusId?: Maybe<Scalars['GUID']>;
	timeZone?: Maybe<Scalars['String']>;
}>;

export type GetDhsCardsQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						dynamicHomeScreen?: Maybe<
							{ __typename?: 'DynamicHomeScreen' } & Pick<DynamicHomeScreen, 'publish'> & {
									cards: Array<
										| ({ __typename?: 'AudioCard' } & Pick<
												AudioCard,
												| 'audioUrl'
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'DefaultCard' } & Pick<
												DefaultCard,
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'EventCard' } & Pick<
												EventCard,
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'ImpactCard' } & Pick<
												ImpactCard,
												'callToActionText' | 'id' | 'title' | 'subtitle' | 'summary' | 'tabName'
										  > & {
													keyMetrics: Array<
														{ __typename?: 'KeyMetric' } & Pick<
															KeyMetric,
															'value' | 'title'
														>
													>;
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'IntroCard' } & Pick<
												IntroCard,
												| 'callToActionText'
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'SpecialCard' } & Pick<
												SpecialCard,
												| 'callToActionText'
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
										| ({ __typename?: 'VideoCard' } & Pick<
												VideoCard,
												| 'videoUrl'
												| 'publishedTime'
												| 'category'
												| 'id'
												| 'title'
												| 'subtitle'
												| 'summary'
												| 'tabName'
										  > & {
													item: { __typename?: 'Item' } & Pick<Item, 'id'> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'id' | 'position'
																  > & {
																			sharedProperties: Array<
																				| ({
																						__typename?: 'AddToContactsProperty';
																				  } & Pick<AddToContactsProperty, 'id'>)
																				| ({
																						__typename?: 'AddressProperty';
																				  } & Pick<AddressProperty, 'id'>)
																				| ({
																						__typename?: 'AppLinkProperty';
																				  } & Pick<AppLinkProperty, 'id'>)
																				| ({
																						__typename?: 'AudioProperty';
																				  } & Pick<AudioProperty, 'id'>)
																				| ({
																						__typename?: 'BlankifyProperty';
																				  } & Pick<BlankifyProperty, 'id'>)
																				| ({
																						__typename?: 'CallToActionProperty';
																				  } & Pick<CallToActionProperty, 'id'>)
																				| ({
																						__typename?: 'CarouselProperty';
																				  } & Pick<CarouselProperty, 'id'>)
																				| ({
																						__typename?: 'DefaultProperty';
																				  } & Pick<DefaultProperty, 'id'>)
																				| ({
																						__typename?: 'GiveProperty';
																				  } & Pick<GiveProperty, 'id'>)
																				| ({
																						__typename?: 'KeyMetricsProperty';
																				  } & Pick<KeyMetricsProperty, 'id'>)
																				| ({
																						__typename?: 'ShareProperty';
																				  } & Pick<ShareProperty, 'id'>)
																				| ({
																						__typename?: 'SmsProperty';
																				  } & Pick<SmsProperty, 'id'>)
																				| ({
																						__typename?: 'TextHtmlProperty';
																				  } & Pick<TextHtmlProperty, 'id'>)
																				| ({
																						__typename?: 'TextProperty';
																				  } & Pick<TextProperty, 'id'>)
																				| ({
																						__typename?: 'TimeframeProperty';
																				  } & Pick<TimeframeProperty, 'id'>)
																				| ({
																						__typename?: 'UserNoteProperty';
																				  } & Pick<UserNoteProperty, 'id'>)
																				| ({
																						__typename?: 'VideoProperty';
																				  } & Pick<VideoProperty, 'id'>)
																				| ({
																						__typename?: 'WebsiteProperty';
																				  } & Pick<WebsiteProperty, 'id'>)
																			>;
																		})
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'id' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'startTime' | 'endTime' | 'allDay'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'id' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'id' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'id' | 'position'
																  >)
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		image?: Maybe<
																			{ __typename?: 'Image' } & Pick<
																				Image,
																				'id'
																			> & {
																					urls?: Maybe<
																						{
																							__typename?: 'ImageFormatUrls';
																						} & Pick<
																							ImageFormatUrls,
																							| 'dynamicHomeScreen'
																							| 'unprocessedImageUrl'
																						>
																					>;
																				}
																		>;
																		cardDefinition?: Maybe<
																			{
																				__typename?: 'ContainerCardDefinition';
																			} & Pick<
																				ContainerCardDefinition,
																				'showCategory' | 'showPublishedTime'
																			> & {
																					featuredCard?: Maybe<
																						{
																							__typename?: 'FeaturedCard';
																						} & Pick<FeaturedCard, 'label'>
																					>;
																				}
																		>;
																	}
															>;
															cardDefinition?: Maybe<
																| ({ __typename?: 'BaseCardDefinition' } & Pick<
																		BaseCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ItemDisplayProperties';
																			} & Pick<
																				ItemDisplayProperties,
																				'showPublishedTime' | 'showCategory'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ItemCardContent';
																				} & Pick<
																					ItemCardContent,
																					'subtitle' | 'summary'
																				>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
																| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																		ImpactCardDefinition,
																		| 'id'
																		| 'cardType'
																		| 'callToActionText'
																		| 'isFeatured'
																  > & {
																			displayProperties: {
																				__typename?: 'ImpactDisplayProperties';
																			} & Pick<
																				ImpactDisplayProperties,
																				'showKeyMetrics' | 'showTitle'
																			>;
																			content?: Maybe<
																				{
																					__typename?: 'ImpactCardContent';
																				} & Pick<ImpactCardContent, 'summary'>
																			>;
																			featuredCard?: Maybe<
																				{ __typename?: 'FeaturedCard' } & Pick<
																					FeaturedCard,
																					'label'
																				>
																			>;
																		})
															>;
														};
												})
									>;
								}
						>;
					}
			>;
		}
	>;
};

export type GetItemQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	itemId: Scalars['GUID'];
	paging?: Maybe<CursorPagingInput>;
}>;

export type GetItemQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<
								Container,
								| 'id'
								| 'name'
								| 'isDeletable'
								| 'isHidden'
								| 'icon'
								| 'template'
								| 'givingLink'
								| 'type'
								| 'navigationAction'
								| 'subtitle'
								| 'resiLink'
							> & {
									children: { __typename?: 'CursorPagedContainerChildren' } & {
										nodes: Array<
											| ({ __typename?: 'ChildContainer' } & Pick<
													ChildContainer,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														container: { __typename?: 'Container' } & Pick<
															Container,
															'id' | 'navigationAction' | 'isDeletable' | 'type'
														> & {
																campus?: Maybe<
																	{ __typename?: 'AppCampus' } & Pick<
																		AppCampus,
																		'id' | 'name'
																	>
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																feed?: Maybe<
																	| ({ __typename?: 'IcalFeed' } & Pick<
																			IcalFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'PodcastFeed' } & Pick<
																			PodcastFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																	| ({ __typename?: 'VideoFeed' } & Pick<
																			VideoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'VimeoFeed' } & Pick<
																			VimeoFeed,
																			'id'
																	  >)
																	| ({ __typename?: 'YouTubeFeed' } & Pick<
																			YouTubeFeed,
																			'id'
																	  >)
																>;
															};
													})
											| ({ __typename?: 'ChildItem' } & Pick<
													ChildItem,
													'id' | 'name' | 'icon' | 'hidden' | 'position'
											  > & {
														item: { __typename?: 'Item' } & Pick<
															Item,
															| 'createdDate'
															| 'updatedDate'
															| 'id'
															| 'type'
															| 'isMirrored'
															| 'feedId'
														> & {
																properties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'city'
																			| 'latitude'
																			| 'longitude'
																			| 'state'
																			| 'street'
																			| 'zip'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			| 'label'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'androidAppUrl'
																			| 'androidDefaultUrl'
																			| 'iosAppUrl'
																			| 'iosDefaultUrl'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'url'
																			| 'label'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'content'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'isExternal'
																			| 'label'
																			| 'url'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'title'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				images: Array<
																					{ __typename?: 'Image' } & Pick<
																						Image,
																						'id'
																					> & {
																							urls?: Maybe<
																								{
																									__typename?: 'ImageFormatUrls';
																								} & Pick<
																									ImageFormatUrls,
																									| 'square'
																									| 'original'
																									| 'panorama'
																									| 'landscape'
																									| 'dynamicHomeScreen'
																									| 'unprocessedImageUrl'
																								>
																							>;
																						}
																				>;
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			| 'data'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'givingLink'
																			| 'isExternal'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'metricsCount'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				metrics: Array<
																					{ __typename?: 'KeyMetric' } & Pick<
																						KeyMetric,
																						'title' | 'value'
																					>
																				>;
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				sharedProperties: Array<
																					| ({
																							__typename?: 'AddToContactsProperty';
																					  } & Pick<
																							AddToContactsProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'AddressProperty';
																					  } & Pick<
																							AddressProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'AppLinkProperty';
																					  } & Pick<
																							AppLinkProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'AudioProperty';
																					  } & Pick<
																							AudioProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'BlankifyProperty';
																					  } & Pick<
																							BlankifyProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'CallToActionProperty';
																					  } & Pick<
																							CallToActionProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'CarouselProperty';
																					  } & Pick<
																							CarouselProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'DefaultProperty';
																					  } & Pick<
																							DefaultProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'GiveProperty';
																					  } & Pick<
																							GiveProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'KeyMetricsProperty';
																					  } & Pick<
																							KeyMetricsProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'ShareProperty';
																					  } & Pick<
																							ShareProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'SmsProperty';
																					  } & Pick<
																							SmsProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'TextHtmlProperty';
																					  } & Pick<
																							TextHtmlProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'TextProperty';
																					  } & Pick<
																							TextProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'TimeframeProperty';
																					  } & Pick<
																							TimeframeProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'UserNoteProperty';
																					  } & Pick<
																							UserNoteProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'VideoProperty';
																					  } & Pick<
																							VideoProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																					| ({
																							__typename?: 'WebsiteProperty';
																					  } & Pick<
																							WebsiteProperty,
																							| 'type'
																							| 'header'
																							| 'hidden'
																							| 'icon'
																							| 'id'
																							| 'itemId'
																							| 'position'
																					  > & {
																								actionBar?: Maybe<
																									{
																										__typename?: 'ActionBar';
																									} & Pick<
																										ActionBar,
																										| 'position'
																										| 'hideOriginalProperty'
																									>
																								>;
																								action?: Maybe<
																									{
																										__typename?: 'ItemPropertyAction';
																									} & Pick<
																										ItemPropertyAction,
																										'click'
																									>
																								>;
																							})
																				>;
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'messageLabel'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'messageBody'
																			| 'phoneNumber'
																			| 'androidDefaultUrl'
																			| 'androidAppUrl'
																			| 'iosDefaultUrl'
																			| 'iosAppUrl'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			| 'text'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'textHtml'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'textDescription'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				timeframe: {
																					__typename?: 'Timeframe';
																				} & Pick<
																					Timeframe,
																					'allDay' | 'endTime' | 'startTime'
																				>;
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'hintText'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			| 'url'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'description'
																			| 'isEmbed'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			| 'label'
																			| 'type'
																			| 'header'
																			| 'position'
																			| 'url'
																			| 'isExternal'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																	  > & {
																				action?: Maybe<
																					{
																						__typename?: 'ItemPropertyAction';
																					} & Pick<
																						ItemPropertyAction,
																						'click'
																					>
																				>;
																				actionBar?: Maybe<
																					{ __typename?: 'ActionBar' } & Pick<
																						ActionBar,
																						| 'hideOriginalProperty'
																						| 'position'
																					>
																				>;
																			})
																>;
																cardDefinition?: Maybe<
																	| ({ __typename?: 'BaseCardDefinition' } & Pick<
																			BaseCardDefinition,
																			'id'
																	  > & {
																				content?: Maybe<
																					{
																						__typename?: 'ItemCardContent';
																					} & Pick<
																						ItemCardContent,
																						'subtitle'
																					>
																				>;
																			})
																	| ({ __typename?: 'ImpactCardDefinition' } & Pick<
																			ImpactCardDefinition,
																			'id'
																	  >)
																>;
																image?: Maybe<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
															};
													})
										>;
									};
									item?: Maybe<
										{ __typename?: 'Item' } & Pick<
											Item,
											'id' | 'name' | 'feedId' | 'isHidden' | 'type' | 'template' | 'icon'
										> & {
												properties: Array<
													| ({ __typename?: 'AddToContactsProperty' } & Pick<
															AddToContactsProperty,
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'AddressProperty' } & Pick<
															AddressProperty,
															| 'city'
															| 'latitude'
															| 'longitude'
															| 'state'
															| 'street'
															| 'zip'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'AppLinkProperty' } & Pick<
															AppLinkProperty,
															| 'androidAppUrl'
															| 'androidDefaultUrl'
															| 'iosAppUrl'
															| 'iosDefaultUrl'
															| 'label'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'AudioProperty' } & Pick<
															AudioProperty,
															| 'label'
															| 'url'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'BlankifyProperty' } & Pick<
															BlankifyProperty,
															| 'content'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'CallToActionProperty' } & Pick<
															CallToActionProperty,
															| 'isExternal'
															| 'label'
															| 'url'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'CarouselProperty' } & Pick<
															CarouselProperty,
															| 'title'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																images: Array<
																	{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																			urls?: Maybe<
																				{
																					__typename?: 'ImageFormatUrls';
																				} & Pick<
																					ImageFormatUrls,
																					| 'square'
																					| 'original'
																					| 'panorama'
																					| 'landscape'
																					| 'dynamicHomeScreen'
																					| 'unprocessedImageUrl'
																				>
																			>;
																		}
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'DefaultProperty' } & Pick<
															DefaultProperty,
															| 'data'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'GiveProperty' } & Pick<
															GiveProperty,
															| 'givingLink'
															| 'isExternal'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'KeyMetricsProperty' } & Pick<
															KeyMetricsProperty,
															| 'metricsCount'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																metrics: Array<
																	{ __typename?: 'KeyMetric' } & Pick<
																		KeyMetric,
																		'title' | 'value'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'ShareProperty' } & Pick<
															ShareProperty,
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																sharedProperties: Array<
																	| ({ __typename?: 'AddToContactsProperty' } & Pick<
																			AddToContactsProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'AddressProperty' } & Pick<
																			AddressProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'AppLinkProperty' } & Pick<
																			AppLinkProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'AudioProperty' } & Pick<
																			AudioProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'BlankifyProperty' } & Pick<
																			BlankifyProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'CallToActionProperty' } & Pick<
																			CallToActionProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'CarouselProperty' } & Pick<
																			CarouselProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'DefaultProperty' } & Pick<
																			DefaultProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'GiveProperty' } & Pick<
																			GiveProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																			KeyMetricsProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'ShareProperty' } & Pick<
																			ShareProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'SmsProperty' } & Pick<
																			SmsProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextHtmlProperty' } & Pick<
																			TextHtmlProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TextProperty' } & Pick<
																			TextProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'TimeframeProperty' } & Pick<
																			TimeframeProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'UserNoteProperty' } & Pick<
																			UserNoteProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'VideoProperty' } & Pick<
																			VideoProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																	| ({ __typename?: 'WebsiteProperty' } & Pick<
																			WebsiteProperty,
																			| 'type'
																			| 'header'
																			| 'hidden'
																			| 'icon'
																			| 'id'
																			| 'itemId'
																			| 'position'
																	  >)
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'SmsProperty' } & Pick<
															SmsProperty,
															| 'messageBody'
															| 'messageLabel'
															| 'phoneNumber'
															| 'androidDefaultUrl'
															| 'androidAppUrl'
															| 'iosDefaultUrl'
															| 'iosAppUrl'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'TextHtmlProperty' } & Pick<
															TextHtmlProperty,
															| 'text'
															| 'textHtml'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'TextProperty' } & Pick<
															TextProperty,
															| 'textDescription'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'TimeframeProperty' } & Pick<
															TimeframeProperty,
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																timeframe: { __typename?: 'Timeframe' } & Pick<
																	Timeframe,
																	'allDay' | 'endTime' | 'startTime'
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'UserNoteProperty' } & Pick<
															UserNoteProperty,
															| 'hintText'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'VideoProperty' } & Pick<
															VideoProperty,
															| 'description'
															| 'isEmbed'
															| 'url'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
													| ({ __typename?: 'WebsiteProperty' } & Pick<
															WebsiteProperty,
															| 'isExternal'
															| 'label'
															| 'url'
															| 'header'
															| 'hidden'
															| 'icon'
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
													  > & {
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'hideOriginalProperty' | 'position'
																	>
																>;
															})
												>;
												cardDefinition?: Maybe<
													| ({ __typename?: 'BaseCardDefinition' } & Pick<
															BaseCardDefinition,
															'id' | 'cardType' | 'callToActionText' | 'isFeatured'
													  > & {
																displayProperties: {
																	__typename?: 'ItemDisplayProperties';
																} & Pick<
																	ItemDisplayProperties,
																	'showPublishedTime' | 'showCategory'
																>;
																content?: Maybe<
																	{ __typename?: 'ItemCardContent' } & Pick<
																		ItemCardContent,
																		'subtitle' | 'summary'
																	>
																>;
																featuredCard?: Maybe<
																	{ __typename?: 'FeaturedCard' } & Pick<
																		FeaturedCard,
																		'label'
																	>
																>;
															})
													| ({ __typename?: 'ImpactCardDefinition' } & Pick<
															ImpactCardDefinition,
															'id' | 'cardType' | 'callToActionText' | 'isFeatured'
													  > & {
																displayProperties: {
																	__typename?: 'ImpactDisplayProperties';
																} & Pick<
																	ImpactDisplayProperties,
																	'showKeyMetrics' | 'showTitle'
																>;
																content?: Maybe<
																	{ __typename?: 'ImpactCardContent' } & Pick<
																		ImpactCardContent,
																		'summary'
																	>
																>;
																featuredCard?: Maybe<
																	{ __typename?: 'FeaturedCard' } & Pick<
																		FeaturedCard,
																		'label'
																	>
																>;
															})
												>;
												parentContainer?: Maybe<
													{ __typename?: 'Container' } & Pick<
														Container,
														'id' | 'name' | 'template'
													> & {
															campus?: Maybe<
																{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id'>
															>;
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																> & {
																		parentContainer?: Maybe<
																			{ __typename?: 'Container' } & Pick<
																				Container,
																				'id' | 'name'
																			>
																		>;
																	}
															>;
														}
												>;
												image?: Maybe<
													{ __typename?: 'Image' } & Pick<Image, 'id'> & {
															urls?: Maybe<
																{ __typename?: 'ImageFormatUrls' } & Pick<
																	ImageFormatUrls,
																	| 'square'
																	| 'original'
																	| 'panorama'
																	| 'landscape'
																	| 'dynamicHomeScreen'
																	| 'unprocessedImageUrl'
																>
															>;
														}
												>;
											}
									>;
									typeSpecificProperties?: Maybe<
										{ __typename?: 'StudioContainerTypeProperties' } & Pick<
											StudioContainerTypeProperties,
											'ctaLabel' | 'description' | 'link' | 'title'
										>
									>;
									parentContainer?: Maybe<
										{ __typename?: 'Container' } & Pick<Container, 'id' | 'name' | 'template'> & {
												parentContainer?: Maybe<
													{ __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
															parentContainer?: Maybe<
																{ __typename?: 'Container' } & Pick<
																	Container,
																	'id' | 'name'
																>
															>;
														}
												>;
											}
									>;
									image?: Maybe<
										{ __typename?: 'Image' } & Pick<Image, 'id'> & {
												urls?: Maybe<
													{ __typename?: 'ImageFormatUrls' } & Pick<
														ImageFormatUrls,
														| 'square'
														| 'original'
														| 'panorama'
														| 'landscape'
														| 'dynamicHomeScreen'
														| 'unprocessedImageUrl'
													>
												>;
											}
									>;
									cardDefinition?: Maybe<
										{ __typename?: 'ContainerCardDefinition' } & Pick<
											ContainerCardDefinition,
											'cardType' | 'id' | 'maxItems' | 'callToActionText'
										> & {
												dataMap: { __typename?: 'CardDataMap' } & {
													audioUrl?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													publishedTime?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													subtitle?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													summary?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
													videoUrl?: Maybe<
														{ __typename?: 'DataMap' } & Pick<DataMap, 'position' | 'type'>
													>;
												};
												featuredCard?: Maybe<
													{ __typename?: 'FeaturedCard' } & Pick<FeaturedCard, 'label'>
												>;
											}
									>;
									relatedContent: { __typename?: 'RelatedContent' } & Pick<
										RelatedContent,
										'isEnabled' | 'title'
									>;
									campus?: Maybe<
										{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name' | 'givingLink'>
									>;
									feed?: Maybe<
										| ({ __typename?: 'IcalFeed' } & Pick<
												IcalFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'PodcastFeed' } & Pick<
												PodcastFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id' | 'url' | 'processState'> & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VideoFeed' } & Pick<
												VideoFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'VimeoFeed' } & Pick<
												VimeoFeed,
												'id' | 'url' | 'processState'
										  > & {
													identifiers?: Maybe<
														{ __typename?: 'VimeoFeedIdentifiers' } & Pick<
															VimeoFeedIdentifiers,
															'channelId' | 'groupId' | 'showcaseId' | 'type' | 'userId'
														>
													>;
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
										| ({ __typename?: 'YouTubeFeed' } & Pick<
												YouTubeFeed,
												'id' | 'url' | 'processState'
										  > & {
													properties?: Maybe<
														{ __typename?: 'FeedProperty' } & Pick<
															FeedProperty,
															| 'actionBarArray'
															| 'forceRegenerate'
															| 'hiddenProperties'
															| 'itemTemplate'
															| 'itemTemplateRaw'
															| 'playlistId'
															| 'shareProperties'
														>
													>;
												})
									>;
								}
						>;
					}
			>;
		}
	>;
};

export type GetItemImageQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	itemId: Scalars['GUID'];
}>;

export type GetItemImageQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & {
					container?: Maybe<
						{ __typename?: 'Container' } & {
							item?: Maybe<
								{ __typename?: 'Item' } & Pick<Item, 'id'> & {
										image?: Maybe<
											{ __typename?: 'Image' } & Pick<Image, 'id'> & {
													urls?: Maybe<
														{ __typename?: 'ImageFormatUrls' } & Pick<
															ImageFormatUrls,
															| 'square'
															| 'original'
															| 'panorama'
															| 'landscape'
															| 'dynamicHomeScreen'
															| 'unprocessedImageUrl'
														>
													>;
												}
										>;
									}
							>;
						}
					>;
				}
			>;
		}
	>;
};

export type GetItemPropertiesQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	containerId: Scalars['GUID'];
	itemId: Scalars['GUID'];
}>;

export type GetItemPropertiesQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						container?: Maybe<
							{ __typename?: 'Container' } & Pick<Container, 'id'> & {
									item?: Maybe<
										{ __typename?: 'Item' } & Pick<Item, 'id'> & {
												properties: Array<
													| ({ __typename?: 'AddToContactsProperty' } & Pick<
															AddToContactsProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'AddressProperty' } & Pick<
															AddressProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'AppLinkProperty' } & Pick<
															AppLinkProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: AppLinkProperty['label'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'AudioProperty' } & Pick<
															AudioProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: AudioProperty['url'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'BlankifyProperty' } & Pick<
															BlankifyProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: BlankifyProperty['content'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'CallToActionProperty' } & Pick<
															CallToActionProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'CarouselProperty' } & Pick<
															CarouselProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'DefaultProperty' } & Pick<
															DefaultProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
															| 'data'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'GiveProperty' } & Pick<
															GiveProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'KeyMetricsProperty' } & Pick<
															KeyMetricsProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'ShareProperty' } & Pick<
															ShareProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'SmsProperty' } & Pick<
															SmsProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: SmsProperty['messageLabel'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'TextHtmlProperty' } & Pick<
															TextHtmlProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: TextHtmlProperty['textHtml'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'TextProperty' } & Pick<
															TextProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: TextProperty['textDescription'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'TimeframeProperty' } & Pick<
															TimeframeProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
																timeframe: { __typename?: 'Timeframe' } & Pick<
																	Timeframe,
																	'allDay' | 'endTime' | 'startTime'
																>;
															})
													| ({ __typename?: 'UserNoteProperty' } & Pick<
															UserNoteProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'VideoProperty' } & Pick<
															VideoProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: VideoProperty['url'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
													| ({ __typename?: 'WebsiteProperty' } & Pick<
															WebsiteProperty,
															| 'id'
															| 'itemId'
															| 'type'
															| 'position'
															| 'header'
															| 'icon'
															| 'hidden'
													  > & { data: WebsiteProperty['label'] } & {
																actionBar?: Maybe<
																	{ __typename?: 'ActionBar' } & Pick<
																		ActionBar,
																		'position' | 'hideOriginalProperty'
																	>
																>;
																action?: Maybe<
																	{ __typename?: 'ItemPropertyAction' } & Pick<
																		ItemPropertyAction,
																		'click'
																	>
																>;
															})
												>;
											}
									>;
								}
						>;
					}
			>;
		}
	>;
};

export type GetRootContentQueryVariables = Exact<{
	organizationKey: Scalars['String'];
	platformCampusKey?: Maybe<Scalars['String']>;
	applicationId: Scalars['GUID'];
	paging: CursorPagingInput;
}>;

export type GetRootContentQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & {
			application?: Maybe<
				{ __typename?: 'Application' } & Pick<Application, 'id'> & {
						rootContainer: { __typename?: 'Container' } & Pick<Container, 'id' | 'name'> & {
								children: { __typename?: 'CursorPagedContainerChildren' } & {
									nodes: Array<
										| ({ __typename?: 'ChildContainer' } & Pick<
												ChildContainer,
												'id' | 'name' | 'icon' | 'hidden' | 'position'
										  > & {
													container: { __typename?: 'Container' } & Pick<
														Container,
														'id' | 'navigationAction' | 'isDeletable' | 'type'
													> & {
															campus?: Maybe<
																{ __typename?: 'AppCampus' } & Pick<
																	AppCampus,
																	'id' | 'name'
																>
															>;
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'square'
																				| 'original'
																				| 'panorama'
																				| 'landscape'
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															feed?: Maybe<
																| ({ __typename?: 'IcalFeed' } & Pick<IcalFeed, 'id'>)
																| ({ __typename?: 'PodcastFeed' } & Pick<
																		PodcastFeed,
																		'id'
																  >)
																| ({ __typename?: 'RssFeed' } & Pick<RssFeed, 'id'>)
																| ({ __typename?: 'VideoFeed' } & Pick<VideoFeed, 'id'>)
																| ({ __typename?: 'VimeoFeed' } & Pick<VimeoFeed, 'id'>)
																| ({ __typename?: 'YouTubeFeed' } & Pick<
																		YouTubeFeed,
																		'id'
																  >)
															>;
														};
												})
										| ({ __typename?: 'ChildItem' } & Pick<
												ChildItem,
												'id' | 'name' | 'icon' | 'hidden' | 'position'
										  > & {
													item: { __typename?: 'Item' } & Pick<
														Item,
														'id' | 'type' | 'isMirrored' | 'feedId'
													> & {
															image?: Maybe<
																{ __typename?: 'Image' } & Pick<Image, 'id'> & {
																		urls?: Maybe<
																			{ __typename?: 'ImageFormatUrls' } & Pick<
																				ImageFormatUrls,
																				| 'square'
																				| 'original'
																				| 'panorama'
																				| 'landscape'
																				| 'dynamicHomeScreen'
																				| 'unprocessedImageUrl'
																			>
																		>;
																	}
															>;
															properties: Array<
																| ({ __typename?: 'AddToContactsProperty' } & Pick<
																		AddToContactsProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'AddressProperty' } & Pick<
																		AddressProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'AppLinkProperty' } & Pick<
																		AppLinkProperty,
																		'label' | 'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'AudioProperty' } & Pick<
																		AudioProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'BlankifyProperty' } & Pick<
																		BlankifyProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'CallToActionProperty' } & Pick<
																		CallToActionProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'CarouselProperty' } & Pick<
																		CarouselProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'DefaultProperty' } & Pick<
																		DefaultProperty,
																		'data' | 'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'GiveProperty' } & Pick<
																		GiveProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'KeyMetricsProperty' } & Pick<
																		KeyMetricsProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'ShareProperty' } & Pick<
																		ShareProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'SmsProperty' } & Pick<
																		SmsProperty,
																		'messageLabel' | 'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'TextHtmlProperty' } & Pick<
																		TextHtmlProperty,
																		'text' | 'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'TextProperty' } & Pick<
																		TextProperty,
																		| 'textDescription'
																		| 'type'
																		| 'header'
																		| 'position'
																  >)
																| ({ __typename?: 'TimeframeProperty' } & Pick<
																		TimeframeProperty,
																		'type' | 'header' | 'position'
																  > & {
																			timeframe: {
																				__typename?: 'Timeframe';
																			} & Pick<
																				Timeframe,
																				'allDay' | 'endTime' | 'startTime'
																			>;
																		})
																| ({ __typename?: 'UserNoteProperty' } & Pick<
																		UserNoteProperty,
																		'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'VideoProperty' } & Pick<
																		VideoProperty,
																		'url' | 'type' | 'header' | 'position'
																  >)
																| ({ __typename?: 'WebsiteProperty' } & Pick<
																		WebsiteProperty,
																		'label' | 'type' | 'header' | 'position'
																  >)
															>;
														};
												})
									>;
									paging: { __typename?: 'CursorPagingOutput' } & Pick<
										CursorPagingOutput,
										'totalItemCount' | 'nextCursor' | 'size'
									>;
								};
							};
					}
			>;
		}
	>;
};

export type GetSupportedCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetSupportedCountriesQuery = { __typename?: 'Query' } & {
	supportedCountriesDetails: Array<
		{ __typename?: 'CountryType' } & Pick<CountryType, 'cca2' | 'name' | 'callingCode'> & {
				timeZones?: Maybe<
					Array<Maybe<{ __typename?: 'TimeZone' } & Pick<TimeZone, 'olsonName' | 'windowsName'>>>
				>;
			}
	>;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = { __typename?: 'Query' } & {
	me?: Maybe<
		{ __typename?: 'Identity' } & Pick<
			Identity,
			'accountType' | 'identityKey' | 'firstName' | 'lastName' | 'createdOn' | 'olsonTimeZone' | 'timeZoneId'
		> & {
				primaryEmailAddress?: Maybe<{ __typename?: 'EmailContact' } & Pick<EmailContact, 'emailAddress'>>;
				allPermissions?: Maybe<
					{ __typename?: 'AllIdentityPermissions' } & {
						organizationPermissions: Array<
							{ __typename?: 'OrganizationPermissions' } & Pick<
								OrganizationPermissions,
								'organizationKey'
							> & {
									organization?: Maybe<
										{ __typename?: 'OrganizationDetails' } & Pick<
											OrganizationDetails,
											'name' | 'ecgId'
										>
									>;
									permissions: Array<{ __typename?: 'Permission' } & Pick<Permission, 'name'>>;
									campusPermissions: Array<
										{ __typename?: 'CampusPermissions' } & Pick<CampusPermissions, 'campusKey'> & {
												permissions: Array<
													{ __typename?: 'Permission' } & Pick<Permission, 'name'>
												>;
											}
									>;
								}
						>;
					}
				>;
				images?: Maybe<
					{ __typename?: 'IdentityImageSet' } & {
						avatar?: Maybe<{ __typename?: 'IdentityImage' } & Pick<IdentityImage, 'url'>>;
					}
				>;
				administeredOrganizations?: Maybe<
					Array<
						{ __typename?: 'AdministeredOrganization' } & Pick<
							AdministeredOrganization,
							'key' | 'name' | 'logoUrl'
						>
					>
				>;
			}
	>;
};

export type MyOrganizationQueryVariables = Exact<{
	organizationKey: Scalars['String'];
}>;

export type MyOrganizationQuery = { __typename?: 'Query' } & {
	organization?: Maybe<
		{ __typename?: 'Organization' } & Pick<Organization, 'key'> & {
				details?: Maybe<
					{ __typename?: 'OrganizationDetails' } & Pick<
						OrganizationDetails,
						'name' | 'ecgId' | 'catholicEntity' | 'catholicSoftwareStatus' | 'isGivingPlatformActive'
					> & {
							productInformation: { __typename?: 'ProductInfo' } & {
								chmsInformation: { __typename?: 'ChmsInfo' } & Pick<
									ChmsInfo,
									'tenantUrl' | 'isProvisioned'
								>;
							};
							entitlements: Array<
								{ __typename?: 'Entitlement' } & Pick<Entitlement, 'key' | 'displayName'>
							>;
							shippingAddress?: Maybe<{ __typename?: 'Address' } & Pick<Address, 'countryCode'>>;
							community?: Maybe<{ __typename?: 'Community' } & Pick<Community, 'isInSync'>>;
						}
				>;
				appStudio?: Maybe<
					{ __typename?: 'AppStudio' } & Pick<
						AppStudio,
						'isAppStudioDesignEnabled' | 'isAppStudioPushNotificationsEnabled'
					>
				>;
				featureFlags: Array<{ __typename?: 'FeatureFlag' } & Pick<FeatureFlag, 'name' | 'enabled'>>;
				applications: Array<
					{ __typename?: 'Application' } & Pick<
						Application,
						| 'id'
						| 'name'
						| 'givingLink'
						| 'downloadLink'
						| 'platformCampusKey'
						| 'productType'
						| 'productVersion'
					> & {
							campuses: Array<
								{ __typename?: 'AppCampus' } & Pick<AppCampus, 'id' | 'name' | 'givingLink'>
							>;
							images: { __typename?: 'ApplicationImages' } & Pick<
								ApplicationImages,
								'brandmark' | 'brandedHeader' | 'brandedHeaderDark' | 'homeScreenPlaceholder'
							>;
							features: Array<
								{ __typename?: 'Feature' } & Pick<Feature, 'id' | 'key' | 'enabled' | 'value'>
							>;
							dynamicHomeScreen?: Maybe<
								{ __typename?: 'DynamicHomeScreen' } & Pick<DynamicHomeScreen, 'publish' | 'isEnabled'>
							>;
							shellCapabilities: Array<
								{ __typename?: 'ShellCapability' } & Pick<ShellCapability, 'key' | 'isSupported'>
							>;
						}
				>;
			}
	>;
};

export type ValidatePendoGuideQueryVariables = Exact<{
	hash: Scalars['String'];
}>;

export type ValidatePendoGuideQuery = { __typename?: 'Query' } & Pick<Query, 'isGuideValid'>;

export const ImageUrlsFragmentDoc = gql`
	fragment ImageUrls on ImageFormatUrls {
		square
		original
		panorama
		landscape
		dynamicHomeScreen
		unprocessedImageUrl
	}
`;
export const ChildItemFragmentDoc = gql`
	fragment ChildItem on ChildItem {
		id
		name
		icon
		hidden
		position
		item {
			id
			type
			isMirrored
			feedId
			image {
				id
				urls {
					...ImageUrls
				}
			}
			properties {
				type
				header
				position
				... on TimeframeProperty {
					timeframe {
						allDay
						endTime
						startTime
					}
				}
				... on VideoProperty {
					url
				}
				... on AppLinkProperty {
					label
				}
				... on WebsiteProperty {
					label
				}
				... on SmsProperty {
					messageLabel
				}
				... on TextProperty {
					textDescription
				}
				... on TextHtmlProperty {
					text
				}
				... on DefaultProperty {
					data
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;
export const ChildContainerFragmentDoc = gql`
	fragment ChildContainer on ChildContainer {
		id
		name
		icon
		hidden
		position
		container {
			id
			navigationAction
			isDeletable
			type
			campus(organizationKey: $organizationKey) {
				id
				name
			}
			image {
				id
				urls {
					...ImageUrls
				}
			}
			feed {
				id
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;
export const ContainerChildrenNodesFragmentDoc = gql`
	fragment ContainerChildrenNodes on CursorPagedContainerChildren {
		nodes {
			...ChildItem
			...ChildContainer
		}
	}
	${ChildItemFragmentDoc}
	${ChildContainerFragmentDoc}
`;
export const ContainerChildrenPagingFragmentDoc = gql`
	fragment ContainerChildrenPaging on CursorPagedContainerChildren {
		paging {
			totalItemCount
			nextCursor
			size
		}
	}
`;
export const ChildContainerWithChildrenFragmentDoc = gql`
	fragment ChildContainerWithChildren on ChildContainer {
		id
		name
		icon
		hidden
		position
		container {
			id
			navigationAction
			isDeletable
			type
			campus(organizationKey: $organizationKey) {
				id
				name
			}
			image {
				id
				urls {
					...ImageUrls
				}
			}
			feed {
				id
			}
			children(paging: $paging) {
				...ContainerChildrenNodes
				...ContainerChildrenPaging
			}
		}
	}
	${ImageUrlsFragmentDoc}
	${ContainerChildrenNodesFragmentDoc}
	${ContainerChildrenPagingFragmentDoc}
`;
export const FeedFragmentDoc = gql`
	fragment Feed on Feed {
		id
		url
		processState
		properties {
			actionBarArray
			forceRegenerate
			hiddenProperties
			itemTemplate
			itemTemplateRaw
			playlistId
			shareProperties
		}
		... on VimeoFeed {
			identifiers {
				channelId
				groupId
				showcaseId
				type
				userId
			}
		}
	}
`;
export const ContainerSettingFragmentDoc = gql`
	fragment ContainerSetting on Container {
		id
		name
		isDeletable
		isHidden
		icon
		template
		givingLink
		type
		navigationAction
		subtitle
		resiLink
		typeSpecificProperties {
			... on StudioContainerTypeProperties {
				ctaLabel
				description
				link
				title
			}
		}
		parentContainer {
			id
			name
			template
			parentContainer {
				id
				name
				parentContainer {
					id
					name
				}
			}
		}
		image {
			id
			urls {
				...ImageUrls
			}
		}
		cardDefinition {
			cardType
			dataMap {
				audioUrl {
					position
					type
				}
				publishedTime {
					position
					type
				}
				subtitle {
					position
					type
				}
				summary {
					position
					type
				}
				videoUrl {
					position
					type
				}
			}
			id
			maxItems
			callToActionText
			featuredCard {
				label
			}
		}
		relatedContent {
			isEnabled
			title
		}
		campus(organizationKey: $organizationKey) {
			id
			name
			givingLink
		}
		feed {
			...Feed
			... on VimeoFeed {
				identifiers {
					channelId
					groupId
					showcaseId
					type
					userId
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
	${FeedFragmentDoc}
`;
export const ItemSettingFragmentDoc = gql`
	fragment ItemSetting on Item {
		id
		name
		feedId
		isHidden
		type
		template
		icon
		properties {
			action {
				click
			}
			actionBar {
				hideOriginalProperty
				position
			}
			header
			hidden
			icon
			id
			itemId
			type
			position
			... on AddressProperty {
				city
				latitude
				longitude
				state
				street
				zip
			}
			... on AppLinkProperty {
				androidAppUrl
				androidDefaultUrl
				iosAppUrl
				iosDefaultUrl
				label
			}
			... on AudioProperty {
				label
				url
			}
			... on BlankifyProperty {
				content
			}
			... on CallToActionProperty {
				isExternal
				label
				url
			}
			... on CarouselProperty {
				images {
					id
					urls {
						...ImageUrls
					}
				}
				title
			}
			... on DefaultProperty {
				data
			}
			... on GiveProperty {
				givingLink
				isExternal
			}
			... on KeyMetricsProperty {
				metrics {
					title
					value
				}
				metricsCount
			}
			... on ShareProperty {
				sharedProperties {
					type
					header
					hidden
					icon
					id
					itemId
					position
				}
			}
			... on SmsProperty {
				messageBody
				messageLabel
				phoneNumber
				androidDefaultUrl
				androidAppUrl
				iosDefaultUrl
				iosAppUrl
			}
			... on TextProperty {
				textDescription
			}
			... on TextHtmlProperty {
				text
				textHtml
			}
			... on TimeframeProperty {
				timeframe {
					allDay
					endTime
					startTime
				}
			}
			... on UserNoteProperty {
				hintText
			}
			... on VideoProperty {
				description
				isEmbed
				url
			}
			... on WebsiteProperty {
				isExternal
				label
				url
			}
		}
		cardDefinition {
			id
			cardType
			callToActionText
			isFeatured
			featuredCard {
				label
			}
			... on BaseCardDefinition {
				displayProperties {
					showPublishedTime
					showCategory
				}
				content {
					subtitle
					summary
				}
			}
			... on ImpactCardDefinition {
				displayProperties {
					showKeyMetrics
					showTitle
				}
				content {
					summary
				}
			}
		}
		parentContainer {
			id
			name
			template
			campus(organizationKey: $organizationKey) {
				id
			}
			parentContainer {
				id
				name
				parentContainer {
					id
					name
				}
			}
		}
		image {
			id
			urls {
				...ImageUrls
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;
export const MeFragmentDoc = gql`
	fragment Me on Identity {
		accountType
		identityKey
		firstName
		lastName
		createdOn
		olsonTimeZone
		timeZoneId
		primaryEmailAddress {
			emailAddress
		}
		allPermissions {
			organizationPermissions {
				organizationKey
				organization {
					name
					ecgId
				}
				permissions {
					name
				}
				campusPermissions {
					campusKey
					permissions {
						name
					}
				}
			}
		}
		images {
			avatar {
				url
			}
		}
		administeredOrganizations {
			key
			name
			logoUrl
		}
	}
`;
export const ProfileFragmentDoc = gql`
	fragment Profile on Identity {
		accountType
		address {
			line1
			line2
			city
			postcode
			state
			countryCode
		}
		birthday {
			day
			month
			year
		}
		emailAddresses {
			emailAddress
			verified
		}
		firstName
		identityKey
		lastName
		olsonTimeZone
		primaryEmailAddress {
			emailAddress
		}
		clientIpAddress {
			ipV4
		}
	}
`;
export const PropertyDataFragmentDoc = gql`
	fragment PropertyData on ItemProperty {
		... on DefaultProperty {
			data
		}
		... on VideoProperty {
			data: url
		}
		... on AudioProperty {
			data: url
		}
		... on TextProperty {
			data: textDescription
		}
		... on WebsiteProperty {
			data: label
		}
		... on AppLinkProperty {
			data: label
		}
		... on BlankifyProperty {
			data: content
		}
		... on SmsProperty {
			data: messageLabel
		}
		... on TextHtmlProperty {
			data: textHtml
		}
		... on TimeframeProperty {
			timeframe {
				allDay
				endTime
				startTime
			}
		}
	}
`;
export const PropertyFragmentDoc = gql`
	fragment Property on ItemProperty {
		action {
			click
		}
		actionBar {
			hideOriginalProperty
			position
		}
		header
		hidden
		icon
		id
		itemId
		type
		position
		... on AddressProperty {
			city
			latitude
			longitude
			state
			street
			zip
		}
		... on AppLinkProperty {
			label
			androidAppUrl
			androidDefaultUrl
			iosAppUrl
			iosDefaultUrl
		}
		... on AudioProperty {
			url
			label
		}
		... on BlankifyProperty {
			content
		}
		... on CallToActionProperty {
			isExternal
			label
			url
		}
		... on CarouselProperty {
			images {
				id
				urls {
					...ImageUrls
				}
			}
			title
		}
		... on DefaultProperty {
			data
		}
		... on GiveProperty {
			givingLink
			isExternal
		}
		... on KeyMetricsProperty {
			metrics {
				title
				value
			}
			metricsCount
		}
		... on ShareProperty {
			sharedProperties {
				actionBar {
					position
					hideOriginalProperty
				}
				action {
					click
				}
				type
				header
				hidden
				icon
				id
				itemId
				position
			}
		}
		... on SmsProperty {
			messageLabel
			messageBody
			phoneNumber
			androidDefaultUrl
			androidAppUrl
			iosDefaultUrl
			iosAppUrl
		}
		... on TextProperty {
			textDescription
		}
		... on TextHtmlProperty {
			textHtml
			text
		}
		... on TimeframeProperty {
			timeframe {
				allDay
				endTime
				startTime
			}
		}
		... on UserNoteProperty {
			hintText
		}
		... on VideoProperty {
			url
			description
			isEmbed
		}
		... on WebsiteProperty {
			url
			isExternal
			label
		}
	}
	${ImageUrlsFragmentDoc}
`;
export const CreateContainerDocument = gql`
	mutation CreateContainer($organizationKey: String!, $platformCampusKey: String, $input: CreateContainerInput!) {
		createContainer(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			createContainerInput: $input
		) {
			createdContainer {
				id
				isDeletable
				isHidden
				name
				icon
				type
				campus(organizationKey: $organizationKey) {
					id
					name
				}
				parentContainer {
					id
					navigationAction
				}
				typeSpecificProperties {
					... on StudioContainerTypeProperties {
						ctaLabel
						description
						link
						title
					}
				}
			}
		}
	}
`;

/**
 * __useCreateContainerMutation__
 *
 * To run a mutation, you first call `useCreateContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContainerMutation, { data, loading, error }] = useCreateContainerMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContainerMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateContainerMutation, CreateContainerMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CreateContainerMutation, CreateContainerMutationVariables>(
		CreateContainerDocument,
		options
	);
}
export type CreateContainerMutationHookResult = ReturnType<typeof useCreateContainerMutation>;
export type CreateContainerMutationResult = Apollo.MutationResult<CreateContainerMutation>;
export type CreateContainerMutationOptions = Apollo.BaseMutationOptions<
	CreateContainerMutation,
	CreateContainerMutationVariables
>;
export const ReorderContainerChildrenDocument = gql`
	mutation ReorderContainerChildren(
		$organizationKey: String!
		$platformCampusKey: String
		$input: ReorderContainerChildrenInput!
	) {
		reorderContainerChildren(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			reorderContainerChildrenInput: $input
		) {
			updatedContainerChildren {
				id
				position
			}
		}
	}
`;

/**
 * __useReorderContainerChildrenMutation__
 *
 * To run a mutation, you first call `useReorderContainerChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderContainerChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderContainerChildrenMutation, { data, loading, error }] = useReorderContainerChildrenMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderContainerChildrenMutation(
	baseOptions?: Apollo.MutationHookOptions<
		ReorderContainerChildrenMutation,
		ReorderContainerChildrenMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ReorderContainerChildrenMutation, ReorderContainerChildrenMutationVariables>(
		ReorderContainerChildrenDocument,
		options
	);
}
export type ReorderContainerChildrenMutationHookResult = ReturnType<typeof useReorderContainerChildrenMutation>;
export type ReorderContainerChildrenMutationResult = Apollo.MutationResult<ReorderContainerChildrenMutation>;
export type ReorderContainerChildrenMutationOptions = Apollo.BaseMutationOptions<
	ReorderContainerChildrenMutation,
	ReorderContainerChildrenMutationVariables
>;
export const SaveContainerSettingsDocument = gql`
	mutation saveContainerSettings(
		$organizationKey: String!
		$platformCampusKey: String
		$input: SaveContainerSettingsInput!
	) @form {
		saveContainerSettings(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			saveContainerSettingsInput: $input
		) {
			updatedContainer {
				...ContainerSetting
			}
		}
	}
	${ContainerSettingFragmentDoc}
`;

/**
 * __useSaveContainerSettingsMutation__
 *
 * To run a mutation, you first call `useSaveContainerSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveContainerSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveContainerSettingsMutation, { data, loading, error }] = useSaveContainerSettingsMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveContainerSettingsMutation(
	baseOptions?: Apollo.MutationHookOptions<SaveContainerSettingsMutation, SaveContainerSettingsMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SaveContainerSettingsMutation, SaveContainerSettingsMutationVariables>(
		SaveContainerSettingsDocument,
		options
	);
}
export type SaveContainerSettingsMutationHookResult = ReturnType<typeof useSaveContainerSettingsMutation>;
export type SaveContainerSettingsMutationResult = Apollo.MutationResult<SaveContainerSettingsMutation>;
export type SaveContainerSettingsMutationOptions = Apollo.BaseMutationOptions<
	SaveContainerSettingsMutation,
	SaveContainerSettingsMutationVariables
>;
export const DeleteContainerDocument = gql`
	mutation DeleteContainer(
		$organizationKey: String!
		$platformCampusKey: String
		$deleteContainerInput: DeleteContainerInput!
	) {
		deleteContainer(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			deleteContainerInput: $deleteContainerInput
		) {
			deletedContainer {
				id
			}
		}
	}
`;

/**
 * __useDeleteContainerMutation__
 *
 * To run a mutation, you first call `useDeleteContainerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContainerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContainerMutation, { data, loading, error }] = useDeleteContainerMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      deleteContainerInput: // value for 'deleteContainerInput'
 *   },
 * });
 */
export function useDeleteContainerMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteContainerMutation, DeleteContainerMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DeleteContainerMutation, DeleteContainerMutationVariables>(
		DeleteContainerDocument,
		options
	);
}
export type DeleteContainerMutationHookResult = ReturnType<typeof useDeleteContainerMutation>;
export type DeleteContainerMutationResult = Apollo.MutationResult<DeleteContainerMutation>;
export type DeleteContainerMutationOptions = Apollo.BaseMutationOptions<
	DeleteContainerMutation,
	DeleteContainerMutationVariables
>;
export const ProcessContainerFeedDocument = gql`
	mutation ProcessContainerFeed(
		$organizationKey: String!
		$platformCampusKey: String
		$input: ProcessContainerFeedInput!
	) {
		processContainerFeed(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			processContainerFeedInput: $input
		) {
			feed {
				id
				processState
			}
		}
	}
`;

/**
 * __useProcessContainerFeedMutation__
 *
 * To run a mutation, you first call `useProcessContainerFeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessContainerFeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processContainerFeedMutation, { data, loading, error }] = useProcessContainerFeedMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProcessContainerFeedMutation(
	baseOptions?: Apollo.MutationHookOptions<ProcessContainerFeedMutation, ProcessContainerFeedMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<ProcessContainerFeedMutation, ProcessContainerFeedMutationVariables>(
		ProcessContainerFeedDocument,
		options
	);
}
export type ProcessContainerFeedMutationHookResult = ReturnType<typeof useProcessContainerFeedMutation>;
export type ProcessContainerFeedMutationResult = Apollo.MutationResult<ProcessContainerFeedMutation>;
export type ProcessContainerFeedMutationOptions = Apollo.BaseMutationOptions<
	ProcessContainerFeedMutation,
	ProcessContainerFeedMutationVariables
>;
export const UpdateContainerVisibilityDocument = gql`
	mutation UpdateContainerVisibility(
		$organizationKey: String!
		$platformCampusKey: String
		$input: UpdateContainerVisibilityInput!
	) {
		updateContainerVisibility(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			updateContainerVisibilityInput: $input
		) {
			updatedContainer {
				id
				isHidden
			}
		}
	}
`;

/**
 * __useUpdateContainerVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateContainerVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContainerVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContainerVisibilityMutation, { data, loading, error }] = useUpdateContainerVisibilityMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContainerVisibilityMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateContainerVisibilityMutation,
		UpdateContainerVisibilityMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateContainerVisibilityMutation, UpdateContainerVisibilityMutationVariables>(
		UpdateContainerVisibilityDocument,
		options
	);
}
export type UpdateContainerVisibilityMutationHookResult = ReturnType<typeof useUpdateContainerVisibilityMutation>;
export type UpdateContainerVisibilityMutationResult = Apollo.MutationResult<UpdateContainerVisibilityMutation>;
export type UpdateContainerVisibilityMutationOptions = Apollo.BaseMutationOptions<
	UpdateContainerVisibilityMutation,
	UpdateContainerVisibilityMutationVariables
>;
export const GenerateFileUploadPostDocument = gql`
	mutation generateFileUploadPost(
		$organizationKey: String!
		$platformCampusKey: String
		$input: GenerateFileUploadPostInput!
	) {
		generateFileUploadPost(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			generateFileUploadPostInput: $input
		) {
			unprocessedImageUrl
			presignedPost {
				url
				key
				algorithm
				credential
				date
				policy
				token
				signature
				tagging
				acl
			}
		}
	}
`;

/**
 * __useGenerateFileUploadPostMutation__
 *
 * To run a mutation, you first call `useGenerateFileUploadPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateFileUploadPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateFileUploadPostMutation, { data, loading, error }] = useGenerateFileUploadPostMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateFileUploadPostMutation(
	baseOptions?: Apollo.MutationHookOptions<GenerateFileUploadPostMutation, GenerateFileUploadPostMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<GenerateFileUploadPostMutation, GenerateFileUploadPostMutationVariables>(
		GenerateFileUploadPostDocument,
		options
	);
}
export type GenerateFileUploadPostMutationHookResult = ReturnType<typeof useGenerateFileUploadPostMutation>;
export type GenerateFileUploadPostMutationResult = Apollo.MutationResult<GenerateFileUploadPostMutation>;
export type GenerateFileUploadPostMutationOptions = Apollo.BaseMutationOptions<
	GenerateFileUploadPostMutation,
	GenerateFileUploadPostMutationVariables
>;
export const CopyItemsDocument = gql`
	mutation CopyItems($organizationKey: String!, $platformCampusKey: String, $input: CopyItemsInput!) {
		copyItems(organizationKey: $organizationKey, platformCampusKey: $platformCampusKey, copyItemsInput: $input) {
			... on CopyItemsOutput {
				copyItemResults {
					... on CopyItemSuccess {
						itemId
						copiedItem {
							id
							name
							isHidden
							icon
							type
							isMirrored
							feedId
							image {
								id
								urls {
									...ImageUrls
								}
							}
							properties {
								...Property
							}
						}
					}
					... on CopyItemError {
						itemId
						message
					}
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
	${PropertyFragmentDoc}
`;

/**
 * __useCopyItemsMutation__
 *
 * To run a mutation, you first call `useCopyItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyItemsMutation, { data, loading, error }] = useCopyItemsMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyItemsMutation(
	baseOptions?: Apollo.MutationHookOptions<CopyItemsMutation, CopyItemsMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CopyItemsMutation, CopyItemsMutationVariables>(CopyItemsDocument, options);
}
export type CopyItemsMutationHookResult = ReturnType<typeof useCopyItemsMutation>;
export type CopyItemsMutationResult = Apollo.MutationResult<CopyItemsMutation>;
export type CopyItemsMutationOptions = Apollo.BaseMutationOptions<CopyItemsMutation, CopyItemsMutationVariables>;
export const CreateItemDocument = gql`
	mutation CreateItem($organizationKey: String!, $platformCampusKey: String, $input: CreateItemInput!) {
		createItem(organizationKey: $organizationKey, platformCampusKey: $platformCampusKey, createItemInput: $input) {
			createdItem {
				id
				name
				isHidden
				icon
				type
				isMirrored
				properties {
					type
					header
					position
					... on TimeframeProperty {
						timeframe {
							allDay
							endTime
							startTime
						}
					}
					... on TextHtmlProperty {
						text
					}
				}
			}
		}
	}
`;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(
	baseOptions?: Apollo.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, options);
}
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = Apollo.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = Apollo.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const FetchItemVideoThumbnailDocument = gql`
	mutation FetchItemVideoThumbnail(
		$organizationKey: String!
		$platformCampusKey: String
		$input: FetchItemVideoThumbnailInput!
	) {
		fetchItemVideoThumbnail(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			fetchItemVideoThumbnailInput: $input
		) {
			image {
				id
				urls {
					...ImageUrls
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;

/**
 * __useFetchItemVideoThumbnailMutation__
 *
 * To run a mutation, you first call `useFetchItemVideoThumbnailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchItemVideoThumbnailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchItemVideoThumbnailMutation, { data, loading, error }] = useFetchItemVideoThumbnailMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFetchItemVideoThumbnailMutation(
	baseOptions?: Apollo.MutationHookOptions<FetchItemVideoThumbnailMutation, FetchItemVideoThumbnailMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<FetchItemVideoThumbnailMutation, FetchItemVideoThumbnailMutationVariables>(
		FetchItemVideoThumbnailDocument,
		options
	);
}
export type FetchItemVideoThumbnailMutationHookResult = ReturnType<typeof useFetchItemVideoThumbnailMutation>;
export type FetchItemVideoThumbnailMutationResult = Apollo.MutationResult<FetchItemVideoThumbnailMutation>;
export type FetchItemVideoThumbnailMutationOptions = Apollo.BaseMutationOptions<
	FetchItemVideoThumbnailMutation,
	FetchItemVideoThumbnailMutationVariables
>;
export const SaveItemSettingsDocument = gql`
	mutation SaveItemSettings($organizationKey: String!, $platformCampusKey: String, $input: SaveItemSettingsInput!)
	@form {
		saveItemSettings(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			saveItemSettingsInput: $input
		) {
			updatedItem {
				...ItemSetting
			}
		}
	}
	${ItemSettingFragmentDoc}
`;

/**
 * __useSaveItemSettingsMutation__
 *
 * To run a mutation, you first call `useSaveItemSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveItemSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveItemSettingsMutation, { data, loading, error }] = useSaveItemSettingsMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveItemSettingsMutation(
	baseOptions?: Apollo.MutationHookOptions<SaveItemSettingsMutation, SaveItemSettingsMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<SaveItemSettingsMutation, SaveItemSettingsMutationVariables>(
		SaveItemSettingsDocument,
		options
	);
}
export type SaveItemSettingsMutationHookResult = ReturnType<typeof useSaveItemSettingsMutation>;
export type SaveItemSettingsMutationResult = Apollo.MutationResult<SaveItemSettingsMutation>;
export type SaveItemSettingsMutationOptions = Apollo.BaseMutationOptions<
	SaveItemSettingsMutation,
	SaveItemSettingsMutationVariables
>;
export const DeleteItemDocument = gql`
	mutation DeleteItem($organizationKey: String!, $platformCampusKey: String, $deleteItemInput: DeleteItemInput!) {
		deleteItem(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			deleteItemInput: $deleteItemInput
		) {
			deletedItem {
				id
			}
		}
	}
`;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      deleteItemInput: // value for 'deleteItemInput'
 *   },
 * });
 */
export function useDeleteItemMutation(
	baseOptions?: Apollo.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, options);
}
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = Apollo.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = Apollo.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const UpdateItemVisibilityDocument = gql`
	mutation UpdateItemVisibility(
		$organizationKey: String!
		$platformCampusKey: String
		$input: UpdateItemVisibilityInput!
	) {
		updateItemVisibility(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			updateItemVisibilityInput: $input
		) {
			updatedItem {
				id
				isHidden
			}
		}
	}
`;

/**
 * __useUpdateItemVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdateItemVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemVisibilityMutation, { data, loading, error }] = useUpdateItemVisibilityMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemVisibilityMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateItemVisibilityMutation, UpdateItemVisibilityMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateItemVisibilityMutation, UpdateItemVisibilityMutationVariables>(
		UpdateItemVisibilityDocument,
		options
	);
}
export type UpdateItemVisibilityMutationHookResult = ReturnType<typeof useUpdateItemVisibilityMutation>;
export type UpdateItemVisibilityMutationResult = Apollo.MutationResult<UpdateItemVisibilityMutation>;
export type UpdateItemVisibilityMutationOptions = Apollo.BaseMutationOptions<
	UpdateItemVisibilityMutation,
	UpdateItemVisibilityMutationVariables
>;
export const MoveChildrenDocument = gql`
	mutation MoveChildren($organizationKey: String!, $platformCampusKey: String, $input: MoveChildrenInput!) {
		moveChildren(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			moveChildrenInput: $input
		) {
			... on MoveChildrenOutput {
				moveChildResults {
					... on MoveChildSuccess {
						movedChild {
							... on Container {
								id
								parentContainer {
									id
								}
							}
							... on Item {
								id
								parentContainer {
									id
								}
							}
						}
					}
					... on MoveChildError {
						childId
						message
					}
				}
				newParentContainer {
					id
					isDeletable
					children {
						...ContainerChildrenNodes
					}
				}
				previousParentContainer {
					id
					isDeletable
				}
			}
		}
	}
	${ContainerChildrenNodesFragmentDoc}
`;

/**
 * __useMoveChildrenMutation__
 *
 * To run a mutation, you first call `useMoveChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveChildrenMutation, { data, loading, error }] = useMoveChildrenMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMoveChildrenMutation(
	baseOptions?: Apollo.MutationHookOptions<MoveChildrenMutation, MoveChildrenMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MoveChildrenMutation, MoveChildrenMutationVariables>(MoveChildrenDocument, options);
}
export type MoveChildrenMutationHookResult = ReturnType<typeof useMoveChildrenMutation>;
export type MoveChildrenMutationResult = Apollo.MutationResult<MoveChildrenMutation>;
export type MoveChildrenMutationOptions = Apollo.BaseMutationOptions<
	MoveChildrenMutation,
	MoveChildrenMutationVariables
>;
export const UpdateProfileDocument = gql`
	mutation UpdateProfile($organizationKey: String!, $input: UpdateProfileInput!) @form @feature(name: "profile") {
		updateProfile(organizationKey: $organizationKey, input: $input) {
			identity {
				...Profile
			}
		}
	}
	${ProfileFragmentDoc}
`;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
	UpdateProfileMutation,
	UpdateProfileMutationVariables
>;
export const PublishChangesDocument = gql`
	mutation publishChanges($organizationKey: String!, $platformCampusKey: String, $input: PublishChangesInput!) {
		publishChanges(
			organizationKey: $organizationKey
			platformCampusKey: $platformCampusKey
			publishChangesInput: $input
		) {
			application {
				id
				pendingChanges
				publishStatus
			}
		}
	}
`;

/**
 * __usePublishChangesMutation__
 *
 * To run a mutation, you first call `usePublishChangesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishChangesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishChangesMutation, { data, loading, error }] = usePublishChangesMutation({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePublishChangesMutation(
	baseOptions?: Apollo.MutationHookOptions<PublishChangesMutation, PublishChangesMutationVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<PublishChangesMutation, PublishChangesMutationVariables>(PublishChangesDocument, options);
}
export type PublishChangesMutationHookResult = ReturnType<typeof usePublishChangesMutation>;
export type PublishChangesMutationResult = Apollo.MutationResult<PublishChangesMutation>;
export type PublishChangesMutationOptions = Apollo.BaseMutationOptions<
	PublishChangesMutation,
	PublishChangesMutationVariables
>;
export const GetProfileDocument = gql`
	query GetProfile @feature(name: "profile") {
		me {
			...Profile
		}
	}
	${ProfileFragmentDoc}
`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export function useGetProfileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
}
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetApplicationPublishStatusDocument = gql`
	query GetApplicationPublishStatus($organizationKey: String!, $platformCampusKey: String, $applicationId: GUID!)
	@feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				pendingChanges
				publishStatus
			}
		}
	}
`;

/**
 * __useGetApplicationPublishStatusQuery__
 *
 * To run a query within a React component, call `useGetApplicationPublishStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationPublishStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationPublishStatusQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetApplicationPublishStatusQuery(
	baseOptions: Apollo.QueryHookOptions<GetApplicationPublishStatusQuery, GetApplicationPublishStatusQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetApplicationPublishStatusQuery, GetApplicationPublishStatusQueryVariables>(
		GetApplicationPublishStatusDocument,
		options
	);
}
export function useGetApplicationPublishStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetApplicationPublishStatusQuery,
		GetApplicationPublishStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetApplicationPublishStatusQuery, GetApplicationPublishStatusQueryVariables>(
		GetApplicationPublishStatusDocument,
		options
	);
}
export type GetApplicationPublishStatusQueryHookResult = ReturnType<typeof useGetApplicationPublishStatusQuery>;
export type GetApplicationPublishStatusLazyQueryHookResult = ReturnType<typeof useGetApplicationPublishStatusLazyQuery>;
export type GetApplicationPublishStatusQueryResult = Apollo.QueryResult<
	GetApplicationPublishStatusQuery,
	GetApplicationPublishStatusQueryVariables
>;
export const GetApplicationSettingsDocument = gql`
	query GetApplicationSettings($organizationKey: String!, $platformCampusKey: String, $applicationId: GUID!)
	@feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				setting {
					id
					key
					value
					valueType
					enabled
				}
				shellCapabilities {
					isSupported
					key
				}
			}
		}
	}
`;

/**
 * __useGetApplicationSettingsQuery__
 *
 * To run a query within a React component, call `useGetApplicationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationSettingsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetApplicationSettingsQuery(
	baseOptions: Apollo.QueryHookOptions<GetApplicationSettingsQuery, GetApplicationSettingsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetApplicationSettingsQuery, GetApplicationSettingsQueryVariables>(
		GetApplicationSettingsDocument,
		options
	);
}
export function useGetApplicationSettingsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationSettingsQuery, GetApplicationSettingsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetApplicationSettingsQuery, GetApplicationSettingsQueryVariables>(
		GetApplicationSettingsDocument,
		options
	);
}
export type GetApplicationSettingsQueryHookResult = ReturnType<typeof useGetApplicationSettingsQuery>;
export type GetApplicationSettingsLazyQueryHookResult = ReturnType<typeof useGetApplicationSettingsLazyQuery>;
export type GetApplicationSettingsQueryResult = Apollo.QueryResult<
	GetApplicationSettingsQuery,
	GetApplicationSettingsQueryVariables
>;
export const GetCarouselImagesDocument = gql`
	query GetCarouselImages(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$itemId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				container(containerId: $containerId) {
					item(itemId: $itemId) {
						id
						properties {
							id
							... on CarouselProperty {
								images {
									id
									urls {
										...ImageUrls
									}
								}
							}
						}
					}
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;

/**
 * __useGetCarouselImagesQuery__
 *
 * To run a query within a React component, call `useGetCarouselImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarouselImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarouselImagesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetCarouselImagesQuery(
	baseOptions: Apollo.QueryHookOptions<GetCarouselImagesQuery, GetCarouselImagesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetCarouselImagesQuery, GetCarouselImagesQueryVariables>(GetCarouselImagesDocument, options);
}
export function useGetCarouselImagesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetCarouselImagesQuery, GetCarouselImagesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetCarouselImagesQuery, GetCarouselImagesQueryVariables>(
		GetCarouselImagesDocument,
		options
	);
}
export type GetCarouselImagesQueryHookResult = ReturnType<typeof useGetCarouselImagesQuery>;
export type GetCarouselImagesLazyQueryHookResult = ReturnType<typeof useGetCarouselImagesLazyQuery>;
export type GetCarouselImagesQueryResult = Apollo.QueryResult<GetCarouselImagesQuery, GetCarouselImagesQueryVariables>;
export const GetContainerChildrenDocument = gql`
	query GetContainerChildren(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$paging: CursorPagingInput!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					id
					name
					isDeletable
					isHidden
					icon
					template
					type
					subtitle
					navigationAction
					feed {
						id
					}
					parentContainer {
						id
						name
						type
						feed {
							id
						}
						parentContainer {
							id
							name
							feed {
								id
							}
							parentContainer {
								id
								name
								feed {
									id
								}
							}
						}
					}
					image {
						id
						urls {
							...ImageUrls
						}
					}
					children(paging: $paging) {
						...ContainerChildrenNodes
						...ContainerChildrenPaging
					}
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
	${ContainerChildrenNodesFragmentDoc}
	${ContainerChildrenPagingFragmentDoc}
`;

/**
 * __useGetContainerChildrenQuery__
 *
 * To run a query within a React component, call `useGetContainerChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerChildrenQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetContainerChildrenQuery(
	baseOptions: Apollo.QueryHookOptions<GetContainerChildrenQuery, GetContainerChildrenQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetContainerChildrenQuery, GetContainerChildrenQueryVariables>(
		GetContainerChildrenDocument,
		options
	);
}
export function useGetContainerChildrenLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContainerChildrenQuery, GetContainerChildrenQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetContainerChildrenQuery, GetContainerChildrenQueryVariables>(
		GetContainerChildrenDocument,
		options
	);
}
export type GetContainerChildrenQueryHookResult = ReturnType<typeof useGetContainerChildrenQuery>;
export type GetContainerChildrenLazyQueryHookResult = ReturnType<typeof useGetContainerChildrenLazyQuery>;
export type GetContainerChildrenQueryResult = Apollo.QueryResult<
	GetContainerChildrenQuery,
	GetContainerChildrenQueryVariables
>;
export const GetContainerChildrenWithNestedChildrenDocument = gql`
	query GetContainerChildrenWithNestedChildren(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$paging: CursorPagingInput!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					id
					children(paging: $paging) {
						nodes {
							...ChildItem
							...ChildContainerWithChildren
						}
						...ContainerChildrenPaging
					}
				}
			}
		}
	}
	${ChildItemFragmentDoc}
	${ChildContainerWithChildrenFragmentDoc}
	${ContainerChildrenPagingFragmentDoc}
`;

/**
 * __useGetContainerChildrenWithNestedChildrenQuery__
 *
 * To run a query within a React component, call `useGetContainerChildrenWithNestedChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerChildrenWithNestedChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerChildrenWithNestedChildrenQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetContainerChildrenWithNestedChildrenQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetContainerChildrenWithNestedChildrenQuery,
		GetContainerChildrenWithNestedChildrenQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetContainerChildrenWithNestedChildrenQuery,
		GetContainerChildrenWithNestedChildrenQueryVariables
	>(GetContainerChildrenWithNestedChildrenDocument, options);
}
export function useGetContainerChildrenWithNestedChildrenLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetContainerChildrenWithNestedChildrenQuery,
		GetContainerChildrenWithNestedChildrenQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetContainerChildrenWithNestedChildrenQuery,
		GetContainerChildrenWithNestedChildrenQueryVariables
	>(GetContainerChildrenWithNestedChildrenDocument, options);
}
export type GetContainerChildrenWithNestedChildrenQueryHookResult = ReturnType<
	typeof useGetContainerChildrenWithNestedChildrenQuery
>;
export type GetContainerChildrenWithNestedChildrenLazyQueryHookResult = ReturnType<
	typeof useGetContainerChildrenWithNestedChildrenLazyQuery
>;
export type GetContainerChildrenWithNestedChildrenQueryResult = Apollo.QueryResult<
	GetContainerChildrenWithNestedChildrenQuery,
	GetContainerChildrenWithNestedChildrenQueryVariables
>;
export const GetContainerFeedDocument = gql`
	query GetContainerFeed(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					id
					children {
						...ContainerChildrenNodes
						...ContainerChildrenPaging
					}
					feed {
						id
						url
						processState
						properties {
							actionBarArray
							forceRegenerate
							hiddenProperties
							itemTemplate
							playlistId
							shareProperties
						}
					}
				}
			}
		}
	}
	${ContainerChildrenNodesFragmentDoc}
	${ContainerChildrenPagingFragmentDoc}
`;

/**
 * __useGetContainerFeedQuery__
 *
 * To run a query within a React component, call `useGetContainerFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerFeedQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetContainerFeedQuery(
	baseOptions: Apollo.QueryHookOptions<GetContainerFeedQuery, GetContainerFeedQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetContainerFeedQuery, GetContainerFeedQueryVariables>(GetContainerFeedDocument, options);
}
export function useGetContainerFeedLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContainerFeedQuery, GetContainerFeedQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetContainerFeedQuery, GetContainerFeedQueryVariables>(
		GetContainerFeedDocument,
		options
	);
}
export type GetContainerFeedQueryHookResult = ReturnType<typeof useGetContainerFeedQuery>;
export type GetContainerFeedLazyQueryHookResult = ReturnType<typeof useGetContainerFeedLazyQuery>;
export type GetContainerFeedQueryResult = Apollo.QueryResult<GetContainerFeedQuery, GetContainerFeedQueryVariables>;
export const GetContainerImageDocument = gql`
	query GetContainerImage(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				container(containerId: $containerId) {
					image {
						id
						urls {
							...ImageUrls
						}
					}
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;

/**
 * __useGetContainerImageQuery__
 *
 * To run a query within a React component, call `useGetContainerImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerImageQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetContainerImageQuery(
	baseOptions: Apollo.QueryHookOptions<GetContainerImageQuery, GetContainerImageQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetContainerImageQuery, GetContainerImageQueryVariables>(GetContainerImageDocument, options);
}
export function useGetContainerImageLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContainerImageQuery, GetContainerImageQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetContainerImageQuery, GetContainerImageQueryVariables>(
		GetContainerImageDocument,
		options
	);
}
export type GetContainerImageQueryHookResult = ReturnType<typeof useGetContainerImageQuery>;
export type GetContainerImageLazyQueryHookResult = ReturnType<typeof useGetContainerImageLazyQuery>;
export type GetContainerImageQueryResult = Apollo.QueryResult<GetContainerImageQuery, GetContainerImageQueryVariables>;
export const GetContainerSettingsDocument = gql`
	query GetContainerSettings(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$paging: CursorPagingInput!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					...ContainerSetting
					children(paging: $paging) {
						...ContainerChildrenNodes
						paging {
							totalItemCount
						}
					}
				}
			}
		}
	}
	${ContainerSettingFragmentDoc}
	${ContainerChildrenNodesFragmentDoc}
`;

/**
 * __useGetContainerSettingsQuery__
 *
 * To run a query within a React component, call `useGetContainerSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerSettingsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetContainerSettingsQuery(
	baseOptions: Apollo.QueryHookOptions<GetContainerSettingsQuery, GetContainerSettingsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetContainerSettingsQuery, GetContainerSettingsQueryVariables>(
		GetContainerSettingsDocument,
		options
	);
}
export function useGetContainerSettingsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContainerSettingsQuery, GetContainerSettingsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetContainerSettingsQuery, GetContainerSettingsQueryVariables>(
		GetContainerSettingsDocument,
		options
	);
}
export type GetContainerSettingsQueryHookResult = ReturnType<typeof useGetContainerSettingsQuery>;
export type GetContainerSettingsLazyQueryHookResult = ReturnType<typeof useGetContainerSettingsLazyQuery>;
export type GetContainerSettingsQueryResult = Apollo.QueryResult<
	GetContainerSettingsQuery,
	GetContainerSettingsQueryVariables
>;
export const GetContainerVisibilityDocument = gql`
	query GetContainerVisibility(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					id
					name
					isHidden
				}
			}
		}
	}
`;

/**
 * __useGetContainerVisibilityQuery__
 *
 * To run a query within a React component, call `useGetContainerVisibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContainerVisibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContainerVisibilityQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useGetContainerVisibilityQuery(
	baseOptions: Apollo.QueryHookOptions<GetContainerVisibilityQuery, GetContainerVisibilityQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetContainerVisibilityQuery, GetContainerVisibilityQueryVariables>(
		GetContainerVisibilityDocument,
		options
	);
}
export function useGetContainerVisibilityLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetContainerVisibilityQuery, GetContainerVisibilityQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetContainerVisibilityQuery, GetContainerVisibilityQueryVariables>(
		GetContainerVisibilityDocument,
		options
	);
}
export type GetContainerVisibilityQueryHookResult = ReturnType<typeof useGetContainerVisibilityQuery>;
export type GetContainerVisibilityLazyQueryHookResult = ReturnType<typeof useGetContainerVisibilityLazyQuery>;
export type GetContainerVisibilityQueryResult = Apollo.QueryResult<
	GetContainerVisibilityQuery,
	GetContainerVisibilityQueryVariables
>;
export const GetDhsCardsDocument = gql`
	query GetDHSCards(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$campusId: GUID
		$timeZone: String
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				dynamicHomeScreen {
					publish
					cards(campusId: $campusId, timeZone: $timeZone) {
						id
						title
						subtitle
						summary
						tabName
						item {
							id
							image {
								id
								urls {
									dynamicHomeScreen
									unprocessedImageUrl
								}
							}
							properties {
								id
								position
								... on VideoProperty {
									url
								}
								... on ShareProperty {
									sharedProperties {
										id
									}
								}
								... on TimeframeProperty {
									timeframe {
										startTime
										endTime
										allDay
									}
								}
							}
							parentContainer {
								id
								name
								image {
									id
									urls {
										dynamicHomeScreen
										unprocessedImageUrl
									}
								}
								cardDefinition {
									showCategory
									showPublishedTime
									featuredCard {
										label
									}
								}
							}
							cardDefinition {
								id
								cardType
								callToActionText
								isFeatured
								featuredCard {
									label
								}
								... on BaseCardDefinition {
									displayProperties {
										showPublishedTime
										showCategory
									}
									content {
										subtitle
										summary
									}
								}
								... on ImpactCardDefinition {
									displayProperties {
										showKeyMetrics
										showTitle
									}
									content {
										summary
									}
								}
							}
						}
						... on DefaultCard {
							publishedTime
							category
						}
						... on ImpactCard {
							callToActionText
							keyMetrics {
								value
								title
							}
						}
						... on SpecialCard {
							callToActionText
							publishedTime
							category
						}
						... on IntroCard {
							callToActionText
							publishedTime
							category
						}
						... on EventCard {
							publishedTime
							category
						}
						... on VideoCard {
							videoUrl
							publishedTime
							category
						}
						... on AudioCard {
							audioUrl
							publishedTime
							category
						}
					}
				}
			}
		}
	}
`;

/**
 * __useGetDhsCardsQuery__
 *
 * To run a query within a React component, call `useGetDhsCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDhsCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDhsCardsQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      campusId: // value for 'campusId'
 *      timeZone: // value for 'timeZone'
 *   },
 * });
 */
export function useGetDhsCardsQuery(baseOptions: Apollo.QueryHookOptions<GetDhsCardsQuery, GetDhsCardsQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetDhsCardsQuery, GetDhsCardsQueryVariables>(GetDhsCardsDocument, options);
}
export function useGetDhsCardsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetDhsCardsQuery, GetDhsCardsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetDhsCardsQuery, GetDhsCardsQueryVariables>(GetDhsCardsDocument, options);
}
export type GetDhsCardsQueryHookResult = ReturnType<typeof useGetDhsCardsQuery>;
export type GetDhsCardsLazyQueryHookResult = ReturnType<typeof useGetDhsCardsLazyQuery>;
export type GetDhsCardsQueryResult = Apollo.QueryResult<GetDhsCardsQuery, GetDhsCardsQueryVariables>;
export const GetItemDocument = gql`
	query GetItem(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$itemId: GUID!
		$paging: CursorPagingInput
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					...ContainerSetting
					children(paging: $paging) {
						...ContainerChildrenNodes
						... on CursorPagedContainerChildren {
							nodes {
								... on ChildItem {
									item {
										createdDate
										updatedDate
										properties {
											...Property
										}
										cardDefinition {
											id
											... on BaseCardDefinition {
												content {
													subtitle
												}
											}
										}
									}
								}
							}
						}
					}
					item(itemId: $itemId) {
						...ItemSetting
					}
				}
			}
		}
	}
	${ContainerSettingFragmentDoc}
	${ContainerChildrenNodesFragmentDoc}
	${PropertyFragmentDoc}
	${ItemSettingFragmentDoc}
`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      itemId: // value for 'itemId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
}
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
}
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const GetItemImageDocument = gql`
	query GetItemImage(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$itemId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				container(containerId: $containerId) {
					item(itemId: $itemId) {
						id
						image {
							id
							urls {
								...ImageUrls
							}
						}
					}
				}
			}
		}
	}
	${ImageUrlsFragmentDoc}
`;

/**
 * __useGetItemImageQuery__
 *
 * To run a query within a React component, call `useGetItemImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemImageQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemImageQuery(
	baseOptions: Apollo.QueryHookOptions<GetItemImageQuery, GetItemImageQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetItemImageQuery, GetItemImageQueryVariables>(GetItemImageDocument, options);
}
export function useGetItemImageLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetItemImageQuery, GetItemImageQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetItemImageQuery, GetItemImageQueryVariables>(GetItemImageDocument, options);
}
export type GetItemImageQueryHookResult = ReturnType<typeof useGetItemImageQuery>;
export type GetItemImageLazyQueryHookResult = ReturnType<typeof useGetItemImageLazyQuery>;
export type GetItemImageQueryResult = Apollo.QueryResult<GetItemImageQuery, GetItemImageQueryVariables>;
export const GetItemPropertiesDocument = gql`
	query GetItemProperties(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$containerId: GUID!
		$itemId: GUID!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				container(containerId: $containerId) {
					id
					item(itemId: $itemId) {
						id
						properties {
							id
							itemId
							type
							position
							header
							icon
							hidden
							actionBar {
								position
								hideOriginalProperty
							}
							action {
								click
							}
							...PropertyData
						}
					}
				}
			}
		}
	}
	${PropertyDataFragmentDoc}
`;

/**
 * __useGetItemPropertiesQuery__
 *
 * To run a query within a React component, call `useGetItemPropertiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemPropertiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemPropertiesQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      containerId: // value for 'containerId'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useGetItemPropertiesQuery(
	baseOptions: Apollo.QueryHookOptions<GetItemPropertiesQuery, GetItemPropertiesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetItemPropertiesQuery, GetItemPropertiesQueryVariables>(GetItemPropertiesDocument, options);
}
export function useGetItemPropertiesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetItemPropertiesQuery, GetItemPropertiesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetItemPropertiesQuery, GetItemPropertiesQueryVariables>(
		GetItemPropertiesDocument,
		options
	);
}
export type GetItemPropertiesQueryHookResult = ReturnType<typeof useGetItemPropertiesQuery>;
export type GetItemPropertiesLazyQueryHookResult = ReturnType<typeof useGetItemPropertiesLazyQuery>;
export type GetItemPropertiesQueryResult = Apollo.QueryResult<GetItemPropertiesQuery, GetItemPropertiesQueryVariables>;
export const GetRootContentDocument = gql`
	query GetRootContent(
		$organizationKey: String!
		$platformCampusKey: String
		$applicationId: GUID!
		$paging: CursorPagingInput!
	) @feature(name: "root") {
		organization(organizationKey: $organizationKey) {
			application(id: $applicationId, platformCampusKey: $platformCampusKey) {
				id
				rootContainer {
					id
					name
					children(paging: $paging) {
						...ContainerChildrenNodes
						...ContainerChildrenPaging
					}
				}
			}
		}
	}
	${ContainerChildrenNodesFragmentDoc}
	${ContainerChildrenPagingFragmentDoc}
`;

/**
 * __useGetRootContentQuery__
 *
 * To run a query within a React component, call `useGetRootContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootContentQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      platformCampusKey: // value for 'platformCampusKey'
 *      applicationId: // value for 'applicationId'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetRootContentQuery(
	baseOptions: Apollo.QueryHookOptions<GetRootContentQuery, GetRootContentQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetRootContentQuery, GetRootContentQueryVariables>(GetRootContentDocument, options);
}
export function useGetRootContentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetRootContentQuery, GetRootContentQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetRootContentQuery, GetRootContentQueryVariables>(GetRootContentDocument, options);
}
export type GetRootContentQueryHookResult = ReturnType<typeof useGetRootContentQuery>;
export type GetRootContentLazyQueryHookResult = ReturnType<typeof useGetRootContentLazyQuery>;
export type GetRootContentQueryResult = Apollo.QueryResult<GetRootContentQuery, GetRootContentQueryVariables>;
export const GetSupportedCountriesDocument = gql`
	query GetSupportedCountries @feature(name: "shell") {
		supportedCountriesDetails {
			cca2
			name
			callingCode
			timeZones {
				olsonName
				windowsName
			}
		}
	}
`;

/**
 * __useGetSupportedCountriesQuery__
 *
 * To run a query within a React component, call `useGetSupportedCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportedCountriesQuery(
	baseOptions?: Apollo.QueryHookOptions<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>(
		GetSupportedCountriesDocument,
		options
	);
}
export function useGetSupportedCountriesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetSupportedCountriesQuery, GetSupportedCountriesQueryVariables>(
		GetSupportedCountriesDocument,
		options
	);
}
export type GetSupportedCountriesQueryHookResult = ReturnType<typeof useGetSupportedCountriesQuery>;
export type GetSupportedCountriesLazyQueryHookResult = ReturnType<typeof useGetSupportedCountriesLazyQuery>;
export type GetSupportedCountriesQueryResult = Apollo.QueryResult<
	GetSupportedCountriesQuery,
	GetSupportedCountriesQueryVariables
>;
export const MeDocument = gql`
	query Me @critical @feature(name: "shell") {
		me {
			...Me
		}
	}
	${MeFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyOrganizationDocument = gql`
	query MyOrganization($organizationKey: String!) @critical @feature(name: "shell") {
		organization(organizationKey: $organizationKey) {
			key
			details {
				productInformation {
					chmsInformation {
						tenantUrl
						isProvisioned
					}
				}
				name
				ecgId
				entitlements {
					key
					displayName
				}
				catholicEntity
				catholicSoftwareStatus
				isGivingPlatformActive
				shippingAddress {
					countryCode
				}
				community {
					isInSync
				}
			}
			appStudio {
				isAppStudioDesignEnabled
				isAppStudioPushNotificationsEnabled
			}
			featureFlags {
				name
				enabled
			}
			applications {
				id
				name
				givingLink
				downloadLink
				campuses(organizationKey: $organizationKey) {
					id
					name
					givingLink
				}
				images {
					brandmark
					brandedHeader
					brandedHeaderDark
					homeScreenPlaceholder
				}
				features {
					id
					key
					enabled
					value
				}
				dynamicHomeScreen {
					publish
					isEnabled
				}
				platformCampusKey
				productType
				productVersion
				shellCapabilities {
					key
					isSupported
				}
			}
		}
	}
`;

/**
 * __useMyOrganizationQuery__
 *
 * To run a query within a React component, call `useMyOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganizationQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useMyOrganizationQuery(
	baseOptions: Apollo.QueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
}
export function useMyOrganizationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<MyOrganizationQuery, MyOrganizationQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<MyOrganizationQuery, MyOrganizationQueryVariables>(MyOrganizationDocument, options);
}
export type MyOrganizationQueryHookResult = ReturnType<typeof useMyOrganizationQuery>;
export type MyOrganizationLazyQueryHookResult = ReturnType<typeof useMyOrganizationLazyQuery>;
export type MyOrganizationQueryResult = Apollo.QueryResult<MyOrganizationQuery, MyOrganizationQueryVariables>;
export const ValidatePendoGuideDocument = gql`
	query ValidatePendoGuide($hash: String!) @feature(name: "shell") {
		isGuideValid(hash: $hash)
	}
`;

/**
 * __useValidatePendoGuideQuery__
 *
 * To run a query within a React component, call `useValidatePendoGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePendoGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePendoGuideQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *   },
 * });
 */
export function useValidatePendoGuideQuery(
	baseOptions: Apollo.QueryHookOptions<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>(
		ValidatePendoGuideDocument,
		options
	);
}
export function useValidatePendoGuideLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ValidatePendoGuideQuery, ValidatePendoGuideQueryVariables>(
		ValidatePendoGuideDocument,
		options
	);
}
export type ValidatePendoGuideQueryHookResult = ReturnType<typeof useValidatePendoGuideQuery>;
export type ValidatePendoGuideLazyQueryHookResult = ReturnType<typeof useValidatePendoGuideLazyQuery>;
export type ValidatePendoGuideQueryResult = Apollo.QueryResult<
	ValidatePendoGuideQuery,
	ValidatePendoGuideQueryVariables
>;

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
	resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
	| ResolverFn<TResult, TParent, TContext, TArgs>
	| ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
	resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
	subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
	resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
	| SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
	| SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
	| ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
	| SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
	parent: TParent,
	context: TContext,
	info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
	obj: T,
	context: TContext,
	info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
	next: NextResolverFn<TResult>,
	parent: TParent,
	args: TArgs,
	context: TContext,
	info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
	AccountType: AccountType;
	ActionBar: ResolverTypeWrapper<ActionBar>;
	ActionBarInput: ActionBarInput;
	AddToContactsProperty: ResolverTypeWrapper<
		Omit<AddToContactsProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	AddToContactsPropertyInput: AddToContactsPropertyInput;
	Address: ResolverTypeWrapper<Address>;
	AddressArgumentsInput: AddressArgumentsInput;
	AddressProperty: ResolverTypeWrapper<
		Omit<AddressProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	AddressPropertyInput: AddressPropertyInput;
	AdministeredOrganization: ResolverTypeWrapper<AdministeredOrganization>;
	AllIdentityPermissions: ResolverTypeWrapper<AllIdentityPermissions>;
	AppCampus: ResolverTypeWrapper<
		Omit<AppCampus, 'givingLink' | 'id' | 'organizationId'> & {
			givingLink?: Maybe<ResolversTypes['URL']>;
			id: ResolversTypes['GUID'];
			organizationId: ResolversTypes['GUID'];
		}
	>;
	AppLinkProperty: ResolverTypeWrapper<
		Omit<AppLinkProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	AppLinkPropertyInput: AppLinkPropertyInput;
	AppStudio: ResolverTypeWrapper<AppStudio>;
	Application: ResolverTypeWrapper<
		Omit<Application, 'givingLink' | 'id' | 'organizationId'> & {
			givingLink?: Maybe<ResolversTypes['URL']>;
			id: ResolversTypes['GUID'];
			organizationId: ResolversTypes['GUID'];
		}
	>;
	ApplicationImages: ResolverTypeWrapper<
		Omit<
			ApplicationImages,
			'brandedHeader' | 'brandedHeaderDark' | 'brandmark' | 'fullLogo' | 'homeScreenPlaceholder' | 'openGraph'
		> & {
			brandedHeader?: Maybe<ResolversTypes['URL']>;
			brandedHeaderDark?: Maybe<ResolversTypes['URL']>;
			brandmark?: Maybe<ResolversTypes['URL']>;
			fullLogo?: Maybe<ResolversTypes['URL']>;
			homeScreenPlaceholder?: Maybe<ResolversTypes['URL']>;
			openGraph?: Maybe<ResolversTypes['URL']>;
		}
	>;
	ApplicationPublishStatus: ApplicationPublishStatus;
	ApplicationSetting: ResolverTypeWrapper<Omit<ApplicationSetting, 'id'> & { id: ResolversTypes['GUID'] }>;
	ApplicationSettingKey: ApplicationSettingKey;
	ApplicationSettingValueType: ApplicationSettingValueType;
	ApplyPolicy: ApplyPolicy;
	AudioCard: ResolverTypeWrapper<Omit<AudioCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	AudioProperty: ResolverTypeWrapper<
		Omit<AudioProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	AudioPropertyInput: AudioPropertyInput;
	BaseCardDefinition: ResolverTypeWrapper<
		Omit<BaseCardDefinition, 'id' | 'sortTimestamp'> & {
			id: ResolversTypes['GUID'];
			sortTimestamp: ResolversTypes['Instant'];
		}
	>;
	BasePropertyInput: BasePropertyInput;
	Birthday: ResolverTypeWrapper<Birthday>;
	BirthdayArgumentsInput: BirthdayArgumentsInput;
	BlankifyProperty: ResolverTypeWrapper<
		Omit<BlankifyProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	BlankifyPropertyInput: BlankifyPropertyInput;
	Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
	BrowserDataInput: BrowserDataInput;
	CallToActionProperty: ResolverTypeWrapper<
		Omit<CallToActionProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	CallToActionPropertyInput: CallToActionPropertyInput;
	CallToActionType: CallToActionType;
	CampusPermissions: ResolverTypeWrapper<Omit<CampusPermissions, 'campusKey'> & { campusKey: ResolversTypes['Key'] }>;
	Card:
		| ResolversTypes['AudioCard']
		| ResolversTypes['DefaultCard']
		| ResolversTypes['EventCard']
		| ResolversTypes['ImpactCard']
		| ResolversTypes['IntroCard']
		| ResolversTypes['SpecialCard']
		| ResolversTypes['VideoCard'];
	CardDataMap: ResolverTypeWrapper<CardDataMap>;
	CardDataMapInput: CardDataMapInput;
	CardDefinitionGenericContentInput: CardDefinitionGenericContentInput;
	CardType: CardType;
	CarouselProperty: ResolverTypeWrapper<
		Omit<CarouselProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	CarouselPropertyInput: CarouselPropertyInput;
	CatholicEntity: CatholicEntity;
	Cca2CountryCodeEnumType: Cca2CountryCodeEnumType;
	Cca3CountryCodeEnumType: Cca3CountryCodeEnumType;
	ChildContainer: ResolverTypeWrapper<Omit<ChildContainer, 'id'> & { id: ResolversTypes['GUID'] }>;
	ChildItem: ResolverTypeWrapper<Omit<ChildItem, 'id'> & { id: ResolversTypes['GUID'] }>;
	ChmsInfo: ResolverTypeWrapper<ChmsInfo>;
	ClientField: ResolverTypeWrapper<ClientField>;
	ClientIpAddress: ResolverTypeWrapper<ClientIpAddress>;
	Community: ResolverTypeWrapper<Community>;
	Container: ResolverTypeWrapper<
		Omit<Container, 'applicationId' | 'givingLink' | 'id' | 'resiLink' | 'typeSpecificProperties'> & {
			applicationId: ResolversTypes['GUID'];
			givingLink?: Maybe<ResolversTypes['URL']>;
			id: ResolversTypes['GUID'];
			resiLink?: Maybe<ResolversTypes['URL']>;
			typeSpecificProperties?: Maybe<ResolversTypes['ContainerTypeSpecificProperties']>;
		}
	>;
	ContainerCardDefinition: ResolverTypeWrapper<Omit<ContainerCardDefinition, 'id'> & { id: ResolversTypes['GUID'] }>;
	ContainerCardDefinitionInput: ContainerCardDefinitionInput;
	ContainerChild: ResolversTypes['ChildContainer'] | ResolversTypes['ChildItem'];
	ContainerNotEmptyError: ResolverTypeWrapper<ContainerNotEmptyError>;
	ContainerTemplate: ContainerTemplate;
	ContainerType: ContainerType;
	ContainerTypeSpecificProperties: ResolversTypes['StudioContainerTypeProperties'];
	ContainerTypeSpecificPropertyInput: ContainerTypeSpecificPropertyInput;
	CopyItemError: ResolverTypeWrapper<Omit<CopyItemError, 'itemId'> & { itemId: ResolversTypes['GUID'] }>;
	CopyItemInput: CopyItemInput;
	CopyItemResult: ResolversTypes['CopyItemError'] | ResolversTypes['CopyItemSuccess'];
	CopyItemSuccess: ResolverTypeWrapper<Omit<CopyItemSuccess, 'itemId'> & { itemId: ResolversTypes['GUID'] }>;
	CopyItemsInput: CopyItemsInput;
	CopyItemsOutput: ResolverTypeWrapper<
		Omit<CopyItemsOutput, 'copyItemResults'> & { copyItemResults: Array<ResolversTypes['CopyItemResult']> }
	>;
	CopyItemsResult:
		| ResolversTypes['CopyItemsOutput']
		| ResolversTypes['InvalidRequestError']
		| ResolversTypes['RootContainerError'];
	CountryType: ResolverTypeWrapper<CountryType>;
	CreateContainerInput: CreateContainerInput;
	CreateContainerResult: ResolverTypeWrapper<CreateContainerResult>;
	CreateItemInput: CreateItemInput;
	CreateItemResult: ResolverTypeWrapper<CreateItemResult>;
	CursorPagedContainerChildren: ResolverTypeWrapper<CursorPagedContainerChildren>;
	CursorPagingInput: CursorPagingInput;
	CursorPagingOutput: ResolverTypeWrapper<CursorPagingOutput>;
	DataMap: ResolverTypeWrapper<DataMap>;
	DataMapInput: DataMapInput;
	DefaultCard: ResolverTypeWrapper<Omit<DefaultCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	DefaultProperty: ResolverTypeWrapper<
		Omit<DefaultProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	DefaultPropertyInput: DefaultPropertyInput;
	DeleteContainerInput: DeleteContainerInput;
	DeleteContainerNewInput: DeleteContainerNewInput;
	DeleteContainerNewResult:
		| ResolversTypes['ContainerNotEmptyError']
		| ResolversTypes['DeleteContainerNewSuccess']
		| ResolversTypes['InvalidRequestError']
		| ResolversTypes['RootContainerError'];
	DeleteContainerNewSuccess: ResolverTypeWrapper<
		Omit<DeleteContainerNewSuccess, 'containerId'> & { containerId: ResolversTypes['GUID'] }
	>;
	DeleteContainerResult: ResolverTypeWrapper<
		Omit<DeleteContainerResult, 'containerId'> & { containerId: ResolversTypes['GUID'] }
	>;
	DeleteItemInput: DeleteItemInput;
	DeleteItemResult: ResolverTypeWrapper<Omit<DeleteItemResult, 'itemId'> & { itemId: ResolversTypes['GUID'] }>;
	DynamicHomeScreen: ResolverTypeWrapper<DynamicHomeScreen>;
	EmailAddress: ResolverTypeWrapper<EmailAddress>;
	EmailContact: ResolverTypeWrapper<EmailContact>;
	EmailPropertyInput: EmailPropertyInput;
	Entitlement: ResolverTypeWrapper<Entitlement>;
	EntitlementKey: EntitlementKey;
	Error:
		| ResolversTypes['ContainerNotEmptyError']
		| ResolversTypes['CopyItemError']
		| ResolversTypes['InvalidRequestError']
		| ResolversTypes['MoveChildError']
		| ResolversTypes['RootContainerError'];
	EventCard: ResolverTypeWrapper<Omit<EventCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	ExistingImageInput: ExistingImageInput;
	FacetimePropertyInput: FacetimePropertyInput;
	FacetimeUrl: ResolverTypeWrapper<FacetimeUrl>;
	Feature: ResolverTypeWrapper<Omit<Feature, 'id'> & { id: ResolversTypes['GUID'] }>;
	FeatureFlag: ResolverTypeWrapper<FeatureFlag>;
	FeatureKey: FeatureKey;
	FeaturedCard: ResolverTypeWrapper<FeaturedCard>;
	Feed:
		| ResolversTypes['IcalFeed']
		| ResolversTypes['PodcastFeed']
		| ResolversTypes['RssFeed']
		| ResolversTypes['VideoFeed']
		| ResolversTypes['VimeoFeed']
		| ResolversTypes['YouTubeFeed'];
	FeedProcessState: FeedProcessState;
	FeedProperty: ResolverTypeWrapper<FeedProperty>;
	FetchItemVideoThumbnailInput: FetchItemVideoThumbnailInput;
	FetchItemVideoThumbnailResult: ResolverTypeWrapper<FetchItemVideoThumbnailResult>;
	FileUpload: ResolverTypeWrapper<Scalars['FileUpload']>;
	GUID: ResolverTypeWrapper<GUID>;
	GenerateFileUploadPostInput: GenerateFileUploadPostInput;
	GenerateFileUploadPostResult: ResolverTypeWrapper<
		Omit<GenerateFileUploadPostResult, 'unprocessedImageUrl'> & { unprocessedImageUrl: ResolversTypes['URL'] }
	>;
	GiveProperty: ResolverTypeWrapper<
		Omit<GiveProperty, 'givingLink' | 'id' | 'itemId'> & {
			givingLink?: Maybe<ResolversTypes['URL']>;
			id: ResolversTypes['GUID'];
			itemId: ResolversTypes['GUID'];
		}
	>;
	GivePropertyInput: GivePropertyInput;
	IcalFeed: ResolverTypeWrapper<
		Omit<IcalFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	IcalFeedInput: IcalFeedInput;
	Identity: ResolverTypeWrapper<
		Omit<Identity, 'olsonTimeZone'> & { olsonTimeZone?: Maybe<ResolversTypes['OlsonTimeZone']> }
	>;
	IdentityAddress: ResolverTypeWrapper<IdentityAddress>;
	IdentityImage: ResolverTypeWrapper<IdentityImage>;
	IdentityImageSet: ResolverTypeWrapper<IdentityImageSet>;
	IdentityKey: ResolverTypeWrapper<Scalars['IdentityKey']>;
	Image: ResolverTypeWrapper<Omit<Image, 'id'> & { id: ResolversTypes['GUID'] }>;
	ImageFormatUrls: ResolverTypeWrapper<
		Omit<
			ImageFormatUrls,
			'dynamicHomeScreen' | 'landscape' | 'original' | 'panorama' | 'square' | 'unprocessedImageUrl'
		> & {
			dynamicHomeScreen?: Maybe<ResolversTypes['URL']>;
			landscape?: Maybe<ResolversTypes['URL']>;
			original?: Maybe<ResolversTypes['URL']>;
			panorama?: Maybe<ResolversTypes['URL']>;
			square?: Maybe<ResolversTypes['URL']>;
			unprocessedImageUrl?: Maybe<ResolversTypes['URL']>;
		}
	>;
	ImageInput: ImageInput;
	ImageSizeInput: ImageSizeInput;
	ImpactCard: ResolverTypeWrapper<Omit<ImpactCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	ImpactCardContent: ResolverTypeWrapper<ImpactCardContent>;
	ImpactCardDefinition: ResolverTypeWrapper<
		Omit<ImpactCardDefinition, 'id' | 'sortTimestamp'> & {
			id: ResolversTypes['GUID'];
			sortTimestamp: ResolversTypes['Instant'];
		}
	>;
	ImpactDisplayProperties: ResolverTypeWrapper<ImpactDisplayProperties>;
	Instant: ResolverTypeWrapper<Instant>;
	Int: ResolverTypeWrapper<Scalars['Int']>;
	IntroCard: ResolverTypeWrapper<Omit<IntroCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	InvalidRequestError: ResolverTypeWrapper<InvalidRequestError>;
	Item: ResolverTypeWrapper<
		Omit<Item, 'createdDate' | 'id' | 'updatedDate'> & {
			createdDate?: Maybe<ResolversTypes['Instant']>;
			id: ResolversTypes['GUID'];
			updatedDate?: Maybe<ResolversTypes['Instant']>;
		}
	>;
	ItemAction: ResolverTypeWrapper<ItemAction>;
	ItemCardContent: ResolverTypeWrapper<ItemCardContent>;
	ItemCardDefinition: ResolversTypes['BaseCardDefinition'] | ResolversTypes['ImpactCardDefinition'];
	ItemDisplayProperties: ResolverTypeWrapper<ItemDisplayProperties>;
	ItemProperty:
		| ResolversTypes['AddToContactsProperty']
		| ResolversTypes['AddressProperty']
		| ResolversTypes['AppLinkProperty']
		| ResolversTypes['AudioProperty']
		| ResolversTypes['BlankifyProperty']
		| ResolversTypes['CallToActionProperty']
		| ResolversTypes['CarouselProperty']
		| ResolversTypes['DefaultProperty']
		| ResolversTypes['GiveProperty']
		| ResolversTypes['KeyMetricsProperty']
		| ResolversTypes['ShareProperty']
		| ResolversTypes['SmsProperty']
		| ResolversTypes['TextHtmlProperty']
		| ResolversTypes['TextProperty']
		| ResolversTypes['TimeframeProperty']
		| ResolversTypes['UserNoteProperty']
		| ResolversTypes['VideoProperty']
		| ResolversTypes['WebsiteProperty'];
	ItemPropertyAction: ResolverTypeWrapper<ItemPropertyAction>;
	ItemPropertyActionInput: ItemPropertyActionInput;
	ItemPropertyActionType: ItemPropertyActionType;
	ItemPropertyInput: ItemPropertyInput;
	ItemTemplate: ItemTemplate;
	ItemType: ItemType;
	JSONObject: ResolverTypeWrapper<JSONObject>;
	JsonString: ResolverTypeWrapper<Scalars['JsonString']>;
	Key: ResolverTypeWrapper<Key>;
	KeyMetric: ResolverTypeWrapper<KeyMetric>;
	KeyMetricInput: KeyMetricInput;
	KeyMetricsProperty: ResolverTypeWrapper<
		Omit<KeyMetricsProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	KeyMetricsPropertyInput: KeyMetricsPropertyInput;
	LocalDate: ResolverTypeWrapper<LocalDate>;
	MonthOfYear: MonthOfYear;
	MoveChildError: ResolverTypeWrapper<Omit<MoveChildError, 'childId'> & { childId: ResolversTypes['GUID'] }>;
	MoveChildInput: MoveChildInput;
	MoveChildResult: ResolversTypes['MoveChildError'] | ResolversTypes['MoveChildSuccess'];
	MoveChildSuccess: ResolverTypeWrapper<
		Omit<MoveChildSuccess, 'movedChild'> & { movedChild: ResolversTypes['MovedChild'] }
	>;
	MoveChildrenInput: MoveChildrenInput;
	MoveChildrenOutput: ResolverTypeWrapper<
		Omit<MoveChildrenOutput, 'moveChildResults'> & { moveChildResults: Array<ResolversTypes['MoveChildResult']> }
	>;
	MoveChildrenResult:
		| ResolversTypes['InvalidRequestError']
		| ResolversTypes['MoveChildrenOutput']
		| ResolversTypes['RootContainerError'];
	MovedChild: ResolversTypes['Container'] | ResolversTypes['Item'];
	Mutation: ResolverTypeWrapper<{}>;
	NavigationAction: NavigationAction;
	NewImageInput: NewImageInput;
	Offset: ResolverTypeWrapper<Scalars['Offset']>;
	OlsonTimeZone: ResolverTypeWrapper<OlsonTimeZone>;
	Organization: ResolverTypeWrapper<Organization>;
	OrganizationDetails: ResolverTypeWrapper<OrganizationDetails>;
	OrganizationPermissions: ResolverTypeWrapper<
		Omit<OrganizationPermissions, 'organizationKey'> & { organizationKey: ResolversTypes['Key'] }
	>;
	Permission: ResolverTypeWrapper<Omit<Permission, 'permissionKey'> & { permissionKey: ResolversTypes['Key'] }>;
	PhoneNumber: ResolverTypeWrapper<PhoneNumber>;
	PhonePropertyInput: PhonePropertyInput;
	PodcastFeed: ResolverTypeWrapper<
		Omit<PodcastFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	PresignedPost: ResolverTypeWrapper<Omit<PresignedPost, 'url'> & { url: ResolversTypes['URL'] }>;
	ProcessContainerFeedInput: ProcessContainerFeedInput;
	ProcessContainerFeedResult: ResolverTypeWrapper<ProcessContainerFeedResult>;
	ProductInfo: ResolverTypeWrapper<ProductInfo>;
	ProductType: ProductType;
	ProductVersion: ProductVersion;
	PropertyType: PropertyType;
	PublishChangesInput: PublishChangesInput;
	PublishChangesResult: ResolverTypeWrapper<PublishChangesResult>;
	Query: ResolverTypeWrapper<{}>;
	RelatedContent: ResolverTypeWrapper<RelatedContent>;
	RelatedContentInput: RelatedContentInput;
	ReorderContainerChildrenInput: ReorderContainerChildrenInput;
	ReorderContainerChildrenResult: ResolverTypeWrapper<ReorderContainerChildrenResult>;
	RootContainerError: ResolverTypeWrapper<RootContainerError>;
	RssFeed: ResolverTypeWrapper<
		Omit<RssFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	RssFeedInput: RssFeedInput;
	RssFeedType: RssFeedType;
	SaveContainerSettingsInput: SaveContainerSettingsInput;
	SaveContainerSettingsResult: ResolverTypeWrapper<SaveContainerSettingsResult>;
	SaveItemCardDefinitionInput: SaveItemCardDefinitionInput;
	SaveItemSettingsInput: SaveItemSettingsInput;
	SaveItemSettingsResult: ResolverTypeWrapper<SaveItemSettingsResult>;
	ShareProperty: ResolverTypeWrapper<
		Omit<ShareProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	SharePropertyInput: SharePropertyInput;
	ShellCapability: ResolverTypeWrapper<ShellCapability>;
	ShellCapabilityKey: ShellCapabilityKey;
	ShowPropertyInput: ShowPropertyInput;
	SmsProperty: ResolverTypeWrapper<
		Omit<SmsProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	SmsPropertyInput: SmsPropertyInput;
	SpecialCard: ResolverTypeWrapper<Omit<SpecialCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	String: ResolverTypeWrapper<Scalars['String']>;
	StudioContainerTypeProperties: ResolverTypeWrapper<
		Omit<StudioContainerTypeProperties, 'link'> & { link?: Maybe<ResolversTypes['URL']> }
	>;
	TextHtmlProperty: ResolverTypeWrapper<
		Omit<TextHtmlProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	TextHtmlPropertyInput: TextHtmlPropertyInput;
	TextProperty: ResolverTypeWrapper<
		Omit<TextProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	TextPropertyInput: TextPropertyInput;
	TimeZone: ResolverTypeWrapper<TimeZone>;
	Timeframe: ResolverTypeWrapper<
		Omit<Timeframe, 'endTime' | 'startTime'> & {
			endTime?: Maybe<ResolversTypes['Instant']>;
			startTime?: Maybe<ResolversTypes['Instant']>;
		}
	>;
	TimeframeProperty: ResolverTypeWrapper<
		Omit<TimeframeProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	TimeframePropertyInput: TimeframePropertyInput;
	URL: ResolverTypeWrapper<URL>;
	UpdateContainerVisibilityInput: UpdateContainerVisibilityInput;
	UpdateContainerVisibilityResult: ResolverTypeWrapper<UpdateContainerVisibilityResult>;
	UpdateItemVisibilityInput: UpdateItemVisibilityInput;
	UpdateItemVisibilityResult: ResolverTypeWrapper<UpdateItemVisibilityResult>;
	UpdateProfileInput: UpdateProfileInput;
	UpdateProfileResult: ResolverTypeWrapper<UpdateProfileResult>;
	UserContextDataInput: UserContextDataInput;
	UserNoteProperty: ResolverTypeWrapper<
		Omit<UserNoteProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	UserNotePropertyInput: UserNotePropertyInput;
	VideoCard: ResolverTypeWrapper<Omit<VideoCard, 'id'> & { id: ResolversTypes['GUID'] }>;
	VideoFeed: ResolverTypeWrapper<
		Omit<VideoFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	VideoProperty: ResolverTypeWrapper<
		Omit<VideoProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	VideoPropertyInput: VideoPropertyInput;
	VimeoFeed: ResolverTypeWrapper<
		Omit<VimeoFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	VimeoFeedIdentifiers: ResolverTypeWrapper<VimeoFeedIdentifiers>;
	VimeoFeedInput: VimeoFeedInput;
	VimeoIdentifierType: VimeoIdentifierType;
	WebsiteProperty: ResolverTypeWrapper<
		Omit<WebsiteProperty, 'id' | 'itemId'> & { id: ResolversTypes['GUID']; itemId: ResolversTypes['GUID'] }
	>;
	WebsitePropertyInput: WebsitePropertyInput;
	WindowsTimeZone: ResolverTypeWrapper<Scalars['WindowsTimeZone']>;
	YouTubeFeed: ResolverTypeWrapper<
		Omit<YouTubeFeed, 'id' | 'url'> & { id: ResolversTypes['GUID']; url?: Maybe<ResolversTypes['URL']> }
	>;
	YouTubeFeedInput: YouTubeFeedInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
	ActionBar: ActionBar;
	ActionBarInput: ActionBarInput;
	AddToContactsProperty: Omit<AddToContactsProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	AddToContactsPropertyInput: AddToContactsPropertyInput;
	Address: Address;
	AddressArgumentsInput: AddressArgumentsInput;
	AddressProperty: Omit<AddressProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	AddressPropertyInput: AddressPropertyInput;
	AdministeredOrganization: AdministeredOrganization;
	AllIdentityPermissions: AllIdentityPermissions;
	AppCampus: Omit<AppCampus, 'givingLink' | 'id' | 'organizationId'> & {
		givingLink?: Maybe<ResolversParentTypes['URL']>;
		id: ResolversParentTypes['GUID'];
		organizationId: ResolversParentTypes['GUID'];
	};
	AppLinkProperty: Omit<AppLinkProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	AppLinkPropertyInput: AppLinkPropertyInput;
	AppStudio: AppStudio;
	Application: Omit<Application, 'givingLink' | 'id' | 'organizationId'> & {
		givingLink?: Maybe<ResolversParentTypes['URL']>;
		id: ResolversParentTypes['GUID'];
		organizationId: ResolversParentTypes['GUID'];
	};
	ApplicationImages: Omit<
		ApplicationImages,
		'brandedHeader' | 'brandedHeaderDark' | 'brandmark' | 'fullLogo' | 'homeScreenPlaceholder' | 'openGraph'
	> & {
		brandedHeader?: Maybe<ResolversParentTypes['URL']>;
		brandedHeaderDark?: Maybe<ResolversParentTypes['URL']>;
		brandmark?: Maybe<ResolversParentTypes['URL']>;
		fullLogo?: Maybe<ResolversParentTypes['URL']>;
		homeScreenPlaceholder?: Maybe<ResolversParentTypes['URL']>;
		openGraph?: Maybe<ResolversParentTypes['URL']>;
	};
	ApplicationSetting: Omit<ApplicationSetting, 'id'> & { id: ResolversParentTypes['GUID'] };
	AudioCard: Omit<AudioCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	AudioProperty: Omit<AudioProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	AudioPropertyInput: AudioPropertyInput;
	BaseCardDefinition: Omit<BaseCardDefinition, 'id' | 'sortTimestamp'> & {
		id: ResolversParentTypes['GUID'];
		sortTimestamp: ResolversParentTypes['Instant'];
	};
	BasePropertyInput: BasePropertyInput;
	Birthday: Birthday;
	BirthdayArgumentsInput: BirthdayArgumentsInput;
	BlankifyProperty: Omit<BlankifyProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	BlankifyPropertyInput: BlankifyPropertyInput;
	Boolean: Scalars['Boolean'];
	BrowserDataInput: BrowserDataInput;
	CallToActionProperty: Omit<CallToActionProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	CallToActionPropertyInput: CallToActionPropertyInput;
	CampusPermissions: Omit<CampusPermissions, 'campusKey'> & { campusKey: ResolversParentTypes['Key'] };
	Card:
		| ResolversParentTypes['AudioCard']
		| ResolversParentTypes['DefaultCard']
		| ResolversParentTypes['EventCard']
		| ResolversParentTypes['ImpactCard']
		| ResolversParentTypes['IntroCard']
		| ResolversParentTypes['SpecialCard']
		| ResolversParentTypes['VideoCard'];
	CardDataMap: CardDataMap;
	CardDataMapInput: CardDataMapInput;
	CardDefinitionGenericContentInput: CardDefinitionGenericContentInput;
	CarouselProperty: Omit<CarouselProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	CarouselPropertyInput: CarouselPropertyInput;
	ChildContainer: Omit<ChildContainer, 'id'> & { id: ResolversParentTypes['GUID'] };
	ChildItem: Omit<ChildItem, 'id'> & { id: ResolversParentTypes['GUID'] };
	ChmsInfo: ChmsInfo;
	ClientField: ClientField;
	ClientIpAddress: ClientIpAddress;
	Community: Community;
	Container: Omit<Container, 'applicationId' | 'givingLink' | 'id' | 'resiLink' | 'typeSpecificProperties'> & {
		applicationId: ResolversParentTypes['GUID'];
		givingLink?: Maybe<ResolversParentTypes['URL']>;
		id: ResolversParentTypes['GUID'];
		resiLink?: Maybe<ResolversParentTypes['URL']>;
		typeSpecificProperties?: Maybe<ResolversParentTypes['ContainerTypeSpecificProperties']>;
	};
	ContainerCardDefinition: Omit<ContainerCardDefinition, 'id'> & { id: ResolversParentTypes['GUID'] };
	ContainerCardDefinitionInput: ContainerCardDefinitionInput;
	ContainerChild: ResolversParentTypes['ChildContainer'] | ResolversParentTypes['ChildItem'];
	ContainerNotEmptyError: ContainerNotEmptyError;
	ContainerTypeSpecificProperties: ResolversParentTypes['StudioContainerTypeProperties'];
	ContainerTypeSpecificPropertyInput: ContainerTypeSpecificPropertyInput;
	CopyItemError: Omit<CopyItemError, 'itemId'> & { itemId: ResolversParentTypes['GUID'] };
	CopyItemInput: CopyItemInput;
	CopyItemResult: ResolversParentTypes['CopyItemError'] | ResolversParentTypes['CopyItemSuccess'];
	CopyItemSuccess: Omit<CopyItemSuccess, 'itemId'> & { itemId: ResolversParentTypes['GUID'] };
	CopyItemsInput: CopyItemsInput;
	CopyItemsOutput: Omit<CopyItemsOutput, 'copyItemResults'> & {
		copyItemResults: Array<ResolversParentTypes['CopyItemResult']>;
	};
	CopyItemsResult:
		| ResolversParentTypes['CopyItemsOutput']
		| ResolversParentTypes['InvalidRequestError']
		| ResolversParentTypes['RootContainerError'];
	CountryType: CountryType;
	CreateContainerInput: CreateContainerInput;
	CreateContainerResult: CreateContainerResult;
	CreateItemInput: CreateItemInput;
	CreateItemResult: CreateItemResult;
	CursorPagedContainerChildren: CursorPagedContainerChildren;
	CursorPagingInput: CursorPagingInput;
	CursorPagingOutput: CursorPagingOutput;
	DataMap: DataMap;
	DataMapInput: DataMapInput;
	DefaultCard: Omit<DefaultCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	DefaultProperty: Omit<DefaultProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	DefaultPropertyInput: DefaultPropertyInput;
	DeleteContainerInput: DeleteContainerInput;
	DeleteContainerNewInput: DeleteContainerNewInput;
	DeleteContainerNewResult:
		| ResolversParentTypes['ContainerNotEmptyError']
		| ResolversParentTypes['DeleteContainerNewSuccess']
		| ResolversParentTypes['InvalidRequestError']
		| ResolversParentTypes['RootContainerError'];
	DeleteContainerNewSuccess: Omit<DeleteContainerNewSuccess, 'containerId'> & {
		containerId: ResolversParentTypes['GUID'];
	};
	DeleteContainerResult: Omit<DeleteContainerResult, 'containerId'> & { containerId: ResolversParentTypes['GUID'] };
	DeleteItemInput: DeleteItemInput;
	DeleteItemResult: Omit<DeleteItemResult, 'itemId'> & { itemId: ResolversParentTypes['GUID'] };
	DynamicHomeScreen: DynamicHomeScreen;
	EmailAddress: EmailAddress;
	EmailContact: EmailContact;
	EmailPropertyInput: EmailPropertyInput;
	Entitlement: Entitlement;
	Error:
		| ResolversParentTypes['ContainerNotEmptyError']
		| ResolversParentTypes['CopyItemError']
		| ResolversParentTypes['InvalidRequestError']
		| ResolversParentTypes['MoveChildError']
		| ResolversParentTypes['RootContainerError'];
	EventCard: Omit<EventCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	ExistingImageInput: ExistingImageInput;
	FacetimePropertyInput: FacetimePropertyInput;
	FacetimeUrl: FacetimeUrl;
	Feature: Omit<Feature, 'id'> & { id: ResolversParentTypes['GUID'] };
	FeatureFlag: FeatureFlag;
	FeaturedCard: FeaturedCard;
	Feed:
		| ResolversParentTypes['IcalFeed']
		| ResolversParentTypes['PodcastFeed']
		| ResolversParentTypes['RssFeed']
		| ResolversParentTypes['VideoFeed']
		| ResolversParentTypes['VimeoFeed']
		| ResolversParentTypes['YouTubeFeed'];
	FeedProperty: FeedProperty;
	FetchItemVideoThumbnailInput: FetchItemVideoThumbnailInput;
	FetchItemVideoThumbnailResult: FetchItemVideoThumbnailResult;
	FileUpload: Scalars['FileUpload'];
	GUID: GUID;
	GenerateFileUploadPostInput: GenerateFileUploadPostInput;
	GenerateFileUploadPostResult: Omit<GenerateFileUploadPostResult, 'unprocessedImageUrl'> & {
		unprocessedImageUrl: ResolversParentTypes['URL'];
	};
	GiveProperty: Omit<GiveProperty, 'givingLink' | 'id' | 'itemId'> & {
		givingLink?: Maybe<ResolversParentTypes['URL']>;
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	GivePropertyInput: GivePropertyInput;
	IcalFeed: Omit<IcalFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	IcalFeedInput: IcalFeedInput;
	Identity: Omit<Identity, 'olsonTimeZone'> & { olsonTimeZone?: Maybe<ResolversParentTypes['OlsonTimeZone']> };
	IdentityAddress: IdentityAddress;
	IdentityImage: IdentityImage;
	IdentityImageSet: IdentityImageSet;
	IdentityKey: Scalars['IdentityKey'];
	Image: Omit<Image, 'id'> & { id: ResolversParentTypes['GUID'] };
	ImageFormatUrls: Omit<
		ImageFormatUrls,
		'dynamicHomeScreen' | 'landscape' | 'original' | 'panorama' | 'square' | 'unprocessedImageUrl'
	> & {
		dynamicHomeScreen?: Maybe<ResolversParentTypes['URL']>;
		landscape?: Maybe<ResolversParentTypes['URL']>;
		original?: Maybe<ResolversParentTypes['URL']>;
		panorama?: Maybe<ResolversParentTypes['URL']>;
		square?: Maybe<ResolversParentTypes['URL']>;
		unprocessedImageUrl?: Maybe<ResolversParentTypes['URL']>;
	};
	ImageInput: ImageInput;
	ImageSizeInput: ImageSizeInput;
	ImpactCard: Omit<ImpactCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	ImpactCardContent: ImpactCardContent;
	ImpactCardDefinition: Omit<ImpactCardDefinition, 'id' | 'sortTimestamp'> & {
		id: ResolversParentTypes['GUID'];
		sortTimestamp: ResolversParentTypes['Instant'];
	};
	ImpactDisplayProperties: ImpactDisplayProperties;
	Instant: Instant;
	Int: Scalars['Int'];
	IntroCard: Omit<IntroCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	InvalidRequestError: InvalidRequestError;
	Item: Omit<Item, 'createdDate' | 'id' | 'updatedDate'> & {
		createdDate?: Maybe<ResolversParentTypes['Instant']>;
		id: ResolversParentTypes['GUID'];
		updatedDate?: Maybe<ResolversParentTypes['Instant']>;
	};
	ItemAction: ItemAction;
	ItemCardContent: ItemCardContent;
	ItemCardDefinition: ResolversParentTypes['BaseCardDefinition'] | ResolversParentTypes['ImpactCardDefinition'];
	ItemDisplayProperties: ItemDisplayProperties;
	ItemProperty:
		| ResolversParentTypes['AddToContactsProperty']
		| ResolversParentTypes['AddressProperty']
		| ResolversParentTypes['AppLinkProperty']
		| ResolversParentTypes['AudioProperty']
		| ResolversParentTypes['BlankifyProperty']
		| ResolversParentTypes['CallToActionProperty']
		| ResolversParentTypes['CarouselProperty']
		| ResolversParentTypes['DefaultProperty']
		| ResolversParentTypes['GiveProperty']
		| ResolversParentTypes['KeyMetricsProperty']
		| ResolversParentTypes['ShareProperty']
		| ResolversParentTypes['SmsProperty']
		| ResolversParentTypes['TextHtmlProperty']
		| ResolversParentTypes['TextProperty']
		| ResolversParentTypes['TimeframeProperty']
		| ResolversParentTypes['UserNoteProperty']
		| ResolversParentTypes['VideoProperty']
		| ResolversParentTypes['WebsiteProperty'];
	ItemPropertyAction: ItemPropertyAction;
	ItemPropertyActionInput: ItemPropertyActionInput;
	ItemPropertyInput: ItemPropertyInput;
	JSONObject: JSONObject;
	JsonString: Scalars['JsonString'];
	Key: Key;
	KeyMetric: KeyMetric;
	KeyMetricInput: KeyMetricInput;
	KeyMetricsProperty: Omit<KeyMetricsProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	KeyMetricsPropertyInput: KeyMetricsPropertyInput;
	LocalDate: LocalDate;
	MoveChildError: Omit<MoveChildError, 'childId'> & { childId: ResolversParentTypes['GUID'] };
	MoveChildInput: MoveChildInput;
	MoveChildResult: ResolversParentTypes['MoveChildError'] | ResolversParentTypes['MoveChildSuccess'];
	MoveChildSuccess: Omit<MoveChildSuccess, 'movedChild'> & { movedChild: ResolversParentTypes['MovedChild'] };
	MoveChildrenInput: MoveChildrenInput;
	MoveChildrenOutput: Omit<MoveChildrenOutput, 'moveChildResults'> & {
		moveChildResults: Array<ResolversParentTypes['MoveChildResult']>;
	};
	MoveChildrenResult:
		| ResolversParentTypes['InvalidRequestError']
		| ResolversParentTypes['MoveChildrenOutput']
		| ResolversParentTypes['RootContainerError'];
	MovedChild: ResolversParentTypes['Container'] | ResolversParentTypes['Item'];
	Mutation: {};
	NewImageInput: NewImageInput;
	Offset: Scalars['Offset'];
	OlsonTimeZone: OlsonTimeZone;
	Organization: Organization;
	OrganizationDetails: OrganizationDetails;
	OrganizationPermissions: Omit<OrganizationPermissions, 'organizationKey'> & {
		organizationKey: ResolversParentTypes['Key'];
	};
	Permission: Omit<Permission, 'permissionKey'> & { permissionKey: ResolversParentTypes['Key'] };
	PhoneNumber: PhoneNumber;
	PhonePropertyInput: PhonePropertyInput;
	PodcastFeed: Omit<PodcastFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	PresignedPost: Omit<PresignedPost, 'url'> & { url: ResolversParentTypes['URL'] };
	ProcessContainerFeedInput: ProcessContainerFeedInput;
	ProcessContainerFeedResult: ProcessContainerFeedResult;
	ProductInfo: ProductInfo;
	PublishChangesInput: PublishChangesInput;
	PublishChangesResult: PublishChangesResult;
	Query: {};
	RelatedContent: RelatedContent;
	RelatedContentInput: RelatedContentInput;
	ReorderContainerChildrenInput: ReorderContainerChildrenInput;
	ReorderContainerChildrenResult: ReorderContainerChildrenResult;
	RootContainerError: RootContainerError;
	RssFeed: Omit<RssFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	RssFeedInput: RssFeedInput;
	SaveContainerSettingsInput: SaveContainerSettingsInput;
	SaveContainerSettingsResult: SaveContainerSettingsResult;
	SaveItemCardDefinitionInput: SaveItemCardDefinitionInput;
	SaveItemSettingsInput: SaveItemSettingsInput;
	SaveItemSettingsResult: SaveItemSettingsResult;
	ShareProperty: Omit<ShareProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	SharePropertyInput: SharePropertyInput;
	ShellCapability: ShellCapability;
	ShowPropertyInput: ShowPropertyInput;
	SmsProperty: Omit<SmsProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	SmsPropertyInput: SmsPropertyInput;
	SpecialCard: Omit<SpecialCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	String: Scalars['String'];
	StudioContainerTypeProperties: Omit<StudioContainerTypeProperties, 'link'> & {
		link?: Maybe<ResolversParentTypes['URL']>;
	};
	TextHtmlProperty: Omit<TextHtmlProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	TextHtmlPropertyInput: TextHtmlPropertyInput;
	TextProperty: Omit<TextProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	TextPropertyInput: TextPropertyInput;
	TimeZone: TimeZone;
	Timeframe: Omit<Timeframe, 'endTime' | 'startTime'> & {
		endTime?: Maybe<ResolversParentTypes['Instant']>;
		startTime?: Maybe<ResolversParentTypes['Instant']>;
	};
	TimeframeProperty: Omit<TimeframeProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	TimeframePropertyInput: TimeframePropertyInput;
	URL: URL;
	UpdateContainerVisibilityInput: UpdateContainerVisibilityInput;
	UpdateContainerVisibilityResult: UpdateContainerVisibilityResult;
	UpdateItemVisibilityInput: UpdateItemVisibilityInput;
	UpdateItemVisibilityResult: UpdateItemVisibilityResult;
	UpdateProfileInput: UpdateProfileInput;
	UpdateProfileResult: UpdateProfileResult;
	UserContextDataInput: UserContextDataInput;
	UserNoteProperty: Omit<UserNoteProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	UserNotePropertyInput: UserNotePropertyInput;
	VideoCard: Omit<VideoCard, 'id'> & { id: ResolversParentTypes['GUID'] };
	VideoFeed: Omit<VideoFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	VideoProperty: Omit<VideoProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	VideoPropertyInput: VideoPropertyInput;
	VimeoFeed: Omit<VimeoFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	VimeoFeedIdentifiers: VimeoFeedIdentifiers;
	VimeoFeedInput: VimeoFeedInput;
	WebsiteProperty: Omit<WebsiteProperty, 'id' | 'itemId'> & {
		id: ResolversParentTypes['GUID'];
		itemId: ResolversParentTypes['GUID'];
	};
	WebsitePropertyInput: WebsitePropertyInput;
	WindowsTimeZone: Scalars['WindowsTimeZone'];
	YouTubeFeed: Omit<YouTubeFeed, 'id' | 'url'> & {
		id: ResolversParentTypes['GUID'];
		url?: Maybe<ResolversParentTypes['URL']>;
	};
	YouTubeFeedInput: YouTubeFeedInput;
};

export type AllowAnonymousDirectiveArgs = {};

export type AllowAnonymousDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = AllowAnonymousDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AuthorizeDirectiveArgs = {
	apply?: ApplyPolicy;
	policy?: Maybe<Scalars['String']>;
	roles?: Maybe<Array<Scalars['String']>>;
};

export type AuthorizeDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = AuthorizeDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ComputedDirectiveArgs = {
	dependantOn?: Maybe<Array<Scalars['String']>>;
};

export type ComputedDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = ComputedDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type CriticalDirectiveArgs = {};

export type CriticalDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = CriticalDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DelegateDirectiveArgs = {
	path?: Maybe<Scalars['String']>;
	schema: Scalars['String'];
};

export type DelegateDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = DelegateDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type EmailAddressDirectiveArgs = {};

export type EmailAddressDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = EmailAddressDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type FeatureDirectiveArgs = {
	name: Scalars['String'];
};

export type FeatureDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = FeatureDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type FileUploadAcceptDirectiveArgs = {
	pattern: Scalars['String'];
};

export type FileUploadAcceptDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = FileUploadAcceptDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type IgnoreDirectiveArgs = {};

export type IgnoreDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = IgnoreDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type LengthDirectiveArgs = {
	max?: Maybe<Scalars['Int']>;
	min?: Maybe<Scalars['Int']>;
};

export type LengthDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = LengthDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type MasCompatibilityDirectiveArgs = {
	reason: Scalars['String'];
};

export type MasCompatibilityDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = MasCompatibilityDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type OneOfDirectiveArgs = {};

export type OneOfDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = OneOfDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RangeDirectiveArgs = {
	maximum: Scalars['Int'];
	minimum: Scalars['Int'];
};

export type RangeDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = RangeDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type RenameDirectiveArgs = {
	name: Scalars['String'];
};

export type RenameDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = RenameDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type SourceDirectiveArgs = {
	name: Scalars['String'];
	schema: Scalars['String'];
};

export type SourceDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = SourceDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type StringLengthDirectiveArgs = {
	maximumLength: Scalars['Int'];
	minimumLength?: Maybe<Scalars['Int']>;
};

export type StringLengthDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = StringLengthDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type UrlDirectiveArgs = {};

export type UrlDirectiveResolver<
	Result,
	Parent,
	ContextType = CacheContext,
	Args = UrlDirectiveArgs
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type ActionBarResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ActionBar'] = ResolversParentTypes['ActionBar']
> = {
	hideOriginalProperty?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddToContactsPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AddToContactsProperty'] = ResolversParentTypes['AddToContactsProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']
> = {
	countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AddressProperty'] = ResolversParentTypes['AddressProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	latitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	longitude?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	zip?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdministeredOrganizationResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AdministeredOrganization'] = ResolversParentTypes['AdministeredOrganization']
> = {
	isMerchantAdmin?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	logoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AllIdentityPermissionsResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AllIdentityPermissions'] = ResolversParentTypes['AllIdentityPermissions']
> = {
	organizationPermissions?: Resolver<Array<ResolversTypes['OrganizationPermissions']>, ParentType, ContextType>;
	permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppCampusResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AppCampus'] = ResolversParentTypes['AppCampus']
> = {
	givingLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	organizationId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppLinkPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AppLinkProperty'] = ResolversParentTypes['AppLinkProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	androidAppUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	androidDefaultUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	iosAppUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	iosDefaultUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppStudioResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AppStudio'] = ResolversParentTypes['AppStudio']
> = {
	isAppStudioDesignEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isAppStudioPushNotificationsEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Application'] = ResolversParentTypes['Application']
> = {
	campus?: Resolver<
		Maybe<ResolversTypes['AppCampus']>,
		ParentType,
		ContextType,
		RequireFields<ApplicationCampusArgs, 'campusId' | 'organizationKey'>
	>;
	campuses?: Resolver<
		Array<ResolversTypes['AppCampus']>,
		ParentType,
		ContextType,
		RequireFields<ApplicationCampusesArgs, 'organizationKey'>
	>;
	container?: Resolver<
		Maybe<ResolversTypes['Container']>,
		ParentType,
		ContextType,
		RequireFields<ApplicationContainerArgs, 'containerId'>
	>;
	downloadLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	dynamicHomeScreen?: Resolver<Maybe<ResolversTypes['DynamicHomeScreen']>, ParentType, ContextType>;
	features?: Resolver<Array<ResolversTypes['Feature']>, ParentType, ContextType>;
	givingLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	images?: Resolver<ResolversTypes['ApplicationImages'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	organizationId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	pendingChanges?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	platformCampusKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	productType?: Resolver<ResolversTypes['ProductType'], ParentType, ContextType>;
	productVersion?: Resolver<ResolversTypes['ProductVersion'], ParentType, ContextType>;
	publishStatus?: Resolver<ResolversTypes['ApplicationPublishStatus'], ParentType, ContextType>;
	rootContainer?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	setting?: Resolver<Array<ResolversTypes['ApplicationSetting']>, ParentType, ContextType>;
	shellCapabilities?: Resolver<Array<ResolversTypes['ShellCapability']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationImagesResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ApplicationImages'] = ResolversParentTypes['ApplicationImages']
> = {
	brandedHeader?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	brandedHeaderDark?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	brandmark?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	fullLogo?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	homeScreenPlaceholder?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	openGraph?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApplicationSettingResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ApplicationSetting'] = ResolversParentTypes['ApplicationSetting']
> = {
	enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['ApplicationSettingKey'], ParentType, ContextType>;
	required?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	valid_select_options?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
	value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	valueType?: Resolver<ResolversTypes['ApplicationSettingValueType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudioCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AudioCard'] = ResolversParentTypes['AudioCard']
> = {
	audioUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AudioPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['AudioProperty'] = ResolversParentTypes['AudioProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCardDefinitionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['BaseCardDefinition'] = ResolversParentTypes['BaseCardDefinition']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	cardType?: Resolver<ResolversTypes['CardType'], ParentType, ContextType>;
	content?: Resolver<Maybe<ResolversTypes['ItemCardContent']>, ParentType, ContextType>;
	displayProperties?: Resolver<ResolversTypes['ItemDisplayProperties'], ParentType, ContextType>;
	featuredCard?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	sortTimestamp?: Resolver<ResolversTypes['Instant'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BirthdayResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Birthday'] = ResolversParentTypes['Birthday']
> = {
	day?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	month?: Resolver<ResolversTypes['MonthOfYear'], ParentType, ContextType>;
	year?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlankifyPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['BlankifyProperty'] = ResolversParentTypes['BlankifyProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	content?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CallToActionPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CallToActionProperty'] = ResolversParentTypes['CallToActionProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isExternal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CampusPermissionsResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CampusPermissions'] = ResolversParentTypes['CampusPermissions']
> = {
	campusKey?: Resolver<ResolversTypes['Key'], ParentType, ContextType>;
	permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Card'] = ResolversParentTypes['Card']
> = {
	__resolveType: TypeResolveFn<
		'AudioCard' | 'DefaultCard' | 'EventCard' | 'ImpactCard' | 'IntroCard' | 'SpecialCard' | 'VideoCard',
		ParentType,
		ContextType
	>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type CardDataMapResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CardDataMap'] = ResolversParentTypes['CardDataMap']
> = {
	audioUrl?: Resolver<Maybe<ResolversTypes['DataMap']>, ParentType, ContextType>;
	publishedTime?: Resolver<Maybe<ResolversTypes['DataMap']>, ParentType, ContextType>;
	subtitle?: Resolver<Maybe<ResolversTypes['DataMap']>, ParentType, ContextType>;
	summary?: Resolver<Maybe<ResolversTypes['DataMap']>, ParentType, ContextType>;
	videoUrl?: Resolver<Maybe<ResolversTypes['DataMap']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CarouselPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CarouselProperty'] = ResolversParentTypes['CarouselProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	images?: Resolver<Array<ResolversTypes['Image']>, ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildContainerResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ChildContainer'] = ResolversParentTypes['ChildContainer']
> = {
	container?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildItemResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ChildItem'] = ResolversParentTypes['ChildItem']
> = {
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChmsInfoResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ChmsInfo'] = ResolversParentTypes['ChmsInfo']
> = {
	contractStatus?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	isIncludedInContract?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isPremier?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isProvisioned?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	package?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	tenantUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientFieldResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ClientField'] = ResolversParentTypes['ClientField']
> = {
	field?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientIpAddressResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ClientIpAddress'] = ResolversParentTypes['ClientIpAddress']
> = {
	ipV4?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	ipV6?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommunityResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Community'] = ResolversParentTypes['Community']
> = {
	isInSync?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Container'] = ResolversParentTypes['Container']
> = {
	applicationId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	campus?: Resolver<
		Maybe<ResolversTypes['AppCampus']>,
		ParentType,
		ContextType,
		RequireFields<ContainerCampusArgs, 'organizationKey'>
	>;
	cardDefinition?: Resolver<Maybe<ResolversTypes['ContainerCardDefinition']>, ParentType, ContextType>;
	children?: Resolver<
		ResolversTypes['CursorPagedContainerChildren'],
		ParentType,
		ContextType,
		RequireFields<ContainerChildrenArgs, never>
	>;
	feed?: Resolver<Maybe<ResolversTypes['Feed']>, ParentType, ContextType>;
	givingLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
	isDeletable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	item?: Resolver<Maybe<ResolversTypes['Item']>, ParentType, ContextType, RequireFields<ContainerItemArgs, 'itemId'>>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	navigationAction?: Resolver<Maybe<ResolversTypes['NavigationAction']>, ParentType, ContextType>;
	parentContainer?: Resolver<Maybe<ResolversTypes['Container']>, ParentType, ContextType>;
	relatedContent?: Resolver<ResolversTypes['RelatedContent'], ParentType, ContextType>;
	resiLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	template?: Resolver<ResolversTypes['ContainerTemplate'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['ContainerType'], ParentType, ContextType>;
	typeSpecificProperties?: Resolver<
		Maybe<ResolversTypes['ContainerTypeSpecificProperties']>,
		ParentType,
		ContextType
	>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerCardDefinitionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ContainerCardDefinition'] = ResolversParentTypes['ContainerCardDefinition']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	cardType?: Resolver<ResolversTypes['CardType'], ParentType, ContextType>;
	dataMap?: Resolver<ResolversTypes['CardDataMap'], ParentType, ContextType>;
	featuredCard?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	maxItems?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	showCategory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	showPublishedTime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerChildResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ContainerChild'] = ResolversParentTypes['ContainerChild']
> = {
	__resolveType: TypeResolveFn<'ChildContainer' | 'ChildItem', ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type ContainerNotEmptyErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ContainerNotEmptyError'] = ResolversParentTypes['ContainerNotEmptyError']
> = {
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerTypeSpecificPropertiesResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ContainerTypeSpecificProperties'] = ResolversParentTypes['ContainerTypeSpecificProperties']
> = {
	__resolveType: TypeResolveFn<'StudioContainerTypeProperties', ParentType, ContextType>;
};

export type CopyItemErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CopyItemError'] = ResolversParentTypes['CopyItemError']
> = {
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CopyItemResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CopyItemResult'] = ResolversParentTypes['CopyItemResult']
> = {
	__resolveType: TypeResolveFn<'CopyItemError' | 'CopyItemSuccess', ParentType, ContextType>;
};

export type CopyItemSuccessResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CopyItemSuccess'] = ResolversParentTypes['CopyItemSuccess']
> = {
	copiedItem?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CopyItemsOutputResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CopyItemsOutput'] = ResolversParentTypes['CopyItemsOutput']
> = {
	copyItemResults?: Resolver<Array<ResolversTypes['CopyItemResult']>, ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CopyItemsResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CopyItemsResult'] = ResolversParentTypes['CopyItemsResult']
> = {
	__resolveType: TypeResolveFn<
		'CopyItemsOutput' | 'InvalidRequestError' | 'RootContainerError',
		ParentType,
		ContextType
	>;
};

export type CountryTypeResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CountryType'] = ResolversParentTypes['CountryType']
> = {
	callingCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	cca2?: Resolver<Maybe<ResolversTypes['Cca2CountryCodeEnumType']>, ParentType, ContextType>;
	cca3?: Resolver<Maybe<ResolversTypes['Cca3CountryCodeEnumType']>, ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	placeholder?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	timeZones?: Resolver<Maybe<Array<Maybe<ResolversTypes['TimeZone']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateContainerResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CreateContainerResult'] = ResolversParentTypes['CreateContainerResult']
> = {
	createdContainer?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateItemResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CreateItemResult'] = ResolversParentTypes['CreateItemResult']
> = {
	createdItem?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CursorPagedContainerChildrenResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CursorPagedContainerChildren'] = ResolversParentTypes['CursorPagedContainerChildren']
> = {
	nodes?: Resolver<Array<ResolversTypes['ContainerChild']>, ParentType, ContextType>;
	paging?: Resolver<ResolversTypes['CursorPagingOutput'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CursorPagingOutputResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['CursorPagingOutput'] = ResolversParentTypes['CursorPagingOutput']
> = {
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	totalItemCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataMapResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DataMap'] = ResolversParentTypes['DataMap']
> = {
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DefaultCard'] = ResolversParentTypes['DefaultCard']
> = {
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DefaultPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DefaultProperty'] = ResolversParentTypes['DefaultProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	data?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteContainerNewResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DeleteContainerNewResult'] = ResolversParentTypes['DeleteContainerNewResult']
> = {
	__resolveType: TypeResolveFn<
		'ContainerNotEmptyError' | 'DeleteContainerNewSuccess' | 'InvalidRequestError' | 'RootContainerError',
		ParentType,
		ContextType
	>;
};

export type DeleteContainerNewSuccessResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DeleteContainerNewSuccess'] = ResolversParentTypes['DeleteContainerNewSuccess']
> = {
	containerId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteContainerResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DeleteContainerResult'] = ResolversParentTypes['DeleteContainerResult']
> = {
	containerId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	deletedContainer?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteItemResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DeleteItemResult'] = ResolversParentTypes['DeleteItemResult']
> = {
	deletedItem?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DynamicHomeScreenResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['DynamicHomeScreen'] = ResolversParentTypes['DynamicHomeScreen']
> = {
	cards?: Resolver<
		Array<ResolversTypes['Card']>,
		ParentType,
		ContextType,
		RequireFields<DynamicHomeScreenCardsArgs, never>
	>;
	isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	publish?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
	name: 'EmailAddress';
}

export type EmailContactResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['EmailContact'] = ResolversParentTypes['EmailContact']
> = {
	emailAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	verified?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitlementResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Entitlement'] = ResolversParentTypes['Entitlement']
> = {
	displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['EntitlementKey'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Error'] = ResolversParentTypes['Error']
> = {
	__resolveType: TypeResolveFn<
		'ContainerNotEmptyError' | 'CopyItemError' | 'InvalidRequestError' | 'MoveChildError' | 'RootContainerError',
		ParentType,
		ContextType
	>;
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
};

export type EventCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['EventCard'] = ResolversParentTypes['EventCard']
> = {
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	timeframe?: Resolver<Maybe<ResolversTypes['Timeframe']>, ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FacetimeUrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FacetimeUrl'], any> {
	name: 'FacetimeUrl';
}

export type FeatureResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Feature'] = ResolversParentTypes['Feature']
> = {
	enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['FeatureKey'], ParentType, ContextType>;
	value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeatureFlagResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['FeatureFlag'] = ResolversParentTypes['FeatureFlag']
> = {
	enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeaturedCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['FeaturedCard'] = ResolversParentTypes['FeaturedCard']
> = {
	label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Feed'] = ResolversParentTypes['Feed']
> = {
	__resolveType: TypeResolveFn<
		'IcalFeed' | 'PodcastFeed' | 'RssFeed' | 'VideoFeed' | 'VimeoFeed' | 'YouTubeFeed',
		ParentType,
		ContextType
	>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
};

export type FeedPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['FeedProperty'] = ResolversParentTypes['FeedProperty']
> = {
	actionBarArray?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	forceRegenerate?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	hiddenProperties?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	itemTemplate?: Resolver<Maybe<ResolversTypes['ItemTemplate']>, ParentType, ContextType>;
	itemTemplateRaw?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	playlistId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	shareProperties?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FetchItemVideoThumbnailResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['FetchItemVideoThumbnailResult'] = ResolversParentTypes['FetchItemVideoThumbnailResult']
> = {
	image?: Resolver<ResolversTypes['Image'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface FileUploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['FileUpload'], any> {
	name: 'FileUpload';
}

export interface GuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GUID'], any> {
	name: 'GUID';
}

export type GenerateFileUploadPostResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['GenerateFileUploadPostResult'] = ResolversParentTypes['GenerateFileUploadPostResult']
> = {
	presignedPost?: Resolver<ResolversTypes['PresignedPost'], ParentType, ContextType>;
	unprocessedImageUrl?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GivePropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['GiveProperty'] = ResolversParentTypes['GiveProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	givingLink?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isExternal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IcalFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['IcalFeed'] = ResolversParentTypes['IcalFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Identity'] = ResolversParentTypes['Identity']
> = {
	accountType?: Resolver<Maybe<ResolversTypes['AccountType']>, ParentType, ContextType>;
	address?: Resolver<Maybe<ResolversTypes['IdentityAddress']>, ParentType, ContextType>;
	administeredOrganizations?: Resolver<
		Maybe<Array<ResolversTypes['AdministeredOrganization']>>,
		ParentType,
		ContextType
	>;
	allPermissions?: Resolver<Maybe<ResolversTypes['AllIdentityPermissions']>, ParentType, ContextType>;
	birthday?: Resolver<Maybe<ResolversTypes['Birthday']>, ParentType, ContextType>;
	clientIpAddress?: Resolver<Maybe<ResolversTypes['ClientIpAddress']>, ParentType, ContextType>;
	createdOn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	emailAddresses?: Resolver<Array<Maybe<ResolversTypes['EmailContact']>>, ParentType, ContextType>;
	firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	identityKey?: Resolver<ResolversTypes['IdentityKey'], ParentType, ContextType>;
	images?: Resolver<Maybe<ResolversTypes['IdentityImageSet']>, ParentType, ContextType>;
	lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	olsonTimeZone?: Resolver<Maybe<ResolversTypes['OlsonTimeZone']>, ParentType, ContextType>;
	primaryEmailAddress?: Resolver<Maybe<ResolversTypes['EmailContact']>, ParentType, ContextType>;
	timeZoneId?: Resolver<Maybe<ResolversTypes['WindowsTimeZone']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityAddressResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['IdentityAddress'] = ResolversParentTypes['IdentityAddress']
> = {
	city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	line1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	line2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	postcode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityImageResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['IdentityImage'] = ResolversParentTypes['IdentityImage']
> = {
	url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IdentityImageSetResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['IdentityImageSet'] = ResolversParentTypes['IdentityImageSet']
> = {
	avatar?: Resolver<
		Maybe<ResolversTypes['IdentityImage']>,
		ParentType,
		ContextType,
		RequireFields<IdentityImageSetAvatarArgs, never>
	>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface IdentityKeyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['IdentityKey'], any> {
	name: 'IdentityKey';
}

export type ImageResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Image'] = ResolversParentTypes['Image']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	urls?: Resolver<Maybe<ResolversTypes['ImageFormatUrls']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImageFormatUrlsResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ImageFormatUrls'] = ResolversParentTypes['ImageFormatUrls']
> = {
	dynamicHomeScreen?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	landscape?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	original?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	panorama?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	square?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	unprocessedImageUrl?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ImpactCard'] = ResolversParentTypes['ImpactCard']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	keyMetrics?: Resolver<Array<ResolversTypes['KeyMetric']>, ParentType, ContextType>;
	keyMetricsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactCardContentResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ImpactCardContent'] = ResolversParentTypes['ImpactCardContent']
> = {
	summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactCardDefinitionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ImpactCardDefinition'] = ResolversParentTypes['ImpactCardDefinition']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	cardType?: Resolver<ResolversTypes['CardType'], ParentType, ContextType>;
	content?: Resolver<Maybe<ResolversTypes['ImpactCardContent']>, ParentType, ContextType>;
	displayProperties?: Resolver<ResolversTypes['ImpactDisplayProperties'], ParentType, ContextType>;
	featuredCard?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	sortTimestamp?: Resolver<ResolversTypes['Instant'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpactDisplayPropertiesResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ImpactDisplayProperties'] = ResolversParentTypes['ImpactDisplayProperties']
> = {
	showKeyMetrics?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	showTitle?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface InstantScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Instant'], any> {
	name: 'Instant';
}

export type IntroCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['IntroCard'] = ResolversParentTypes['IntroCard']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvalidRequestErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['InvalidRequestError'] = ResolversParentTypes['InvalidRequestError']
> = {
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Item'] = ResolversParentTypes['Item']
> = {
	actions?: Resolver<Array<ResolversTypes['ItemAction']>, ParentType, ContextType>;
	cardDefinition?: Resolver<Maybe<ResolversTypes['ItemCardDefinition']>, ParentType, ContextType>;
	createdDate?: Resolver<Maybe<ResolversTypes['Instant']>, ParentType, ContextType>;
	feedId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	image?: Resolver<Maybe<ResolversTypes['Image']>, ParentType, ContextType>;
	isHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isMirrored?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	parentContainer?: Resolver<Maybe<ResolversTypes['Container']>, ParentType, ContextType>;
	properties?: Resolver<Array<ResolversTypes['ItemProperty']>, ParentType, ContextType>;
	related?: Resolver<Array<ResolversTypes['Item']>, ParentType, ContextType>;
	template?: Resolver<ResolversTypes['ItemTemplate'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['ItemType'], ParentType, ContextType>;
	updatedDate?: Resolver<Maybe<ResolversTypes['Instant']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemActionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemAction'] = ResolversParentTypes['ItemAction']
> = {
	isHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	property?: Resolver<ResolversTypes['ItemProperty'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCardContentResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemCardContent'] = ResolversParentTypes['ItemCardContent']
> = {
	subtitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemCardDefinitionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemCardDefinition'] = ResolversParentTypes['ItemCardDefinition']
> = {
	__resolveType: TypeResolveFn<'BaseCardDefinition' | 'ImpactCardDefinition', ParentType, ContextType>;
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	cardType?: Resolver<ResolversTypes['CardType'], ParentType, ContextType>;
	featuredCard?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	sortTimestamp?: Resolver<ResolversTypes['Instant'], ParentType, ContextType>;
};

export type ItemDisplayPropertiesResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemDisplayProperties'] = ResolversParentTypes['ItemDisplayProperties']
> = {
	showCategory?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	showPublishedTime?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemProperty'] = ResolversParentTypes['ItemProperty']
> = {
	__resolveType: TypeResolveFn<
		| 'AddToContactsProperty'
		| 'AddressProperty'
		| 'AppLinkProperty'
		| 'AudioProperty'
		| 'BlankifyProperty'
		| 'CallToActionProperty'
		| 'CarouselProperty'
		| 'DefaultProperty'
		| 'GiveProperty'
		| 'KeyMetricsProperty'
		| 'ShareProperty'
		| 'SmsProperty'
		| 'TextHtmlProperty'
		| 'TextProperty'
		| 'TimeframeProperty'
		| 'UserNoteProperty'
		| 'VideoProperty'
		| 'WebsiteProperty',
		ParentType,
		ContextType
	>;
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
};

export type ItemPropertyActionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ItemPropertyAction'] = ResolversParentTypes['ItemPropertyAction']
> = {
	click?: Resolver<ResolversTypes['ItemPropertyActionType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
	name: 'JSONObject';
}

export interface JsonStringScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JsonString'], any> {
	name: 'JsonString';
}

export interface KeyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Key'], any> {
	name: 'Key';
}

export type KeyMetricResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['KeyMetric'] = ResolversParentTypes['KeyMetric']
> = {
	title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyMetricsPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['KeyMetricsProperty'] = ResolversParentTypes['KeyMetricsProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	metrics?: Resolver<Array<ResolversTypes['KeyMetric']>, ParentType, ContextType>;
	metricsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LocalDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LocalDate'], any> {
	name: 'LocalDate';
}

export type MoveChildErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MoveChildError'] = ResolversParentTypes['MoveChildError']
> = {
	childId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveChildResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MoveChildResult'] = ResolversParentTypes['MoveChildResult']
> = {
	__resolveType: TypeResolveFn<'MoveChildError' | 'MoveChildSuccess', ParentType, ContextType>;
};

export type MoveChildSuccessResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MoveChildSuccess'] = ResolversParentTypes['MoveChildSuccess']
> = {
	movedChild?: Resolver<ResolversTypes['MovedChild'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveChildrenOutputResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MoveChildrenOutput'] = ResolversParentTypes['MoveChildrenOutput']
> = {
	moveChildResults?: Resolver<Array<ResolversTypes['MoveChildResult']>, ParentType, ContextType>;
	newParentContainer?: Resolver<Maybe<ResolversTypes['Container']>, ParentType, ContextType>;
	nextCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	previousParentContainer?: Resolver<Maybe<ResolversTypes['Container']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveChildrenResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MoveChildrenResult'] = ResolversParentTypes['MoveChildrenResult']
> = {
	__resolveType: TypeResolveFn<
		'InvalidRequestError' | 'MoveChildrenOutput' | 'RootContainerError',
		ParentType,
		ContextType
	>;
};

export type MovedChildResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['MovedChild'] = ResolversParentTypes['MovedChild']
> = {
	__resolveType: TypeResolveFn<'Container' | 'Item', ParentType, ContextType>;
};

export type MutationResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']
> = {
	copyItems?: Resolver<
		ResolversTypes['CopyItemsResult'],
		ParentType,
		ContextType,
		RequireFields<MutationCopyItemsArgs, 'copyItemsInput' | 'organizationKey'>
	>;
	createContainer?: Resolver<
		ResolversTypes['CreateContainerResult'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateContainerArgs, 'createContainerInput' | 'organizationKey'>
	>;
	createItem?: Resolver<
		ResolversTypes['CreateItemResult'],
		ParentType,
		ContextType,
		RequireFields<MutationCreateItemArgs, 'createItemInput' | 'organizationKey'>
	>;
	deleteContainer?: Resolver<
		ResolversTypes['DeleteContainerResult'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteContainerArgs, 'deleteContainerInput' | 'organizationKey'>
	>;
	deleteContainerNew?: Resolver<
		ResolversTypes['DeleteContainerNewResult'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteContainerNewArgs, 'deleteContainerNewInput' | 'organizationKey'>
	>;
	deleteItem?: Resolver<
		ResolversTypes['DeleteItemResult'],
		ParentType,
		ContextType,
		RequireFields<MutationDeleteItemArgs, 'deleteItemInput' | 'organizationKey'>
	>;
	fetchItemVideoThumbnail?: Resolver<
		ResolversTypes['FetchItemVideoThumbnailResult'],
		ParentType,
		ContextType,
		RequireFields<MutationFetchItemVideoThumbnailArgs, 'fetchItemVideoThumbnailInput' | 'organizationKey'>
	>;
	generateFileUploadPost?: Resolver<
		ResolversTypes['GenerateFileUploadPostResult'],
		ParentType,
		ContextType,
		RequireFields<MutationGenerateFileUploadPostArgs, 'generateFileUploadPostInput' | 'organizationKey'>
	>;
	moveChildren?: Resolver<
		ResolversTypes['MoveChildrenResult'],
		ParentType,
		ContextType,
		RequireFields<MutationMoveChildrenArgs, 'moveChildrenInput' | 'organizationKey'>
	>;
	processContainerFeed?: Resolver<
		ResolversTypes['ProcessContainerFeedResult'],
		ParentType,
		ContextType,
		RequireFields<MutationProcessContainerFeedArgs, 'organizationKey' | 'processContainerFeedInput'>
	>;
	publishChanges?: Resolver<
		ResolversTypes['PublishChangesResult'],
		ParentType,
		ContextType,
		RequireFields<MutationPublishChangesArgs, 'organizationKey' | 'publishChangesInput'>
	>;
	reorderContainerChildren?: Resolver<
		ResolversTypes['ReorderContainerChildrenResult'],
		ParentType,
		ContextType,
		RequireFields<MutationReorderContainerChildrenArgs, 'organizationKey' | 'reorderContainerChildrenInput'>
	>;
	saveContainerSettings?: Resolver<
		ResolversTypes['SaveContainerSettingsResult'],
		ParentType,
		ContextType,
		RequireFields<MutationSaveContainerSettingsArgs, 'organizationKey' | 'saveContainerSettingsInput'>
	>;
	saveItemSettings?: Resolver<
		ResolversTypes['SaveItemSettingsResult'],
		ParentType,
		ContextType,
		RequireFields<MutationSaveItemSettingsArgs, 'organizationKey' | 'saveItemSettingsInput'>
	>;
	updateContainerVisibility?: Resolver<
		ResolversTypes['UpdateContainerVisibilityResult'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateContainerVisibilityArgs, 'organizationKey' | 'updateContainerVisibilityInput'>
	>;
	updateItemVisibility?: Resolver<
		ResolversTypes['UpdateItemVisibilityResult'],
		ParentType,
		ContextType,
		RequireFields<MutationUpdateItemVisibilityArgs, 'organizationKey' | 'updateItemVisibilityInput'>
	>;
	updateProfile?: Resolver<
		Maybe<ResolversTypes['UpdateProfileResult']>,
		ParentType,
		ContextType,
		RequireFields<MutationUpdateProfileArgs, 'input' | 'organizationKey'>
	>;
};

export interface OffsetScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Offset'], any> {
	name: 'Offset';
}

export interface OlsonTimeZoneScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['OlsonTimeZone'], any> {
	name: 'OlsonTimeZone';
}

export type OrganizationResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']
> = {
	appStudio?: Resolver<Maybe<ResolversTypes['AppStudio']>, ParentType, ContextType>;
	application?: Resolver<
		Maybe<ResolversTypes['Application']>,
		ParentType,
		ContextType,
		RequireFields<OrganizationApplicationArgs, 'id'>
	>;
	applications?: Resolver<Array<ResolversTypes['Application']>, ParentType, ContextType>;
	details?: Resolver<Maybe<ResolversTypes['OrganizationDetails']>, ParentType, ContextType>;
	featureFlags?: Resolver<Array<ResolversTypes['FeatureFlag']>, ParentType, ContextType>;
	key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationDetailsResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['OrganizationDetails'] = ResolversParentTypes['OrganizationDetails']
> = {
	catholicEntity?: Resolver<ResolversTypes['CatholicEntity'], ParentType, ContextType>;
	catholicSoftwareStatus?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	community?: Resolver<Maybe<ResolversTypes['Community']>, ParentType, ContextType>;
	ecgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	entitlements?: Resolver<Array<ResolversTypes['Entitlement']>, ParentType, ContextType>;
	isGivingPlatformActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	isMasAppPresent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	isSalesforceCustomAppPresent?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
	name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	productInformation?: Resolver<ResolversTypes['ProductInfo'], ParentType, ContextType>;
	shippingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationPermissionsResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['OrganizationPermissions'] = ResolversParentTypes['OrganizationPermissions']
> = {
	campusPermissions?: Resolver<Array<ResolversTypes['CampusPermissions']>, ParentType, ContextType>;
	organization?: Resolver<Maybe<ResolversTypes['OrganizationDetails']>, ParentType, ContextType>;
	organizationKey?: Resolver<ResolversTypes['Key'], ParentType, ContextType>;
	permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']
> = {
	name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	permissionKey?: Resolver<ResolversTypes['Key'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PhoneNumberScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['PhoneNumber'], any> {
	name: 'PhoneNumber';
}

export type PodcastFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['PodcastFeed'] = ResolversParentTypes['PodcastFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PresignedPostResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['PresignedPost'] = ResolversParentTypes['PresignedPost']
> = {
	acl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	algorithm?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	credential?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	policy?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	signature?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tagging?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	url?: Resolver<ResolversTypes['URL'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessContainerFeedResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ProcessContainerFeedResult'] = ResolversParentTypes['ProcessContainerFeedResult']
> = {
	feed?: Resolver<ResolversTypes['Feed'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductInfoResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ProductInfo'] = ResolversParentTypes['ProductInfo']
> = {
	chmsInformation?: Resolver<ResolversTypes['ChmsInfo'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PublishChangesResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['PublishChangesResult'] = ResolversParentTypes['PublishChangesResult']
> = {
	application?: Resolver<ResolversTypes['Application'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']
> = {
	isGuideValid?: Resolver<
		ResolversTypes['Boolean'],
		ParentType,
		ContextType,
		RequireFields<QueryIsGuideValidArgs, 'hash'>
	>;
	me?: Resolver<Maybe<ResolversTypes['Identity']>, ParentType, ContextType>;
	organization?: Resolver<
		Maybe<ResolversTypes['Organization']>,
		ParentType,
		ContextType,
		RequireFields<QueryOrganizationArgs, 'organizationKey'>
	>;
	supportedCountriesDetails?: Resolver<Array<ResolversTypes['CountryType']>, ParentType, ContextType>;
};

export type RelatedContentResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['RelatedContent'] = ResolversParentTypes['RelatedContent']
> = {
	enabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	isEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReorderContainerChildrenResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ReorderContainerChildrenResult'] = ResolversParentTypes['ReorderContainerChildrenResult']
> = {
	updatedContainerChildren?: Resolver<Array<ResolversTypes['ContainerChild']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RootContainerErrorResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['RootContainerError'] = ResolversParentTypes['RootContainerError']
> = {
	message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RssFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['RssFeed'] = ResolversParentTypes['RssFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveContainerSettingsResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['SaveContainerSettingsResult'] = ResolversParentTypes['SaveContainerSettingsResult']
> = {
	updatedContainer?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SaveItemSettingsResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['SaveItemSettingsResult'] = ResolversParentTypes['SaveItemSettingsResult']
> = {
	updatedItem?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SharePropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ShareProperty'] = ResolversParentTypes['ShareProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	sharedProperties?: Resolver<Array<ResolversTypes['ItemProperty']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShellCapabilityResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['ShellCapability'] = ResolversParentTypes['ShellCapability']
> = {
	isSupported?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	key?: Resolver<ResolversTypes['ShellCapabilityKey'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmsPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['SmsProperty'] = ResolversParentTypes['SmsProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	androidAppUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	androidDefaultUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	iosAppUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	iosDefaultUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	messageBody?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	messageLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	phoneNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['SpecialCard'] = ResolversParentTypes['SpecialCard']
> = {
	callToActionText?: Resolver<Maybe<ResolversTypes['CallToActionType']>, ParentType, ContextType>;
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StudioContainerTypePropertiesResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['StudioContainerTypeProperties'] = ResolversParentTypes['StudioContainerTypeProperties']
> = {
	ctaLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	link?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextHtmlPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['TextHtmlProperty'] = ResolversParentTypes['TextHtmlProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	textHtml?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['TextProperty'] = ResolversParentTypes['TextProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	textDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeZoneResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['TimeZone'] = ResolversParentTypes['TimeZone']
> = {
	olsonName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	windowsName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeframeResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['Timeframe'] = ResolversParentTypes['Timeframe']
> = {
	allDay?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	endTime?: Resolver<Maybe<ResolversTypes['Instant']>, ParentType, ContextType>;
	startTime?: Resolver<Maybe<ResolversTypes['Instant']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeframePropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['TimeframeProperty'] = ResolversParentTypes['TimeframeProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	timeframe?: Resolver<ResolversTypes['Timeframe'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
	name: 'URL';
}

export type UpdateContainerVisibilityResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['UpdateContainerVisibilityResult'] = ResolversParentTypes['UpdateContainerVisibilityResult']
> = {
	updatedContainer?: Resolver<ResolversTypes['Container'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateItemVisibilityResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['UpdateItemVisibilityResult'] = ResolversParentTypes['UpdateItemVisibilityResult']
> = {
	updatedItem?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateProfileResultResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['UpdateProfileResult'] = ResolversParentTypes['UpdateProfileResult']
> = {
	identity?: Resolver<Maybe<ResolversTypes['Identity']>, ParentType, ContextType>;
	success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserNotePropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['UserNoteProperty'] = ResolversParentTypes['UserNoteProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	hintText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoCardResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['VideoCard'] = ResolversParentTypes['VideoCard']
> = {
	category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	featured?: Resolver<Maybe<ResolversTypes['FeaturedCard']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	item?: Resolver<ResolversTypes['Item'], ParentType, ContextType>;
	publishedTime?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	subtitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	summary?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	tabName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	videoUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['VideoFeed'] = ResolversParentTypes['VideoFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VideoPropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['VideoProperty'] = ResolversParentTypes['VideoProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isEmbed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VimeoFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['VimeoFeed'] = ResolversParentTypes['VimeoFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	identifiers?: Resolver<Maybe<ResolversTypes['VimeoFeedIdentifiers']>, ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VimeoFeedIdentifiersResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['VimeoFeedIdentifiers'] = ResolversParentTypes['VimeoFeedIdentifiers']
> = {
	channelId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	groupId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	showcaseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	type?: Resolver<ResolversTypes['VimeoIdentifierType'], ParentType, ContextType>;
	userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WebsitePropertyResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['WebsiteProperty'] = ResolversParentTypes['WebsiteProperty']
> = {
	action?: Resolver<Maybe<ResolversTypes['ItemPropertyAction']>, ParentType, ContextType>;
	actionBar?: Resolver<Maybe<ResolversTypes['ActionBar']>, ParentType, ContextType>;
	header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	hidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	icon?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	isExternal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
	itemId?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	position?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
	type?: Resolver<ResolversTypes['PropertyType'], ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface WindowsTimeZoneScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['WindowsTimeZone'], any> {
	name: 'WindowsTimeZone';
}

export type YouTubeFeedResolvers<
	ContextType = CacheContext,
	ParentType extends ResolversParentTypes['YouTubeFeed'] = ResolversParentTypes['YouTubeFeed']
> = {
	id?: Resolver<ResolversTypes['GUID'], ParentType, ContextType>;
	processState?: Resolver<ResolversTypes['FeedProcessState'], ParentType, ContextType>;
	properties?: Resolver<Maybe<ResolversTypes['FeedProperty']>, ParentType, ContextType>;
	url?: Resolver<Maybe<ResolversTypes['URL']>, ParentType, ContextType>;
	__isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = CacheContext> = {
	ActionBar?: ActionBarResolvers<ContextType>;
	AddToContactsProperty?: AddToContactsPropertyResolvers<ContextType>;
	Address?: AddressResolvers<ContextType>;
	AddressProperty?: AddressPropertyResolvers<ContextType>;
	AdministeredOrganization?: AdministeredOrganizationResolvers<ContextType>;
	AllIdentityPermissions?: AllIdentityPermissionsResolvers<ContextType>;
	AppCampus?: AppCampusResolvers<ContextType>;
	AppLinkProperty?: AppLinkPropertyResolvers<ContextType>;
	AppStudio?: AppStudioResolvers<ContextType>;
	Application?: ApplicationResolvers<ContextType>;
	ApplicationImages?: ApplicationImagesResolvers<ContextType>;
	ApplicationSetting?: ApplicationSettingResolvers<ContextType>;
	AudioCard?: AudioCardResolvers<ContextType>;
	AudioProperty?: AudioPropertyResolvers<ContextType>;
	BaseCardDefinition?: BaseCardDefinitionResolvers<ContextType>;
	Birthday?: BirthdayResolvers<ContextType>;
	BlankifyProperty?: BlankifyPropertyResolvers<ContextType>;
	CallToActionProperty?: CallToActionPropertyResolvers<ContextType>;
	CampusPermissions?: CampusPermissionsResolvers<ContextType>;
	Card?: CardResolvers<ContextType>;
	CardDataMap?: CardDataMapResolvers<ContextType>;
	CarouselProperty?: CarouselPropertyResolvers<ContextType>;
	ChildContainer?: ChildContainerResolvers<ContextType>;
	ChildItem?: ChildItemResolvers<ContextType>;
	ChmsInfo?: ChmsInfoResolvers<ContextType>;
	ClientField?: ClientFieldResolvers<ContextType>;
	ClientIpAddress?: ClientIpAddressResolvers<ContextType>;
	Community?: CommunityResolvers<ContextType>;
	Container?: ContainerResolvers<ContextType>;
	ContainerCardDefinition?: ContainerCardDefinitionResolvers<ContextType>;
	ContainerChild?: ContainerChildResolvers<ContextType>;
	ContainerNotEmptyError?: ContainerNotEmptyErrorResolvers<ContextType>;
	ContainerTypeSpecificProperties?: ContainerTypeSpecificPropertiesResolvers<ContextType>;
	CopyItemError?: CopyItemErrorResolvers<ContextType>;
	CopyItemResult?: CopyItemResultResolvers<ContextType>;
	CopyItemSuccess?: CopyItemSuccessResolvers<ContextType>;
	CopyItemsOutput?: CopyItemsOutputResolvers<ContextType>;
	CopyItemsResult?: CopyItemsResultResolvers<ContextType>;
	CountryType?: CountryTypeResolvers<ContextType>;
	CreateContainerResult?: CreateContainerResultResolvers<ContextType>;
	CreateItemResult?: CreateItemResultResolvers<ContextType>;
	CursorPagedContainerChildren?: CursorPagedContainerChildrenResolvers<ContextType>;
	CursorPagingOutput?: CursorPagingOutputResolvers<ContextType>;
	DataMap?: DataMapResolvers<ContextType>;
	DefaultCard?: DefaultCardResolvers<ContextType>;
	DefaultProperty?: DefaultPropertyResolvers<ContextType>;
	DeleteContainerNewResult?: DeleteContainerNewResultResolvers<ContextType>;
	DeleteContainerNewSuccess?: DeleteContainerNewSuccessResolvers<ContextType>;
	DeleteContainerResult?: DeleteContainerResultResolvers<ContextType>;
	DeleteItemResult?: DeleteItemResultResolvers<ContextType>;
	DynamicHomeScreen?: DynamicHomeScreenResolvers<ContextType>;
	EmailAddress?: GraphQLScalarType;
	EmailContact?: EmailContactResolvers<ContextType>;
	Entitlement?: EntitlementResolvers<ContextType>;
	Error?: ErrorResolvers<ContextType>;
	EventCard?: EventCardResolvers<ContextType>;
	FacetimeUrl?: GraphQLScalarType;
	Feature?: FeatureResolvers<ContextType>;
	FeatureFlag?: FeatureFlagResolvers<ContextType>;
	FeaturedCard?: FeaturedCardResolvers<ContextType>;
	Feed?: FeedResolvers<ContextType>;
	FeedProperty?: FeedPropertyResolvers<ContextType>;
	FetchItemVideoThumbnailResult?: FetchItemVideoThumbnailResultResolvers<ContextType>;
	FileUpload?: GraphQLScalarType;
	GUID?: GraphQLScalarType;
	GenerateFileUploadPostResult?: GenerateFileUploadPostResultResolvers<ContextType>;
	GiveProperty?: GivePropertyResolvers<ContextType>;
	IcalFeed?: IcalFeedResolvers<ContextType>;
	Identity?: IdentityResolvers<ContextType>;
	IdentityAddress?: IdentityAddressResolvers<ContextType>;
	IdentityImage?: IdentityImageResolvers<ContextType>;
	IdentityImageSet?: IdentityImageSetResolvers<ContextType>;
	IdentityKey?: GraphQLScalarType;
	Image?: ImageResolvers<ContextType>;
	ImageFormatUrls?: ImageFormatUrlsResolvers<ContextType>;
	ImpactCard?: ImpactCardResolvers<ContextType>;
	ImpactCardContent?: ImpactCardContentResolvers<ContextType>;
	ImpactCardDefinition?: ImpactCardDefinitionResolvers<ContextType>;
	ImpactDisplayProperties?: ImpactDisplayPropertiesResolvers<ContextType>;
	Instant?: GraphQLScalarType;
	IntroCard?: IntroCardResolvers<ContextType>;
	InvalidRequestError?: InvalidRequestErrorResolvers<ContextType>;
	Item?: ItemResolvers<ContextType>;
	ItemAction?: ItemActionResolvers<ContextType>;
	ItemCardContent?: ItemCardContentResolvers<ContextType>;
	ItemCardDefinition?: ItemCardDefinitionResolvers<ContextType>;
	ItemDisplayProperties?: ItemDisplayPropertiesResolvers<ContextType>;
	ItemProperty?: ItemPropertyResolvers<ContextType>;
	ItemPropertyAction?: ItemPropertyActionResolvers<ContextType>;
	JSONObject?: GraphQLScalarType;
	JsonString?: GraphQLScalarType;
	Key?: GraphQLScalarType;
	KeyMetric?: KeyMetricResolvers<ContextType>;
	KeyMetricsProperty?: KeyMetricsPropertyResolvers<ContextType>;
	LocalDate?: GraphQLScalarType;
	MoveChildError?: MoveChildErrorResolvers<ContextType>;
	MoveChildResult?: MoveChildResultResolvers<ContextType>;
	MoveChildSuccess?: MoveChildSuccessResolvers<ContextType>;
	MoveChildrenOutput?: MoveChildrenOutputResolvers<ContextType>;
	MoveChildrenResult?: MoveChildrenResultResolvers<ContextType>;
	MovedChild?: MovedChildResolvers<ContextType>;
	Mutation?: MutationResolvers<ContextType>;
	Offset?: GraphQLScalarType;
	OlsonTimeZone?: GraphQLScalarType;
	Organization?: OrganizationResolvers<ContextType>;
	OrganizationDetails?: OrganizationDetailsResolvers<ContextType>;
	OrganizationPermissions?: OrganizationPermissionsResolvers<ContextType>;
	Permission?: PermissionResolvers<ContextType>;
	PhoneNumber?: GraphQLScalarType;
	PodcastFeed?: PodcastFeedResolvers<ContextType>;
	PresignedPost?: PresignedPostResolvers<ContextType>;
	ProcessContainerFeedResult?: ProcessContainerFeedResultResolvers<ContextType>;
	ProductInfo?: ProductInfoResolvers<ContextType>;
	PublishChangesResult?: PublishChangesResultResolvers<ContextType>;
	Query?: QueryResolvers<ContextType>;
	RelatedContent?: RelatedContentResolvers<ContextType>;
	ReorderContainerChildrenResult?: ReorderContainerChildrenResultResolvers<ContextType>;
	RootContainerError?: RootContainerErrorResolvers<ContextType>;
	RssFeed?: RssFeedResolvers<ContextType>;
	SaveContainerSettingsResult?: SaveContainerSettingsResultResolvers<ContextType>;
	SaveItemSettingsResult?: SaveItemSettingsResultResolvers<ContextType>;
	ShareProperty?: SharePropertyResolvers<ContextType>;
	ShellCapability?: ShellCapabilityResolvers<ContextType>;
	SmsProperty?: SmsPropertyResolvers<ContextType>;
	SpecialCard?: SpecialCardResolvers<ContextType>;
	StudioContainerTypeProperties?: StudioContainerTypePropertiesResolvers<ContextType>;
	TextHtmlProperty?: TextHtmlPropertyResolvers<ContextType>;
	TextProperty?: TextPropertyResolvers<ContextType>;
	TimeZone?: TimeZoneResolvers<ContextType>;
	Timeframe?: TimeframeResolvers<ContextType>;
	TimeframeProperty?: TimeframePropertyResolvers<ContextType>;
	URL?: GraphQLScalarType;
	UpdateContainerVisibilityResult?: UpdateContainerVisibilityResultResolvers<ContextType>;
	UpdateItemVisibilityResult?: UpdateItemVisibilityResultResolvers<ContextType>;
	UpdateProfileResult?: UpdateProfileResultResolvers<ContextType>;
	UserNoteProperty?: UserNotePropertyResolvers<ContextType>;
	VideoCard?: VideoCardResolvers<ContextType>;
	VideoFeed?: VideoFeedResolvers<ContextType>;
	VideoProperty?: VideoPropertyResolvers<ContextType>;
	VimeoFeed?: VimeoFeedResolvers<ContextType>;
	VimeoFeedIdentifiers?: VimeoFeedIdentifiersResolvers<ContextType>;
	WebsiteProperty?: WebsitePropertyResolvers<ContextType>;
	WindowsTimeZone?: GraphQLScalarType;
	YouTubeFeed?: YouTubeFeedResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = CacheContext> = {
	allowAnonymous?: AllowAnonymousDirectiveResolver<any, any, ContextType>;
	authorize?: AuthorizeDirectiveResolver<any, any, ContextType>;
	computed?: ComputedDirectiveResolver<any, any, ContextType>;
	critical?: CriticalDirectiveResolver<any, any, ContextType>;
	delegate?: DelegateDirectiveResolver<any, any, ContextType>;
	emailAddress?: EmailAddressDirectiveResolver<any, any, ContextType>;
	feature?: FeatureDirectiveResolver<any, any, ContextType>;
	fileUploadAccept?: FileUploadAcceptDirectiveResolver<any, any, ContextType>;
	ignore?: IgnoreDirectiveResolver<any, any, ContextType>;
	length?: LengthDirectiveResolver<any, any, ContextType>;
	masCompatibility?: MasCompatibilityDirectiveResolver<any, any, ContextType>;
	oneOf?: OneOfDirectiveResolver<any, any, ContextType>;
	range?: RangeDirectiveResolver<any, any, ContextType>;
	rename?: RenameDirectiveResolver<any, any, ContextType>;
	source?: SourceDirectiveResolver<any, any, ContextType>;
	stringLength?: StringLengthDirectiveResolver<any, any, ContextType>;
	url?: UrlDirectiveResolver<any, any, ContextType>;
};

const saveContainerSettingsSchema = {
	organizationKey: '',
	platformCampusKey: '',
	input: {
		applicationId: null as GUID | null,
		campusId: null as GUID | null,
		cardDefinition: {
			callToActionText: null as CallToActionType | null,
			cardType: null as CardType | null,
			dataMap: {
				audioUrl: {
					position: null as number | null,
					type: null as PropertyType | null,
				},
				publishedTime: {
					position: null as number | null,
					type: null as PropertyType | null,
				},
				subtitle: {
					position: null as number | null,
					type: null as PropertyType | null,
				},
				summary: {
					position: null as number | null,
					type: null as PropertyType | null,
				},
				videoUrl: {
					position: null as number | null,
					type: null as PropertyType | null,
				},
			},
			featuredLabel: '',
			maxItems: null as number | null,
		},
		containerId: null as GUID | null,
		givingLink: '' as URL,
		icalFeed: {
			properties: '' as JSONObject,
			url: '' as URL,
		},
		icon: '',
		imageUrl: '' as URL,
		name: '',
		navigationAction: null as NavigationAction | null,
		parentContainerId: null as GUID | null,
		relatedContent: {
			isEnabled: false,
			title: '',
		},
		resiLink: '' as URL,
		rssFeed: {
			properties: '' as JSONObject,
			type: null as RssFeedType | null,
			url: '' as URL,
		},
		subtitle: '',
		template: null as ContainerTemplate | null,
		type: null as ContainerType | null,
		typeSpecificProperties: {
			ctaLabel: '',
			description: '',
			link: '' as URL,
			title: '',
		},
		vimeoFeed: {
			channelId: '',
			groupId: '',
			identifier: null as VimeoIdentifierType | null,
			properties: '' as JSONObject,
			showcaseId: '',
			userId: '',
		},
		youtubeFeed: {
			properties: '' as JSONObject,
			url: '' as URL,
		},
	},
};

export type SaveContainerSettingsSchema = typeof saveContainerSettingsSchema;

const saveContainerSettingsValidationSchema: Forms.ValidationSchema<SaveContainerSettingsSchema> = {
	organizationKey: [{ type: Forms.RuleType.Required }],
	input: {
		_nonNull: true,
		applicationId: [{ type: Forms.RuleType.Required }],
		cardDefinition: {
			cardType: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			dataMap: {
				_nonNull: true,
				audioUrl: {
					position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				},
				publishedTime: {
					position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				},
				subtitle: {
					position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				},
				summary: {
					position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				},
				videoUrl: {
					position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				},
			},
			maxItems: [
				{ type: Forms.RuleType.Range, minimum: 1, maximum: 20 },
				{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue },
			],
		},
		containerId: [{ type: Forms.RuleType.Required }],
		icalFeed: {
			url: [{ type: Forms.RuleType.Url }],
		},
		imageUrl: [{ type: Forms.RuleType.Url }],
		name: [{ type: Forms.RuleType.Required }],
		parentContainerId: [{ type: Forms.RuleType.Required }],
		relatedContent: {
			_nonNull: true,
			isEnabled: [{ type: Forms.RuleType.Required }],
			title: [{ type: Forms.RuleType.StringLength, maximumLength: 30 }],
		},
		rssFeed: {
			type: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			url: [{ type: Forms.RuleType.Url }],
		},
		template: [{ type: Forms.RuleType.Required }],
		type: [{ type: Forms.RuleType.Required }],
		typeSpecificProperties: {
			ctaLabel: [
				{ type: Forms.RuleType.StringLength, maximumLength: 30 },
				{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue },
			],
			description: [{ type: Forms.RuleType.StringLength, maximumLength: 150 }],
			title: [
				{ type: Forms.RuleType.StringLength, maximumLength: 60 },
				{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue },
			],
		},
		vimeoFeed: {
			identifier: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
		},
		youtubeFeed: {
			url: [{ type: Forms.RuleType.Url }],
		},
	},
};

export function useSaveContainerSettingsForm(
	options: ApolloForms.UseMutationFormOptions<
		SaveContainerSettingsSchema,
		SaveContainerSettingsMutation,
		SaveContainerSettingsMutationVariables
	> = {}
) {
	const { mutationOptions, ...rest } = options;
	return ApolloForms.useMutationForm({
		formName: 'saveContainerSettings',
		selectKey: 'saveContainerSettings',
		mutationOptions,
		mutationHook: useSaveContainerSettingsMutation,
		schema: saveContainerSettingsSchema,
		validationSchema: saveContainerSettingsValidationSchema,
		...rest,
	});
}
const saveItemSettingsSchema = {
	organizationKey: '',
	platformCampusKey: '',
	input: {
		applicationId: null as GUID | null,
		cardDefinition: {
			callToActionText: null as CallToActionType | null,
			cardType: null as CardType | null,
			featuredLabel: '',
			genericContent: {
				subtitle: '',
				summary: '',
			},
			isFeatured: false,
			showProperties: {
				category: false,
				keyMetrics: false,
				publishedTime: false,
				title: false,
			},
		},
		icon: '',
		imageUrl: '' as URL,
		itemId: null as GUID | null,
		name: '',
		parentContainerId: null as GUID | null,
		properties: [
			{
				[Forms.oneOfDirectiveSymbol]: true,
				addToContactsPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
				},
				addressPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					city: '',
					latitude: '',
					longitude: '',
					state: '',
					street: '',
					zip: '',
				},
				appLinkPropertyInput: {
					androidAppUrl: '',
					androidDefaultUrl: '',
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					iosAppUrl: '',
					iosDefaultUrl: '',
					label: '',
				},
				audioPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					label: '',
					url: '',
				},
				blankifyPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					content: '',
				},
				callToActionPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					isExternal: false,
					label: '',
					url: '',
				},
				carouselPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					images: [
						{
							[Forms.oneOfDirectiveSymbol]: true,
							existingImageInput: {
								id: null as GUID | null,
							},
							newImageInput: {
								id: null as GUID | null,
								unprocessedImageUrl: '' as URL,
							},
						},
					],
					title: '',
				},
				defaultPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					data: '',
				},
				emailPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					emailAddress: null as EmailAddress | null,
				},
				facetimePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					facetimeUrl: null as FacetimeUrl | null,
				},
				givePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					givingLink: '' as URL,
				},
				keyMetricsPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					metrics: [
						{
							title: '',
							value: '',
						},
					],
					metricsCount: null as number | null,
				},
				phonePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					phoneNumber: null as PhoneNumber | null,
				},
				sharePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					sharedProperties: [null as GUID | null],
				},
				smsPropertyInput: {
					androidAppUrl: '',
					androidDefaultUrl: '',
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					iosAppUrl: '',
					iosDefaultUrl: '',
					messageBody: '',
					messageLabel: '',
					phoneNumber: '',
				},
				textHtmlPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					text: '',
					textHtml: '',
				},
				textPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					textDescription: '',
				},
				timeframePropertyInput: {
					allDay: false,
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					endTime: null as Instant | null,
					startTime: null as Instant | null,
				},
				userNotePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					hintText: '',
				},
				videoPropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					description: '',
					isEmbed: false,
					url: '',
				},
				websitePropertyInput: {
					baseProperty: {
						action: {
							click: null as ItemPropertyActionType | null,
						},
						actionBar: {
							hideOriginalProperty: false,
							position: null as number | null,
						},
						header: '',
						icon: '',
						id: null as GUID | null,
						isHidden: false,
						position: null as number | null,
					},
					isExternal: false,
					label: '',
					url: '',
				},
			},
		],
		template: null as ItemTemplate | null,
		type: null as ItemType | null,
	},
};

export type SaveItemSettingsSchema = typeof saveItemSettingsSchema;

const saveItemSettingsValidationSchema: Forms.ValidationSchema<SaveItemSettingsSchema> = {
	organizationKey: [{ type: Forms.RuleType.Required }],
	input: {
		_nonNull: true,
		applicationId: [{ type: Forms.RuleType.Required }],
		cardDefinition: {
			cardType: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			showProperties: {
				_nonNull: true,
			},
		},
		imageUrl: [{ type: Forms.RuleType.Url }],
		itemId: [{ type: Forms.RuleType.Required }],
		name: [{ type: Forms.RuleType.Required }],
		parentContainerId: [{ type: Forms.RuleType.Required }],
		properties: {
			_nonNull: true,
			addToContactsPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			addressPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			appLinkPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			audioPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			blankifyPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			callToActionPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
				isExternal: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			},
			carouselPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
				images: {
					_nonNull: true,
					existingImageInput: {
						id: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					newImageInput: {
						id: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						unprocessedImageUrl: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
				},
			},
			defaultPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			emailPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
				emailAddress: [{ type: Forms.RuleType.EmailAddress }],
			},
			facetimePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			givePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			keyMetricsPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
				metrics: {
					_nonNull: true,
				},
				metricsCount: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			},
			phonePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			sharePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			smsPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			textHtmlPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			textPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			timeframePropertyInput: {
				allDay: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			userNotePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			videoPropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
			},
			websitePropertyInput: {
				baseProperty: {
					_nonNull: true,
					action: {
						click: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					actionBar: {
						hideOriginalProperty: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
						position: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
					},
					id: [{ type: Forms.RuleType.Required }],
					isHidden: [{ type: Forms.RuleType.Required }],
					position: [{ type: Forms.RuleType.Required }],
				},
				isExternal: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			},
		},
		template: [{ type: Forms.RuleType.Required }],
		type: [{ type: Forms.RuleType.Required }],
	},
};

export function useSaveItemSettingsForm(
	options: ApolloForms.UseMutationFormOptions<
		SaveItemSettingsSchema,
		SaveItemSettingsMutation,
		SaveItemSettingsMutationVariables
	> = {}
) {
	const { mutationOptions, ...rest } = options;
	return ApolloForms.useMutationForm({
		formName: 'saveItemSettings',
		selectKey: 'saveItemSettings',
		mutationOptions,
		mutationHook: useSaveItemSettingsMutation,
		schema: saveItemSettingsSchema,
		validationSchema: saveItemSettingsValidationSchema,
		...rest,
	});
}
const updateProfileSchema = {
	organizationKey: '',
	input: {
		address: {
			city: '',
			countryCode: null as Cca3CountryCodeEnumType | null,
			line1: '',
			line2: '',
			postcode: '',
			state: '',
		},
		birthday: {
			day: null as number | null,
			month: null as MonthOfYear | null,
			year: null as number | null,
		},
		commandId: '',
		emailAddresses: [''],
		firstName: '',
		identityKey: null as Key | null,
		lastName: '',
		timeZone: null as OlsonTimeZone | null,
		userContext: {
			browser: {
				userAgent: '',
			},
			ipAddress: '',
			olsonTimeZone: null as OlsonTimeZone | null,
		},
	},
};

export type UpdateProfileSchema = typeof updateProfileSchema;

const updateProfileValidationSchema: Forms.ValidationSchema<UpdateProfileSchema> = {
	organizationKey: [{ type: Forms.RuleType.Required }],
	input: {
		_nonNull: true,
		birthday: {
			day: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
			month: [{ type: Forms.RuleType.RequiredWhenAnySiblingHasValue }],
		},
		commandId: [{ type: Forms.RuleType.Required }],
		identityKey: [{ type: Forms.RuleType.Required }],
		userContext: {
			_nonNull: true,
			ipAddress: [{ type: Forms.RuleType.Required }],
			olsonTimeZone: [{ type: Forms.RuleType.Required }],
		},
	},
};

export function useUpdateProfileForm(
	options: ApolloForms.UseMutationFormOptions<
		UpdateProfileSchema,
		UpdateProfileMutation,
		UpdateProfileMutationVariables
	> = {}
) {
	const { mutationOptions, ...rest } = options;
	return ApolloForms.useMutationForm({
		formName: 'updateProfile',
		selectKey: 'updateProfile',
		mutationOptions,
		mutationHook: useUpdateProfileMutation,
		schema: updateProfileSchema,
		validationSchema: updateProfileValidationSchema,
		...rest,
	});
}

export enum TypeName {
	Boolean = 'Boolean',
	EmailAddress = 'EmailAddress',
	FacetimeUrl = 'FacetimeUrl',
	FileUpload = 'FileUpload',
	GUID = 'GUID',
	IdentityKey = 'IdentityKey',
	Instant = 'Instant',
	Int = 'Int',
	JSONObject = 'JSONObject',
	JsonString = 'JsonString',
	Key = 'Key',
	LocalDate = 'LocalDate',
	Offset = 'Offset',
	OlsonTimeZone = 'OlsonTimeZone',
	PhoneNumber = 'PhoneNumber',
	String = 'String',
	URL = 'URL',
	WindowsTimeZone = 'WindowsTimeZone',
	ActionBar = 'ActionBar',
	ActionBarInput = 'ActionBarInput',
	AddToContactsProperty = 'AddToContactsProperty',
	AddToContactsPropertyInput = 'AddToContactsPropertyInput',
	Address = 'Address',
	AddressArgumentsInput = 'AddressArgumentsInput',
	AddressProperty = 'AddressProperty',
	AddressPropertyInput = 'AddressPropertyInput',
	AdministeredOrganization = 'AdministeredOrganization',
	AllIdentityPermissions = 'AllIdentityPermissions',
	AppCampus = 'AppCampus',
	AppLinkProperty = 'AppLinkProperty',
	AppLinkPropertyInput = 'AppLinkPropertyInput',
	AppStudio = 'AppStudio',
	Application = 'Application',
	ApplicationImages = 'ApplicationImages',
	ApplicationSetting = 'ApplicationSetting',
	AudioCard = 'AudioCard',
	AudioProperty = 'AudioProperty',
	AudioPropertyInput = 'AudioPropertyInput',
	BaseCardDefinition = 'BaseCardDefinition',
	BasePropertyInput = 'BasePropertyInput',
	Birthday = 'Birthday',
	BirthdayArgumentsInput = 'BirthdayArgumentsInput',
	BlankifyProperty = 'BlankifyProperty',
	BlankifyPropertyInput = 'BlankifyPropertyInput',
	BrowserDataInput = 'BrowserDataInput',
	CallToActionProperty = 'CallToActionProperty',
	CallToActionPropertyInput = 'CallToActionPropertyInput',
	CampusPermissions = 'CampusPermissions',
	CardDataMap = 'CardDataMap',
	CardDataMapInput = 'CardDataMapInput',
	CardDefinitionGenericContentInput = 'CardDefinitionGenericContentInput',
	CarouselProperty = 'CarouselProperty',
	CarouselPropertyInput = 'CarouselPropertyInput',
	ChildContainer = 'ChildContainer',
	ChildItem = 'ChildItem',
	ChmsInfo = 'ChmsInfo',
	ClientField = 'ClientField',
	ClientIpAddress = 'ClientIpAddress',
	Community = 'Community',
	Container = 'Container',
	ContainerCardDefinition = 'ContainerCardDefinition',
	ContainerCardDefinitionInput = 'ContainerCardDefinitionInput',
	ContainerNotEmptyError = 'ContainerNotEmptyError',
	ContainerTypeSpecificPropertyInput = 'ContainerTypeSpecificPropertyInput',
	CopyItemError = 'CopyItemError',
	CopyItemInput = 'CopyItemInput',
	CopyItemSuccess = 'CopyItemSuccess',
	CopyItemsInput = 'CopyItemsInput',
	CopyItemsOutput = 'CopyItemsOutput',
	CountryType = 'CountryType',
	CreateContainerInput = 'CreateContainerInput',
	CreateContainerResult = 'CreateContainerResult',
	CreateItemInput = 'CreateItemInput',
	CreateItemResult = 'CreateItemResult',
	CursorPagedContainerChildren = 'CursorPagedContainerChildren',
	CursorPagingInput = 'CursorPagingInput',
	CursorPagingOutput = 'CursorPagingOutput',
	DataMap = 'DataMap',
	DataMapInput = 'DataMapInput',
	DefaultCard = 'DefaultCard',
	DefaultProperty = 'DefaultProperty',
	DefaultPropertyInput = 'DefaultPropertyInput',
	DeleteContainerInput = 'DeleteContainerInput',
	DeleteContainerNewInput = 'DeleteContainerNewInput',
	DeleteContainerNewSuccess = 'DeleteContainerNewSuccess',
	DeleteContainerResult = 'DeleteContainerResult',
	DeleteItemInput = 'DeleteItemInput',
	DeleteItemResult = 'DeleteItemResult',
	DynamicHomeScreen = 'DynamicHomeScreen',
	EmailContact = 'EmailContact',
	EmailPropertyInput = 'EmailPropertyInput',
	Entitlement = 'Entitlement',
	EventCard = 'EventCard',
	ExistingImageInput = 'ExistingImageInput',
	FacetimePropertyInput = 'FacetimePropertyInput',
	Feature = 'Feature',
	FeatureFlag = 'FeatureFlag',
	FeaturedCard = 'FeaturedCard',
	FeedProperty = 'FeedProperty',
	FetchItemVideoThumbnailInput = 'FetchItemVideoThumbnailInput',
	FetchItemVideoThumbnailResult = 'FetchItemVideoThumbnailResult',
	GenerateFileUploadPostInput = 'GenerateFileUploadPostInput',
	GenerateFileUploadPostResult = 'GenerateFileUploadPostResult',
	GiveProperty = 'GiveProperty',
	GivePropertyInput = 'GivePropertyInput',
	IcalFeed = 'IcalFeed',
	IcalFeedInput = 'IcalFeedInput',
	Identity = 'Identity',
	IdentityAddress = 'IdentityAddress',
	IdentityImage = 'IdentityImage',
	IdentityImageSet = 'IdentityImageSet',
	Image = 'Image',
	ImageFormatUrls = 'ImageFormatUrls',
	ImageInput = 'ImageInput',
	ImageSizeInput = 'ImageSizeInput',
	ImpactCard = 'ImpactCard',
	ImpactCardContent = 'ImpactCardContent',
	ImpactCardDefinition = 'ImpactCardDefinition',
	ImpactDisplayProperties = 'ImpactDisplayProperties',
	IntroCard = 'IntroCard',
	InvalidRequestError = 'InvalidRequestError',
	Item = 'Item',
	ItemAction = 'ItemAction',
	ItemCardContent = 'ItemCardContent',
	ItemDisplayProperties = 'ItemDisplayProperties',
	ItemPropertyAction = 'ItemPropertyAction',
	ItemPropertyActionInput = 'ItemPropertyActionInput',
	ItemPropertyInput = 'ItemPropertyInput',
	KeyMetric = 'KeyMetric',
	KeyMetricInput = 'KeyMetricInput',
	KeyMetricsProperty = 'KeyMetricsProperty',
	KeyMetricsPropertyInput = 'KeyMetricsPropertyInput',
	MoveChildError = 'MoveChildError',
	MoveChildInput = 'MoveChildInput',
	MoveChildSuccess = 'MoveChildSuccess',
	MoveChildrenInput = 'MoveChildrenInput',
	MoveChildrenOutput = 'MoveChildrenOutput',
	NewImageInput = 'NewImageInput',
	Organization = 'Organization',
	OrganizationDetails = 'OrganizationDetails',
	OrganizationPermissions = 'OrganizationPermissions',
	Permission = 'Permission',
	PhonePropertyInput = 'PhonePropertyInput',
	PodcastFeed = 'PodcastFeed',
	PresignedPost = 'PresignedPost',
	ProcessContainerFeedInput = 'ProcessContainerFeedInput',
	ProcessContainerFeedResult = 'ProcessContainerFeedResult',
	ProductInfo = 'ProductInfo',
	PublishChangesInput = 'PublishChangesInput',
	PublishChangesResult = 'PublishChangesResult',
	RelatedContent = 'RelatedContent',
	RelatedContentInput = 'RelatedContentInput',
	ReorderContainerChildrenInput = 'ReorderContainerChildrenInput',
	ReorderContainerChildrenResult = 'ReorderContainerChildrenResult',
	RootContainerError = 'RootContainerError',
	RssFeed = 'RssFeed',
	RssFeedInput = 'RssFeedInput',
	SaveContainerSettingsInput = 'SaveContainerSettingsInput',
	SaveContainerSettingsResult = 'SaveContainerSettingsResult',
	SaveItemCardDefinitionInput = 'SaveItemCardDefinitionInput',
	SaveItemSettingsInput = 'SaveItemSettingsInput',
	SaveItemSettingsResult = 'SaveItemSettingsResult',
	ShareProperty = 'ShareProperty',
	SharePropertyInput = 'SharePropertyInput',
	ShellCapability = 'ShellCapability',
	ShowPropertyInput = 'ShowPropertyInput',
	SmsProperty = 'SmsProperty',
	SmsPropertyInput = 'SmsPropertyInput',
	SpecialCard = 'SpecialCard',
	StudioContainerTypeProperties = 'StudioContainerTypeProperties',
	TextHtmlProperty = 'TextHtmlProperty',
	TextHtmlPropertyInput = 'TextHtmlPropertyInput',
	TextProperty = 'TextProperty',
	TextPropertyInput = 'TextPropertyInput',
	TimeZone = 'TimeZone',
	Timeframe = 'Timeframe',
	TimeframeProperty = 'TimeframeProperty',
	TimeframePropertyInput = 'TimeframePropertyInput',
	UpdateContainerVisibilityInput = 'UpdateContainerVisibilityInput',
	UpdateContainerVisibilityResult = 'UpdateContainerVisibilityResult',
	UpdateItemVisibilityInput = 'UpdateItemVisibilityInput',
	UpdateItemVisibilityResult = 'UpdateItemVisibilityResult',
	UpdateProfileInput = 'UpdateProfileInput',
	UpdateProfileResult = 'UpdateProfileResult',
	UserContextDataInput = 'UserContextDataInput',
	UserNoteProperty = 'UserNoteProperty',
	UserNotePropertyInput = 'UserNotePropertyInput',
	VideoCard = 'VideoCard',
	VideoFeed = 'VideoFeed',
	VideoProperty = 'VideoProperty',
	VideoPropertyInput = 'VideoPropertyInput',
	VimeoFeed = 'VimeoFeed',
	VimeoFeedIdentifiers = 'VimeoFeedIdentifiers',
	VimeoFeedInput = 'VimeoFeedInput',
	WebsiteProperty = 'WebsiteProperty',
	WebsitePropertyInput = 'WebsitePropertyInput',
	YouTubeFeed = 'YouTubeFeed',
	YouTubeFeedInput = 'YouTubeFeedInput',
}

export enum PropertyTypeName {
	AddToContactsProperty = 'AddToContactsProperty',
	AddressProperty = 'AddressProperty',
	AppLinkProperty = 'AppLinkProperty',
	AudioProperty = 'AudioProperty',
	BlankifyProperty = 'BlankifyProperty',
	CallToActionProperty = 'CallToActionProperty',
	CarouselProperty = 'CarouselProperty',
	DefaultProperty = 'DefaultProperty',
	FeedProperty = 'FeedProperty',
	GiveProperty = 'GiveProperty',
	KeyMetricsProperty = 'KeyMetricsProperty',
	ShareProperty = 'ShareProperty',
	SmsProperty = 'SmsProperty',
	TextHtmlProperty = 'TextHtmlProperty',
	TextProperty = 'TextProperty',
	TimeframeProperty = 'TimeframeProperty',
	UserNoteProperty = 'UserNoteProperty',
	VideoProperty = 'VideoProperty',
	WebsiteProperty = 'WebsiteProperty',
}

export const TypeMapper = {
	ActionBar: {
		hideOriginalProperty: 'Boolean!',
		position: 'Int!',
	},

	ActionBarInput: {
		hideOriginalProperty: 'Boolean!',
		position: 'Int!',
	},

	AddToContactsProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	AddToContactsPropertyInput: {
		baseProperty: 'BasePropertyInput!',
	},

	Address: {
		countryCode: 'String',
	},

	AddressArgumentsInput: {
		city: 'String',
		countryCode: 'Cca3CountryCodeEnumType',
		line1: 'String',
		line2: 'String',
		postcode: 'String',
		state: 'String',
	},

	AddressProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		city: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		latitude: 'String',
		longitude: 'String',
		position: 'Int!',
		state: 'String',
		street: 'String',
		type: 'PropertyType!',
		zip: 'String',
	},

	AddressPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		city: 'String',
		latitude: 'String',
		longitude: 'String',
		state: 'String',
		street: 'String',
		zip: 'String',
	},

	AdministeredOrganization: {
		isMerchantAdmin: 'Boolean!',
		key: 'String!',
		logoUrl: 'String!',
		name: 'String!',
	},

	AllIdentityPermissions: {
		organizationPermissions: '[OrganizationPermissions!]!',
		permissions: '[Permission!]!',
	},

	AppCampus: {
		givingLink: 'URL',
		id: 'GUID!',
		name: 'String!',
		organizationId: 'GUID!',
	},

	AppLinkProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		androidAppUrl: 'String',
		androidDefaultUrl: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		iosAppUrl: 'String',
		iosDefaultUrl: 'String',
		itemId: 'GUID!',
		label: 'String',
		position: 'Int!',
		type: 'PropertyType!',
	},

	AppLinkPropertyInput: {
		androidAppUrl: 'String',
		androidDefaultUrl: 'String',
		baseProperty: 'BasePropertyInput!',
		iosAppUrl: 'String',
		iosDefaultUrl: 'String',
		label: 'String',
	},

	AppStudio: {
		isAppStudioDesignEnabled: 'Boolean!',
		isAppStudioPushNotificationsEnabled: 'Boolean!',
	},

	Application: {
		campus: 'AppCampus',
		campuses: '[AppCampus!]!',
		container: 'Container',
		downloadLink: 'String',
		dynamicHomeScreen: 'DynamicHomeScreen',
		features: '[Feature!]!',
		givingLink: 'URL',
		id: 'GUID!',
		images: 'ApplicationImages!',
		name: 'String!',
		organizationId: 'GUID!',
		pendingChanges: 'Int!',
		platformCampusKey: 'String',
		productType: 'ProductType!',
		productVersion: 'ProductVersion!',
		publishStatus: 'ApplicationPublishStatus!',
		rootContainer: 'Container!',
		setting: '[ApplicationSetting!]!',
		shellCapabilities: '[ShellCapability!]!',
	},

	ApplicationImages: {
		brandedHeader: 'URL',
		brandedHeaderDark: 'URL',
		brandmark: 'URL',
		fullLogo: 'URL',
		homeScreenPlaceholder: 'URL',
		openGraph: 'URL',
	},

	ApplicationSetting: {
		enabled: 'Boolean!',
		id: 'GUID!',
		key: 'ApplicationSettingKey!',
		required: 'Boolean!',
		valid_select_options: '[String!]',
		value: 'String',
		valueType: 'ApplicationSettingValueType!',
	},

	AudioCard: {
		audioUrl: 'String',
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
	},

	AudioProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		label: 'String',
		position: 'Int!',
		type: 'PropertyType!',
		url: 'String',
	},

	AudioPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		label: 'String',
		url: 'String',
	},

	BaseCardDefinition: {
		callToActionText: 'CallToActionType',
		cardType: 'CardType!',
		content: 'ItemCardContent',
		displayProperties: 'ItemDisplayProperties!',
		featuredCard: 'FeaturedCard',
		id: 'GUID!',
		isFeatured: 'Boolean!',
		sortTimestamp: 'Instant!',
	},

	BasePropertyInput: {
		action: 'ItemPropertyActionInput',
		actionBar: 'ActionBarInput',
		header: 'String',
		icon: 'String',
		id: 'GUID!',
		isHidden: 'Boolean!',
		position: 'Int!',
	},

	Birthday: {
		day: 'Int!',
		month: 'MonthOfYear!',
		year: 'Int',
	},

	BirthdayArgumentsInput: {
		day: 'Int!',
		month: 'MonthOfYear!',
		year: 'Int',
	},

	BlankifyProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		content: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	BlankifyPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		content: 'String',
	},

	BrowserDataInput: {
		userAgent: 'String',
	},

	CallToActionProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		isExternal: 'Boolean!',
		itemId: 'GUID!',
		label: 'String',
		position: 'Int!',
		type: 'PropertyType!',
		url: 'String',
	},

	CallToActionPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		isExternal: 'Boolean!',
		label: 'String',
		url: 'String',
	},

	CampusPermissions: {
		campusKey: 'Key!',
		permissions: '[Permission!]!',
	},

	CardDataMap: {
		audioUrl: 'DataMap',
		publishedTime: 'DataMap',
		subtitle: 'DataMap',
		summary: 'DataMap',
		videoUrl: 'DataMap',
	},

	CardDataMapInput: {
		audioUrl: 'DataMapInput',
		publishedTime: 'DataMapInput',
		subtitle: 'DataMapInput',
		summary: 'DataMapInput',
		videoUrl: 'DataMapInput',
	},

	CardDefinitionGenericContentInput: {
		subtitle: 'String',
		summary: 'String',
	},

	CarouselProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		images: '[Image!]!',
		itemId: 'GUID!',
		position: 'Int!',
		title: 'String',
		type: 'PropertyType!',
	},

	CarouselPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		images: '[ImageInput!]!',
		title: 'String',
	},

	ChildContainer: {
		container: 'Container!',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		name: 'String!',
		position: 'Int!',
	},

	ChildItem: {
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		item: 'Item!',
		name: 'String!',
		position: 'Int!',
	},

	ChmsInfo: {
		contractStatus: 'String',
		id: 'String',
		isIncludedInContract: 'Boolean!',
		isPremier: 'Boolean!',
		isProvisioned: 'Boolean!',
		package: 'String',
		tenantUrl: 'String',
	},

	ClientField: {
		field: 'String!',
	},

	ClientIpAddress: {
		ipV4: 'String!',
		ipV6: 'String!',
	},

	Community: {
		isInSync: 'Boolean!',
	},

	Container: {
		applicationId: 'GUID!',
		campus: 'AppCampus',
		cardDefinition: 'ContainerCardDefinition',
		children: 'CursorPagedContainerChildren!',
		feed: 'Feed',
		givingLink: 'URL',
		icon: 'String',
		id: 'GUID!',
		image: 'Image',
		isDeletable: 'Boolean!',
		isHidden: 'Boolean!',
		item: 'Item',
		name: 'String!',
		navigationAction: 'NavigationAction',
		parentContainer: 'Container',
		relatedContent: 'RelatedContent!',
		resiLink: 'URL',
		subtitle: 'String',
		template: 'ContainerTemplate!',
		type: 'ContainerType!',
		typeSpecificProperties: 'ContainerTypeSpecificProperties',
	},

	ContainerCardDefinition: {
		callToActionText: 'CallToActionType',
		cardType: 'CardType!',
		dataMap: 'CardDataMap!',
		featuredCard: 'FeaturedCard',
		id: 'GUID!',
		isFeatured: 'Boolean!',
		maxItems: 'Int!',
		showCategory: 'Boolean!',
		showPublishedTime: 'Boolean!',
	},

	ContainerCardDefinitionInput: {
		callToActionText: 'CallToActionType',
		cardType: 'CardType!',
		dataMap: 'CardDataMapInput!',
		featuredLabel: 'String',
		maxItems: 'Int!',
	},

	ContainerNotEmptyError: {
		message: 'String!',
	},

	ContainerTypeSpecificPropertyInput: {
		ctaLabel: 'String!',
		description: 'String',
		link: 'URL',
		title: 'String!',
	},

	CopyItemError: {
		itemId: 'GUID!',
		message: 'String!',
	},

	CopyItemInput: {
		copiedItemName: 'String!',
		itemId: 'GUID!',
	},

	CopyItemSuccess: {
		copiedItem: 'Item!',
		itemId: 'GUID!',
	},

	CopyItemsInput: {
		applicationId: 'GUID!',
		copyItems: '[CopyItemInput!]!',
		currentCursor: 'String',
		parentContainerId: 'String!',
	},

	CopyItemsOutput: {
		copyItemResults: '[CopyItemResult!]!',
		nextCursor: 'String',
	},

	CountryType: {
		callingCode: 'String!',
		cca2: 'Cca2CountryCodeEnumType',
		cca3: 'Cca3CountryCodeEnumType',
		name: 'String!',
		placeholder: 'String!',
		timeZones: '[TimeZone]',
	},

	CreateContainerInput: {
		applicationId: 'GUID!',
		currentCursor: 'String',
		icon: 'String',
		insertBefore: 'GUID',
		name: 'String!',
		parentContainerId: 'GUID!',
		relatedContent: 'RelatedContentInput!',
		template: 'ContainerTemplate!',
		type: 'ContainerType!',
		typeSpecificProperties: 'ContainerTypeSpecificPropertyInput',
	},

	CreateContainerResult: {
		createdContainer: 'Container!',
		nextCursor: 'String',
	},

	CreateItemInput: {
		applicationId: 'GUID!',
		currentCursor: 'String',
		icon: 'String',
		insertBefore: 'GUID',
		name: 'String!',
		parentContainerId: 'GUID!',
		properties: '[ItemPropertyInput!]!',
		template: 'ItemTemplate!',
		type: 'ItemType!',
	},

	CreateItemResult: {
		createdItem: 'Item!',
		nextCursor: 'String',
	},

	CursorPagedContainerChildren: {
		nodes: '[ContainerChild!]!',
		paging: 'CursorPagingOutput!',
	},

	CursorPagingInput: {
		after: 'String',
		size: 'Int',
	},

	CursorPagingOutput: {
		nextCursor: 'String',
		size: 'Int!',
		totalItemCount: 'Int!',
	},

	DataMap: {
		position: 'Int!',
		type: 'PropertyType!',
	},

	DataMapInput: {
		position: 'Int!',
		type: 'PropertyType!',
	},

	DefaultCard: {
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
	},

	DefaultProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		data: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	DefaultPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		data: 'String',
	},

	DeleteContainerInput: {
		applicationId: 'GUID!',
		containerId: 'GUID!',
		currentCursor: 'String',
	},

	DeleteContainerNewInput: {
		applicationId: 'GUID!',
		containerId: 'GUID!',
		currentCursor: 'String',
	},

	DeleteContainerNewSuccess: {
		containerId: 'GUID!',
		nextCursor: 'String',
	},

	DeleteContainerResult: {
		containerId: 'GUID!',
		deletedContainer: 'Container!',
		nextCursor: 'String',
	},

	DeleteItemInput: {
		applicationId: 'GUID!',
		currentCursor: 'String',
		itemId: 'GUID!',
		parentContainerId: 'GUID!',
	},

	DeleteItemResult: {
		deletedItem: 'Item!',
		itemId: 'GUID!',
		nextCursor: 'String',
	},

	DynamicHomeScreen: {
		cards: '[Card!]!',
		isEnabled: 'Boolean!',
		publish: 'Boolean!',
	},

	EmailContact: {
		emailAddress: 'String',
		verified: 'Boolean',
	},

	EmailPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		emailAddress: 'EmailAddress',
	},

	Entitlement: {
		displayName: 'String!',
		key: 'EntitlementKey!',
	},

	EventCard: {
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		timeframe: 'Timeframe',
		title: 'String!',
	},

	ExistingImageInput: {
		id: 'GUID!',
	},

	FacetimePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		facetimeUrl: 'FacetimeUrl',
	},

	Feature: {
		enabled: 'Boolean!',
		id: 'GUID!',
		key: 'FeatureKey!',
		value: 'String',
	},

	FeatureFlag: {
		enabled: 'Boolean!',
		name: 'String!',
	},

	FeaturedCard: {
		label: 'String',
	},

	FeedProperty: {
		actionBarArray: '[String]',
		forceRegenerate: 'Boolean',
		hiddenProperties: '[String]',
		itemTemplate: 'ItemTemplate',
		itemTemplateRaw: 'String',
		playlistId: 'String',
		shareProperties: '[String]',
	},

	FetchItemVideoThumbnailInput: {
		applicationId: 'GUID!',
		itemId: 'GUID!',
		parentContainerId: 'GUID!',
		videoPropertyId: 'GUID!',
	},

	FetchItemVideoThumbnailResult: {
		image: 'Image!',
	},

	GenerateFileUploadPostInput: {
		applicationId: 'GUID!',
	},

	GenerateFileUploadPostResult: {
		presignedPost: 'PresignedPost!',
		unprocessedImageUrl: 'URL!',
	},

	GiveProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		givingLink: 'URL',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		isExternal: 'Boolean!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	GivePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		givingLink: 'URL',
	},

	IcalFeed: {
		id: 'GUID!',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	IcalFeedInput: {
		properties: 'JSONObject',
		url: 'URL',
	},

	Identity: {
		accountType: 'AccountType',
		address: 'IdentityAddress',
		administeredOrganizations: '[AdministeredOrganization!]',
		allPermissions: 'AllIdentityPermissions',
		birthday: 'Birthday',
		clientIpAddress: 'ClientIpAddress',
		createdOn: 'String',
		emailAddresses: '[EmailContact]!',
		firstName: 'String',
		identityKey: 'IdentityKey!',
		images: 'IdentityImageSet',
		lastName: 'String',
		olsonTimeZone: 'OlsonTimeZone',
		primaryEmailAddress: 'EmailContact',
		timeZoneId: 'WindowsTimeZone',
	},

	IdentityAddress: {
		city: 'String',
		countryCode: 'String',
		line1: 'String',
		line2: 'String',
		postcode: 'String',
		state: 'String',
	},

	IdentityImage: {
		url: 'String',
	},

	IdentityImageSet: {
		avatar: 'IdentityImage',
	},

	Image: {
		id: 'GUID!',
		urls: 'ImageFormatUrls',
	},

	ImageFormatUrls: {
		dynamicHomeScreen: 'URL',
		landscape: 'URL',
		original: 'URL',
		panorama: 'URL',
		square: 'URL',
		unprocessedImageUrl: 'URL',
	},

	ImageInput: {
		existingImageInput: 'ExistingImageInput',
		newImageInput: 'NewImageInput',
	},

	ImageSizeInput: {
		height: 'Int!',
		width: 'Int!',
	},

	ImpactCard: {
		callToActionText: 'CallToActionType',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		keyMetrics: '[KeyMetric!]!',
		keyMetricsCount: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
	},

	ImpactCardContent: {
		summary: 'String',
	},

	ImpactCardDefinition: {
		callToActionText: 'CallToActionType',
		cardType: 'CardType!',
		content: 'ImpactCardContent',
		displayProperties: 'ImpactDisplayProperties!',
		featuredCard: 'FeaturedCard',
		id: 'GUID!',
		isFeatured: 'Boolean!',
		sortTimestamp: 'Instant!',
	},

	ImpactDisplayProperties: {
		showKeyMetrics: 'Boolean!',
		showTitle: 'Boolean!',
	},

	IntroCard: {
		callToActionText: 'CallToActionType',
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
	},

	InvalidRequestError: {
		message: 'String!',
	},

	Item: {
		actions: '[ItemAction!]!',
		cardDefinition: 'ItemCardDefinition',
		createdDate: 'Instant',
		feedId: 'String',
		icon: 'String',
		id: 'GUID!',
		image: 'Image',
		isHidden: 'Boolean!',
		isMirrored: 'Boolean!',
		name: 'String!',
		parentContainer: 'Container',
		properties: '[ItemProperty!]!',
		related: '[Item!]!',
		template: 'ItemTemplate!',
		type: 'ItemType!',
		updatedDate: 'Instant',
	},

	ItemAction: {
		isHidden: 'Boolean!',
		property: 'ItemProperty!',
	},

	ItemCardContent: {
		subtitle: 'String',
		summary: 'String',
	},

	ItemDisplayProperties: {
		showCategory: 'Boolean!',
		showPublishedTime: 'Boolean!',
	},

	ItemPropertyAction: {
		click: 'ItemPropertyActionType!',
	},

	ItemPropertyActionInput: {
		click: 'ItemPropertyActionType!',
	},

	ItemPropertyInput: {
		addToContactsPropertyInput: 'AddToContactsPropertyInput',
		addressPropertyInput: 'AddressPropertyInput',
		appLinkPropertyInput: 'AppLinkPropertyInput',
		audioPropertyInput: 'AudioPropertyInput',
		blankifyPropertyInput: 'BlankifyPropertyInput',
		callToActionPropertyInput: 'CallToActionPropertyInput',
		carouselPropertyInput: 'CarouselPropertyInput',
		defaultPropertyInput: 'DefaultPropertyInput',
		emailPropertyInput: 'EmailPropertyInput',
		facetimePropertyInput: 'FacetimePropertyInput',
		givePropertyInput: 'GivePropertyInput',
		keyMetricsPropertyInput: 'KeyMetricsPropertyInput',
		phonePropertyInput: 'PhonePropertyInput',
		sharePropertyInput: 'SharePropertyInput',
		smsPropertyInput: 'SmsPropertyInput',
		textHtmlPropertyInput: 'TextHtmlPropertyInput',
		textPropertyInput: 'TextPropertyInput',
		timeframePropertyInput: 'TimeframePropertyInput',
		userNotePropertyInput: 'UserNotePropertyInput',
		videoPropertyInput: 'VideoPropertyInput',
		websitePropertyInput: 'WebsitePropertyInput',
	},

	KeyMetric: {
		title: 'String',
		value: 'String',
	},

	KeyMetricInput: {
		title: 'String',
		value: 'String',
	},

	KeyMetricsProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		metrics: '[KeyMetric!]!',
		metricsCount: 'Int!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	KeyMetricsPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		metrics: '[KeyMetricInput!]!',
		metricsCount: 'Int!',
	},

	MoveChildError: {
		childId: 'GUID!',
		message: 'String!',
	},

	MoveChildInput: {
		childId: 'GUID!',
	},

	MoveChildSuccess: {
		movedChild: 'MovedChild!',
	},

	MoveChildrenInput: {
		applicationId: 'GUID!',
		currentCursor: 'String',
		moveChildren: '[MoveChildInput!]!',
		newParentContainerId: 'GUID!',
		parentContainerId: 'GUID!',
	},

	MoveChildrenOutput: {
		moveChildResults: '[MoveChildResult!]!',
		newParentContainer: 'Container',
		nextCursor: 'String',
		previousParentContainer: 'Container',
	},

	NewImageInput: {
		id: 'GUID!',
		unprocessedImageUrl: 'URL!',
	},

	Organization: {
		appStudio: 'AppStudio',
		application: 'Application',
		applications: '[Application!]!',
		details: 'OrganizationDetails',
		featureFlags: '[FeatureFlag!]!',
		key: 'String!',
	},

	OrganizationDetails: {
		catholicEntity: 'CatholicEntity!',
		catholicSoftwareStatus: 'Boolean!',
		community: 'Community',
		ecgId: 'String',
		entitlements: '[Entitlement!]!',
		isGivingPlatformActive: 'Boolean',
		isMasAppPresent: 'Boolean',
		isSalesforceCustomAppPresent: 'Boolean',
		name: 'String',
		productInformation: 'ProductInfo!',
		shippingAddress: 'Address',
	},

	OrganizationPermissions: {
		campusPermissions: '[CampusPermissions!]!',
		organization: 'OrganizationDetails',
		organizationKey: 'Key!',
		permissions: '[Permission!]!',
	},

	Permission: {
		name: 'String!',
		permissionKey: 'Key!',
	},

	PhonePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		phoneNumber: 'PhoneNumber',
	},

	PodcastFeed: {
		id: 'GUID!',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	PresignedPost: {
		acl: 'String!',
		algorithm: 'String!',
		credential: 'String!',
		date: 'String!',
		key: 'String!',
		policy: 'String!',
		signature: 'String!',
		tagging: 'String!',
		token: 'String!',
		url: 'URL!',
	},

	ProcessContainerFeedInput: {
		applicationId: 'GUID!',
		containerId: 'GUID!',
	},

	ProcessContainerFeedResult: {
		feed: 'Feed!',
	},

	ProductInfo: {
		chmsInformation: 'ChmsInfo!',
	},

	PublishChangesInput: {
		applicationId: 'GUID!',
	},

	PublishChangesResult: {
		application: 'Application!',
	},

	RelatedContent: {
		enabled: 'Boolean!',
		isEnabled: 'Boolean!',
		title: 'String',
	},

	RelatedContentInput: {
		isEnabled: 'Boolean!',
		title: 'String',
	},

	ReorderContainerChildrenInput: {
		applicationId: 'GUID!',
		containerChildId: 'GUID!',
		insertBefore: 'GUID',
		parentContainerId: 'GUID!',
	},

	ReorderContainerChildrenResult: {
		updatedContainerChildren: '[ContainerChild!]!',
	},

	RootContainerError: {
		message: 'String!',
	},

	RssFeed: {
		id: 'GUID!',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	RssFeedInput: {
		properties: 'JSONObject',
		type: 'RssFeedType!',
		url: 'URL',
	},

	SaveContainerSettingsInput: {
		applicationId: 'GUID!',
		campusId: 'GUID',
		cardDefinition: 'ContainerCardDefinitionInput',
		containerId: 'GUID!',
		givingLink: 'URL',
		icalFeed: 'IcalFeedInput',
		icon: 'String',
		imageUrl: 'URL',
		name: 'String!',
		navigationAction: 'NavigationAction',
		parentContainerId: 'GUID!',
		relatedContent: 'RelatedContentInput!',
		resiLink: 'URL',
		rssFeed: 'RssFeedInput',
		subtitle: 'String',
		template: 'ContainerTemplate!',
		type: 'ContainerType!',
		typeSpecificProperties: 'ContainerTypeSpecificPropertyInput',
		vimeoFeed: 'VimeoFeedInput',
		youtubeFeed: 'YouTubeFeedInput',
	},

	SaveContainerSettingsResult: {
		updatedContainer: 'Container!',
	},

	SaveItemCardDefinitionInput: {
		callToActionText: 'CallToActionType',
		cardType: 'CardType!',
		featuredLabel: 'String',
		genericContent: 'CardDefinitionGenericContentInput',
		isFeatured: 'Boolean',
		showProperties: 'ShowPropertyInput!',
	},

	SaveItemSettingsInput: {
		applicationId: 'GUID!',
		cardDefinition: 'SaveItemCardDefinitionInput',
		icon: 'String',
		imageUrl: 'URL',
		itemId: 'GUID!',
		name: 'String!',
		parentContainerId: 'GUID!',
		properties: '[ItemPropertyInput!]!',
		template: 'ItemTemplate!',
		type: 'ItemType!',
	},

	SaveItemSettingsResult: {
		updatedItem: 'Item!',
	},

	ShareProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		sharedProperties: '[ItemProperty!]!',
		type: 'PropertyType!',
	},

	SharePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		sharedProperties: '[GUID]',
	},

	ShellCapability: {
		isSupported: 'Boolean!',
		key: 'ShellCapabilityKey!',
	},

	ShowPropertyInput: {
		category: 'Boolean',
		keyMetrics: 'Boolean',
		publishedTime: 'Boolean',
		title: 'Boolean',
	},

	SmsProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		androidAppUrl: 'String',
		androidDefaultUrl: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		iosAppUrl: 'String',
		iosDefaultUrl: 'String',
		itemId: 'GUID!',
		messageBody: 'String',
		messageLabel: 'String',
		phoneNumber: 'String',
		position: 'Int!',
		type: 'PropertyType!',
	},

	SmsPropertyInput: {
		androidAppUrl: 'String',
		androidDefaultUrl: 'String',
		baseProperty: 'BasePropertyInput!',
		iosAppUrl: 'String',
		iosDefaultUrl: 'String',
		messageBody: 'String',
		messageLabel: 'String',
		phoneNumber: 'String',
	},

	SpecialCard: {
		callToActionText: 'CallToActionType',
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
	},

	StudioContainerTypeProperties: {
		ctaLabel: 'String!',
		description: 'String',
		link: 'URL',
		title: 'String!',
	},

	TextHtmlProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		text: 'String',
		textHtml: 'String',
		type: 'PropertyType!',
	},

	TextHtmlPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		text: 'String',
		textHtml: 'String',
	},

	TextProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		textDescription: 'String',
		type: 'PropertyType!',
	},

	TextPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		textDescription: 'String',
	},

	TimeZone: {
		olsonName: 'String!',
		windowsName: 'String!',
	},

	Timeframe: {
		allDay: 'Boolean!',
		endTime: 'Instant',
		startTime: 'Instant',
	},

	TimeframeProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		timeframe: 'Timeframe!',
		type: 'PropertyType!',
	},

	TimeframePropertyInput: {
		allDay: 'Boolean!',
		baseProperty: 'BasePropertyInput!',
		endTime: 'Instant',
		startTime: 'Instant',
	},

	UpdateContainerVisibilityInput: {
		applicationId: 'GUID!',
		containerId: 'GUID!',
		isHidden: 'Boolean!',
	},

	UpdateContainerVisibilityResult: {
		updatedContainer: 'Container!',
	},

	UpdateItemVisibilityInput: {
		applicationId: 'GUID!',
		containerId: 'GUID!',
		isHidden: 'Boolean!',
		itemId: 'GUID!',
	},

	UpdateItemVisibilityResult: {
		updatedItem: 'Item!',
	},

	UpdateProfileInput: {
		address: 'AddressArgumentsInput',
		birthday: 'BirthdayArgumentsInput',
		commandId: 'String!',
		emailAddresses: '[String]',
		firstName: 'String',
		identityKey: 'Key!',
		lastName: 'String',
		timeZone: 'OlsonTimeZone',
		userContext: 'UserContextDataInput!',
	},

	UpdateProfileResult: {
		identity: 'Identity',
		success: 'Boolean!',
	},

	UserContextDataInput: {
		browser: 'BrowserDataInput',
		ipAddress: 'String!',
		olsonTimeZone: 'OlsonTimeZone!',
	},

	UserNoteProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		hintText: 'String',
		icon: 'String',
		id: 'GUID!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
	},

	UserNotePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		hintText: 'String',
	},

	VideoCard: {
		category: 'String!',
		featured: 'FeaturedCard',
		id: 'GUID!',
		item: 'Item!',
		publishedTime: 'Int!',
		subtitle: 'String!',
		summary: 'String!',
		tabName: 'String!',
		title: 'String!',
		videoUrl: 'String!',
	},

	VideoFeed: {
		id: 'GUID!',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	VideoProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		description: 'String',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		isEmbed: 'Boolean!',
		itemId: 'GUID!',
		position: 'Int!',
		type: 'PropertyType!',
		url: 'String',
	},

	VideoPropertyInput: {
		baseProperty: 'BasePropertyInput!',
		description: 'String',
		isEmbed: 'Boolean',
		url: 'String',
	},

	VimeoFeed: {
		id: 'GUID!',
		identifiers: 'VimeoFeedIdentifiers',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	VimeoFeedIdentifiers: {
		channelId: 'String',
		groupId: 'String',
		showcaseId: 'String',
		type: 'VimeoIdentifierType!',
		userId: 'String',
	},

	VimeoFeedInput: {
		channelId: 'String',
		groupId: 'String',
		identifier: 'VimeoIdentifierType!',
		properties: 'JSONObject',
		showcaseId: 'String',
		userId: 'String',
	},

	WebsiteProperty: {
		action: 'ItemPropertyAction',
		actionBar: 'ActionBar',
		header: 'String',
		hidden: 'Boolean!',
		icon: 'String',
		id: 'GUID!',
		isExternal: 'Boolean!',
		itemId: 'GUID!',
		label: 'String',
		position: 'Int!',
		type: 'PropertyType!',
		url: 'String',
	},

	WebsitePropertyInput: {
		baseProperty: 'BasePropertyInput!',
		isExternal: 'Boolean!',
		label: 'String',
		url: 'String',
	},

	YouTubeFeed: {
		id: 'GUID!',
		processState: 'FeedProcessState!',
		properties: 'FeedProperty',
		url: 'URL',
	},

	YouTubeFeedInput: {
		properties: 'JSONObject',
		url: 'URL',
	},
};
